<template>
  <div class="areaSelect">
    <!-- <el-cascader
      size="large"
      :options="addressOptions"
      v-model="selectedOptions"
      @change="handleChange"
      filterable
    >
    </el-cascader> -->

    <el-select
      v-model="provinceId"
      filterable
      placeholder="请选择"
      @change="getCityList"
      style="width: 150px;margin-right: 10px;"
    >
      <el-option
        v-for="item in provinceList"
        :key="item.id"
        :label="item.name_cn"
        :value="item.id"
      >
      </el-option>
    </el-select>
    <el-select
      v-model="cityId"
      filterable
      placeholder="请选择"
      @change="getAreaList"
      style="width: 150px;margin-right: 10px;"
    >
      <el-option
        v-for="item in cityList"
        :key="item.id"
        :label="item.name_cn"
        :value="item.id"
      >
      </el-option>
    </el-select>
    <el-select
      v-model="areaId"
      filterable
      placeholder="请选择"
      style="width: 150px;"
      @change="submitArea"
    >
      <el-option
        v-for="item in areaList"
        :key="item.id"
        :label="item.name_cn"
        :value="item.id"
      >
      </el-option>
    </el-select>
  </div>
</template>
<script>
// import { regionData, codeToText } from "element-china-area-data";
import { getArea } from "@/api/login";
export default {
  components: {},
  props: {
    // 回显的数据
    defVal: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // addressOptions: regionData,
      // selectedOptions: {},
      // addtions: {},
      // // 需要回显的数据  格式：省/市/区(县)
      // defAreaData: "",

      provinceId: "",
      provinceList: [],

      cityId: "",
      cityList: [],

      areaId: "",
      areaList: [],
    };
  },
  watch: {
    defVal: {
      handler(val) {
        if (val.provinceId && val.cityId && val.areaId) {
          this.provinceId = +val.provinceId;
          this.getCityList(val.provinceId);
          this.cityId = +val.cityId;
          this.getAreaList(val.cityId);
          this.areaId = +val.areaId;
        } else {
          this.provinceId = "";

          this.cityId = "";
          this.cityList = [];

          this.areaId = "";
          this.areaList = [];
        }
      },
      deep: true,
    },
  },
  created() {
    this.getProvinceList();
  },
  mounted() {},
  methods: {
    submitArea() {
      this.$emit("submitArea", {
        provinceId: this.provinceId,
        cityId: this.cityId,
        areaId: this.areaId,
      });
    },

    getProvinceList() {
      let pdata = {
        pid: 0,
      };
      getArea(pdata).then((res) => {
        this.provinceList = res.data;
      });
    },
    getCityList(pid) {
      this.cityId = null;
      this.areaId = null;
      let pdata = {
        pid: pid,
      };
      getArea(pdata).then((res) => {
        this.cityList = res.data;
      });
    },
    getAreaList(pid) {
      this.areaId = null;
      let pdata = {
        pid: pid,
      };
      getArea(pdata).then((res) => {
        this.areaList = res.data;
      });
    },
    // handleShowData() {
    //   let arr = this.defAreaData.split("/");
    //   // 储存地区码code -----
    //   let areaCode = [];
    //   regionData.forEach((e) => {
    //     if (e.label == arr[0]) {
    //       // 省
    //       areaCode.push(e.value);
    //       e.children.forEach((el) => {
    //         if (el.label == arr[1]) {
    //           // 市
    //           areaCode.push(el.value);
    //           el.children.forEach((element) => {
    //             if (element.label == arr[2]) {
    //               // 县
    //               areaCode.push(element.value);
    //             }
    //           });
    //         }
    //       });
    //     }
    //   });
    //   // 这里的赋值就是回显的数据，在上面处理了，但是当我拿出三个嵌套，阁下又该如何应对
    //   this.selectedOptions = areaCode;
    // },
    // handleChange(value) {
    //   //我们选择地址后，selectedOptions 会保存对应的区域码，例如北京的区域码为'110000'
    //   //我们要取出区域码对应的汉字，就需要用CodeToText(区域码)进行输出
    //   this.addtions.selectedOptions = value;
    //   let name = "";
    //   // 通过区域码 拿到 对应的汉字
    //   this.selectedOptions.map((item) => (name += codeToText[item] + "/"));
    //   let areaArr = name.split("/");
    //   let areaObj = {
    //     province: areaArr[0],
    //     city: areaArr[1],
    //     area: areaArr[2],
    //   };
    //   // 抛出数据 格式:{ province:省， city:市， area:区(县)}
    //   this.$emit("onAreaChange", areaObj);
    // },
  },
};
</script>
<style scoped lang="less"></style>
