<template>
  <div class="main" :style="$bgImg('sihua_bg.png')">
    <div class="container">
      <div class="form">
        <div class="breadcrumb">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/company/solution' }"
              >解决方案</el-breadcrumb-item
            >
            <el-breadcrumb-item>内容</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="form-header">
          <div class="form-header-title">
            <span class="text">基本信息</span>
          </div>
          <div class="form-body">
            <el-form
              ref="BasicForm"
              style="width: 68%;"
              :rules="BasicFormRule"
              :model="BasicForm"
              label-width="150px"
              label-position="left"
            >
              <el-form-item label="方案名称" prop="product_name">
                <el-input
                  v-model="BasicForm.product_name"
                  style="width: 68%;"
                ></el-input>
              </el-form-item>
              <el-form-item label="方案简介" prop="content">
                <el-input
                  v-model="BasicForm.content"
                  style="width: 68%;"
                ></el-input>
              </el-form-item>
              <el-form-item label="企业名称" prop="qi_name">
                <el-input
                  v-model="BasicForm.qi_name"
                  disabled
                  style="width: 68%;"
                ></el-input>
              </el-form-item>
              <el-form-item label="发布时间" prop="e_time">
                <el-date-picker
                  type="date"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd"
                  placeholder="选择时间"
                  v-model="BasicForm.e_time"
                  style="width: 68%;"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item
                label="适用行业"
                prop="suitability_hy"
                class="is-required"
              >
                <el-input
                  v-model="descForm.suitability_hy"
                  style="width: 68%;"
                ></el-input>
              </el-form-item>
              <!-- <el-form-item label="数字化转型领域简述" prop="digitalize_desc">
                <el-input
                  v-model="descForm.digitalize_desc"
                  type="textarea"
                  :rows="2"
                ></el-input>
              </el-form-item>
              <el-form-item label="网络化协同简述" prop="network_desc">
                <el-input
                  v-model="descForm.network_desc"
                  type="textarea"
                  :rows="2"
                ></el-input>
              </el-form-item>
              <el-form-item label="智能化制造简述" prop="intellect_desc">
                <el-input
                  v-model="descForm.intellect_desc"
                  type="textarea"
                  :rows="2"
                ></el-input>
              </el-form-item>
              <el-form-item label="绿色化提升简述" prop="greenization_desc">
                <el-input
                  v-model="descForm.greenization_desc"
                  type="textarea"
                  :rows="2"
                ></el-input>
              </el-form-item>
              <el-form-item label="其他说明事项" prop="other">
                <el-input
                  v-model="descForm.other"
                  type="textarea"
                  :rows="2"
                ></el-input>
              </el-form-item> -->
            </el-form>
          </div>
          <div class="form-header-title">
            <span class="text">对应领域</span>
          </div>
          <div class="form-body">
            <el-form
              ref="descForm"
              style="width: 68%;"
              :model="descForm"
              label-width="150px"
              label-position="left"
            >
              <el-form-item label="数字化转型领域简述" prop="digitalize_desc">
                <el-input
                  v-model="descForm.digitalize_desc"
                  type="textarea"
                  :rows="2"
                  :maxlength="500"
                  :show-word-limit="true"
                ></el-input>
              </el-form-item>
              <el-form-item label="网络化协同简述" prop="network_desc">
                <el-input
                  v-model="descForm.network_desc"
                  type="textarea"
                  :rows="2"
                  :maxlength="500"
                  :show-word-limit="true"
                ></el-input>
              </el-form-item>
              <el-form-item label="智能化制造简述" prop="intellect_desc">
                <el-input
                  v-model="descForm.intellect_desc"
                  type="textarea"
                  :rows="2"
                  :maxlength="500"
                  :show-word-limit="true"
                ></el-input>
              </el-form-item>
              <el-form-item label="绿色化提升简述" prop="greenization_desc">
                <el-input
                  v-model="descForm.greenization_desc"
                  type="textarea"
                  :rows="2"
                  :maxlength="500"
                  :show-word-limit="true"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div class="form-header-title">
            <span class="text">其他说明</span>
          </div>
          <div class="form-body">
            <el-form
              ref="descForm"
              style="width: 68%;"
              :model="descForm"
              label-width="150px"
              label-position="left"
            >
              <el-form-item label="其他说明事项" prop="other">
                <el-input
                  v-model="descForm.other"
                  type="textarea"
                  :rows="2"
                  :maxlength="500"
                  :show-word-limit="true"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div class="form-header-title">
            <span class="text">方案介绍</span>
          </div>
          <div class="form-body">
            <el-form
              label-position="left"
              :model="dynamicValidateForm"
              ref="dynamicValidateForm"
              label-width="112px"
              class="inline-dynamic"
            >
              <div
                class="form-body-cont flex"
                v-for="(domain, index) in dynamicValidateForm.domains"
                :key="index"
              >
                <div class="itemleft">
                  <el-form-item
                    label="图片"
                    :prop="'domains.' + index + '.img'"
                    :rules="{
                      required: true,
                      message: '图片不能为空',
                      trigger: 'change',
                    }"
                  >
                    <TUpupload
                      :TU_lifils="domain.domainsImg"
                      :allownum="1"
                      @tufile-sent="handleDynamicImg(index, $event)"
                    ></TUpupload>
                  </el-form-item>
                </div>
                <div class="itemright">
                  <el-form-item
                    label="介绍标题"
                    :prop="'domains.' + index + '.title'"
                    :rules="{
                      required: true,
                      message: '标题不能为空',
                      trigger: 'blur',
                    }"
                  >
                    <el-input
                      v-model="domain.title"
                      :maxlength="120"
                      :show-word-limit="true"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item
                    label="介绍内容"
                    :prop="'domains.' + index + '.cont'"
                    :rules="{
                      required: true,
                      message: '内容不能为空',
                      trigger: 'blur',
                    }"
                  >
                    <el-input
                      type="textarea"
                      v-model="domain.cont"
                      :maxlength="250"
                      :rows="6"
                      :show-word-limit="true"
                    >
                    </el-input>
                  </el-form-item>
                </div>
                <div class="additem flex column horizontally vertical pointer">
                  <span
                    class="flex column horizontally vertical pointer"
                    @click="addDynamic"
                    v-show="index == dynamicValidateForm.domains.length - 1"
                  >
                    <img
                      class="addimg"
                      :src="'additem.png' | staticMedia"
                      alt=""
                    />
                    <p class="addtip">添加更多数据</p>
                  </span>
                </div>
                <div
                  class="delBtn pointer"
                  v-if="
                    index == dynamicValidateForm.domains.length - 1 &&
                      dynamicValidateForm.domains.length > 1
                  "
                  @click="delDomain(index)"
                >
                  删除
                </div>
                <div
                  v-if="
                    dynamicValidateForm.domains.length > 1 &&
                      index != dynamicValidateForm.domains.length - 1
                  "
                ></div>
              </div>
            </el-form>
          </div>
          <div class="form-header-title">
            <span class="text">方案特点</span>
          </div>
          <div class="form-body">
            <el-form
              label-position="left"
              :model="characteristicForm"
              ref="characteristicForm"
              label-width="112px"
              class="inline-dynamic"
            >
              <div
                class="form-body-cont flex"
                v-for="(domain, index) in characteristicForm.domains"
                :key="index"
              >
                <div class="itemleft">
                  <el-form-item
                    label="图片"
                    :prop="'domains.' + index + '.img'"
                    :rules="{
                      required: true,
                      message: '图片不能为空',
                      trigger: 'change',
                    }"
                  >
                    <TUpupload
                      :TU_lifils="domain.domainsImg"
                      :allownum="1"
                      @tufile-sent="handleCharacteristicImg(index, $event)"
                    ></TUpupload>
                  </el-form-item>
                </div>
                <div class="itemright">
                  <el-form-item
                    label="介绍标题"
                    :prop="'domains.' + index + '.title'"
                    :rules="{
                      required: true,
                      message: '标题不能为空',
                      trigger: 'blur',
                    }"
                  >
                    <el-input
                      v-model="domain.title"
                      :maxlength="120"
                      :show-word-limit="true"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item
                    label="介绍内容"
                    :prop="'domains.' + index + '.cont'"
                    :rules="{
                      required: true,
                      message: '内容不能为空',
                      trigger: 'blur',
                    }"
                  >
                    <el-input
                      type="textarea"
                      v-model="domain.cont"
                      :maxlength="250"
                      :rows="6"
                      :show-word-limit="true"
                    >
                    </el-input>
                  </el-form-item>
                </div>
                <div class="additem flex horizontally vertical">
                  <span
                    class="flex column horizontally vertical pointer"
                    @click="addCharac"
                    v-show="index == characteristicForm.domains.length - 1"
                  >
                    <img
                      class="addimg"
                      :src="'additem.png' | staticMedia"
                      alt=""
                    />
                    <p class="addtip">添加更多数据</p>
                  </span>
                </div>
                <div
                  class="delBtn pointer"
                  v-if="
                    index == characteristicForm.domains.length - 1 &&
                      characteristicForm.domains.length > 1
                  "
                  @click="delCharac(index)"
                >
                  删除
                </div>
                <div
                  v-if="
                    characteristicForm.domains.length > 1 &&
                      index != characteristicForm.domains.length - 1
                  "
                ></div>
              </div>
            </el-form>
          </div>
          <div class="form-header-title">
            <span class="text">方案价值</span>
          </div>
          <div class="form-body">
            <el-form
              :model="ValueForm"
              label-position="left"
              ref="ValueForm"
              label-width="122px"
            >
              <div class="the_Value flex bewteen">
                <div
                  class="valueli"
                  v-for="(domain, index) in ValueForm.domains"
                  :key="index"
                >
                  <el-form-item
                    :label="domain.label1"
                    :prop="'domains.' + index + '.value'"
                    :rules="{
                      required: true,
                      message: '标题不能为空',
                      trigger: 'blur',
                    }"
                  >
                    <el-input
                      placeholder="请输入方案价值标题"
                      v-model="domain.value"
                      :maxlength="120"
                      :show-word-limit="true"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    :label="domain.label2"
                    :prop="'domains.' + index + '.cont'"
                    :rules="{
                      required: true,
                      message: '内容不能为空',
                      trigger: 'blur',
                    }"
                  >
                    <el-input
                      type="textarea"
                      :maxlength="200"
                      :show-word-limit="true"
                      :rows="6"
                      placeholder="请输入方案价值介绍"
                      v-model="domain.cont"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
            </el-form>
          </div>
          <div class="form-header-title">
            <span class="text">核心优势</span>
          </div>
          <div class="form-body">
            <el-form
              :model="advantagesForm"
              label-position="left"
              ref="advantagesForm"
              label-width="122px"
            >
              <div class="the_Value flex bewteen">
                <div
                  class="advantages"
                  v-for="(domain, index) in advantagesForm.domains"
                  :key="index"
                >
                  <el-form-item
                    :label="domain.label1"
                    :prop="'domains.' + index + '.value'"
                    :rules="{
                      required: true,
                      message: '标题不能为空',
                      trigger: 'blur',
                    }"
                  >
                    <el-input
                      placeholder="请输入方案优势标题"
                      v-model="domain.value"
                      :maxlength="120"
                      :show-word-limit="true"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    :label="domain.label2"
                    :prop="'domains.' + index + '.cont'"
                    :rules="{
                      required: true,
                      message: '内容不能为空',
                      trigger: 'blur',
                    }"
                  >
                    <el-input
                      type="textarea"
                      :maxlength="200"
                      :show-word-limit="true"
                      :rows="6"
                      placeholder="请输入方案优势介绍"
                      v-model="domain.cont"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
            </el-form>
          </div>
          <div class="form-header-title">
            <span class="text">选择推荐案例</span>
          </div>
          <div class="form-body" style="width: 48%;">
            <el-form
              :model="AnliForm"
              label-position="left"
              ref="AnliForm"
              label-width="122px"
            >
              <el-form-item label="推荐案例" prop="value">
                <el-select
                  style="width:100%"
                  v-model="AnliForm.value"
                  multiple
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in AnliForm.options"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label-width="0">
                <el-checkbox v-model="isAgreement"
                  >本人承诺：认真遵守国家保密法律、法规和规章制度，内容不涉及国家秘密、商业秘密、个人隐私和其他敏感信息，自愿接受保密审查。如有违反，自愿承担相应责任和后果。</el-checkbox
                >
              </el-form-item>
            </el-form>
          </div>
          <div class="form-body">
            <el-form label-position="left" label-width="122px">
              <el-form-item>
                <el-button
                  type="primary"
                  class="primaryBtn1"
                  @click="handleSubmit"
                  >提交</el-button
                >
                <el-button
                  type="primary"
                  class="primaryBtn1"
                  @click="handleGetDraft"
                  >获取草稿</el-button
                >
                <el-button
                  type="primary"
                  class="primaryBtn1"
                  @click="handleSaveDraft"
                  >保存草稿</el-button
                >
                <el-button class="cancleBtn1" @click="handleCancle"
                  >取消</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TUpupload from "@/components/TUpupload.vue";
import {
  getAnLiList,
  // getCompanyInfo,
  addSolution,
  editSolution,
  getSolution,
  saveDraft,
  getDraft,
} from "@/api/pts";
export default {
  components: {
    TUpupload,
  },
  data() {
    return {
      isAdd: false,

      BasicForm: {
        product_name: "",
        content: "",
        qi_name: "",
        e_time: "",
      },

      descForm: {
        suitability_hy: "",
        digitalize_desc: "",
        network_desc: "",
        intellect_desc: "",
        greenization_desc: "",
        other: "",
      },

      BasicFormRule: {
        product_name: [
          { required: true, message: "方案名称不能为空", trigger: "blur" },
        ],
        content: [
          { required: true, message: "方案简介不能为空", trigger: "blur" },
        ],
      },

      dynamicValidateForm: {
        domains: [
          {
            domainsImg: [],
            title: "",
            cont: "",
            img: "",
          },
        ],
      },
      characteristicForm: {
        domains: [
          {
            domainsImg: [],
            title: "",
            cont: "",
            img: "",
          },
        ],
      },
      ValueForm: {
        domains: [
          {
            value: "",
            cont: "",
            label1: "价值标题一",
            label2: "价值内容一",
          },
          {
            value: "",
            cont: "",
            label1: "价值标题二",
            label2: "价值内容二",
          },
          {
            value: "",
            cont: "",
            label1: "价值标题三",
            label2: "价值内容三",
          },
        ],
      },
      advantagesForm: {
        domains: [
          {
            value: "",
            cont: "",
            label1: "优势标题一",
            label2: "优势内容一",
          },
          {
            value: "",
            cont: "",
            label1: "优势标题二",
            label2: "优势内容二",
          },
          {
            value: "",
            cont: "",
            label1: "优势标题三",
            label2: "优势内容三",
          },
          {
            value: "",
            cont: "",
            label1: "优势标题四",
            label2: "优势内容四",
          },
        ],
      },
      AnliForm: {
        options: [],
        value: [],
      },

      isAgreement: false,
    };
  },
  created() {},
  mounted() {
    this.getAnLiList();
    this.getCompanyInfo();
    console.log("this.asfor", this.advantagesForm);

    this.isAdd = this.$route.query.type;
    if (this.$route.query.id) {
      this.getSolution();
    }
  },
  methods: {
    handleGetDraft() {
      let _this = this;
      let pdata = {
        type: 1,
      };

      // reset一下
      _this.BasicForm = {
        product_name: "",
        content: "",
        qi_name: JSON.parse(sessionStorage.getItem("ptsInfo")).name,
        e_time: "",
      };

      _this.dynamicValidateForm = {
        domains: [
          {
            domainsImg: [],
            title: "",
            cont: "",
            img: "",
          },
        ],
      };
      (_this.characteristicForm = {
        domains: [
          {
            domainsImg: [],
            title: "",
            cont: "",
            img: "",
          },
        ],
      }),
        (_this.ValueForm = {
          domains: [
            {
              value: "",
              cont: "",
              label1: "价值标题一",
              label2: "价值内容一",
            },
            {
              value: "",
              cont: "",
              label1: "价值标题二",
              label2: "价值内容二",
            },
            {
              value: "",
              cont: "",
              label1: "价值标题三",
              label2: "价值内容三",
            },
          ],
        }),
        (_this.advantagesForm = {
          domains: [
            {
              value: "",
              cont: "",
              label1: "优势标题一",
              label2: "优势内容一",
            },
            {
              value: "",
              cont: "",
              label1: "优势标题二",
              label2: "优势内容二",
            },
            {
              value: "",
              cont: "",
              label1: "优势标题三",
              label2: "优势内容三",
            },
            {
              value: "",
              cont: "",
              label1: "优势标题四",
              label2: "优势内容四",
            },
          ],
        }),
        getDraft(pdata).then((res) => {
          if (res.code == 1) {
            _this.$message.success("获取草稿成功");
            let data = JSON.parse(res.data.content);

            // console.log("data========================", data);

            // _this.BasicForm.product_name = JSON.parse(
            //   sessionStorage.getItem("ptsInfo")
            // ).name;
            _this.BasicForm.product_name = data.product_name;
            _this.BasicForm.content = data.content;
            _this.BasicForm.e_time = data.e_time;
            _this.dynamicValidateForm.domains = data.desc;
            data.desc.forEach((item, index) => {
              _this.dynamicValidateForm.domains[index].domainsImg = [
                { url: item.img },
              ];
            });
            _this.characteristicForm.domains = data.features;
            data.features.forEach((item, index) => {
              _this.characteristicForm.domains[index].domainsImg = [
                { url: item.img },
              ];
            });
            _this.ValueForm.domains = data.value;
            _this.advantagesForm.domains = data.advantage;
            if (data.case_ids) {
              var arr = data.case_ids.split(",");
              arr.forEach((item) => {
                _this.AnliForm.value.push(Number(item));
              });
            }

            _this.descForm.suitability_hy = data.suitability_hy;
            _this.descForm.digitalize_desc = data.digitalize_desc;
            _this.descForm.network_desc = data.network_desc;
            _this.descForm.intellect_desc = data.intellect_desc;
            _this.descForm.greenization_desc = data.greenization_desc;
            _this.descForm.other = data.other;
          } else {
            _this.$message.error("获取草稿失败");
          }
        });
    },
    handleSaveDraft() {
      let _this = this;
      let data = {
        case_ids: _this.AnliForm.value.join(","),
        // image: _this.companyInfo.image,
        product_name: _this.BasicForm.product_name,
        content: _this.BasicForm.content,
        // qi_name: _this.BasicForm.qi_name,
        e_time: _this.BasicForm.e_time,
        desc: _this.dynamicValidateForm.domains,
        features: _this.characteristicForm.domains,
        value: _this.ValueForm.domains,
        advantage: _this.advantagesForm.domains,
        suitability_hy: _this.descForm.suitability_hy,
        digitalize_desc: _this.descForm.digitalize_desc,
        network_desc: _this.descForm.network_desc,
        intellect_desc: _this.descForm.intellect_desc,
        greenization_desc: _this.descForm.greenization_desc,
        other: _this.descForm.other,
      };

      let pdata = {
        type: 1,
        content: JSON.stringify(data),
      };

      saveDraft(pdata).then((res) => {
        if (res.code == 1) {
          _this.$message.success("保存草稿成功");
        } else {
          _this.$message.error("保存草稿失败");
        }
      });
    },
    getSolution() {
      let _this = this;
      let pdata = {
        id: _this.$route.query.id,
      };
      getSolution(pdata).then((res) => {
        _this.BasicForm.product_name = res.data.product_name;
        _this.BasicForm.content = res.data.content;
        _this.BasicForm.e_time = res.data.e_time;
        _this.dynamicValidateForm.domains = JSON.parse(res.data.desc);
        JSON.parse(res.data.desc).forEach((el, index) => {
          _this.dynamicValidateForm.domains[index].domainsImg = [
            { url: el.img },
          ];
        });
        _this.characteristicForm.domains = JSON.parse(res.data.features);
        JSON.parse(res.data.features).forEach((el, index) => {
          _this.characteristicForm.domains[index].domainsImg = [
            { url: el.img },
          ];
        });
        _this.ValueForm.domains = JSON.parse(res.data.value);
        if (res.data.advantage) {
          _this.advantagesForm.domains = JSON.parse(res.data.advantage);
        }
        if (res.data.case_ids) {
          let arr = res.data.case_ids.split(",");
          arr.forEach((item) => {
            _this.AnliForm.value.push(Number(item));
          });
        } else {
          _this.AnliForm.value = [];
        }
        _this.descForm.suitability_hy = res.data.suitability_hy;
        _this.descForm.digitalize_desc = res.data.digitalize_desc;
        _this.descForm.network_desc = res.data.network_desc;
        _this.descForm.intellect_desc = res.data.intellect_desc;
        _this.descForm.greenization_desc = res.data.greenization_desc;
        _this.descForm.other = res.data.other;
      });
    },
    handleSubmit() {
      if (!this.descForm.suitability_hy) {
        this.$message.error("适用行业不能为空");
        return false;
      }
      if (!this.isAgreement) {
        this.$confirm(
          "是否承诺认真遵守国家保密法律、法规和规章制度，内容不涉及国家秘密、商业秘密、个人隐私和其他敏感信息，自愿接受保密审查？",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            this.isAgreement = true;
            this.$refs["BasicForm"].validate((valid) => {
              if (valid) {
                this.$refs["dynamicValidateForm"].validate((valid) => {
                  if (valid) {
                    this.$refs["characteristicForm"].validate((valid) => {
                      if (valid) {
                        this.$refs["ValueForm"].validate((valid) => {
                          if (valid) {
                            this.$refs["advantagesForm"].validate((valid) => {
                              if (valid) {
                                let _this = this;
                                _this.dynamicValidateForm.domains.forEach(
                                  (el) => {
                                    delete el.domainsImg;
                                  }
                                );
                                _this.characteristicForm.domains.forEach(
                                  (el) => {
                                    delete el.domainsImg;
                                  }
                                );
                                let pdata = {
                                  case_ids: this.AnliForm.value.join(","),
                                  // image: _this.companyInfo.image,
                                  product_name: _this.BasicForm.product_name,
                                  content: _this.BasicForm.content,
                                  qi_name: _this.BasicForm.qi_name,
                                  e_time: _this.BasicForm.e_time,
                                  desc: JSON.stringify(
                                    _this.dynamicValidateForm.domains
                                  ),
                                  features: JSON.stringify(
                                    _this.characteristicForm.domains
                                  ),
                                  value: JSON.stringify(
                                    _this.ValueForm.domains
                                  ),
                                  advantage: JSON.stringify(
                                    _this.advantagesForm.domains
                                  ),
                                  suitability_hy: _this.descForm.suitability_hy,
                                  digitalize_desc:
                                    _this.descForm.digitalize_desc,
                                  network_desc: _this.descForm.network_desc,
                                  intellect_desc: _this.descForm.intellect_desc,
                                  greenization_desc:
                                    _this.descForm.greenization_desc,
                                  other: _this.descForm.other,
                                };
                                if (_this.isAdd == "true") {
                                  addSolution(pdata)
                                    .then((res) => {
                                      console.log("res", res);
                                      if (res.code == 1) {
                                        _this.$message.success("新增成功");
                                      } else {
                                        _this.$message.error("新增失败");
                                      }
                                    })
                                    .catch((err) => {
                                      console.log("err", err);
                                    })
                                    .finally(() => {
                                      _this.$router.push({
                                        path: "/company/solution",
                                      });
                                    });
                                } else {
                                  pdata.id = _this.$route.query.id;
                                  editSolution(pdata)
                                    .then((res) => {
                                      console.log("res", res);
                                      if (res.code == 1) {
                                        _this.$message.success("编辑成功");
                                      } else {
                                        _this.$message.error("编辑失败");
                                      }
                                    })
                                    .catch((err) => {
                                      console.log("err", err);
                                    })
                                    .finally(() => {
                                      _this.$router.push({
                                        path: "/company/solution",
                                      });
                                    });
                                }
                              }
                            });
                          }
                        });
                      }
                    });
                  }
                });
              }
            });
          })
          .catch(() => {
            this.isAgreement = false;
            return false;
          });
      } else {
        this.$refs["BasicForm"].validate((valid) => {
          if (valid) {
            this.$refs["dynamicValidateForm"].validate((valid) => {
              if (valid) {
                this.$refs["characteristicForm"].validate((valid) => {
                  if (valid) {
                    this.$refs["ValueForm"].validate((valid) => {
                      if (valid) {
                        this.$refs["advantagesForm"].validate((valid) => {
                          if (valid) {
                            let _this = this;
                            _this.dynamicValidateForm.domains.forEach((el) => {
                              delete el.domainsImg;
                            });
                            _this.characteristicForm.domains.forEach((el) => {
                              delete el.domainsImg;
                            });
                            let pdata = {
                              case_ids: this.AnliForm.value.join(","),
                              // image: _this.companyInfo.image,
                              product_name: _this.BasicForm.product_name,
                              content: _this.BasicForm.content,
                              qi_name: _this.BasicForm.qi_name,
                              e_time: _this.BasicForm.e_time,
                              desc: JSON.stringify(
                                _this.dynamicValidateForm.domains
                              ),
                              features: JSON.stringify(
                                _this.characteristicForm.domains
                              ),
                              value: JSON.stringify(_this.ValueForm.domains),
                              advantage: JSON.stringify(
                                _this.advantagesForm.domains
                              ),
                              suitability_hy: _this.descForm.suitability_hy,
                              digitalize_desc: _this.descForm.digitalize_desc,
                              network_desc: _this.descForm.network_desc,
                              intellect_desc: _this.descForm.intellect_desc,
                              greenization_desc:
                                _this.descForm.greenization_desc,
                              other: _this.descForm.other,
                            };
                            if (_this.isAdd == "true") {
                              addSolution(pdata)
                                .then((res) => {
                                  console.log("res", res);
                                  if (res.code == 1) {
                                    _this.$message.success("新增成功");
                                  } else {
                                    _this.$message.error("新增失败");
                                  }
                                })
                                .catch((err) => {
                                  console.log("err", err);
                                })
                                .finally(() => {
                                  _this.$router.push({
                                    path: "/company/solution",
                                  });
                                });
                            } else {
                              pdata.id = _this.$route.query.id;
                              editSolution(pdata)
                                .then((res) => {
                                  console.log("res", res);
                                  if (res.code == 1) {
                                    _this.$message.success("编辑成功");
                                  } else {
                                    _this.$message.error("编辑失败");
                                  }
                                })
                                .catch((err) => {
                                  console.log("err", err);
                                })
                                .finally(() => {
                                  _this.$router.push({
                                    path: "/company/solution",
                                  });
                                });
                            }
                          }
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }
    },
    handleCancle() {
      this.$router.go(-1);
    },
    getCompanyInfo() {
      let _this = this;
      // getCompanyInfo().then((res) => {
      //   _this.BasicForm.qi_name = res.data.name;
      //   // _this.companyInfo = res.data;
      // });
      _this.BasicForm.qi_name = JSON.parse(
        sessionStorage.getItem("ptsInfo")
      ).name;
    },
    getAnLiList() {
      let pdata = {
        page: 1,
      };
      getAnLiList(pdata).then((res) => {
        this.AnliForm.options = res.data.map((e) => {
          return {
            label: e.info.title,
            id: e.id,
          };
        });
      });
    },
    delDomain(index) {
      this.dynamicValidateForm.domains.splice(index, 1);
    },
    delCharac(index) {
      this.characteristicForm.domains.splice(index, 1);
    },
    addCharac() {
      if (this.characteristicForm.domains.length < 6) {
        this.characteristicForm.domains.push({
          title: "",
          cont: "",
          domainsImg: [],
          img: "",
        });
      } else {
        this.$message.error("最多添加6个方案特点");
      }
    },
    addDynamic() {
      if (this.dynamicValidateForm.domains.length < 6) {
        this.dynamicValidateForm.domains.push({
          title: "",
          cont: "",
          domainsImg: [],
          img: "",
        });
      } else {
        this.$message.error("最多添加6个方案介绍");
      }
    },
    handleDynamicImg(index, file) {
      console.log("handleDynamicImg", index, file);
      this.dynamicValidateForm.domains[index].img = file.url;
    },
    handleCharacteristicImg(index, file) {
      console.log("handleCharacteristicImg", index, file);
      this.characteristicForm.domains[index].img = file.url;
    },
  },
};
</script>
<style scoped lang="less">
::v-deep .el-checkbox__label {
  white-space: normal;
  word-break: break-all;
  vertical-align: text-top;
}

.el-button + .el-button {
  margin-left: 30px;
}

.container {
  position: relative;

  .form {
    width: 84%;
    margin: 0 auto;

    .form-header {
      background-color: #fff;
      border-radius: 10px;
      padding: 0 50px;
      box-sizing: border-box;

      .form-header-title {
        width: 100%;
        border-bottom: 1px solid #d8d8d8;
        margin-bottom: 20px;

        .text {
          display: inline-block;
          height: 72px;
          line-height: 72px;
          font-size: 18px;
          padding: 0 8px;
          margin-left: 8px;
          border-bottom: 3px solid #9bbef9;
          font-weight: 600;
        }
      }
    }

    .form-body {
      overflow: hidden;

      .form-body-cont {
        position: relative;

        .itemleft {
          width: 30%;
        }

        .itemright {
          width: 58%;
          padding-right: 80px;
        }

        .additem {
          width: calc(100% - 58% - 30%);

          .addimg {
            width: 26px;
            height: 26px;
            margin-bottom: 20px;
          }

          .addtip {
            font-size: 16px;
            color: #205dc3;
          }
        }

        .delBtn {
          position: absolute;
          right: 9%;
          width: 88px;
          height: 40px;
          line-height: 40px;
          background-color: #e2e2e2;
          color: #666;
          text-align: center;
          border-radius: 10px;
        }
      }

      .the_Value {
        .valueli {
          width: 30%;
        }

        .advantages {
          width: 48%;
        }
      }
    }
  }
}

::v-deep .avatar-uploader .avatar {
  width: 100% !important;
  height: fit-content;
}
</style>
