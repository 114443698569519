<template>
  <div class="main" :style="$bgImg('sihua_bg.png')">
    <div class="container">
      <div class="detail-body">
        <div class="breadcrumb">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/company/needinfo' }"
              >需求信息</el-breadcrumb-item
            >
            <el-breadcrumb-item>详情</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="content">
          <div class="content-header flex vertical">
            <div class="content-header-title line1">{{ info.name }}</div>
            <div class="content-header-time flex vertical">
              <img
                class="bottom-icon"
                :src="'/needinfo-time.png' | staticMedia"
                alt=""
              />
              <span class="line1">{{ formatDateTime(info.createtime) }}</span>
            </div>
          </div>
          <div class="content-body">
            <div class="content-body-item flex">
              <div class="content-body-item-title flex">
                <img
                  class="content-body-item-title-img"
                  :src="'/cname.png' | staticMedia"
                  alt=""
                />
                企业名称
              </div>
              <div class="content-body-item-container">
                {{ info.name }}
              </div>
            </div>
            <div class="content-body-item flex">
              <div class="content-body-item-title flex">
                <img
                  class="content-body-item-title-img"
                  :src="'/address.png' | staticMedia"
                  alt=""
                />
                企业地址
              </div>
              <div class="content-body-item-container">
                {{ info.address }}
              </div>
            </div>
            <div class="content-body-item flex">
              <div class="content-body-item-title flex">
                <img
                  class="content-body-item-title-img"
                  :src="'/phone.png' | staticMedia"
                  alt=""
                />
                联系方式
              </div>
              <div class="content-body-item-container">
                {{ info.mobile }}
              </div>
            </div>
            <div class="content-body-item  flex">
              <div class="content-body-item-title flex">
                <img
                  class="content-body-item-title-img"
                  :src="'/sshy.png' | staticMedia"
                  alt=""
                />
                所属行业
              </div>
              <div class="content-body-item-container">
                {{ info.industry }}
              </div>
            </div>
            <div class="content-body-item  flex">
              <div class="content-body-item-title flex">
                <img
                  class="content-body-item-title-img"
                  :src="'/ssly.png' | staticMedia"
                  alt=""
                />
                所属领域
              </div>
              <div class="content-body-item-container">
                {{ info.field }}
              </div>
            </div>
            <div class="content-body-item  flex">
              <div class="content-body-item-title flex">
                <img
                  class="content-body-item-title-img"
                  :src="'/xqms.png' | staticMedia"
                  alt=""
                />
                需求描述
              </div>
              <div
                class="content-body-item-container overflowH"
                v-html="info.xqms"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getNeedInfoDetail } from "@/api/pts";
import { formatDateTime } from "@/utils/common";
export default {
  data() {
    return {
      info: {},
    };
  },
  created() {},
  mounted() {
    this.getNeedInfoDetail();
  },
  methods: {
    formatDateTime(time) {
      return formatDateTime(time);
    },
    getNeedInfoDetail() {
      let _this = this;
      let pdata = {
        id: _this.$route.query.id,
      };
      getNeedInfoDetail(pdata).then((res) => {
        this.info = res.data;
      });
    },
  },
};
</script>
<style scoped lang="less">
.el-button + .el-button {
  margin-left: 30px;
}
.container {
  position: relative;
  .detail-body {
    width: 84%;
    margin: 0 auto;
    .content {
      background-color: #fff;
      padding: 0 60px 20px;
      border-radius: 10px;
      box-sizing: border-box;
      .content-header {
        height: 94px;
        border-bottom: 1px solid #d8d8d8;
        .content-header-title {
          font-size: 24px;
          font-weight: 600;
          color: #333;
          width: calc(100% - 160px);
        }
        .content-header-time {
          color: #666;
          font-size: 13px;
          margin-left: 10px;
          width: 160px;
          justify-content: flex-end;
          img {
            width: 26px;
            height: 26px;
          }
        }
      }
      .content-body {
        padding: 20px 0 0;
        .content-body-item {
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 0;
          }
          .content-body-item-title {
            width: 150px;
            line-height: 24px;
            color: #666;

            .content-body-item-title-img {
              width: 24px;
              height: 24px;
              margin-right: 8px;
            }
          }
          .content-body-item-container {
            width: calc(100% - 150px);
            line-height: 24px;
          }
        }
      }
    }
  }
}
</style>
