<template>
  <div class="four_step">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="150px"
      class="demo-ruleForm"
    >
      <el-form-item label="诊断报告" required style="margin-bottom: 0;">
        <p>允许格式：doc,docx,pdf,csv,xls,xlsx</p>
      </el-form-item>
      <el-form-item label="" label-width="0" class="mosulop" prop="report_file">
        <Moseupload
          ref="domref"
          :uploadlist="parentMessage"
          :allownum="1"
          @domfile-sent="handlefile"
          accept=".doc,.docx,.pdf,.csv,.xls,.xlsx"
        ></Moseupload>
        <!-- 代替form验证 -->
        <el-input v-model="ruleForm.report_file" v-if="false"></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { Get_poststep3 } from "@/api/pts";
import Moseupload from "@/components/moreupload";
export default {
  components: {
    Moseupload,
  },
  data() {
    return {
      ruleForm: {
        report_file: "",
      },
      rules: {
        report_file: [
          { required: true, message: "请上传诊断报告", trigger: "change" },
        ],
        // onefile: [
        //     { required: true, message: "请上传", trigger: "change" }
        // ],
      },
      parentMessage: [], //传文件数据进上传文件
    };
  },
  mounted() {},
  methods: {
    Getinfo(obj) {
      console.log(obj, "444444");
      if (obj.report_file) {
        var domayy = [];
        let obi = {
          url: obj.report_file.url,
          name: obj.report_file.filename,
          state: 1, //代表已经上传过
          id: obj.report_file.id,
          size: (obj.report_file.filesize / 1024).toFixed(1) + "kb",
          isdele: true,
          renewdata: this.timestampToTime(obj.report_file.uploadtime),
        };
        domayy.push(obi);
        this.parentMessage = domayy;
      }
      this.ruleForm = { ...obj };
    },
    handlefile(list) {
      console.log(list);
      if (list.length > 0) {
        var arr = [];
        list.forEach((item) => {
          arr.push(item.id);
        });

        console.log(this.arr, "-----------4");
        this.ruleForm.report_file = arr;
      } else {
        console.log("==============1");
        this.ruleForm.report_file = "";
      }
      console.log(this.ruleForm.report_file);
      this.$refs.ruleForm.validateField(["report_file"]); //单个校验
    },
    async submitForm(formName) {
      try {
        //调用表单的validate方法，执行表单校验
        await this.$refs[formName].validate();
      } catch (e) {
        //如果表单有报错，则进入catch，此时直接return不执行表单提交
        return false;
      }
      return true;
    },
    async Post_four() {
      console.log(this.ruleForm);
      let params = { ...this.ruleForm };
      if (Array.isArray(params.report_file)) {
        params.report_file = params.report_file.join(",");
      } else {
        params.report_file = params.report_file.id;
      }
      // params.report_file = params.report_file.id;

      console.log(params);
      try {
        await Get_poststep3(params);
      } catch (e) {
        return false;
      }
      return true;
    },
    timestampToTime(timestamp) {
      var date = new Date(timestamp * 1000);
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();

      let strDate = Y + M + D + h + m + s;
      return strDate;
    },
  },
};
</script>
<style lang="less" scoped>
.four_step {
  height: 500px;
  overflow: auto;
  padding-right: 20px;
  padding-bottom: 20px;
}
</style>
