<template>
  <div class="main" :style="$bgImg('sihua_bg.png')">
    <div class="container">
      <div class="breadcrumb" style="width: 84%;margin: 0 auto 30px;">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/company/diagnose' }"
            >四化诊断</el-breadcrumb-item
          >
          <el-breadcrumb-item>问卷内容</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="question-list">
        <div
          class="question-header flex column horizontally"
          :style="questionHeaderImg"
        >
          <h3 class="question-header-title">{{ title }}</h3>
          <div class="question-header-tips">
            为评估制造业企业“四化”水平，本评测表从基础要素、数字化转型、网络化协同、智能化制造、绿色化提升5个方面定位制造业企业现阶段“四化”转型的优势和短板。
          </div>
          <div class="question-header-tips">
            为准确、客观、全面地反映企业“四化”真实水平，建议由企业信息化部门会同研发、采购、生产、财务、市场等所有与“四化”相关的部门联合填写。评测表内容仅供诊断企业
          </div>
        </div>
        <div class="question-form">
          <el-form
            ref="infoForm"
            :model="infoForm"
            label-width="70px"
            label-position="left"
            size="mini"
            :rules="rules"
            :inline="true"
          >
            <el-form-item label="企业：" prop="company_id">
              <el-select
                v-model="infoForm.company_id"
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in myCompanyList"
                  :key="item.id"
                  :label="item.nickname"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="录入人：" prop="username">
              <el-input
                v-model="infoForm.username"
                placeholder="请输入"
                disabled
              ></el-input>
            </el-form-item>
          </el-form>

          <el-form
            ref="questionForm"
            label-width="40px"
            size="mini"
            class="question-form"
          >
            <div v-for="(item, index) in formList" :key="index">
              <h4 class="question-form-h4 first">
                {{ index + 1 }}、{{ item.name }}
              </h4>
              <div
                v-for="(sItem, sIndex) in item.s_cate"
                :key="index + '.' + sIndex"
                class="pl24"
              >
                <h5 class="question-form-h5 second">
                  {{ index + 1 }}.{{ sIndex + 1 }} {{ sItem.name }}
                </h5>
                <div
                  v-for="(tItem, tIndex) in sItem.ti_data"
                  :key="index + '.' + sIndex + '.' + tIndex"
                  class="pl24"
                >
                  <h5 class="question-form-h5 third">
                    {{ index + 1 }}.{{ sIndex + 1 }}.{{ tIndex + 1 }}
                    {{ tItem.name }}
                  </h5>
                  <!--is_type: {{ tItem.is_type }}-->
                  <!--单选题-->
                  <el-form-item v-if="tItem.is_type == '1'">
                    <el-radio-group v-model="tItem.answer" class="mt5">
                      <el-radio
                        v-for="(answer, aIndex) in tItem.content"
                        :key="aIndex"
                        :label="answer.key"
                        >{{ answer.key + ". " + answer.value }}</el-radio
                      >
                    </el-radio-group>
                  </el-form-item>
                  <!--多选题-->
                  <el-form-item v-if="tItem.is_type == '2'">
                    <el-checkbox-group v-model="tItem.answer">
                      <el-checkbox
                        v-for="(answer, aIndex) in tItem.content"
                        :key="aIndex"
                        :label="answer.key"
                        >{{ answer.key + ". " + answer.value }}</el-checkbox
                      >
                    </el-checkbox-group>
                  </el-form-item>
                  <!--判断题-->
                  <el-form-item v-if="tItem.is_type == '3'">
                    <el-switch v-model="tItem.answer"></el-switch>
                  </el-form-item>
                  <!--简单题-->
                  <el-form-item v-if="tItem.is_type == '4'">
                    <el-input
                      v-model="tItem.answer"
                      class="mt5"
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
            </div>
            <div class="question-form-btns flex horizontally">
              <el-button class="primaryBtn1 btn" @click="submit"
                >提交</el-button
              >
              <el-button class="cancleBtn1 btn" @click="cancleSubmit"
                >取消</el-button
              >
            </div>
          </el-form>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>
<script>
// import { setSHQuestion } from "@/api/qy";
import { getDiagnose_Wj, getMyCompany, setDiagnose_Wj } from "@/api/pts";
export default {
  components: {},
  data() {
    return {
      title: "制造业企业 “四化” 水平测评表",
      infoForm: {
        company_id: "",
        username: JSON.parse(sessionStorage.getItem("ptsInfo")).truename,
      },
      rules: {
        company_id: [
          { required: true, message: "请选择企业", trigger: "change" },
        ],
      },
      formList: [],

      myCompanyList: [],
    };
  },
  computed: {
    questionHeaderImg() {
      const filteredImageUrl = this.$options.filters.staticMedia(
        "/text_table_bg.png"
      );
      return {
        backgroundImage: `url(${filteredImageUrl})`,
      };
    },
  },
  created() {},
  mounted() {
    this.getRequsetData();
    this.getMyCompany();
  },
  methods: {
    submit() {
      if (!this.infoForm.company_id) {
        return this.$message("请选择企业");
      }

      let _this = this;
      const answer = _this.getAnswer();
      let form = _this.infoForm;
      console.log("this.", form, this.formList, answer);
      let loading = this.$loading({
        lock: true,
        text: "提交中",
        background: "rgba(0, 0, 0, 0.7)",
      });

      let pdata = {
        company_id: form.company_id || "",
        // company_id: this.$route.query.companyId || "",
        answer: JSON.stringify(answer),
      };

      setDiagnose_Wj(pdata)
        .then((res) => {
          _this.$message.success(res.msg);
          setTimeout(() => {
            _this.$router.go(0);
          }, 1500);
        })
        .catch((err) => {
          _this.$message.error(err || err.msg);
        })
        .finally(() => {
          loading.close();
          setTimeout(() => {
            document.documentElement.scrollTop = 0;
          }, 1500);
        });
    },
    cancleSubmit() {
      let _this = this;
      this.$confirm("退出后，将会清除已答题目", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "已退出",
          });
          setTimeout(() => {
            _this.$router.push({
              path: "/company/diagnose",
            });
          }, 2000);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    getMyCompany() {
      getMyCompany().then((res) => {
        console.log("res", res);
        this.myCompanyList = res.data;
      });
    },
    getRequsetData() {
      let _this = this;
      getDiagnose_Wj()
        .then((res) => {
          //   let result = JSON.parse(res);
          let result = res;
          let data = result.data;
          if (result.code === 1) {
            _this.formList = _this.handleAnswer(data.p_cate);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    handleAnswer(list) {
      list.forEach(function(el) {
        if (el.s_cate && el.s_cate.length) {
          el.s_cate.forEach(function(e) {
            if (e.ti_data && e.ti_data.length) {
              e.ti_data.forEach(function(tiObj) {
                if (tiObj.is_type == "2") {
                  tiObj.answer = [];
                }
              });
            }
          });
        }
      });
      return list;
    },
    getAnswer() {
      var answer = {};
      this.formList.forEach(function(el) {
        if (el.s_cate && el.s_cate.length) {
          answer[el.id] = {};
          el.s_cate.forEach(function(e) {
            if (e.ti_data && e.ti_data.length) {
              answer[el.id][e.id] = {};
              e.ti_data.forEach(function(tiObj) {
                if (typeof tiObj.answer == "string") {
                  answer[el.id][e.id][tiObj.id] = tiObj.answer
                    ? [tiObj.answer]
                    : [];
                } else {
                  answer[el.id][e.id][tiObj.id] = tiObj.answer || [];
                }
              });
            }
          });
        }
      });
      return answer;
    },
  },
};
</script>
<style scoped lang="less">
.el-button + .el-button {
  margin-left: 30px;
}
.container {
  position: relative;
  .question-list {
    width: 84%;
    margin: 0 auto;
    .question-header {
      width: 100%;
      height: 300px;
      background-size: 100% 100%;
      color: #fff;
      padding: 0 2% 0 32%;
      margin-bottom: 20px;
      box-sizing: border-box;
      .question-header-title {
        font-size: 24px;
        line-height: 36px;
        height: 36px;
        margin-bottom: 20px;
      }

      .question-header-tips {
        line-height: 32px;
        font-size: 15px;
      }
    }
    .question-form {
      background-color: #fff;
      padding: 40px 60px;
      border-radius: 10px;
      .question-form-title {
        position: relative;
        margin-bottom: 88px;
        font-size: 24px;
        text-align: center;
        &::before {
          content: "";
          width: 300px;
          height: 4px;
          position: absolute;
          display: inline-block;
          width: 250px;
          height: 4px;
          background-color: #205dc3;
          bottom: -10px;
          right: 0;
          left: 0;
          margin: 0 auto;
          border-radius: 3px;
        }
      }
      .question-form-item {
        .question-form-item-unit {
          display: inline-block;
          width: 35px;
          text-align: left;
          margin-left: 10px;
          font-size: 14px;
          color: #606266;
        }
      }
      .question-form {
        .question-form-h4,
        .question-form-h5 {
          font-size: 14px;
          color: #606266;
        }
        .first {
          padding: 8px 0;
        }
        .second {
          padding: 5px 0;
          text-indent: 2rem;
        }
        .third {
          padding: 5px 0;
          text-indent: 4rem;
          line-height: 1.5;
        }
        .el-form-item--mini.el-form-item {
          padding: 0 2rem;
        }
        .el-radio {
          margin-bottom: 10px;
        }
        .el-checkbox {
          display: flex;
          white-space: pre-wrap;
          .el-checkbox__input {
            padding-top: 3px;
          }
        }
      }
    }
  }
}
</style>
