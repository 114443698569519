<template>
    <div>
        <el-upload class="avatar-uploader" :action="uploadUrl" :show-file-list="false" :on-success="handleAvatarSuccess"
            accept=".jpg, .jpeg, .png">
            <el-button type="primary" class="upload-btn">点击上传</el-button>
        </el-upload>
    </div>
</template>
  
<script>
import configs from "@/config/index";
export default {
    data() {
        return {
            uploadUrl: "", // 图片上传路径
            imageList: [],
        };
    },
    created() {
        this.uploadUrl = `${configs.baseUrl}/api/common/upload`;
    },
    methods: {
        // 上传成功
        handleAvatarSuccess(res, file) {
            console.log("file, ", file);
            // this.imageList.push(file.response.data.url);
            this.$message.success("上传现场照片成功");
            this.$emit("uploadImgList", file.response.data.url);
        },
    },
};
</script>
  
<style lang="less" scoped>
::v-deep .el-button {
    height: 38px;
    line-height: 38px;
    padding: 0 18px;
    background: #205dc3;
    color: #ffffff;
    font-size: 14px;
    border-radius: 2px;
}
</style>
  