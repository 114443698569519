<template>
  <div class="main">
    <div class="m_ain">
      <div v-if="!detailShowPage && !terraceShowPage">
        <echarts></echarts>
        <tableView
          @detailShow="detailShow"
          @terraceShow="terraceShow"
          v-show="userId != 275"
        ></tableView>
      </div>
      <tableDetail
        v-if="detailShowPage && !terraceShowPage && userId != 275"
        :detailData="detailData"
        @returnIndex="returnIndex"
      >
      </tableDetail>
      <terraceList
        v-if="terraceShowPage && !detailShowPage && userId != 275"
        @returnIndex="returnIndex"
      ></terraceList>
    </div>
  </div>
</template>

<script>
import echarts from "./echarts.vue";
import terraceList from "./terraceList";
import tableView from "./tableView";
import tableDetail from "./tableDetail";
export default {
  components: {
    echarts,
    terraceList,
    tableView,
    tableDetail,
  },
  data() {
    return {
      detailShowPage: false,
      detailData: {},
      terraceShowPage: false,
      userId: ""
    };
  },
  created() {},
  mounted() {
    this.userId = JSON.parse(sessionStorage.getItem("user")).id;
    localStorage.setItem("userId", this.userId);
  },
  methods: {
    detailShow(v) {
      this.detailData = v;
      this.detailShowPage = true;
    },
    returnIndex() {
      this.detailShowPage = false;
      this.terraceShowPage = false;
    },
    terraceShow() {
      this.terraceShowPage = true;
    },
  },
};
</script>
<style scoped lang="less">
.main {
  background-color: #eff4fe;
}

.m_ain {
  width: 84%;
  min-width: 1200px;
  margin: 0 auto;
  min-height: 440px;
}
</style>
