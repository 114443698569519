import api from "./index";

export function login(data) {
  return api.post("/index/user/login", { data });
}

export function register(data) {
  return api.post("/index/user/register", { data });
}

export function getCaptcha(data) {
  return api.post("/index/user/getCaptcha?r=" + data);
}

// 获取验证码
export function getSmsCode(data) {
  return api.post("/index/user/getSms", { data });
}

// 忘记密码
export function forgetPwd(data) {
  return api.post("/index/user/forgetPwd", { data });
}
// 政府审批数量提示
export function Get_zf_Count(data) {
  return api.post("/index/Government/companyApplyCount", { data });
}
// 平台商审批数量提示
export function Get_pts_Count(data) {
  return api.post("/index/company/companyApplyCheckCount", { data });
}
// 获取行业数据
export function GetTrade(data) {
  return api.post("/api/common/getTrade", { data });
}

// 公共上传文件
export function UploadFile(data) {
  return api.post("/api/common/upload", { data });
}

// 获取地区数据
export function getArea(data) {
  return api.post("/api/common/getArea", { data });
}

// 添加反馈
export function addFeedback(data) {
  return api.post("/official/zczx/feedback", { data });
}

// 修改密码
export function UpdatePassword(data) {
  return api.post("/index/company/changePwd", { data });
}

// 获取所属场景列表
export function getSceenList() {
  return api.get("/api/Common/getDigitizationTree");
}

export default {
  login,
  register,
  getCaptcha,
  getSceenList,
};
