<template>
  <div class="dialog-container">
    <!-- 
      通过element-UI的el-dialog组件，开发出来的删除项目组件。
      此处定义了el-dialog的标题、并将dialog添加到页面的body标签中、通过dialogShow控制el-dialog的显示、设置closeDialog()方法作为关闭dialog的回调、设置close-on-click-modal为false以确保用户只能通过取消事件来关闭该弹窗。
      代码第4行 v-if="isDestroy" 通过isDestroy变量控制当前组件显示与否，并且解决页面调用组件时，因外部原因导致组件未能完全加载问题。
    -->
    <el-dialog
      v-if="isDestroy"
      append-to-body
      title="信息"
      :visible.sync="dialogShow"
      @close="closeDialog"
      width="20%"
      top="35vh"
      :close-on-click-modal="false"
    >
      <!-- 
        此处为弹窗主题，包括定义了弹窗提示语“确定要删除么？”，及插槽footer下插入的两个按钮：确定按钮、取消按钮。
        确定按钮点击事件绑定 handleConfirm 取消按钮点击事件绑定 handleCancel 。
        各自触发逻辑下文解释。
      -->
      <span>确定要删除么？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleConfirm">确定</el-button>
        <el-button class="delBtn" @click="handleCancel">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// 引入删除API方法
import { delContent } from "@/api/zf";
export default {
  props: {
    delDialogShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 此处定义控制 dialog 显示与否的变量。
      dialogShow: false,
      isDestroy: false,
      // 此处为存储需要删除的数据的变量。
      row: "",
    };
  },
  created() {},
  methods: {
    // 打开当前弹窗方法，在引用组件时，通过this.$refs[refName].openDialog(rowData)方式调用
    openDialog(row) {
      this.row = row;
      this.dialogShow = true;
      this.isDestroy = true;
    },
    // 关闭弹窗，该方法作为dialog关闭时的回调函数
    closeDialog() {
      this.dialogShow = false;
      this.isDestroy = false;
    },
    // 处理取消按钮的点击事件，此处调用上文定义的closeDialog()方法。
    handleCancel() {
      this.closeDialog();
    },
    // 处理确定按钮的点击事件，此处调用上文引入的删除API方法，传入存储好的需要删除的数据实现删除操作。
    handleConfirm() {
      delContent({ id: this.row }).then((res) => {
        // 当后端返回结果中res.code == 1时，代表请求成功，这个时候调用this.$emit("事件名")，给予用户反馈，提升用户使用体验。
        if (res.code === 1) {
          this.$emit("delSuccess");
          // 通过该方法关闭弹窗。
          this.closeDialog();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.dialog-container {
  box-shadow: 1px 1px 50px rgba(0, 0, 0, 0.3);
  max-width: 300px;
}

::v-deep .el-dialog__header {
  padding: 0 80px 0 20px;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #f0f0f0;
  overflow: hidden;
  border-radius: 2px 2px 0 0;
  box-sizing: border-box;

  .el-dialog__title {
    font-size: 14px;
    color: #333333;
  }
}

::v-deep .el-dialog__headerbtn {
  top: 17px;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: #333333;
  font-weight: 600;
}

::v-deep .el-dialog__headerbtn:hover .el-dialog__close {
  color: #333333;
  opacity: 0.8;
}

::v-deep .el-dialog__body {
  padding: 20px;
  line-height: 24px;
  font-size: 14px;
}

::v-deep .el-button {
  width: 58px;
  height: 28px;
  padding: 0;
  border-radius: 2px;

  &:first-child {
    border-color: #205dc3;
    background-color: #205dc3;
    color: #fff;
  }

  &:last-child {
    border: 1px solid #dedede;
    background-color: #fff;
    color: #333;

    &:hover {
      border: 1px solid #dedede;
      color: #909090;
    }
  }

  &:hover {
    opacity: 0.9;
  }
}
</style>
