<template>
  <div class="main-container" :style="hoverImg">
    <div class="main-info">
      <div class="title">
        <h4 class="line1">{{ itemDetail.info.title }}</h4>
      </div>
      <div class="btns">
        <el-button class="widthBtn btn primaryBtn1" @click="handleEdit"
          >修改</el-button
        >
        <el-button class="widthBtn btn cancleBtn1" @click="handleDel"
          >删除</el-button
        >
      </div>
    </div>
    <div class="other-info">
      <div class="source">
        <img :src="'/source.png' | staticMedia" alt="" />
        来源：{{ itemDetail.info.source }}
      </div>
      <div class="time">
        <img :src="'/time.png' | staticMedia" alt="" />
        {{ formatDateTime(itemDetail.publish_time) }}
      </div>
    </div>
  </div>
</template>

<script>
import { formatDateTime } from "@/utils/common.js";
export default {
  props: {
    itemDetail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      createTime: formatDateTime(this.itemDetail.createtime),
    };
  },
  computed: {
    hoverImg() {
      const filteredImageUrl = this.$options.filters.staticMedia(
        "/sihua_list_bg.png"
      );
      return {
        backgroundImage: `url(${filteredImageUrl})`,
      };
    },
  },
  methods: {
    formatDateTime(timetemp) {
      return formatDateTime(timetemp);
    },
    handleEdit() {
      this.$emit("editContent", true, this.itemDetail);
    },
    handleDel() {
      this.$emit("openDeleteDialog", true, this.itemDetail);
    },
  },
};
</script>

<style lang="less" scoped>
.main-container {
  width: 100%;
  height: 115px;
  margin-bottom: 20px;
  padding: 20px 28px 0;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  position: relative;
  box-sizing: border-box;

  .main-info {
    height: 50px;
    padding-bottom: 0;
    border-bottom: none;
    display: flex;
    justify-content: space-between;
    position: relative;

    .title {
      width: 80%;
      cursor: pointer;
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;

      h4 {
        width: 100%;
        height: 50px;
        line-height: 50px;
        font-size: 20px;
        font-weight: bold;
        color: #333333;
        margin: 0;
        margin-bottom: 8px;
      }
    }

    .btns {
      display: flex;
      position: relative;
      top: 12px;
      margin: auto 0;
    }
  }

  .other-info {
    width: fit-content;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: #262626;

    .source {
      margin-right: 15px;
    }

    img {
      height: 26px;
      vertical-align: middle;
    }
  }

  &:hover {
    box-shadow: 0 0 10px rgba(74, 109, 231, 0.4);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: -2px 0;
    transition: all 0.5s;
  }

  &:hover .title h4 {
    color: #205dc3;
  }
}
</style>
