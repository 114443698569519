<template>
  <div class="main" :style="$bgImg('newsBg.png')">
    <div class="container">
      <div class="breadcrumb" style="width: 84%;margin: 0 auto 30px;">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/company/classicCases' }">经典案例</el-breadcrumb-item>
          <el-breadcrumb-item>内容</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form">
        <div class="form-header">
          <div class="form-header-text">
            {{ title }}
          </div>
        </div>
        <div class="form-sub" v-if="type == 1">
          您已发布<span class="form-sub-font">{{ count }}/5</span>个案例
        </div>
        <el-form class="form-body" ref="form" :model="infoForm" label-width="152px" label-position="left">
          <el-form-item label="案例标题">
            <el-input v-model="infoForm.info.title"></el-input>
          </el-form-item>
          <el-form-item label="副标题">
            <el-input v-model="infoForm.info.subheading"></el-input>
          </el-form-item>
          <el-form-item label="来源">
            <el-input v-model="infoForm.info.source" disabled></el-input>
          </el-form-item>
          <el-form-item label="列表图片">
            <div class="tips">*请上传16:9尺寸的图片</div>
            <TUpupload :TU_lifils="imageList" :allownum="1" @tufile-sent="handletufile"></TUpupload>
          </el-form-item>
          <el-form-item label="案例详情">
            <WangEditor @editorInput="handleEditorInput" :defVal="infoForm.info.content"></WangEditor>
          </el-form-item>
          <el-form-item label="行业">
            <el-select v-model="infoForm.hy" multiple placeholder="请选择" style="width: 42%;">
              <el-option v-for="(item, index) in hyOptions" :key="index" :label="item" :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="领域">
            <el-select v-model="infoForm.ly" multiple placeholder="请选择" style="width: 42%;">
              <el-option v-for="(item, index) in lyOptions" :key="index" :label="item" :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="优先级">
            <el-select v-model="infoForm.yxj" placeholder="请选择" style="width: 42%;">
              <el-option v-for="(item, index) in yxjOptions" :key="index" :label="item" :value="item">
              </el-option>
            </el-select>
            <div class="tips" style="width: 250px;font-weight: 400;margin-left: 10px;">
              提示：等级 1 到 5, 表示从高到低的优先级
            </div>
          </el-form-item>
          <el-form-item label-width="0">
            <el-checkbox
              v-model="isAgreement">本人承诺：认真遵守国家保密法律、法规和规章制度，内容不涉及国家秘密、商业秘密、个人隐私和其他敏感信息，自愿接受保密审查。如有违反，自愿承担相应责任和后果。</el-checkbox>
          </el-form-item>
          <el-form-item>
            <el-button class="primaryBtn1 btn" @click="handleSubmit">确认</el-button>
            <el-button class="cancleBtn1 btn" @click="cancleUpdate">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import configs from "@/config/index";
// import Editor from "@/components/Editor";
import WangEditor from "@/components/WangEditor";
import TUpupload from "@/components/TUpupload.vue";
import {
  getClassicCaseCount,
  getClassicCaseDetail,
  updateClassicCase,
} from "@/api/pts";
export default {
  components: {
    // Editor,
    TUpupload,
    WangEditor,
  },
  data() {
    return {
      title: "增加内容",
      infoForm: {
        info: {
          title: "",
          subheading: "",
          source: "",
          content: "",
        },
        hy: [],
        ly: [],
        yxj: "",
      },
      config: {},
      imageUrl: "",
      imageList: [],

      count: 0,
      type: 0,

      id: 0,
      editorStr: "",

      hyOptions: [
        "煤炭/黑色金属矿开采/石油天然气开采",
        "黑色金属",
        "有色金属",
        "石化化工",
        "建材",
        "医药",
        "纺织",
        "家电",
        "食品",
        "烟草",
        "轻工",
        "机械",
        "汽车",
        "航天/航空",
        "船舶",
        "轨道交通",
        "电子",
        "电力",
        "热力和燃气",
        "建筑业",
        "农业",
        "其他",
      ],
      lyOptions: [
        "安全生产",
        "节能减排",
        "质量管控",
        "供应链管理",
        "研发设计",
        "生产制造",
        "运营管理",
        "仓储物流",
        "运维服务",
        "其他",
      ],
      yxjOptions: [1, 2, 3, 4, 5],


      isAgreement: false
    };
  },
  created() {
    this.type = this.$route.query.type;
    if (this.type === "1") {
      this.title = "添加内容";
    } else {
      this.title = "修改内容";
    }
  },
  mounted() {
    this.config = configs;
    this.type = this.$route.query.type;
    console.log("type", this.type);

    this.getClassicCaseCount();

    this.infoForm.info.source = JSON.parse(
      sessionStorage.getItem("ptsInfo")
    ).name;

    if (this.$route.query.id) {
      this.getClassicCaseDetail();
    }
  },
  methods: {
    handleEditorInput(html) {
      this.editorStr = html;
    },
    handleSubmit() {
      this.editorStr = this.$encodeHTML(this.editorStr);
      let _this = this;
      let pdata = {
        id: "0",
        official_category_id: "35",
        title: _this.infoForm.info.title,
        subheading: _this.infoForm.info.subheading,
        source: _this.infoForm.info.source,
        image: _this.imageUrl,
        content: _this.editorStr,
        hy: _this.infoForm.hy.join(","),
        ly: _this.infoForm.ly.join(","),
        yxj: _this.infoForm.yxj,
        type: 7,
      };
      if (pdata.content == "<p><br></p>" || pdata.content == null) {
        this.$message.error("请输入案例详情");
        return false
      }
      if (!this.isAgreement) {
        this.$confirm('是否承诺认真遵守国家保密法律、法规和规章制度，内容不涉及国家秘密、商业秘密、个人隐私和其他敏感信息，自愿接受保密审查？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.isAgreement = true
          if (_this.type == "1") {
            updateClassicCase(pdata).then((res) => {
              if (res.code == 1) {
                _this.$message.success("添加成功");
                setTimeout(() => {
                  _this.$router.go(-1);
                }, 1500);
              }
            });
          } else if (_this.type == "2") {
            pdata.id = _this.$route.query.id;
            updateClassicCase(pdata).then((res) => {
              if (res.code == 1) {
                _this.$message.success("修改成功");
                setTimeout(() => {
                  _this.$router.go(-1);
                }, 1500);
              }
            });
          }

        }).catch(() => {
          this.isAgreement = false
          return false
        });
      } else {
        if (_this.type == "1") {
          updateClassicCase(pdata).then((res) => {
            if (res.code == 1) {
              _this.$message.success("添加成功");
              setTimeout(() => {
                _this.$router.go(-1);
              }, 1500);
            }
          });
        } else if (_this.type == "2") {
          pdata.id = _this.$route.query.id;
          updateClassicCase(pdata).then((res) => {
            if (res.code == 1) {
              _this.$message.success("修改成功");
              setTimeout(() => {
                _this.$router.go(-1);
              }, 1500);
            }
          });
        }
      }
    },
    handletufile(file) {
      console.log("file", file.url);

      this.imageUrl = file.url;
    },
    getClassicCaseDetail() {
      let _this = this;
      let pdata = {
        id: _this.$route.query.id,
      };
      getClassicCaseDetail(pdata).then((res) => {
        _this.infoForm = res.data;
        _this.infoForm.info.title = res.data.info.title;
        _this.infoForm.info.subheading = res.data.info.sub_title;
        _this.infoForm.info.source = res.data.info.source;
        // _this.infoForm.info.content = res.data.info.content;
        _this.infoForm.info.content = this.$encodeToHTML(res.data.info.content)
        _this.infoForm.hy = res.data.hy.split(",");
        _this.infoForm.ly = res.data.ly.split(",");
        _this.imageList.push({ url: res.data.image });
        _this.imageUrl = res.data.image;
      });
    },
    getClassicCaseCount() {
      let _this = this;
      getClassicCaseCount().then((res) => {
        if (res.code == 1) {
          _this.count = res.data;
        }
      });
    },
    cancleUpdate() {
      this.$router.back();
    },
  },
};
</script>
<style scoped lang="less">
::v-deep .el-checkbox__label {
  white-space: normal;
  word-break: break-all;
  vertical-align: text-top;
}
.el-button+.el-button {
  margin-left: 30px;
}

.container {
  position: relative;

  .form {
    width: 84%;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 10px;

    .form-header {
      border-bottom: 1px solid #d8d8d8;

      .form-header-text {
        width: 118px;
        text-align: center;
        color: #333;
        font-size: 18px;
        line-height: 60px;
        margin-left: 30px;
        border-bottom: 3px solid #9bbef9;
        box-sizing: border-box;
      }
    }

    .form-sub {
      font-weight: 600;
      font-size: 15px;
      padding: 20px 70px 0;

      .form-sub-font {
        color: #205dc3;
      }
    }

    .form-body {
      padding: 20px 200px 20px 40px;
    }
  }
}
</style>
