<template>
  <div class="main-container">
    <div class="form-container">
      <formView :formData="formData" :tradeOptions="tradeOptions" @formSearch="getList" @formDownload="formDownload">
      </formView>
    </div>
    <div class="table-container">
      <Table :tableData="tableData" :columns="columns" :operateHandle="true" :operateDetail="'查看详情'"
        :operateChange="'变更信息'" @tableRowDetail="tableRowDetail" @tableRowChange="tableRowChange" :leftSize="true">
      </Table>
    </div>
    <div class="pagination-container">
      <Pagination :total="total" :currentPage="page" :pageSize="pageSize" :pageSizes="pageSizes"
        :layout="'prev, pager, next, jumper,total, sizes '" @pageChange="pageChange" @sizeChange="sizeChange">
      </Pagination>
    </div>
    <changeDialog ref="changeDialog" @editSuccess="getList"></changeDialog>
  </div>
</template>

<script>
import formView from "./formView";
import Table from "@/components/Table";
import Pagination from "@/components/Pagination";
import changeDialog from "./changeDialog";
import { fourDiagnoseList } from "@/api/zf";
export default {
  components: {
    formView,
    Table,
    Pagination,
    changeDialog,
  },
  data() {
    return {
      formData: {
        trade: "",
        company: "",
      },
      tradeOptions: JSON.parse(sessionStorage.getItem("hylist")),
      tableData: [],
      columns: [
        {
          id: 1,
          title: "诊断机构名称",
          prop: "d_company",
          showTopTip: true,
        },
        {
          id: 2,
          title: "诊断机构联系人",
          prop: "d_contact",
        },
        {
          id: 3,
          title: "诊断机构联系电话",
          prop: "d_phone",
        },
        {
          id: 4,
          title: "被诊断企业名称",
          prop: "nickname",
          showTopTip: true,
        },
        {
          id: 5,
          title: "被诊断企业联系电话",
          prop: "phone",
          width: 150,
        },
        {
          id: 6,
          title: "申报行业",
          prop: "trade",
          showTopTip: true,
        },
        {
          id: 7,
          title: "协议签订时间",
          prop: "signtime",
        },
      ],
      total: 0,
      page: 1,
      pageSize: 10,
      pageSizes: [10, 15, 20, 25, 30],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      fourDiagnoseList({
        page: this.page,
        pageSize: this.pageSize,
        trade_id: this.formData.trade,
        level: "",
        d_company: this.formData.company,
      }).then((res) => {
        if (res.code === 1) {
          this.tableData = res.data.data;
          this.tableData.forEach((item) => {
            // if (item.company != null && item.company != "") {
            //   item.nickname = item.company.nickname;
            // } else {
            //   item.nickname = "";
            // }
            item.nickname = item.company_name
          });
          this.total = res.data.total;
          this.page = res.data.current_page;
          this.pageSize = Number(res.data.per_page);
        }
      });
    },
    formDownload(v) {
      this.$emit("terraceShow", v);
    },
    tableRowDetail(v) {
      this.$emit("detailShow", v);
    },
    tableRowChange(v) {
      this.$refs.changeDialog.openDialog(v);
    },
    pageChange(v) {
      this.page = v;
      this.getList();
    },
    sizeChange(v) {
      this.pageSize = v;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.main-container {
  .pagination-container {}
}

::v-deep .el-pagination {
  width: 100%;
  text-align: left;
  padding: 10px 0;
  background-color: #fff;
}
</style>
