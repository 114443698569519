<template>
  <div class="main" :style="$bgImg('newsBg.png')">
    <div class="container">
      <div class="add-btn">
        <div
          class="add-btn-body pointer flex column center"
          @click="goAddClassic"
        >
          <img class="add-btn-icon" :src="'/addContent.png' | staticMedia" />
          <span class="add-btn-title">发布案例</span>
        </div>
      </div>
      <div class="classic-case-list" v-if="classicCaseList.length > 0">
        <div
          class="classic-case-item pointer shadow"
          v-for="(item, index) in classicCaseList"
          :key="index"
          @click="goClassicDetail(item.id)"
          :style="hoverImg"
        >
          <div class="flex bweteen">
            <div class="classic-case-level">
              <div class="text">优先级</div>
              <div class="num">{{ item.yxj }}</div>
            </div>
            <div class="classic-case-img">
              <img
                class="classic-case-image image100"
                :src="item.image | contentMedia"
                alt=""
              />
            </div>
            <div class="classic-case-title flex column bewteen">
              <div class="title line1">{{ item.info ? item.info.title : "" }}</div>
              <div class="subtitle flex ">
                <div
                  class="subtitle-item"
                  v-for="(el, eindex) in item.hy.split(',')"
                  :key="eindex"
                >
                  {{ el }}
                </div>
              </div>
              <div class="info flex bewteen-wrapper">
                <div class="company flex vertical">
                  <img class="bottom-icon" :src="'/source.png' | staticMedia" />
                  <span class="line1">来源：{{ item.info ? item.info.source : "" }}</span>
                </div>
                <div class="createtime flex vertical">
                  <img class="bottom-icon" :src="'/time.png' | staticMedia" />
                  <span class="line1" style="width: fit-content;">{{
                    formatDateTime(item.create_time)
                  }}</span>
                </div>
              </div>
            </div>
            <div class="classic-case-btns flex column center">
              <el-button
                class="primaryBtn1 btn"
                @click.stop="goUpdateClassic(item.id)"
                >修改</el-button
              >
              <el-button
                class="cancleBtn1 btn"
                @click.stop="beforeDelClassic(item.id)"
                >删除</el-button
              >
            </div>
          </div>
        </div>
      </div>
      <div class="classic-case-list" v-else>
        <div class="list-emp-text">
          暂无数据
        </div>
      </div>
      <!-- <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :current-page="page"
        :page-size="pageSize"
        @current-change="handleCurrentChange"
      >
      </el-pagination> -->
    </div>
  </div>
</template>
<script>
import { formatDateTime } from "@/utils/common.js";
import { getClassicCaseList } from "@/api/pts";
export default {
  components: {},
  data() {
    return {
      classicCaseList: [],
      page: 1,
      pageSize: 10,
      total: 0,
    };
  },
  computed: {
    hoverImg() {
      const filteredImageUrl = this.$options.filters.staticMedia(
        "/yxal_list_bg.png"
      );
      return {
        backgroundImage: `url(${filteredImageUrl})`,
      };
    },
  },
  created() {},
  mounted() {
    this.getClassicCaseList();
  },
  methods: {
    handleCurrentChange(page) {
      this.page = page;
      this.getClassicCaseList();
    },
    formatDateTime(time) {
      return formatDateTime(time);
    },
    getClassicCaseList() {
      let _this = this;
      let pdata = {
        page: _this.page,
        pageSize: _this.pageSize,
      };
      getClassicCaseList(pdata).then((res) => {
        console.log("res", res);
        _this.classicCaseList = res.data;
        _this.total = res.data.length;
      });
    },
    goAddClassic() {
      this.$router.push({
        path: "/company/classicCases/add",
        query: { type: 1 },
      });
    },
    goUpdateClassic(id) {
      this.$router.push({
        path: "/company/classicCases/edit",
        query: { type: 2, id },
      });
    },
    beforeDelClassic(id) {
      this.$Delete("post", "/index/company/classicaldel", { id }).then(
        (res) => {
          console.log("res", res);
          this.getClassicCaseList();
        }
      );
    },
    goClassicDetail(id) {
      this.$router.push({
        path: "/company/classicCases/detail",
        query: {
          id,
        },
      });
    },
  },
};
</script>
<style scoped lang="less">
.el-button + .el-button {
  margin-left: 0;
  margin-top: 10px;
}
.container {
  position: relative;
  .classic-case-list {
    width: 84%;
    min-height: 388px;
    margin: 0 auto;
    .classic-case-item {
      border-radius: 10px;
      background-color: #fff;
      padding: 30px 20px;
      margin-bottom: 20px;
      transition: all 0.5s;
      background-position: 0 -200px;
      &:hover {
        /* background-image: url("../../../assets/addons/official/images/yxal_list_bg.png"); */
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: 0;
        transition: all 0.5s;
        .classic-case-image {
          transform: scale(1.2);
          transition: all 0.3s;
        }
        .title {
          color: #205dc3 !important;
        }
      }
      .classic-case-level {
        width: 56px;
        border: 1px solid #ccc;
        margin: 0 30px 0 10px;
        .text {
          height: 39px;
          line-height: 39px;
          color: #666666;
          background-color: #cccccc50;
          font-size: 13px;
          text-align: center;
          border-bottom: 1px solid #ccc;
        }
        .num {
          color: #4a6de7;
          line-height: 86px;
          text-align: center;
          font-size: 18px;
          font-weight: 600;
        }
      }

      .classic-case-img {
        width: 194px;
        height: 128px;
        overflow: hidden;
        .classic-case-image {
          transition: all 0.3s;
        }
      }
      .classic-case-title {
        width: calc(100% - 200px - 194px - 96px);
        padding: 0 30px;
        box-sizing: border-box;
        .title {
          width: 100%;
          line-height: 30px;
          color: #333;
          font-size: 20px;
          font-weight: 600;
        }
        .subtitle {
          .subtitle-item {
            font-size: 14px;
            padding: 5px 12px;
            background: #ecf5ff;
            color: #205dc3;
            border-radius: 5px;
            margin-right: 14px;
          }
        }
        .info {
          font-size: 14px;
          .company {
            width: calc(100% - 250px);
          }
          .createtime {
            width: 250px;
            justify-content: flex-end;
          }
        }
      }
      .classic-case-btns {
        width: 200px;
        height: 124px;
      }
    }
  }
}
</style>
