<template>
  <div class="main-container">
    <div class="main-info">
      <div class="form-header">
        <div class="form-header-text">{{ addOrEdit }}内容</div>
      </div>
      <div class="form-container">
        <el-form
          ref="formData"
          :model="formData"
          label-position="left"
          label-width="150px"
          :rules="formRules"
          hide-requied-aterisk
        >
          <el-form-item
            label="内容分类"
            class="same-width"
            prop="official_category_id"
            v-if="addOrEdit === '添加'"
          >
            <el-select
              v-model="formData.official_category_id"
              placeholder="请选择内容分类"
              popper-class="eloption"
              :popper-append-to-body="false"
              @change="officialCategoryChange"
            >
              <el-option
                v-for="item in officialCategoryOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="列表图片" prop="image" class="is-required">
            <UploadImg
              v-model="formData.image"
              @uploadImgFile="uploadImgFile"
              :detailImage="detailImage"
            >
            </UploadImg>
            <!-- <el-input v-model="formData.image" v-show="false"></el-input> -->
          </el-form-item>
          <el-form-item label="标题" class="same-width" prop="title">
            <el-input
              v-model="formData.title"
              placeholder="请输入新闻标题"
            ></el-input>
          </el-form-item>
          <el-form-item label="副标题" class="same-width" prop="subheading">
            <el-input
              type="textarea"
              v-model="formData.subheading"
              placeholder="请输入副标题"
            ></el-input>
          </el-form-item>
          <el-form-item label="来源" class="same-width" prop="source">
            <el-input
              v-model="formData.source"
              placeholder="请输入来源"
            ></el-input>
          </el-form-item>
          <el-form-item label="详情" class="same-width" prop="content">
            <!-- <Editor v-model="formData.content" @editorContent="editorContent" :detailContent="detailContent"
              class="editor-content">
            </Editor> -->
            <WangEditor
              :defVal="formData.content"
              @editorInput="handleEditorInput"
            ></WangEditor>
          </el-form-item>
          <el-form-item
            label="行业"
            class="same-width"
            prop="hy"
            v-if="navName === '优秀案例'"
          >
            <el-select
              v-model="formData.hy"
              placeholder="请选择行业"
              popper-class="eloption"
              :popper-append-to-body="false"
              multiple
              collapse-tags
              @change="hyChange"
            >
              <el-option
                v-for="(item, index) in hyOptions"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="领域"
            class="same-width"
            prop="ly"
            v-if="navName === '优秀案例'"
          >
            <el-select
              v-model="formData.ly"
              placeholder="请选择领域"
              popper-class="eloption"
              :popper-append-to-body="false"
              multiple
              collapse-tags
              @change="lyChange"
            >
              <el-option
                v-for="(item, index) in lyOptions"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="优先级"
            class="same-width"
            prop="yxj"
            v-if="navName === '优秀案例'"
          >
            <el-select
              v-model="formData.yxj"
              placeholder="请选择优先级"
              popper-class="eloption"
              :popper-append-to-body="false"
              @change="yxjChange"
            >
              <el-option
                v-for="(item, index) in yxjOptions"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
            <div class="level-tips">
              提示：等级 1 到 5, 表示从高到低的优先级
            </div>
          </el-form-item>
          <el-form-item label-width="0">
            <el-checkbox v-model="isAgreement"
              >本人承诺：认真遵守国家保密法律、法规和规章制度，内容不涉及国家秘密、商业秘密、个人隐私和其他敏感信息，自愿接受保密审查。如有违反，自愿承担相应责任和后果。</el-checkbox
            >
          </el-form-item>
          <el-form-item label-width="0">
            <el-button class="primaryBtn1 btn-size" @click="handleConfirm"
              >确认</el-button
            >
            <el-button class="cancleBtn1 btn-size" @click="handleCancle"
              >取消</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import UploadImg from "@/components/UploadImg";
// import Editor from "@/components/Editor";
import { addContent, getDetail, editContent } from "@/api/zf";
import WangEditor from "../../components/WangEditor.vue";
export default {
  props: {
    addOrEdit: {
      type: String,
      default: "",
    },
    navName: {
      type: String,
      default: "",
    },
    editId: {
      type: String,
      default: "",
    },
  },
  components: {
    UploadImg,
    // Editor,
    WangEditor,
  },
  data() {
    return {
      formData: {
        type: "",
        official_category_id: "", // 内容分类
        image: "", // 列表图片
        title: "", // 标题
        subheading: "", // 副标题
        source: "", // 来源
        content: "", // 详情
        hy: [], // 行业
        ly: [], // 领域
        yxj: "", // 优先级
        // mobile: '', // 手机号
      },
      imageUrl: "", // 图片上传路径
      type: [
        {
          value: 1,
          label: "新闻动态",
        },
        {
          value: 3,
          label: "政策原文",
        },
        {
          value: 5,
          label: "名单公示",
        },
        {
          value: 4,
          label: "政策解读",
        },
        {
          value: 2,
          label: "通知公告",
        },
        {
          value: 6,
          label: "行业解决方案",
        },
        {
          value: 7,
          label: "优秀案例",
        },
      ],
      officialCategoryOptions: [
        {
          value: 24,
          label: "新闻动态",
        },
        {
          value: 25,
          label: "政策原文",
        },
        {
          value: 27,
          label: "名单公示",
        },
        {
          value: 29,
          label: "政策解读",
        },
        {
          value: 26,
          label: "通知公告",
        },
        {
          value: 30,
          label: "行业解决方案",
        },
      ],
      // hyOptions: JSON.parse(sessionStorage.getItem('hylist')),
      // 行业选项
      hyOptions: [
        "煤炭/黑色金属矿开采/石油天然气开采",
        "黑色金属",
        "有色金属",
        "石化化工",
        "建材",
        "医药",
        "纺织",
        "家电",
        "食品",
        "烟草",
        "轻工",
        "机械",
        "汽车",
        "航天/航空",
        "船舶",
        "轨道交通",
        "电子",
        "电力",
        "热力和燃气",
        "建筑业",
        "农业",
        "其他",
      ],
      // 领域选项
      lyOptions: [
        "安全生产",
        "节能减排",
        "质量管控",
        "供应链管理",
        "研发设计",
        "生产制造",
        "运营管理",
        "仓储物流",
        "运维服务",
        "其他",
      ],
      yxjOptions: [1, 2, 3, 4, 5],
      formRules: {
        official_category_id: [
          { required: true, message: "请选择内容分类", trigger: "change" },
        ],
        // image: [
        //     { required: true, message: '请上传列表图片', trigger: 'change' },
        // ],
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        subheading: [
          { required: true, message: "请输入副标题", trigger: "blur" },
        ],
        source: [{ required: true, message: "请输入来源", trigger: "blur" }],
        content: [{ required: true, message: "请输入详情", trigger: "blur" }],
        hy: [{ required: true, message: "请选择行业", trigger: "blur" }],
        ly: [{ required: true, message: "请选择领域", trigger: "blur" }],
        yxj: [{ required: true, message: "请选择优先级", trigger: "blur" }],
      },
      detailData: {},
      detailImage: "",
      detailContent: "",
      isAgreement: false,
    };
  },
  mounted() {
    this.formData.official_category_id =
      this.navName === "新闻动态"
        ? 26
        : this.navName === "四化政策"
        ? 29
        : this.navName === "名单公示"
        ? 27
        : this.navName === "行业解决方案"
        ? 30
        : "";
    if (this.addOrEdit === "修改") {
      this.getDetail();
    }
  },
  methods: {
    handleEditorInput(html) {
      this.formData.content = html;
    },
    officialCategoryChange(v) {
      this.formData.official_category_id = v;
    },
    hyChange(v) {
      this.formData.hy = v;
    },
    lyChange(v) {
      this.formData.ly = v;
    },
    yxjChange(v) {
      this.formData.yxj = v;
    },
    uploadImgFile(v) {
      let _this = this;
      _this.formData.image = v;
      console.log("v", v);
      // if (_this.formData.image != "") {
      //     _this.$refs.formData.validateField('image')
      // }
    },
    editorContent(v) {
      this.formData.content = v;
      if (this.formData.content != "") {
        this.$refs.formData.validateField("content");
      }
    },
    handleConfirm() {
      this.formData.content = this.$encodeHTML(this.formData.content);
      if (this.formData.image == "" || this.formData.image == null) {
        this.$message.error("请上传列表图片");
        return false;
      }
      if (
        this.formData.content == "<p><br></p>" ||
        this.formData.content == null
      ) {
        this.$message.error("请输入详情");
        return false;
      }
      if (!this.isAgreement) {
        this.$confirm(
          "是否承诺认真遵守国家保密法律、法规和规章制度，内容不涉及国家秘密、商业秘密、个人隐私和其他敏感信息，自愿接受保密审查？",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            this.isAgreement = true;
            this.officialCategoryOptions.forEach((item) => {
              if (item.value === this.formData.official_category_id) {
                this.type.forEach((i) => {
                  if (i.label === item.label) {
                    this.formData.type = i.value;
                  }
                });
              }
            });
            for (let item in this.formData) {
              if (
                typeof this.formData[item] === "undefined" ||
                this.formData[item] === null ||
                this.formData[item] === ""
              ) {
                delete this.formData[item];
              }
            }
            this.$refs["formData"].validate((valid) => {
              if (valid) {
                if (this.addOrEdit === "添加") {
                  this.addContent();
                } else {
                  this.editContent();
                }
              } else {
                return false;
              }
            });
            // this.$router.go(0)
          })
          .catch(() => {
            this.isAgreement = false;
            return false;
          });
      } else {
        this.officialCategoryOptions.forEach((item) => {
          if (item.value === this.formData.official_category_id) {
            this.type.forEach((i) => {
              if (i.label === item.label) {
                this.formData.type = i.value;
              }
            });
          }
        });
        for (let item in this.formData) {
          if (
            typeof this.formData[item] === "undefined" ||
            this.formData[item] === null ||
            this.formData[item] === ""
          ) {
            delete this.formData[item];
          }
        }
        this.$refs["formData"].validate((valid) => {
          if (valid) {
            if (this.addOrEdit === "添加") {
              this.addContent();
            } else {
              this.editContent();
            }
          } else {
            return false;
          }
        });
        // this.$router.go(0)
      }
    },
    handleCancle() {
      this.$emit("addSuccess", false);
    },
    addContent() {
      addContent(this.formData).then((res) => {
        if (res.code === 1) {
          this.$emit("addSuccess", true);
        }
      });
    },
    getDetail() {
      getDetail({
        id: this.editId,
      }).then((res) => {
        if (res.code === 1) {
          this.detailData = res.data;
          this.formData = { ...this.detailData };
          this.formData.title = res.data.info.title;
          this.formData.source = res.data.info.source;
          this.formData.content = this.$encodeToHTML(res.data.info.content);
          this.formData.subheading = res.data.info.sub_title;
          this.detailImage = res.data.image;
          this.detailContent = res.data.content;
          this.editorContent(this.detailData.content);
          this.formData.hy = res.data.hy ? res.data.hy.split(",") : "";
          this.formData.ly = res.data.ly ? res.data.ly.split(",") : "";
        }
      });
    },
    editContent() {
      editContent(this.formData).then((res) => {
        if (res.code === 1) {
          this.$emit("addSuccess", true);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
::v-deep .el-checkbox__label {
  white-space: normal;
  word-break: break-all;
  vertical-align: text-top;
}
.main-container {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: #ffffff;

  .main-info {
    .form-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-self: center;
      border-bottom: 1px solid #d6dce4;

      .form-header-text {
        width: 78px;
        height: 100%;
        line-height: 60px;
        margin-left: 30px;
        font-size: 18px;
        border-bottom: 3px solid #9bbef9;
        text-align: center;
        text-indent: 0;
        color: #333333;
        box-sizing: border-box;
      }
    }

    .form-container {
      width: 100%;
      padding: 40px;

      .same-width {
        width: 80%;
      }

      .level-tips {
        display: inline;
        color: red;
        font-size: 12px;
        margin-left: 10px;
      }

      .btn-size {
        color: #ffffff;
        width: 58px;
        height: 38px;
        padding: 10px;
      }
    }
  }
}

::v-deep .el-form-item__label {
  font-weight: 600;
  color: #333333;
}

::v-deep .el-select {
  width: 25%;
}

::v-deep .el-select .el-input.is-focus .el-input__inner,
::v-deep .el-select .el-input__inner:focus,
::v-deep .eloption .el-select-dropdown,
::v-deep .el-input__inner:focus,
::v-deep .el-textarea__inner:focus {
  border-color: #cad3dd !important;
}

::v-deep .eloption .el-select-dropdown__item {
  // height: 26px;
  // line-height: 26px;
}

::v-deep .eloption .el-select-dropdown__item.selected,
::v-deep .eloption .el-select-dropdown__item:hover {
  // color: #ffffff;
  // background-color: #767676;
  // font-weight: normal;
  // border-color: #cad3dd !important;
}

::v-deep .el-textarea__inner {
  // height: 186px;
  font-family: "Microsoft";
}
</style>
