<template>
  <div class="main">
    <div class="container">
      <div class="add-btn">
        <div
          class="add-btn-body pointer flex column center"
          @click="goAddCourse"
        >
          <img class="add-btn-icon" :src="'/addContent.png' | staticMedia" />
          <span class="add-btn-title">发布课程</span>
        </div>
      </div>
      <div class="course-list" v-if="courseList.length > 0">
        <div class="flex wrap">
          <div
            class="course-item pointer shadow"
            v-for="(item, index) in courseList"
            :key="index"
          >
            <div class="course-img">
              <img
                :src="item.image | contentMedia"
                class="course-image image100"
                alt=""
              />
            </div>
            <div class="course-main ">
              <div class="course-title line2">
                {{ item.title }}
              </div>
            </div>
            <div class="course-btns flex horizontally vertical">
              <el-button
                class="primaryBtn1 btn"
                @click="goUpdateCourse(item.id)"
                >修改</el-button
              >
              <el-button
                class="cancleBtn1 btn"
                @click="beforeDelCourse(item.id)"
                >删除</el-button
              >
            </div>
          </div>
        </div>
      </div>
      <div class="course-list" v-else>
        <div class="list-emp-text">
          暂无数据
        </div>
      </div>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :current-page="page"
        :page-size="pageSize"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { getCourseList } from "@/api/zf";
export default {
  name: "CoursePublic",
  data() {
    return {
      courseList: [],
      page: 1,
      pageSize: 8,
      total: 0,
    };
  },
  created() {},
  mounted() {
    this.getCourseList();
  },
  methods: {
    handleCurrentChange(page) {
      this.page = page;
      this.getCourseList();
    },
    beforeDelCourse(id) {
      this.$Delete("post", "/index/government/del_course", { id }).then(
        (res) => {
          console.log("res", res);
          this.getCourseList();
        }
      );
    },
    getCourseList() {
      let _this = this;
      let pdata = {
        page: _this.page,
        pageSize: _this.pageSize,
      };
      getCourseList(pdata).then((res) => {
        console.log("res", res);
        _this.courseList = res.data.data;
        _this.total = res.data.total;
      });
    },
    goUpdateCourse(id) {
      this.$router.push({
        path: "/government/course/edit",
        query: {
          type: false,
          id: id,
        },
      });
    },
    goAddCourse() {
      this.$router.push({
        path: "/government/course/add",
        query: {
          type: true,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.el-button + .el-button {
  margin-left: 30px;
}

.container {
  position: relative;

  .course-list {
    width: 84%;
    min-height: 388px;
    margin: 0 auto;

    .course-item {
      width: 23.95%;
      margin-right: 1.4%;
      border-radius: 10px;
      background-color: #fff;
      //   padding: 30px 25px 15px;
      margin-bottom: 20px;
      box-sizing: border-box;
      overflow: hidden;

      &:nth-child(4n) {
        margin-right: 0;
      }

      &:hover {
        .title {
          color: #205dc3;
        }

        .course-image {
          transform: scale(1.2);
          transition: all 0.3s;
        }

        .course-title {
          color: #205dc3 !important;
        }
      }

      .course-img {
        height: 214px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        margin-bottom: 10px;

        .course-image {
          transition: all 0.3s;
        }
      }

      .course-main {
        padding: 10px 20px 15px;
        .course-title {
          font-size: 20px;
          line-height: 30px;
          height: 60px;
        }

        .course-info {
          margin: 10px auto;
          font-size: 14px;
        }
      }

      .course-btns {
        height: 60px;
        width: 100%;
        margin: 0;
        padding: 8px 0 8px;
        border-top: 1px solid #d9d9d9;
      }
    }
  }
}
</style>
