<template>
  <div class="two_step">
    <el-table
      :data="tableData"
      border
      show-summary
      :header-cell-style="{ background: '#205DC3', color: '#fff' }"
    >
      <!-- <el-table-column prop="pname" label="一级指标" width="180">
      </el-table-column>
      <el-table-column prop="name" label="二级指标"> </el-table-column>
      <el-table-column prop="score" label="分值"> </el-table-column>
      <el-table-column prop="actual_score" label="实际得分"> -->
      <el-table-column prop="a" label="一级指标">
      </el-table-column>
      <el-table-column prop="b" label="二级指标"> </el-table-column>
      <el-table-column prop="c" label="分值"> </el-table-column>
      <el-table-column prop="d" label="实际得分">
        <!-- <template slot-scope="scope">
            <el-input v-model="scope.row.actual_score" :max="scope.row.score" @input="handleInput(scope)"
                type="Number" placeholder="请输入内容"></el-input>
        </template> -->
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
// import { GetWjDiagnoseRes, getStep2 } from "@/api/pts";
import { getStep2 } from "@/api/pts";
export default {
  data() {
    return {
      tableData: [],
      // answer_data: [],
      // allactual_score: 0,
      // allscore: 0,
      // userid: "",
    };
  },
  mounted() {},
  methods: {
    Getinfo(obj) {
      console.log(obj, "22222222222");
      this._getWjDiagnoseRes(obj.id);
    },
    _getWjDiagnoseRes(id) {
      // this.userid = id;
      console.log(id);
      let paroam = {
        id: id,
      };
      // GetWjDiagnoseRes(paroam).then((res) => {
      getStep2(paroam).then((res) => {
        // this.scopelog = true;
        var data = res.data;

        let score = [
          "3.00",
          "2.00",
          "2.00",
          "3.00",
          "5.00",
          "5.00",
          "5.00",
          "5.00",
          "4.75",
          "3.25",
          "3.75",
          "5.00",
          "4.00",
          "4.25",
          "6.00",
          "4.25",
          "4.00",
          "3.75",
          "5.00",
          "2.00",
          "4.00",
          "4.00",
          "2.00",
          "3.00",
          "3.00",
          "4.00",
          "0.00",
        ];

        // let scoreIndex = 0;

        if (res.code === 1) {
          // this.answer_data = data.answer;
          var list = [];
          if (data.answer) {
            // for (var key in data.answer) {
            //   var el = data.answer[key];
            //   var obj = {
            //     pid: el.id,
            //     pname: el.name,
            //     // rowspan: el.two_cate.length
            //   };
            //   if (el.two_cate && el.two_cate.length) {
            //     el.two_cate.forEach(function(item, index) {
            //       list.push({
            //         ...obj,
            //         rowspan: index ? 0 : el.two_cate.length,
            //         name: item.name,
            //         score: score[scoreIndex],
            //         actual_score: item.actual_score,
            //       });
            //       scoreIndex++;
            //     });
            //   }
            // }
            data.answer.forEach((item, index) => {
              let obj = {
                a: item.a,
                b: item.b,
                c: score[index],
                d: item.d,
              };
              list.push(obj);
            });
            this.tableData = list;
          }
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    // objectSpanMethod({ row, columnIndex }) {
    //   if (columnIndex === 0) {
    //     if (row.rowspan) {
    //       return {
    //         rowspan: row.rowspan,
    //         colspan: 1,
    //       };
    //     } else {
    //       return {
    //         rowspan: 0,
    //         colspan: 0,
    //       };
    //     }
    //   }
    // },
  },
};
</script>
<style lang="less" scoped>
.two_step {
  height: 500px;
  overflow: auto;
  padding-right: 20px;
  padding-bottom: 20px;
}
</style>
