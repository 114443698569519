import Vue from "vue";
import VueRouter from "vue-router";
import MenHuRouter from "./zf";
import PingTaiShangRouter from "./pts";
import CommonRouter from "./common";
import QiYeRouter from "./qy";
import ZfYeRouter from "./zf";

Vue.use(VueRouter);

const routes = MenHuRouter.concat(PingTaiShangRouter)
  .concat(CommonRouter)
  .concat(QiYeRouter)
  .concat(ZfYeRouter);

const router = new VueRouter({
  routes: routes,
});

// 解决重复点击导航时，控制台出现报错
const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};

router.beforeEach((to, from, next) => {
  // console.log("to --------------> ", from, to);
  const szz_v1_token_expiration_time = sessionStorage.getItem(
    "szz_v1_token_expiration_time"
  );
  const t = 1000 * 60 * 5; // 毫秒，5分钟

  if (to.path === "/login" || to.path === "/register") {
    next();
    return;
  }
  if (szz_v1_token_expiration_time - t >= new Date().getTime()) {
    next();
  } else {
    sessionStorage.clear();
    next("/login");
  }
  // console.log("to --------------> ", from, to);

  // 这里的东西的意思是从 / -> /xxx 就是从门户端跳转回来
  if (from.path == "/") {
    if (sessionStorage.getItem("token")) {
      // console.log("登录了，给meanHeader赋值 ----- ===== ");
      // 平台商端到第4个 -- 产品发布
      if (sessionStorage.getItem("is_stu") == 2 && to.path == '/company/product') {
        sessionStorage.setItem("menuIndex", 3);
      }
      // 企业端到第一个 -- 需求发布
      else if (sessionStorage.getItem("is_stu") == 3 && to.path == '/requirePublic') {
        sessionStorage.setItem("menuIndex", 0);
      }
      // 子平台端到第一个 -- 四化诊断
      // 这个不用判断是否进入子平台的哪个模块，因为他就一个模块，不能跳转
      else if (sessionStorage.getItem("is_stu") == 4 && to.path == '/company/diagnose') {
        sessionStorage.setItem("menuIndex", 0);
      }
    }
  }

  // 根据登录状态判断重定向的路由地址
  if (to.path === "/") {
    // console.log('判断是否有token');
    
    if (sessionStorage.getItem("token")) {
      // console.log('有token');
      if (sessionStorage.getItem("is_stu") == 1) {
        next("/government/index");
      } else if (sessionStorage.getItem("is_stu") == 2) {
        next("/company/product");
      } else if (sessionStorage.getItem("is_stu") == 3) {
        next("/requirePublic");
      } else if (sessionStorage.getItem("is_stu") == 4) {
        console.log('有token，跳转到/company/diagnose');
        next("/company/diagnose");
      }
    } else {
      // console.log('无token');
      next("/login");
    }
  }
});

export default router;
