<template>
  <div class="main" :style="$bgImg('sihua_bg.png')">
    <div class="container">
      <div class="add-btn">
        <div class="add-btn-body pointer flex column center" @click="openResultDialog">
          <img class="add-btn-icon" :src="'/watchresult.png' | staticMedia" />
          <span class="add-btn-title">查看问卷结果</span>
        </div>
      </div>
      <div class="question-list">
        <div class="question-header flex column horizontally" :style="questionHeaderImg">
          <h3 class="question-header-title">{{ title }}</h3>
          <!-- 临时修改 2 -->
          <!-- <div class="question-header-tips">
            广州市教师数字化评分调查问卷
          </div> -->
          <div class="question-header-tips">
            1、制造业中小企业指的是从业人员1000人以下或营业收入40000万元以下的制造业企业。
          </div>
          <div class="question-header-tips">
            2、本问卷围绕数字化基础、数字化经营、数字化管理、数字化成效四个一级指标，十六个二级指标对企业现阶段数字化成熟度情况进行评估。
          </div>
          <div class="question-header-tips" style="margin-bottom: 20px">
            3、本问卷建议由企业信息化部门会同研发、采购、生产、市场等所有应用数字化设备/系统/平台的部门联合填写，以准确、客观地反映企业数字化转型实际情况。问卷结果仅供诊断数字化转型情况使用，预计答题时间约50分钟。感谢您拨冗参与。
          </div>
        </div>
        <div class="question-form">
          <!-- 临时修改 2  -->
          <h3 class="question-form-title">企业基本情况</h3>
          <!-- <h3 class="question-form-title">学校基本情况</h3> -->
          <el-form
            ref="infoForm"
            :model="infoForm"
            label-width="177px"
            label-position="left"
            size="mini"
          >
            <!-- 临时修改 2  -->
            <!-- <el-form-item label="学校名称" prop="qi_name" class="is-required"> -->
            <el-form-item label="企业名称" prop="qi_name" class="is-required">
              <el-input v-model="infoForm.qi_name" placeholder="请输入"></el-input>
            </el-form-item>
            <!-- 临时修改 2  -->
            <el-form-item label="企业地址">
              <!-- <el-form-item label="学校地址"> -->
              <el-input v-model="infoForm.qi_address" placeholder="请输入"></el-input>
            </el-form-item>
            <!-- 临时修改 2  -->
            <el-form-item class="is-required" label="所属行业大类" prop="hy1">
              <el-select
                v-model="infoForm.hy1"
                placeholder="请选择"
                id="selectTrade"
                @change="getWjTrade_xf"
              >
                <el-option
                  v-for="item in tradeOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="is-required" label="细分行业" prop="hy2">
              <el-select
                v-model="infoForm.hy2"
                placeholder="请选择"
                no-data-text="请先选择所属行业大类"
              >
                <el-option
                  v-for="item in trade_xf_Options"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <!-- 临时修改 2  -->
            <el-form-item label="企业联系人" prop="qi_username" class="is-required">
              <!-- <el-form-item label="学校联系人" prop="qi_username" class="is-required"> -->
              <el-input v-model="infoForm.qi_username" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="电话" prop="qi_telephone" class="is-required">
              <el-input v-model="infoForm.qi_telephone" placeholder="请输入"></el-input>
            </el-form-item>
            <!-- 临时修改 2  -->
            <el-form-item label="上年度企业营业收入" prop="sys" class="is-required">
              <div class="question-form-item flex vertical">
                <el-input v-model="infoForm.sys" placeholder="请输入"></el-input>
                <span class="question-form-item-unit">万元</span>
              </div>
            </el-form-item>
            <el-form-item label="上年末企业员工人数" prop="srs" class="is-required">
              <div class="question-form-item flex vertical">
                <el-input v-model="infoForm.srs" placeholder="请输入"></el-input>
                <span class="question-form-item-unit">人</span>
              </div>
            </el-form-item>
            <el-form-item class="is-required" label="企业是否为专精特新企业" prop="is_new">
              <el-radio-group v-model="infoForm.is_new">
                <el-radio label="1" selected>是</el-radio>
                <el-radio label="0">否(如选否，则直接跳到“企业简介”)</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              class="is-required"
              label="企业专精特新等级"
              prop="is_new_level"
              v-show="infoForm.is_new == '1'"
            >
              <el-radio-group v-model="infoForm.is_new_level">
                <el-radio label="国家专精特新小巨人"></el-radio>
                <el-radio label="省级专精特新"></el-radio>
                <el-radio label="市级专精特新"></el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              class="is-required"
              label="企业是否曾经获得过工信部专精特新相关奖补资金"
              prop="is_new_money"
              v-show="infoForm.is_new == '1'"
            >
              <el-radio-group v-model="infoForm.is_new_money">
                <el-radio label="1">是</el-radio>
                <el-radio label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              class="is-required"
              label="工信部专精特新相关奖补资金"
              prop="new_money"
              v-show="infoForm.is_new_money == '1' && infoForm.is_new == '1'"
            >
              <div class="question-form-item flex vertical">
                <el-input v-model="infoForm.new_money" placeholder="请填写资金金额"></el-input>
                <span class="question-form-item-unit">元</span>
              </div>
            </el-form-item>
            <el-form-item label="企业简介">
              <el-input
                v-model="infoForm.introduction"
                type="textarea"
                :rows="6"
                placeholder="请输入内容"
              >
              </el-input>
            </el-form-item>
            <el-form-item
              class="is-required"
              label="企业是否有数字化项目落地意愿"
              prop="new_project"
            >
              <el-radio-group v-model="infoForm.new_project">
                <el-radio label="1">是</el-radio>
                <el-radio label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="企业数字化建设的主要需求" prop="new_need_info">
              <el-input
                v-model="infoForm.new_need_info"
                type="textarea"
                :rows="6"
                placeholder="请输入内容"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="是否涉及数字化车间或智能工厂" prop="is_has">
              <el-radio-group v-model="infoForm.is_has">
                <el-radio label="1">是</el-radio>
                <el-radio label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>

          <el-form ref="questionForm" label-width="40px" size="mini" class="question-form2">
            <div v-for="(item, index) in formList" :key="index">
              <h4 class="question-form2-h4 first">{{ index + 1 }}、{{ item.name }}</h4>
              <div v-for="(sItem, sIndex) in item.s_cate" :key="index + '.' + sIndex" class="pl24">
                <h5 class="question-form2-h5 second">
                  {{ index + 1 }}.{{ sIndex + 1 }} {{ sItem.name }}
                </h5>
                <div
                  v-for="(tItem, tIndex) in sItem.ti_data"
                  :key="index + '.' + sIndex + '.' + tIndex"
                  class="pl24"
                >
                  <h5 class="question-form2-h5 third">
                    {{ index + 1 }}.{{ sIndex + 1 }}.{{ tIndex + 1 }}
                    {{ tItem.name }}
                  </h5>
                  <!--is_type: {{ tItem.is_type }}-->
                  <!--单选题-->
                  <el-form-item v-if="tItem.is_type == '1'">
                    <el-radio-group v-model="tItem.answer" class="mt5">
                      <el-radio
                        v-for="(answer, aIndex) in tItem.content"
                        :key="aIndex"
                        :label="answer.key"
                        >{{ answer.key + ". " + answer.value }}</el-radio
                      >
                    </el-radio-group>
                  </el-form-item>
                  <!--多选题-->
                  <el-form-item v-if="tItem.is_type == '2'">
                    <el-checkbox-group v-model="tItem.answer">
                      <el-checkbox
                        v-for="(answer, aIndex) in tItem.content"
                        :key="aIndex"
                        :label="answer.key"
                        >{{ answer.key + ". " + answer.value }}</el-checkbox
                      >
                    </el-checkbox-group>
                  </el-form-item>
                  <!--判断题-->
                  <el-form-item v-if="tItem.is_type == '3'">
                    <el-switch v-model="tItem.answer"></el-switch>
                  </el-form-item>
                  <!--简单题-->
                  <el-form-item v-if="tItem.is_type == '4'">
                    <el-input v-model="tItem.answer" class="mt5" placeholder="请输入"></el-input>
                  </el-form-item>
                </div>
              </div>
            </div>
            <div class="question-form2-btns flex horizontally">
              <el-button class="primaryBtn1 btn" @click="handleSubmit">提交</el-button>
              <el-button class="cancleBtn1 btn" @click="handleCancle">取消</el-button>
            </div>
          </el-form>
        </div>
      </div>
    </div>
    <el-dialog
      title="选择问卷结果"
      :visible.sync="resultDialog"
      width="30%"
      :close-on-click-modal="false"
    >
      <div class="result-btn flex vertical" @click="getSZHQuestionResult">
        <img class="result-btn-img" :src="'/szh_wj_nav.png' | staticMedia" alt="" />
        制造业中小企业数字化水平评估问卷结果
        <!-- 临时修改 2  -->
        <!-- 数字化评分调查问卷结果 -->
      </div>
    </el-dialog>
    <!-- 临时修改 2  -->
    <el-dialog
      title="制造业中小企业数字化水平评估问卷结果"
      :visible.sync="shuZiHuaResultDialog"
      width="788px"
      top="3vh"
      :close-on-click-modal="false"
    >
      <!-- <el-dialog
      title="数字化评分调查问卷结果"
      :visible.sync="shuZiHuaResultDialog"
      width="788px"
      top="3vh"
      :close-on-click-modal="false"
    > -->
      <div class="shuzihua-result">
        <div class="shuzihua-result-img">
          <img class="shuzihua-result-image" :src="'/resultbac.png' | staticMedia" alt="" />
        </div>
        <div class="shuzihua-part">
          <h4 class="shuzihua-part-title flex vertical">
            <!-- 临时修改 2  -->
            <span class="blue-point"></span>制造业中小企业数字化水平得分情况
            <!-- <span class="blue-point"></span>数字化评分调查问卷得分情况 -->
          </h4>
          <div class="shuzihua-part-text">
            <p>
              您公司在基础要素、数字化转型
              、网络化协同、智能化制造、绿色化提升五个一级指标得分如下表所示，分别体现您的自评估得分和评估诊断机构的评估得分。
            </p>
          </div>
          <div class="shuzihua-part-table">
            <div class="shuzihua-part-table-title flex horizontally">
              <div class="shuzihua-table-title-item">一级指标</div>
              <div class="shuzihua-table-title-item">一级指标得分</div>
            </div>
            <div
              class="shuzihua-part-table-list flex horizontally"
              v-for="(item, index) in tipList"
              :key="index"
              v-show="item.name != '其他相关信息'"
            >
              <div class="shuzihua-table-list-item">
                {{ item.name }}
              </div>
              <div class="shuzihua-table-list-item">
                {{ item.statistical }}
              </div>
            </div>
            <div class="shuzihua-part-table-list flex horizontally">
              <div class="shuzihua-table-list-item">综合得分</div>
              <div class="shuzihua-table-list-item">
                {{ dataList.total }}
              </div>
            </div>
            <div class="shuzihua-part-table-list flex horizontally">
              <div class="shuzihua-table-list-item">企业评定等级</div>
              <div class="shuzihua-table-list-item">
                {{
                  dataList.level == 1
                    ? "一级"
                    : dataList.level == 2
                    ? "二级"
                    : dataList.level == 3
                    ? "三级"
                    : dataList.level == 4
                    ? "四级"
                    : "五级"
                }}
              </div>
            </div>
          </div>
          <div class="shuzihua-part-text">
            <p>
              <b class="shuzihua-part-text-weight">各等级主要特征描述如下：</b>
            </p>
            <p>
              <b class="shuzihua-part-text-weight">一级(20—40分):</b
              >开展了基础业务流程梳理和数据规范化管理，并进行了信息技术简单应用。
            </p>
            <p>
              <b class="shuzihua-part-text-weight">二级(40—60分):</b
              >利用信息技术手段或管理工具实现了单一业务数字化管理。
            </p>
            <p>
              <b class="shuzihua-part-text-weight">三级(60—80分):</b
              >应用信息系统及数字化技术进行数据分析，实现全部主营业务数字化管控。
            </p>
            <p>
              <b class="shuzihua-part-text-weight">四级(80分以上):</b
              >利用全业务链数据集成分析，实现数据驱动的业务协同与智能决策。
            </p>
          </div>
          <div class="shuzihua-part">
            <h4 class="shuzihua-part-title flex vertical">
              <!-- 临时修改 2  -->
              <span class="blue-point"></span>企业一级指标得分雷达图
              <!-- <span class="blue-point"></span>学校一级指标得分雷达图 -->
            </h4>
            <div class="shuzihua-echarts" ref="shuzihuaEcharts"></div>
          </div>
        </div>
      </div>
    </el-dialog>

    <Footer></Footer>
  </div>
</template>
<script>
import {
  getSZHQuestionList,
  getTradeOptions,
  getTradeXFOptions,
  setSZHQuestion,
  getSZHResult,
} from "@/api/qy";
export default {
  components: {},
  data() {
    return {
      // Dialog
      resultDialog: false,
      shuZiHuaResultDialog: false,
      // 临时修改 2
      title: "制造业中小企业数字化水平评估问卷",
      // title: "广州市教师数字化评分调查问卷",

      // 企业信息表格（非问卷
      infoForm: {
        qi_name: "",
        qi_address: "",
        hy1: "",
        hy2: "",
        qi_username: "",
        qi_telephone: "",
        sys: "",
        srs: "",
        is_new: "",
        is_new_level: "",
        is_new_money: "",
        new_money: "",
        introduction: "",
        new_project: "",
        new_need_info: "",
        is_has: "",
      },
      formList: [],
      // 行业选项列表
      tradeOptions: [],
      // 细分行业选项列表
      trade_xf_Options: [],
      // 问卷结果表格数据
      tipList: [
        {
          name: "数字化成效",
          statistical: "1.00",
        },
        {
          name: "数字化管理",
          statistical: "1.10",
        },
        {
          name: "数字化经营",
          statistical: "2.10",
        },
      ],
      // 问卷结果表格数据
      dataList: {
        total: "200.00",
        level: "1",
      },
      option: {
        tooltip: {
          show: true,
        },
        radar: [
          {
            indicator: [
              { text: "基础要素", color: "#205dc3", min: 0, max: 10 },
              { text: "绿色化提升", color: "#205dc3", min: 0, max: 10 },
              { text: "智能化制造", color: "#205dc3", min: 0, max: 10 },
              { text: "网络化协同", color: "#205dc3", min: 0, max: 10 },
              { text: "数字化转型", color: "#205dc3", min: 0, max: 10 },
            ],
            center: ["50%", "50%"],
            radius: 215,
            startAngle: 90,
            splitNumber: 4,
            shape: "circle",
            scale: true,
            axisName: {
              formatter: "{value}",
              color: "#205dc3",
            },
            splitArea: {
              show: true,
              areaStyle: {
                color: ["#64AFE945", "#64AFE990", "#64AFE9", "#428BD4"],
                shadowColor: "rgba(0, 0, 0, 0.2)",
                shadowBlur: 10,
              },
            },
            axisLine: {
              lineStyle: {
                color: "rgba(211, 253, 250, 0.8)",
              },
            },
            splitLine: {
              lineStyle: {
                color: "rgba(211, 253, 250, 0.8)",
              },
            },
          },
        ],
        series: [
          {
            type: "radar",
            lineStyle: {
              width: 4,
            },
            emphasis: {
              lineStyle: {
                width: 5,
              },
            },
            data: [
              {
                value: [10, 20, 10, 10, 10],
                name: "",
              },
            ],
          },
        ],
      },
    };
  },
  computed: {
    questionHeaderImg() {
      const filteredImageUrl = this.$options.filters.staticMedia("/text_table_bg.png");
      return {
        backgroundImage: `url(${filteredImageUrl})`,
      };
    },
  },
  created() {},
  mounted() {
    this.getQuestionList();
    this.getTradeOptions();
  },
  methods: {
    getSZHQuestionResult() {
      let _this = this;
      _this.shuZiHuaResultDialog = true;
      _this.resultDialog = false;

      getSZHResult().then((res) => {
        console.log("res", res);
        _this.dataList = res.data;
        _this.tipList = res.data.data;
        _this.zdScoreList = res.data.data2;

        let ayy = [];
        let sna = [];

        // 默认最大为35
        let maxScore = 0;
        _this.tipList.forEach((item) => {
          if (item.name != "其他相关信息") {
            if (maxScore < Number(item.statistical)) {
              maxScore = item.statistical;
            }
          }
        });

        _this.tipList.forEach((item) => {
          console.log(item);
          if (item.name != "其他相关信息") {
            let obj = {
              text: item.name,
              color: "#205dc3",
              min: 0,
              max: maxScore,
            };
            ayy.push(obj);
            sna.push(item.statistical);
          }
        });

        _this.option.radar[0].indicator = ayy;
        _this.option.series[0].data[0].value = sna;

        setTimeout(() => {
          _this.chartDom = _this.$refs.shuzihuaEcharts;
          _this.myChart = _this.$echarts.init(_this.chartDom);
          _this.myChart.setOption(_this.option);
        }, 500);
      });
    },
    handleSubmit() {
      var _this = this;
      let loading = _this.$loading({
        lock: true,
        text: "提交中",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const answer = _this.getAnswer();
      var form = _this.infoForm;
      // 临时修改 2
      if (form.qi_name == "" || form.qi_name == null) {
        _this.$message({
          message: "请输入企业名称！",
          type: "warning",
        });
        loading.close();
        return false;
      }
      if (form.hy1 == "" || form.hy1 == null) {
        _this.$message({
          message: "请选择所属行业大类！",
          type: "warning",
        });
        loading.close();
        return false;
      }
      if (form.hy2 == "" || form.hy2 == null) {
        _this.$message({
          message: "请选择细分行业！",
          type: "warning",
        });
        loading.close();
        return false;
      }
      if (form.qi_username == "" || form.qi_username == null) {
        _this.$message({
          message: "请输入企业联系人！",
          type: "warning",
        });
        loading.close();
        return false;
      }
      if (form.qi_telephone == "" || form.qi_telephone == null) {
        _this.$message({
          message: "请输入电话！",
          type: "warning",
        });
        loading.close();
        return false;
      }
      if (form.qi_telephone.length !== 11) {
        _this.$message({
          message: "请输入正确的手机号码！",
          type: "warning",
        });
        loading.close();
        return false;
      }
      if (form.sys == "" || form.sys == null) {
        _this.$message({
          message: "请输入上年度企业营业收入！",
          type: "warning",
        });
        loading.close();
        return false;
      }
      if (form.srs == "" || form.srs == null) {
        _this.$message({
          message: "请输入上年末企业员工人数！",
          type: "warning",
        });
        loading.close();
        return false;
      }
      if (form.is_new == "" || form.is_new == null) {
        _this.$message({
          message: "请选择企业是否为专精特新企业！",
          type: "warning",
        });
        loading.close();
        return false;
      }
      if (form.is_new == 1 && (form.is_new_level == "" || form.is_new_level == null)) {
        _this.$message({
          message: "请选择企业专精特新等级！",
          type: "warning",
        });
        loading.close();
        return false;
      }
      if (form.is_new == 1 && (form.is_new_money == "" || form.is_new_money == null)) {
        _this.$message({
          message: "请选择企业是否曾经获得过工信部专精特新相关奖补资金！",
          type: "warning",
        });
        loading.close();
        return false;
      }
      if (
        form.is_new == 1 &&
        form.is_new_money == 1 &&
        (form.new_money == "" || form.new_money == null)
      ) {
        _this.$message({
          message: "请输入工信部专精特新相关奖补资金！",
          type: "warning",
        });
        loading.close();
        return false;
      }
      if (form.new_project == "" || form.new_project == null) {
        _this.$message({
          message: "请选择企业是否有数字化项目落地意愿！",
          type: "warning",
        });
        loading.close();
        return false;
      }

      let new_data = {
        hy1: "" + form.hy1 || "",
        hy2: "" + form.hy2 || "",
        is_new_level: form.is_new_level || "",
        is_new_money: form.is_new_money || "",
        new_money: form.new_money || "",
        introduction: form.introduction || "",
        new_project: form.new_project || "",
        new_need_info: form.new_need_info || "",
        is_has: form.is_has || "",
      };
      let pdata = {
        qi_name: form.qi_name || "",
        qi_username: form.qi_username || "",
        qi_telephone: form.qi_telephone || "",
        qi_address: form.qi_address || "",
        trade_id: "" + form.hy1 || "",
        sshy: "" + form.hy2 || [],
        sys: form.sys || "",
        srs: form.srs || "",
        answer: JSON.stringify(answer),
        is_new: form.is_new || "",
        new_data: JSON.stringify(new_data),
      };

      setSZHQuestion(pdata)
        .then((res) => {
          _this.$message.success(res.msg);
          setTimeout(() => {
            _this.$router.go(0);
          }, 1500);
        })
        .catch((err) => {
          _this.$message.error(err || err.msg);
        })
        .finally(() => {
          loading.close();
          setTimeout(() => {
            document.documentElement.scrollTop = 0;
          }, 1500);
        });
    },
    handleCancle() {},
    openResultDialog() {
      this.resultDialog = true;
    },
    getTradeOptions() {
      let pdata = {
        pid: 0,
      };
      getTradeOptions(pdata).then((res) => {
        console.log("res", res);
        this.tradeOptions = res.data.map((e) => {
          return {
            id: e.id,
            name: e.name,
          };
        });
      });
    },
    getWjTrade_xf(id) {
      console.log("123", id);
      let pdata = {
        pid: id,
      };
      getTradeXFOptions(pdata).then((res) => {
        console.log("res", res);
        this.trade_xf_Options = res.data.map((e) => {
          return {
            id: e.id,
            name: e.name,
          };
        });
      });
    },
    handleAnswer(list) {
      list.forEach((el) => {
        if (el.s_cate && el.s_cate.length) {
          el.s_cate.forEach((e) => {
            if (e.ti_data && e.ti_data.length) {
              e.ti_data.forEach((tiObj) => {
                if (tiObj.is_type == "2") {
                  tiObj.answer = [];
                }
              });
            }
          });
        }
      });
      return list;
    },
    getQuestionList() {
      let _this = this;
      getSZHQuestionList()
        .then((res) => {
          console.log("res", res);
          _this.formList = _this.handleAnswer(res.data.p_cate);
          _this.infoForm.qi_name = res.data.name;
          _this.infoForm.qi_username = res.data.truename;
          _this.infoForm.qi_telephone = res.data.mobile;
          _this.infoForm.qi_address = `${res.data.province}${res.data.city}${res.data.area}`;
        })
        .finally(() => {});
    },
    getAnswer() {
      var answer = {};
      this.formList.forEach(function (el) {
        if (el.s_cate && el.s_cate.length) {
          answer[el.id] = {};
          el.s_cate.forEach(function (e) {
            if (e.ti_data && e.ti_data.length) {
              answer[el.id][e.id] = {};
              e.ti_data.forEach(function (tiObj) {
                if (typeof tiObj.answer == "string") {
                  answer[el.id][e.id][tiObj.id] = tiObj.answer ? [tiObj.answer] : [];
                } else {
                  answer[el.id][e.id][tiObj.id] = tiObj.answer || [];
                }
              });
            }
          });
        }
      });
      return answer;
    },
  },
};
</script>
<style lang="less" scoped>
.el-button + .el-button {
  margin-left: 30px;
}
.container {
  position: relative;
  .question-list {
    width: 84%;
    margin: 0 auto;
    .question-header {
      width: 100%;
      height: 300px;
      background-size: 100% 100%;
      color: #fff;
      padding: 0 2% 0 32%;
      margin-bottom: 20px;
      box-sizing: border-box;
      .question-header-title {
        font-size: 24px;
        line-height: 36px;
        height: 36px;
        margin-bottom: 20px;
      }

      .question-header-tips {
        line-height: 32px;
        font-size: 15px;
      }
    }
    .question-form {
      background-color: #fff;
      padding: 40px 200px;
      border-radius: 10px;
      .question-form-title {
        position: relative;
        margin-bottom: 88px;
        font-size: 24px;
        text-align: center;
        &::before {
          content: "";
          width: 300px;
          height: 4px;
          position: absolute;
          display: inline-block;
          width: 250px;
          height: 4px;
          background-color: #205dc3;
          bottom: -10px;
          right: 0;
          left: 0;
          margin: 0 auto;
          border-radius: 3px;
        }
      }
      .question-form-item {
        .question-form-item-unit {
          display: inline-block;
          width: 35px;
          text-align: left;
          margin-left: 10px;
          font-size: 14px;
          color: #606266;
        }
      }
      .el-form {
        width: 84%;
      }
      .question-form2 {
        .question-form2-h4,
        .question-form2-h5 {
          font-size: 14px;
          color: #606266;
        }
        .first {
          padding: 8px 0;
        }
        .second {
          padding: 5px 0;
          text-indent: 2rem;
        }
        .third {
          padding: 5px 0;
          text-indent: 4rem;
          line-height: 1.5;
        }
        .el-form-item--mini.el-form-item {
          padding: 0 2rem;
        }
        .el-radio {
          margin-bottom: 10px;
        }
        .el-checkbox {
          display: flex;
          white-space: pre-wrap;
          .el-checkbox__input {
            padding-top: 3px;
          }
        }
      }
    }
  }
}
.result-btn {
  width: fit-content;
  height: 80px;
  font-size: 18px;
  color: #333;
  padding: 0 10px;
  &:hover {
    color: #fff;
    background-color: #205dc3;
    border-radius: 10px;
  }
  .result-btn-img {
    width: 46px;
    height: 46px;
    margin-right: 10px;
  }
}
.shuzihua-result {
  padding: 0 50px 10px;
  .shuzihua-result-image {
    width: 100%;
  }
  .shuzihua-part {
    padding: 10px 0;
    .shuzihua-part-title {
      font-size: 20px;
      font-weight: 600;
      color: #333;
      .blue-point {
        width: 10px;
        height: 10px;
        background-color: #205dc3;
        border-radius: 10px;
        margin: 0 5px;
      }
    }
    .shuzihua-part-text {
      line-height: 1.5;
      margin-top: 10px;
      margin-left: 20px;
      color: #333;
      .shuzihua-part-text-weight {
        color: #333;
        font-weight: 600;
      }
    }
    .shuzihua-part-table {
      width: 373px;
      margin: 10px auto;
      border: 1px solid #d8d8d8;
      .shuzihua-part-table-title {
        .shuzihua-table-title-item {
          width: 186px;
          height: 46px;
          line-height: 46px;
          background-color: #205dc3;
          text-align: center;
          color: #fff;
          border-right: 1px solid #d8d8d8;
          &:last-child {
            border-right: none;
          }
        }
      }
      .shuzihua-part-table-list {
        &:last-child {
          .shuzihua-table-list-item {
            border-bottom: none;
          }
        }
        .shuzihua-table-list-item {
          width: 186px;
          height: 56px;
          line-height: 56px;
          color: #333;
          text-align: center;
          border-bottom: 1px solid #d8d8d8;
          border-right: 1px solid #d8d8d8;
          &:last-child {
            border-right: none;
          }
        }
      }
    }
    .shuzihua-echarts {
      width: 600px;
      height: 500px;
      margin: 10px auto;
    }
  }
}
</style>
