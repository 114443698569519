import { render, staticRenderFns } from "./changeDialog.vue?vue&type=template&id=a545a822&scoped=true&"
import script from "./changeDialog.vue?vue&type=script&lang=js&"
export * from "./changeDialog.vue?vue&type=script&lang=js&"
import style0 from "./changeDialog.vue?vue&type=style&index=0&id=a545a822&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a545a822",
  null
  
)

export default component.exports