<!-- 支持传入ID以创建多个富文本编辑器 -->
<template>
  <div :ref="wangId"></div>
</template>

<script>
import { UploadFile } from "@/api/login";
import configs from "@/config/index";
import WangEditor from "wangeditor";

export default {
  props: {
    defVal: {
      type: String,
      default: "",
    },
    wangId: {
      type: String,
      default: "wangeditor",
    },
  },
  data() {
    return {
      editor: null,
    };
  },
  watch: {
    async defVal(newVal) {
      console.log("newVal", newVal);
      await this.onCreatedEditor();
      this.editor.txt.html(newVal);
    },
  },
  methods: {
    onCreatedEditor() {
      let _this = this;
      _this.editor = new WangEditor(_this.$refs[_this.wangId]);
      _this.editor.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "fontName", // 字体
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "link", // 插入链接
        "list", // 列表
        "justify", // 对齐方式
        "emoticon", // 表情
        "image", // 插入图片
        "undo", // 撤销
        "redo", // 重复
      ];
      // 设置编辑器层级
      _this.editor.config.zIndex = 10;
      // 上传图片到服务器,对应的是controller层的@RequestMapping("/upload")
      //接口名称
      _this.editor.config.uploadImgServer = `${configs.baseUrl}/api/common/upload`;
      _this.editor.config.customUploadImg = function(resultFiles, insertImgFn) {
        console.log("resultFiles", resultFiles);
        console.log("insertVideoFn", insertImgFn);

        // resultFiles 是 input 中选中的文件列表
        // insertVideoFn 是获取视频 url 后，插入到编辑器的方法
        _this.uploadImg(resultFiles[0], insertImgFn);
      };

      _this.editor.create();
    },
    onDestoryEditor() {
      this.editor = null;
    },
    getEditorHTML() {
      return this.editor.txt.html();
    },
    setEditorHTML(html) {
      this.editor.txt.html(html);
    },
    uploadImg(file, insertFn) {
      // file 即选中的文件
      // 自己实现上传，并得到图片 url alt href
      let _this = this;
      let fileObj = {};
      let formData = new FormData();
      formData.append("file", file);

      UploadFile(formData)
        .then((res) => {
          console.log("res", res);
          if (res.code == 1) {
            _this.$message.success("上传成功");
            fileObj = res.data;
            // 最后插入图片
            console.log(res);
            insertFn(
              configs.baseUrl + fileObj.fullurl,
              fileObj.name,
              configs.baseUrl + fileObj.fullurl
            );
          }
        })
        .catch((err) => {
          console.log("err", err);
        })
        .finally(() => {
          console.log("finally");
        });
    },
  },
};
</script>

<style lang="less" scoped></style>
