<template>
  <div class="main-container">
    <div class="main-info">
      <div class="main-content">
        <div class="picture">
          <img :src="itemDetail.image | contentMedia" alt="" />
        </div>
        <div class="desc">
          <div class="title">
            <h4 class="line1">
              {{ itemDetail.info.title || "" }}
            </h4>
          </div>
          <div class="content">{{ itemDetail.info.sub_title || "" }}</div>
        </div>
      </div>
      <div class="btns">
        <el-button class="widthBtn btn primaryBtn1" @click="handleEdit"
          >修改</el-button
        >
        <el-button class="widthBtn btn cancleBtn1" @click="handleDel"
          >删除</el-button
        >
      </div>
    </div>
    <div class="other-info">
      <div class="source">
        <img :src="'/source.png' | staticMedia" alt="" />
        来源：{{ itemDetail.info.source || "" }}
      </div>
      <div class="time">
        <img :src="'/time.png' | staticMedia" alt="" />
        {{ formatDateTime(itemDetail.publish_time) }}
      </div>
    </div>
  </div>
</template>

<script>
import { formatDateTime } from "@/utils/common.js";
export default {
  props: {
    itemDetail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      createTime: formatDateTime(this.itemDetail.createtime),
    };
  },
  mounted() {},
  methods: {
    formatDateTime(timetemp) {
      return formatDateTime(timetemp);
    },
    handleEdit() {
      this.$emit("editContent", true, this.itemDetail);
    },
    handleDel() {
      this.$emit("openDeleteDialog", true, this.itemDetail);
    },
  },
};
</script>

<style lang="less" scoped>
.main-container {
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
  padding: 40px 28px 0;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  position: relative;
  box-sizing: border-box;
  transition: all 0.5s;

  &::before {
    content: "";
    height: 22px;
    width: 2px;
    background-color: #205dc3;
    position: absolute;
    left: 0;
    top: 20px;
  }

  .main-info {
    height: 125px;
    padding-bottom: 40px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #d8d8d8;

    .main-content {
      width: 80%;
      position: relative;
      cursor: pointer;
      color: #333333;
      display: flex;

      .picture {
        width: 16.2%;
        height: 124px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: all 0.5s;
        }
      }

      .desc {
        width: 70%;
        height: 100%;
        border-left: none;
        padding: 0px 20px;
        flex: 1;

        .title {
          width: 100%;
          line-height: 30px;
          // white-space: nowrap;
          // overflow: hidden;
          // text-overflow: ellipsis;

          h4 {
            font-size: 20px;
            font-weight: bold;
            display: inline-block;
            color: #333333;
            margin: 0;
            margin-bottom: 8px;
          }
        }

        .content {
          width: 100%;
          height: 82px;
          line-height: 26px;
          font-size: 16px;
          color: #999999;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &:hover .title h4 {
        color: #205dc3;
      }
    }

    .btns {
      width: 10%;
      margin: auto 0;
    }
  }

  .other-info {
    height: 66px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      height: 26px;
      vertical-align: middle;
    }
  }

  &:hover {
    box-shadow: 0 0 10px rgba(74, 109, 231, 0.4);
  }

  :hover .picture img {
    transform: scale(1.2);
    transition: all 0.5s;
  }

  &:hover .desc .title h4 {
    color: #205dc3;
  }
}

.el-button + .el-button,
.el-checkbox.is-bordered + .el-checkbox.is-bordered {
  margin-left: 0;
  margin-top: 4px;
}
</style>
