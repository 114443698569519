import Header from "@/components/CenterHeader";
import Footer from "@/components/Footer.vue";
import Product from "@/views/pts/product/index";
import ProductDetail from "@/views/pts/product/detail";
import ProductForm from "@/views/pts/product/form";
import ClassicCases from "@/views/pts/classicCases/index";
import ClassicCasesForm from "@/views/pts/classicCases/form";
import ClassicCasesDetail from "@/views/pts/classicCases/detail";
import NeedInfo from "@/views/pts/needInfo/index";
import NeedInfoDetail from "@/views/pts/needInfo/detail";
import CompanyInfo from "@/views/pts/companyInfo/index";
import CompanyInfoForm from "@/views/pts/companyInfo/form";
import Solution from "@/views/pts/solution/index";
import SolutionForm from "@/views/pts/solution/form";
import Xxbt from "@/views/pts/xxtb/index";
import Dwsp from "@/views/pts/dwsp/index";
import Diagnose from "@/views/pts/diagnose/index";
import DiagnoseWj from "@/views/pts/diagnose/question/index";
import Application from "@/views/pts/ApplicationSolicitation/index";
import ApplicationList from "@/views/pts/ApplicationSolicitation/list";
import ApplicationForm from "@/views/pts/ApplicationSolicitation/form";
import SearchTableForm from "@/views/pts/ApplicationSolicitation/form2";
import SthzhbForm from "@/views/pts/ApplicationSolicitation/form3";
import SthzhbList from "@/views/pts/ApplicationSolicitation/list3";
// 平台商

export default [
  {
    path: "/company/product",
    name: "Product",
    components: {
      header: Header,
      default: Product, //默认省略不写name的情况
      footer: Footer,
      meta: {
        keepAlive: true,
      },
    },
  },
  {
    path: "/company/product/detail",
    name: "ProductDetail",
    components: {
      header: Header,
      default: ProductDetail,
      footer: Footer,
      meta: {
        keepAlive: true,
      },
    },
  },
  {
    path: "/company/product/add",
    name: "ProductAdd",
    components: {
      header: Header,
      default: ProductForm,
      footer: Footer,
      meta: {
        keepAlive: true,
      },
    },
  },
  {
    path: "/company/product/edit",
    name: "ProductEdit",
    components: {
      header: Header,
      default: ProductForm,
      footer: Footer,
      meta: {
        keepAlive: true,
      },
    },
  },
  {
    path: "/company/needinfo",
    name: "NeedInfo",
    components: {
      header: Header,
      default: NeedInfo,
      footer: Footer,
      meta: {
        keepAlive: true,
      },
    },
  },
  {
    path: "/company/needinfo/detail",
    name: "NeedInfoDetail",
    components: {
      header: Header,
      default: NeedInfoDetail,
      footer: Footer,
      meta: {
        keepAlive: true,
      },
    },
  },
  {
    path: "/company/classicCases",
    name: "ClassicCases",
    components: {
      header: Header,
      default: ClassicCases,
      footer: Footer,
      meta: {
        keepAlive: true,
      },
    },
  },
  {
    path: "/company/classicCases/edit",
    name: "ClassicCasesEdit",
    components: {
      header: Header,
      default: ClassicCasesForm,
      footer: Footer,
      meta: {
        keepAlive: true,
      },
    },
  },
  {
    path: "/company/classicCases/add",
    name: "ClassicCasesAdd",
    components: {
      header: Header,
      default: ClassicCasesForm,
      footer: Footer,
      meta: {
        keepAlive: true,
      },
    },
  },
  {
    path: "/company/classicCases/detail",
    name: "ClassicCasesDetail",
    components: {
      header: Header,
      default: ClassicCasesDetail,
      footer: Footer,
      meta: {
        keepAlive: true,
      },
    },
  },
  {
    path: "/company/companyInfo",
    name: "CompanyInfo",
    components: {
      header: Header,
      default: CompanyInfo,
      footer: Footer,
      meta: {
        keepAlive: true,
      },
    },
  },
  {
    path: "/company/companyInfo/edit",
    name: "CompanyInfoForm",
    components: {
      header: Header,
      default: CompanyInfoForm,
      footer: Footer,
      meta: {
        keepAlive: true,
      },
    },
  },
  {
    path: "/company/solution",
    name: "Solution",
    components: {
      header: Header,
      default: Solution,
      footer: Footer,
      meta: {
        keepAlive: true,
      },
    },
  },
  {
    path: "/company/solution/add",
    name: "SolutionAdd",
    components: {
      header: Header,
      default: SolutionForm,
      footer: Footer,
      meta: {
        keepAlive: true,
      },
    },
  },
  {
    path: "/company/solution/edit",
    name: "SolutionEdit",
    components: {
      header: Header,
      default: SolutionForm,
      footer: Footer,
      meta: {
        keepAlive: true,
      },
    },
  },
  {
    path: "/company/xxtb",
    name: "Xxtb",
    components: {
      header: Header,
      default: Xxbt,
      footer: Footer,
      meta: {
        keepAlive: true,
      },
    },
  },
  {
    path: "/company/dwsp",
    name: "Dwsp",
    components: {
      header: Header,
      default: Dwsp,
      footer: Footer,
      meta: {
        keepAlive: true,
      },
    },
  },
  {
    path: "/company/diagnose",
    name: "diagnose",
    components: {
      header: Header,
      default: Diagnose,
      footer: Footer,
      meta: {
        keepAlive: true,
      },
    },
  },
  {
    path: "/company/diagnose/question",
    name: "DiagnoseQuestion",
    components: {
      header: Header,
      default: DiagnoseWj,
      footer: Footer,
      meta: {
        keepAlive: true,
      },
    },
  },
  {
    path: "/company/sbzj",
    name: "Sbzj",
    components: {
      header: Header,
      default: Application,
      footer: Footer,
      meta: {
        keepAlive: true,
      },
    },
  },
  {
    path: "/company/sbzj/list",
    name: "SbzjList",
    components: {
      header: Header,
      default: ApplicationList,
      footer: Footer,
      meta: {
        keepAlive: true,
      },
    },
  },
  {
    path: "/company/sbzj/form",
    name: "SbzjForm",
    components: {
      header: Header,
      default: ApplicationForm,
      footer: Footer,
      meta: {
        keepAlive: true,
      },
    },
  },
  {
    path: "/company/mcb/form",
    name: "McbForm",
    components: {
      header: Header,
      default: SearchTableForm,
      footer: Footer,
      meta: {
        keepAlive: true,
      },
    },
  },
  {
    path: "/company/sthzhb/form",
    name: "SthzhbForm",
    components: {
      header: Header,
      default: SthzhbForm,
      footer: Footer,
      meta: {
        keepAlive: true,
      },
    },
  },
  {
    path: "/company/sthzhb/list",
    name: "SthzhbList",
    components: {
      header: Header,
      default: SthzhbList,
      footer: Footer,
      meta: {
        keepAlive: true,
      },
    },
  },
];
