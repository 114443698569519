import { IMG_CONFIG } from "@/config/env";

export class MediaUrl {
  static prefix = "v2szz_media_";
  /**
   * 动态拼接资源参数
   * @param {String} type 图片类型 DEFAULT自有资源；CONTENT动态内容图片
   * @param {String} key
   * @returns
   */
  static getImgUrl(type = "DEFAULT", key) {
    if (type === "CONTENT") {
      console.log(IMG_CONFIG.SERVER, "IMG_CONFIG.SERVER");
      if (key.indexOf(MediaUrl.prefix) === -1) {
        return IMG_CONFIG.SERVER + key;
      } else {
        throw new Error("参数错误，不存在该模式");
      }
    }
    let path = "";
    if (type === "DEFAULT") {
      path = IMG_CONFIG.static;
    } else {
      path = IMG_CONFIG.content;
    }
    return IMG_CONFIG.SERVER + path + key;
  }
}
