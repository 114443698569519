<template>
  <div class="main-container">
    <div class="chart-list">
      <div class="chart-item" id="chart1"></div>
      <div class="chart-item" id="chart2"></div>
      <div class="chart-item" id="chart3"></div>
    </div>
    <el-button class="primaryBtn1 btn" @click="downsel">批量导出</el-button>
    <Table
      :multipleSelect="true"
      :orderNumber="true"
      :tableData="tableData"
      :columns="columns"
      :page="page"
      :pageSize="pageSize"
      @tableSelect="moreseclect"
    ></Table>
    <div class="pagination-container">
      <Pagination
        :total="total"
        :currentPage="page"
        :pageSize="pageSize"
        :pageSizes="pageSizes"
        :layout="'prev, pager, next, jumper,total, sizes'"
        @pageChange="pageChange"
        @sizeChange="sizeChange"
      >
      </Pagination>
    </div>
  </div>
</template>

<script>
import Table from "@/components/Table";
import Pagination from "@/components/Pagination";
import { fourRemodelList, fourRemodelchart } from "@/api/zf";
import * as echarts from "echarts";
import configs from "@/config/index";
export default {
  components: {
    Table,
    Pagination,
  },
  data() {
    return {
      tableData: [],
      columns: [
        {
          id: 1,
          title: "ID",
          prop: "id",
        },
        {
          id: 2,
          title: "企业名称",
          prop: "qi_name",
          showTopTip: true,
        },
        {
          id: 3,
          title: "企业地点",
          prop: "qi_addres",
          showTopTip: true,
        },
        {
          id: 4,
          title: "情况描述",
          prop: "content",
          showTopTip: true,
        },
        {
          id: 5,
          title: "联系人",
          prop: "username",
        },
        {
          id: 6,
          title: "联系电话",
          prop: "mobile",
          showTopTip: true,
        },
        {
          id: 7,
          title: "列表图",
          prop: "image",
          image: true,
        },
        {
          id: 8,
          title: "证明材料",
          prop: "files",
          width: 138,
          minWidth: 100,
        },
      ],
      total: 0,
      page: 1,
      pageSize: 10,
      pageSizes: [10, 15, 20, 25, 30],
      option1: {
        tooltip: {
          trigger: "axis",
          // axisPointer: {
          //     type: 'shadow'
          // }
        },
        grid: {
          left: "0%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: {
              show: true,
              excludeComponents: ["toolbox"],
              pixelRatio: 2,
            },
          },
        },
        xAxis: {
          type: "value",
          splitArea: { show: false },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false, //隐藏x轴线
          },
          splitLine: {
            show: false,
          },
        },
        yAxis: {
          type: "category",
          data: [],
          axisLabel: {
            // interval: 0,
            // textStyle: {
            //     fontSize: 14,
            //     color: "#333333",
            // },
            // formatter: function (params) {
            //     return this.handleText(params);
            // },
          },
          splitArea: { show: false },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false, //隐藏x轴线
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            data: [],
            type: "bar",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(196, 214, 240, 0.4)",
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#00B2FF" },
                // { offset: 0.5, color: '#188df0' },
                { offset: 1, color: "#016DFF" },
              ]),
            },
          },
        ],
      },
      myChart1: "",
      option2: {
        tooltip: {
          trigger: "axis",
          // axisPointer: {
          //     type: 'shadow'
          // }
        },
        // formatter: function(val) {
        //     return val.split("-").join("\n");
        // },
        grid: {
          left: "0%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: {
              show: true,
              excludeComponents: ["toolbox"],
              pixelRatio: 2,
            },
          },
        },
        xAxis: {
          type: "value",
          splitArea: { show: false },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false, //隐藏x轴线
          },
          splitLine: {
            show: false,
          },
        },
        yAxis: {
          type: "category",
          data: [],
          axisLabel: {
            interval: 0,
            // textStyle: {
            //     fontSize: 14,
            //     color: "#333333",
            // },
            // formatter: function (params) {
            //     return this.handleText(params);
            // },
          },
          splitArea: { show: false },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false, //隐藏x轴线
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            type: "bar",
            data: [],
            showBackground: true,
            backgroundStyle: {
              color: "rgba(196, 214, 240, 0.4)",
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#00B2FF" },
                // { offset: 0.5, color: '#188df0' },
                { offset: 1, color: "#016DFF" },
              ]),
            },
          },
        ],
      },
      myChart2: "",
      option3: {
        tooltip: {
          trigger: "axis",
          // axisPointer: {
          //     type: 'shadow'
          // }
        },
        grid: {
          left: "0%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: {
              show: true,
              excludeComponents: ["toolbox"],
              pixelRatio: 2,
            },
          },
        },
        xAxis: {
          type: "value",
          splitArea: { show: false },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false, //隐藏x轴线
          },
          splitLine: {
            show: false,
          },
        },
        yAxis: {
          type: "category",
          data: [],
          axisLabel: {
            interval: 0,
            // textStyle: {
            //     fontSize: 14,
            //     color: "#333333",
            // },
            // formatter: function (params) {
            //     return this.handleText(params);
            // },
          },
          splitArea: { show: false },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false, //隐藏x轴线
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            type: "bar",
            data: [],
            showBackground: true,
            backgroundStyle: {
              color: "rgba(196, 214, 240, 0.4)",
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#00B2FF" },
                // { offset: 0.5, color: '#188df0' },
                { offset: 1, color: "#016DFF" },
              ]),
            },
          },
        ],
      },
      myChart3: "",
      moressecect: [],
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    // var chartDom1 = document.getElementById("chart1");
    // chartDom2 = document.getElementById("chart2");
    // chartDom3 = document.getElementById("chart3");
    // var myChart1 = echarts.init(chartDom1);
    // myChart2 = echarts.init(chartDom2);
    // myChart3 = echarts.init(chartDom3);
    // option1, option2, option3;
    this.getecharts(1);
    this.getecharts(2);
    this.getecharts(3);
  },
  methods: {
    getecharts(number) {
      var pdata = { stu: number };
      fourRemodelchart(pdata)
        .then((res) => {
          console.log(res);
          console.log(res, number, "res111");
          if (res && res.data && res.data.length) {
            switch (number) {
              case 1:
                var chartDom1 = document.getElementById("chart1");
                this.myChart1 = echarts.init(chartDom1);
                this.option1.yAxis.data = res.data.map(function(el) {
                  return el.title;
                });
                this.option1.series[0].data = res.data.map(function(el) {
                  return el.value;
                });
                this.option1 && this.myChart1.setOption(this.option1);
                break;
              case 2:
                var chartDom2 = document.getElementById("chart2");
                this.myChart2 = echarts.init(chartDom2);
                this.option2.yAxis.data = res.data.map(function(el) {
                  return el.title;
                });
                this.option2.series[0].data = res.data.map(function(el) {
                  return el.value;
                });
                this.option2 && this.myChart2.setOption(this.option2);
                break;
              case 3:
                var chartDom3 = document.getElementById("chart3");
                this.myChart3 = echarts.init(chartDom3);
                this.option3.yAxis.data = res.data.map(function(el) {
                  return el.title;
                });
                this.option3.series[0].data = res.data.map(function(el) {
                  return el.value;
                });
                console.log(
                  this.option3.series[0].data,
                  "option3.series[0].data"
                );
                this.option3 && this.myChart3.setOption(this.option3);
                break;
              default:
                break;
            }
          }
        })
        .catch((err) => {
          console.log(err, "22222222");
        });
    },
    getList() {
      fourRemodelList({
        page: this.page,
        pageSize: this.pageSize,
      }).then((res) => {
        if (res.code === 1) {
          console.log(res, "列表结果");
          this.tableData = res.data.data;
          this.total = res.data.total;
        }
      });
    },
    moreseclect(val) {
      console.log(val, "11111111111");
      this.moressecect = val;
    },
    downsel() {
      if (this.moressecect.length == 0) {
        this.$message.error("至少选择一项");
        return;
      }
      var checkIds = [];
      if (this.moressecect.length > 0) {
        this.moressecect.forEach((res) => {
          checkIds.push(res.id);
        });
      }
      let result = checkIds.join(",");
      window.location.href =
        configs.baseUrl + "/index/government/derive?ids=" + result;
    },
    pageChange(v) {
      this.page = v;
      this.getList();
    },
    sizeChange(v) {
      this.pageSize = v;
      this.getList();
    },
    handleText(params) {
      var newParamsName = "";
      var paramsNameNumber = params.length;
      var provideNumber = 10;
      var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
      if (paramsNameNumber > provideNumber) {
        for (var p = 0; p < rowNumber; p++) {
          var tempStr = "";
          var start = p * provideNumber;
          var end = start + provideNumber;
          if (p == rowNumber - 1) {
            tempStr = params.substring(start, paramsNameNumber);
          } else {
            tempStr = params.substring(start, end) + "\n";
          }
          newParamsName += tempStr;
        }
      } else {
        newParamsName = params;
      }
      return newParamsName;
    },
  },
};
</script>

<style lang="less" scoped>
.main-container {
  width: 100%;
  height: 100%;

  .btn{
    margin-bottom: 10px;
  }

  .chart-list {
    width: 100%;
    display: flex;

    .chart-item {
      flex: 1;
      height: 500px;
    }
  }
}

::v-deep .el-pagination {
  width: 100%;
  text-align: left;
  padding: 10px 0;
  background-color: #fff;
}

::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev,
::v-deep .el-pager li,
::v-deep .el-pagination button:disabled {
  background-color: transparent !important;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #205dc3 !important;
}
</style>
