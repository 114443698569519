import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import animated from "animate.css";
import VueParticles from "vue-particles";
import store from "./vuex/index";
import { staticMedia, contentMedia } from "./utils/filter";
import "wowjs/css/libs/animate.css";
import "./assets/css/public2.css";
import "./assets/css/public.css";
import "./assets/css/reset.css";
import * as echarts from "echarts";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import "./assets/css/resetElement.css";
// 引入cookie
import jsCookie from 'js-cookie'
Vue.prototype.$cookie = jsCookie;

// 注册wangEditor的导入附件组件
import { Boot } from '@wangeditor/editor'
import attachmentModule from '@wangeditor/plugin-upload-attachment'
 
// 注册。要在创建编辑器之前注册，且只能注册一次，不可重复注册。
Boot.registerModule(attachmentModule)

// 注册背景方法
import { containerImg } from "@/utils/common.js";
Vue.prototype.$bgImg = containerImg;

// 注册转换encode方法
import { encodeHTML } from "@/utils/common.js";
Vue.prototype.$encodeHTML = encodeHTML;

// 注册转换html方法
import { encodeToHTML } from "@/utils/common.js";
Vue.prototype.$encodeToHTML = encodeToHTML;

// 注册正则表达式方法
import { validateRegex } from "@/utils/common.js";
Vue.prototype.$validateRegex = validateRegex;

// 删除注册
import { Delete_btn } from "@/utils/common.js";
Vue.prototype.$Delete = Delete_btn;

import { MessageBox } from "element-ui";

Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$message = MessageBox.message;

// loading引入
import { showLoading, hideLoading } from "@/utils/loadding";

Vue.prototype.$showLoading = showLoading;
Vue.prototype.$hideLoading = hideLoading;

Vue.prototype.$echarts = echarts;

Vue.config.productionTip = false;
Vue.use(VueParticles);
Vue.use(animated);
Vue.use(ElementUI);
Vue.filter("staticMedia", staticMedia);
Vue.filter("contentMedia", contentMedia);

// Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
