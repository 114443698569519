<template>
  <div class="main" :style="$bgImg('compony_bg.png')">
    <div class="container">
      <div class="company">
        <div class="company-main flex">
          <div class="company-img">
            <img
              class="company-image image100"
              :src="companyContentObj.image | contentMedia"
              alt=""
            />
          </div>
          <div class="company-content">
            <div class="company-title line1">{{ companyContentObj.name }}</div>
            <div class="company-item">
              <div class="company-item-title">企业描述</div>
              <div
                class="company-item-content"
                v-html="companyContentObj.description"
              ></div>
            </div>
            <div class="company-item">
              <div class="company-item-title">所属行政区</div>
              <div class="company-item-content">
                {{ companyContentObj.belong_area }}
              </div>
            </div>
            <div class="company-item">
              <div class="company-item-title">分管副总</div>
              <div class="company-item-content">
                {{ companyContentObj.deputy }}
              </div>
            </div>
            <div class="company-item">
              <div class="company-item-title">分管副总职务</div>
              <div class="company-item-content">
                {{ companyContentObj.deputy_job }}
              </div>
            </div>
            <div class="company-item">
              <div class="company-item-title">分管副总邮箱</div>
              <div class="company-item-content">
                {{ companyContentObj.deputy_email }}
              </div>
            </div>
            <div class="company-item">
              <div class="company-item-title">分管副总电话</div>
              <div class="company-item-content">
                {{ companyContentObj.deputy_mobile }}
              </div>
            </div>
            <div class="company-item">
              <div class="company-item-title">日常联系人</div>
              <div class="company-item-content">
                {{ companyContentObj.daily_person }}
              </div>
            </div>
            <div class="company-item">
              <div class="company-item-title">日常联系人职务</div>
              <div class="company-item-content">
                {{ companyContentObj.daily_job }}
              </div>
            </div>
            <div class="company-item">
              <div class="company-item-title">日常联系人邮箱</div>
              <div class="company-item-content">
                {{ companyContentObj.daily_email }}
              </div>
            </div>
            <div class="company-item">
              <div class="company-item-title">日常联系人电话</div>
              <div class="company-item-content">
                {{ companyContentObj.daily_mobile }}
              </div>
            </div>
            <div class="company-item">
              <div class="company-item-title">擅长行业</div>
              <div class="company-item-content">{{ companyContentObj.hy }}</div>
            </div>
            <div class="company-item">
              <div class="company-item-title">其他擅长行业</div>
              <div class="company-item-content">
                {{ companyContentObj.otherhy }}
              </div>
            </div>
            <div class="company-item">
              <div class="company-item-title">意向行业</div>
              <div class="company-item-content">{{ companyContentObj.ly }}</div>
            </div>
            <div class="company-item">
              <div class="company-item-title">其他意向行业</div>
              <div class="company-item-content">
                {{ companyContentObj.otherly }}
              </div>
            </div>
            <div class="company-item">
              <div class="company-item-title">
                擅长的制造业行业领域以及数字化转型解决方案介绍
              </div>
              <div class="company-item-content">
                {{ companyContentObj.expertise }}
              </div>
            </div>
          </div>
        </div>
        <div class="left-box">
          <div
            class="left-box-t left-box-item flex column vertical"
            @click="goUpdateCompInfo"
          >
            <img
              class="left-box-item-img"
              :src="'/company_info.png' | staticMedia"
            />
            <span class="left-box-item-text">企业信息维护</span>
          </div>
          <div
            class="left-box-b left-box-item flex column vertical"
            @click="goBackupStaff"
          >
            <img
              class="left-box-item-img"
              :src="'/conpany_ryba.png' | staticMedia"
            />
            <span class="left-box-item-text">人员备案</span>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      :title="'人员备案'"
      :visible.sync="staffDialog"
      width="942px"
      top="5vh"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form"
        :model="form"
        label-width="100px"
        label-position="left"
        style="padding: 0 50px 0 20px"
      >
        <el-form-item label="">
          <el-button class="primaryBtn1" @click="getTemplate"
            >下载模板</el-button
          >
          <a
            v-show="false"
            ref="templateLink"
            target="_blank"
            :href="templateLink"
          ></a>
          <el-button
            class="primaryBtn1"
            @click="getPerson"
            style="margin-left: 20px"
            >下载已上传名单</el-button
          >
          <a
            v-show="false"
            ref="personLink"
            target="_blank"
            :href="personLink"
          ></a>
        </el-form-item>
        <el-form-item label="人员备案名单" prop="files">
          <MoreUpload
            ref="domref"
            :uploadlist="parentMessage"
            :allownum="1"
            @domfile-sent="handlefile"
            :accept="'.xls,.xlsx'"
          ></MoreUpload>
          <!-- 代替form验证 -->
          <el-input v-model="ruleForm.files" v-if="false"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="primaryBtn1" @click="handleSubmit">提交</el-button>
          <el-button class="cancleBtn1" @click="handleCancle">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { getCompanyInfo, UploadPerson } from "@/api/pts";
import configs from "@/config/index";
import MoreUpload from "@/components/moreupload";
export default {
  components: { MoreUpload },
  data() {
    return {
      staffDialog: false,
      templateLink: `${configs.realUrl}/assets/addons/official/static/file/template.xls`,
      personLink: `${
        configs.realUrl
      }/index/diagnose/export?token=${sessionStorage.getItem("token")}`,

      form: {},
      companyContentObj: {},

      parentMessage: [], // 传文件数据进上传文件
      domlist: [], // 接收传过来的文件
      ruleForm: {
        files: [],
      },
    };
  },
  created() {
    this.getCompanyInfo();
  },
  methods: {
    handleSubmit() {
      if (this.domlist.length > 0) {
        this.$confirm("是否提交该文件？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let formData = new FormData();
            formData.append("file", this.ruleForm.files[0].raw);
            let pdata = {
              file: formData,
            };
            console.log("pdata", pdata, this.ruleForm.files[0]);

            UploadPerson(formData)
              .then((res) => {
                console.log("res", res);
                if (res.code == 1) {
                  this.$message({
                    type: "success",
                    message: "上传成功",
                  });
                } else {
                  this.$message({
                    type: "error",
                    message: "上传失败",
                  });
                }
              })
              .catch((err) => {
                console.log("err", err);
                this.$message({
                  type: "success",
                  message: "上传成功",
                });
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          })
          .finally(() => {
            this.parentMessage = [];
            this.domlist = [];
            this.staffDialog = false;
          });
      } else {
        this.$message({
          type: "error",
          message: "请上传文件！",
        });
      }
    },
    handlefile(file) {
      console.log("file", file);
      if (file.length > 0) {
        this.domlist = file;
        this.ruleForm.files = this.domlist;
      } else {
        this.ruleForm.files = [];
      }
    },
    handleCancle() {
      // 清空文件列表
      this.parentMessage = [];
      this.domlist = [];
      this.staffDialog = false;
    },
    getPerson() {
      this.$refs.personLink.click();
    },
    getTemplate() {
      this.$refs.templateLink.click();
    },
    getCompanyInfo() {
      getCompanyInfo().then((res) => {
        console.log("res", res);
        this.companyContentObj = res.data;
        this.companyContentObj.description = this.$encodeToHTML(
          res.data.description
        );
      });
    },
    goBackupStaff() {
      this.staffDialog = true;
    },
    goUpdateCompInfo() {
      this.$router.push({
        path: "/company/companyInfo/edit",
      });
    },
  },
};
</script>
<style scoped lang="less">
.el-button + .el-button {
  margin-left: 20px;
}
.container {
  position: relative;
  .company {
    width: 84%;
    margin: 0 auto;
    .left-box {
      padding: 10px;
      background: #fff;
      border-radius: 10px;
      position: fixed;
      right: 10%;
      bottom: 25%;
      z-index: 99;
      box-shadow: 0 0 10px #205dc340;
      &:hover {
        box-shadow: 0 0 10px #205dc385;
      }
      .left-box-item {
        width: 96px;
        padding: 10px;
        cursor: pointer;
        &:hover {
          background-color: #205dc3;
          border-radius: 10px;
          .left-box-item-text {
            color: #fff;
          }
        }
        .left-box-item-img {
          width: 40px;
          height: 46px;
          margin-bottom: 8px;
        }
        .left-box-item-text {
          color: #333;
        }
      }
    }

    .company-main {
      width: 88%;
      padding: 20px 30px;
      box-sizing: border-box;
      background: #fff;
      border-radius: 10px;
      min-height: 567px;
      cursor: default;
      &:hover {
        .company-image {
          transform: scale(1.2);
          transition: all 0.3s;
        }

        .company-title {
          color: #205dc3 !important;
        }
      }
      .company-img {
        width: 220px;
        height: 124px;
        overflow: hidden;
        .company-image {
          transition: all 0.3s;
        }
      }
      .company-content {
        width: calc(100% - 220px);
        padding-left: 30px;
        box-sizing: border-box;
        .company-title {
          width: 100%;
          line-height: 30px;
          color: #333;
          font-size: 24px;
          font-weight: 600;
          margin-bottom: 10px;
        }
        .company-item {
          .company-item-title {
            line-height: 28px;
            padding-left: 20px;
            border-left: 3px solid #1562cd;
            font-size: 18px;
            color: #333;
            margin: 15px 0;
          }
          .company-item-content {
            line-height: 26px;
            color: #999;
            font-size: 14px;
            overflow: hidden;
          }
        }
      }
    }
  }
}
::v-deep .el-form-item__label {
  margin-top: 13px;
}
</style>
