<template>
  <div class="main" :style="$bgImg('newsBg.png')">
    <div class="container">
      <div class="detail-body">
        <div class="breadcrumb">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/company/classicCases' }"
              >经典案例</el-breadcrumb-item
            >
            <el-breadcrumb-item>详情</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="content flex wrap">
          <div class="content-item flex">
            <div class="content-title">案例标题</div>
            <div class="content-text">{{ infoForm.info.title }}</div>
          </div>
          <div class="content-item flex">
            <div class="content-title">行业</div>
            <div class="content-text">{{ infoForm.hy }}</div>
          </div>
          <div class="content-item flex">
            <div class="content-title">来源</div>
            <div class="content-text">{{ infoForm.info.source }}</div>
          </div>
          <div class="content-item flex">
            <div class="content-title">领域</div>
            <div class="content-text">{{ infoForm.ly }}</div>
          </div>
          <div class="content-item flex">
            <div class="content-title">列表图片</div>
            <div class="content-text">
              <img
                class="content-text-image"
                :src="infoForm.image | contentMedia"
                alt=""
              />
            </div>
          </div>
          <div class="content-item flex">
            <div class="content-title">优先级</div>
            <div class="content-text">{{ infoForm.yxj }}</div>
          </div>
          <div class="content-item flex w100">
            <div class="content-title">案例详情</div>
            <div class="content-text" v-html="infoForm.info.content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getClassicCaseDetail } from "@/api/pts";
export default {
  components: {},
  data() {
    return {
      infoForm: {},
    };
  },
  created() {},
  mounted() {
    this.getClassicCaseDetail();
  },
  methods: {
    getClassicCaseDetail() {
      let _this = this;
      let pdata = {
        id: _this.$route.query.id,
      };
      getClassicCaseDetail(pdata).then((res) => {
        console.log("res", res);
        _this.infoForm = res.data;
      });
    },
  },
};
</script>
<style scoped lang="less">
.el-button + .el-button {
  margin-left: 30px;
}
.w100 {
  width: 100% !important;
}
.container {
  position: relative;
  .detail-body {
    width: 84%;
    margin: 0 auto;
    .content {
      background-color: #fff;
      margin-top: 20px;
      padding: 20px 30px;
      border-radius: 10px;
      box-sizing: border-box;
      .content-item {
        width: 49%;
        margin-right: 2%;
        margin-bottom: 30px;
        &:nth-child(2n) {
          margin-right: 0;
        }
        .content-title {
          width: 88px;
          font-size: 16px;
          line-height: 1.4;
          color: #666;
        }
        .content-text {
          // flex: 1;
          width: calc(100% - 88px);
          line-height: 1.5;
          font-size: 15px;
          overflow: hidden;
          .content-text-image {
            width: 220px;
            height: 124px;
            object-fit: cover;
          }
          p img {
            width: 100%;
          }
        }
      }
    }
  }
}
::v-deep .el-breadcrumb__inner.is-link {
  font-weight: 400;
  color: #333;
  &:hover {
    color: #205dc3;
  }
}
::v-deep .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
  cursor: default;
}
</style>
