<template>
  <div>
    <el-upload
      ref="tuupload"
      :action="uploadUrl"
      accept=".jpg, .jpeg, .png"
      list-type="picture-card"
      :on-change="handleChange"
      :on-success="(value) => handleAvatarSuccess(value)"
      :on-error="handError"
      :file-list="TUlifils"
      :limit="allownum"
      :class="{ hide: hideUpload }"
      class="fileupload"
    >
      <i slot="default" class="el-icon-plus"></i>
      <div slot="file" slot-scope="{ file }">
        <!-- <span>{{ file }}</span> -->
        <img
          v-if="file.response"
          class="el-upload-list__item-thumbnail"
          :src="file.response.data.url | contentMedia"
          alt="file.response"
        />
        <img
          v-else
          class="el-upload-list__item-thumbnail"
          :src="file.url | contentMedia"
          alt="file.url"
        />
        <span class="el-upload-list__item-actions">
          <span
            class="el-upload-list__item-preview"
            @click="handlePictureCardPreview(file)"
          >
            <i class="el-icon-zoom-in"></i>
          </span>
          <!-- <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleDownload(file)">
                    <i class="el-icon-download"></i>
                </span> -->
          <span
            v-if="!disabled"
            class="el-upload-list__item-delete"
            @click="handleRemove(file)"
          >
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>
<script>
import configs from "@/config/index";
export default {
  props: {
    TU_lifils: {
      type: Array,
    },
    allownum: {},
  },
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      TUlifils: [], // 图片上传列表
      hideUpload: false, // 是否隐藏上传框
      limitCount: 3, // 图片上传的数量限制
      uploadUrl: "", // 图片上传路径

      file: {}, // 图片链接
    };
  },
  watch: {
    TU_lifils: {
      handler: function(val) {
        let _this = this;
        _this.TUlifils = val ? val : [];
        _this.hideUpload = _this.TUlifils.length >= _this.limitCount;
      },
      immediate: true, //首次加载时执行监听
      deep: true, //值改变时执行监听
    },
    allownum: {
      handler: function(val) {
        console.log(val);
        this.limitCount = val;
        this.hideUpload = this.TUlifils.length >= this.limitCount;
      },
      immediate: true, //首次加载时执行监听
      deep: true,
    },
  },
  created() {
    this.uploadUrl = `${configs.baseUrl}/api/common/upload`;
  },
  methods: {
    // 选中后
    handleChange(file, fileList) {
      console.log("file", file);
      this.TUlifils = fileList;
      this.hideUpload = this.TUlifils.length >= this.limitCount;
    },
    // 上传成功
    handleAvatarSuccess(value) {
      let obj = {
        url: value.data.url,
        id: value.data.id,
      };
      var ayy = [];
      ayy.push(obj);
      this.TUlifils = ayy;
      console.log("value", value, this.TUlifils, obj);

      // 单个传出去
      this.$emit("tufile-sent", value.data);
    },
    // 上传失败
    handError() {
      this.TUlifils = [];
      this.hideUpload = this.TUlifils.length >= this.limitCount;
      this.$message.error("上传失败，请重新上传！");
    },
    // 移除
    handleRemove(file) {
      /* 
        移除文件，重新设置  TUlifils ，编写处理方法
      */
      let fileNameList = [];
      this.TUlifils.forEach((element) => {
        fileNameList.push(element.name);
      });

      this.TUlifils.splice(fileNameList.indexOf(file.name), 1);
      this.hideUpload = this.TUlifils.length >= this.limitCount;

      // this.TUlifils = [];
      // this.hideUpload = this.TUlifils.length >= this.limitCount;
      this.$emit("tufile-remove", this.TUlifils);
    },
    // 预览
    handlePictureCardPreview(file) {
      if (file.url.indexOf("http") == -1) {
        this.dialogImageUrl = `${configs.baseUrl}${file.url}`;
      } else {
        this.dialogImageUrl = file.url;
      }
      this.dialogVisible = true;
    },
    // 清空列表
    clearfiles() {
      this.$refs.tuupload.clearFiles();
      this.TUlifils = [];
      this.hideUpload = this.TUlifils.length >= this.limitCount;
    },
    // 下载
    // handleDownload(file) {
    // },
  },
};
</script>
<style scoped lang="less">
/deep/.hide .el-upload--picture-card {
  display: none;
}

::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 100%;
  height: fit-content;
  max-width: 300px;
  max-height: 600px;
}

::v-deep .el-upload-list--picture-card .el-upload-list__item-thumbnail {
  display: block;
}
</style>
