<template>
  <el-dialog
    class="editbod"
    :title="edittitle"
    :visible.sync="editVisible"
    center
    width="1000px"
    top="6vh"
    :destroy-on-close="true"
    :close-on-click-modal="false"
  >
    <div class="page_step">
      <div class="stepAll">
        <div
          class="step_s flex "
          :class="item.showclass == true ? 'selected' : ''"
          v-for="(item, index) in stepAll_list"
          :key="index"
        >
          <div class="s_l flex column vertical">
            <div class="zero">{{ index + 1 }}</div>
            <div class="which">{{ item.name }}</div>
          </div>
          <div class="s_r" v-if="index != 3">
            <div class="heng">
              <span v-for="(item, index) in 7" :key="index"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="thetip">
        <div class="forlist" v-for="(item, index) in Tip_list" :key="index">
          <div class=" flex" v-if="index == which_step">
            <p style="width:60px"><b>温馨提示：</b></p>
            <p style="flex:1">{{ item.title }}</p>
          </div>
        </div>
      </div>
      <Step_one v-show="which_step == 0" ref="stepone"></Step_one>
      <Step_two v-show="which_step == 1" ref="steptwo"></Step_two>
      <Step_three v-show="which_step == 2" ref="stepthree"></Step_three>
      <Step_four v-show="which_step == 3" ref="stepfour"></Step_four>
      <div class="Requeset">
        <!-- <el-button type="primary" @click="keeploadData" v-if="which_step!=1">保 存</el-button> -->
        <el-button type="warning" @click="Upbtn" v-if="which_step != 0"
          >上一步</el-button
        >
        <el-button type="warning" @click="Nextbtn" v-if="which_step != 3"
          >下一步</el-button
        >
        <el-button type="primary" @click="sendloadData" v-if="which_step == 3"
          >保 存</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { get_diagnose_detail } from "@/api/pts";
import Step_one from "./steps/stepone.vue";
import Step_two from "./steps/steptwo.vue";
import Step_three from "./steps/stepthree.vue";
import Step_four from "./steps/stepfour.vue";
export default {
  components: {
    Step_one,
    Step_two,
    Step_three,
    Step_four,
  },
  data() {
    return {
      edittitle: "诊断服务协议备案",
      editVisible: false,
      which_step: 0,
      stepAll_list: [
        {
          name: "流程一",
          showclass: false,
        },
        {
          name: "流程二",
          showclass: false,
        },
        {
          name: "流程三",
          showclass: false,
        },
        {
          name: "流程四",
          showclass: false,
        },
      ],
      Tip_list: [
        {
          title:
            "一个企业只能被诊断一次，协议签订日期需要在自评估日期之前，选择文件后要点击开始上传按钮，点击下一步等同于保存。",
        },
        {
          title: "非必填，仅查看被诊断企业”四化“能力自评估的分数。",
        },
        {
          title:
            "上传备案资料，每次诊断至少要上传一张现场图片，诊断人员最少要选择4位其中至少一位是高级工程师。",
        },
        {
          title:
            "上传备案资料，按照允许的格式选择诊断报告后，需点击开始上传才可以保存信息。",
        },
      ],
      listdata: {},
    };
  },
  watch: {
    which_step: {
      handler: function(val) {
        console.log(val, "111111111111111");
        if (val == 0) {
          this.edittitle = "诊断服务协议备案";
        } else if (val == 1) {
          this.edittitle = "被诊断企业自评估";
        } else if (val == 2) {
          this.edittitle = "诊断现场";
        } else if (val == 3) {
          this.edittitle = "诊断结果";
        }
      },
      immediate: true, //首次加载时执行监听
      deep: true, //值改变时执行监听
    },
  },
  mounted() {},
  methods: {
    sendid(id) {
      this.which_step = 0;
      this.stepAll_list[this.which_step].showclass = true;
      this.Getdetail(id);
    },

    Getdetail(id) {
      get_diagnose_detail({ id: id }).then((res) => {
        console.log(res);
        this.listdata = res.data;
        this.$refs["stepone"].getMyCompany2();
        this.$refs["stepone"].Getinfo(res.data);
      });
    },
    // 流程四保存
    sendloadData() {
      this.$refs["stepfour"].submitForm("ruleForm").then((res) => {
        console.log(res);
        if (res == true) {
          this.$refs["stepfour"].Post_four().then((res) => {
            console.log(res, "\\\\\\\\\\\\1");
            if (res == true) {
              this.$message.success("保存成功");
            }
          });
        }
      });
    },
    Nextbtn() {
      console.log(this.which_step);
      // 流程一进流程二
      if (this.which_step == 0) {
        this.Get_numone();
        return;
      }
      // 流程二进流程三
      if (this.which_step == 1) {
        this.Get_three_info();

        return;
      }
      // 流程三进流程4先保存
      if (this.which_step == 2) {
        this.Get_three();
        return;
      }
    },
    Upbtn() {
      this.stepAll_list[this.which_step].showclass = false;
      if (this.which_step != 0) {
        this.which_step--;
      }
    },
    // 流程一校验保存
    Get_numone() {
      this.$refs["stepone"].submitForm("ruleForm").then((res) => {
        console.log(res);
        if (res == true) {
          this.$refs["stepone"].Post_one().then((res) => {
            console.log(res, "\\\\\\\\\\\\1");
            if (res == true) {
              // if (this.which_step != this.stepAll_list.length - 1) {
              this.which_step++;
              this.stepAll_list[this.which_step].showclass = true;
              this.Get_numtwo();
              // }
            }
          });
        }
      });
    },
    // 流程二
    Get_numtwo() {
      this.$refs["steptwo"].Getinfo(this.listdata);
    },
    // 流程四
    Get_numfour() {
      this.$refs["stepfour"].Getinfo(this.listdata);
    },
    // 流程三信息
    Get_three_info() {
      this.which_step++;
      this.stepAll_list[this.which_step].showclass = true;
      this.$refs["stepthree"]._GetFilingPersonnel(this.listdata);
    },
    // 流程三保存
    Get_three() {
      let _this = this
      this.$refs["stepthree"].submitForm("ruleForm").then((res) => {
        console.log(res, '\\\\0');
        if (res == true) {
          this.$refs["stepthree"].Post_one().then((res) => {
            console.log(res, "\\\\\\\\\\\\1");
            if (res == true) {
              // if (this.which_step != this.stepAll_list.length - 1) {
              // 相当于重新获取信息
              get_diagnose_detail({ id:  this.listdata.id }).then((res) => {
                console.log(res, 'get_diagnose_detail');
                _this.listdata = res.data;
                _this.which_step++;
                _this.stepAll_list[this.which_step].showclass = true;
                _this.Get_numfour();
              });
              // }
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.page_step {
  .stepAll {
    display: flex;
    color: #999;
    height: 80px;
    padding: 0 50px;
    justify-content: center;

    .s_l {
      .zero {
        width: 34px;
        height: 34px;
        border-radius: 50%;
        background: #ffffff;
        box-sizing: border-box;
        border: 2px solid #999;
        color: #999;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .which {
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0em;
        color: #999;
        margin-top: 10px;
      }
    }

    .s_r {
      display: flex;

      /* align-items: center; */
      .heng {
        display: flex;
        margin-top: 17px;

        span {
          width: 4px;
          height: 4px;
          opacity: 1;
          border-radius: 50%;
          background: #999999;
          margin-right: 12px;
        }
      }
    }

    .selected {
      .s_l {
        .zero {
          border: 2px solid #205dc3;
          color: #fff;
          background: #205dc3;
        }

        .which {
          color: #205dc3;
        }
      }

      .s_r .heng span {
        background: #205dc3;
      }
    }
  }

  .thetip {
    width: 572px;
    height: 60px;
    opacity: 1;
    background: #fff9e4;
    border: 1px solid #ffe282;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding: 0 16px;
    margin-bottom: 20px;

    p {
      font-size: 12px;
      line-height: 16px;
      font-weight: normal;
      letter-spacing: 0em;

      color: #3d3d3d;
    }
  }

  .Requeset {
    text-align: center;
    padding-top: 20px;
  }
}
</style>
