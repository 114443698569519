<template>
  <div class="terrace-container">
    <div class="breadcrumb">
      <div class="breadcrumb-text" @click="handleReturn">四化诊断</div>
      <div class="el-icon-arrow-right"></div>
      <div class="breadcrumb-text">人员备案下载</div>
    </div>
    <div class="terrace-list">
      <div class="terrace-item" v-for="item in dataList" :key="item.id">
        <div class="company-name">{{ item.nickname }}</div>
        <div class="download-btn">
          <a :href="item.downUrl" :download="item.nickname">下载</a>
        </div>
      </div>
    </div>
    <div class="pagination-container">
      <Pagination
        :total="total"
        :currentPage="page"
        :pageSize="pageSize"
        :layout="'total,prev, pager, next'"
        @pageChange="pageChange"
        @sizeChange="sizeChange"
      >
      </Pagination>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import { getTerraceList } from "@/api/zf";
import configs from "@/config/index";
export default {
  components: { Pagination },
  data() {
    return {
      dataList: [],
      total: 0,
      page: 1,
      pageSize: 10,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    handleReturn() {
      this.$emit("returnIndex");
    },
    getList() {
      getTerraceList({
        page: this.page,
        pageSize: this.pageSize,
      }).then((res) => {
        this.dataList = res.data.data;
        this.dataList.forEach(item => {
          item.downUrl = `${configs.baseUrl}/index/gov_diagnose/export?id=${item.id}`
        });
        this.total = +res.data.total;
        this.page = +res.data.current_page;
        this.pageSize = Number(res.data.per_page);
      });
    },
    // handleDownload(id) {
    // terraceDownload(id).then(res => {
    //     console.log(res, "下载结果");
    // })
    // },
    pageChange(v) {
      this.page = v;
      this.getList();
    },
    sizeChange(v) {
      this.pageSize = v;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.terrace-container {
  .breadcrumb {
    color: #3f3f3f;
    font-size: 14px;
    margin-bottom: 30px;
    display: flex;

    .el-icon-arrow-right {
      margin: 0 5px;
    }

    .breadcrumb-text {
      cursor: pointer;

      &:hover {
        color: #205dd0;
      }
    }
  }

  .terrace-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .terrace-item {
      width: 18%;
      margin-bottom: 30px;
      border-radius: 10px;
      background: #ffffff;

      .company-name {
        min-height: 80px;
        line-height: 1.5;
        padding: 30px 20px;
        border-radius: 2px;
        font-size: 18px;
      }

      .download-btn {
        width: 68px;
        height: 40px;
        margin: 0 auto 30px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #1562cd;
        color: #ffffff;
        cursor: pointer;

        a {
          text-decoration: none;
          color: #ffffff;
        }
      }
    }
  }
}

::v-deep .el-pagination {
  width: 100%;
  text-align: right;
}
</style>
