<template>
  <div class="dialog-container">
    <el-dialog
      v-if="isDestroy"
      append-to-body
      title="变更信息"
      :visible.sync="dialogShow"
      @close="closeDialog"
      width="500px"
      :close-on-click-modal="false"
    >
      <el-form
        ref="formData"
        :model="formData"
        label-position="left"
        label-width="135px"
        :rules="rules"
      >
        <el-form-item label="诊断机构名称" prop="company_id">
          <el-select
            v-model="formData.company_id"
            placeholder="请选择诊断机构名称"
            @change="companyChange"
          >
            <el-option
              v-for="(item, index) in companyOptions"
              :key="index"
              :label="item.nickname"
              :value="item.id"
              @click.native="labelClick(item.nickname)"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="诊断机构联系人" prop="d_contact">
          <el-input
            v-model="formData.d_contact"
            placeholder="请输入诊断机构联系人"
          ></el-input>
        </el-form-item>
        <el-form-item label="诊断机构联系电话" prop="d_phone">
          <el-input
            v-model="formData.d_phone"
            placeholder="请输入诊断机构联系电话"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleConfirm">保存</el-button>
        <el-button @click="closeDialog">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getCompanyList, editDiagnose } from "@/api/zf";
import { validateMobile } from "@/utils/common.js";
export default {
  props: {
    delDialogShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogShow: false,
      isDestroy: false,
      row: "",
      formData: {
        id: "",
        d_company: "",
        d_contact: "",
        d_phone: "",
        company_id: "",
      },
      companyOptions: [],
      rules: {
        company_id: [
          { required: true, message: "请选择诊断机构名称", trigger: "change" },
        ],
        d_contact: [
          { required: true, message: "请输入诊断机构联系人", trigger: "blur" },
        ],
        d_phone: [
          {
            required: true,
            validator: validateMobile.bind(this),
            trigger: "blur",
          },
        ],
      },
      
    };
  },
  methods: {
    openDialog(row) {
      this.dialogShow = true;
      this.isDestroy = true;
      this.row = row;
      this.valueData();
    },
    valueData() {
      console.log(this.row, "--00");
      this.formData.id = this.row.id;
      // this.formData.company_id = this.row.d_company;
      this.formData.d_company = this.row.d_company;
      this.formData.d_contact = this.row.d_contact;
      this.formData.d_phone = this.row.d_phone;
      this.getCompanyList();
    },
    closeDialog() {
      this.dialogShow = false;
      this.isDestroy = false;
    },
    labelClick(v){
      this.formData.d_company = v;
    },
    companyChange(v) {
      this.formData.company_id = v;
    },
    getCompanyList() {
      getCompanyList().then((res) => {
        this.companyOptions = res.data;
      });
    },
    handleConfirm() {
      this.$refs.formData.validate((valid) => {
        if (valid) {
          if (this.formData.company_id == 0) {
            this.$message.error("变更失败，请检查诊断企业");
            return false;
          }
          console.log("this.forat", this.formData);
          editDiagnose(this.formData).then((res) => {
            if (res.code === 1) {
              this.$emit("editSuccess");
              this.closeDialog();
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .el-input__inner {
  width: 300px;
}
</style>
