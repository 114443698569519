<template>
  <div id="szzd">
    <div id="app">
      <el-form
        :inline="true"
        :model="formInline11"
        class="demo-form-inline"
        v-show="userId != 275"
      >
        <el-form-item label="年度" class="mr50">
          <el-date-picker
            v-model="formInline11.year"
            type="year"
            format="yyyy"
            value-format="yyyy"
            placeholder="请选择"
            class="w200"
          >
          </el-date-picker>
          <!--<el-select v-model="" placeholder="" >-->
          <!--    <el-option label="行业" value="1"></el-option>-->
          <!--    <el-option label="子行业" value="0"></el-option>-->
          <!--</el-select>-->
        </el-form-item>
        <el-form-item label="区域" class="mr50">
          <!-- <el-cascader v-model="formInline11.area" :options="areaOptions" clearable class="w200"></el-cascader> -->
          <el-select
            v-model="formInline11.city"
            clearable
            placeholder="请选择"
            class="flex-1"
          >
            <el-option
              v-for="item in cityOptions"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-select
            v-model="formInline11.area"
            clearable
            placeholder="请选择"
            class="flex-1"
          >
            <el-option
              v-for="item in areaOptions"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="横坐标" class="mr50">
          <el-select
            v-model="formInline11.is_parent"
            @change="leichange"
            placeholder="请选择"
            class="w200"
          >
            <el-option label="行业" :value="1"></el-option>
            <el-option label="子行业" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <div style="width: 700px">
          <el-form-item label="纵坐标" class="mr50">
            <div class="flex" style="max-width: 450px">
              <el-select
                v-model="formInline11.type"
                placeholder="请选择"
                clearable
                class="flex-1 mr20 mws200"
                @change="selectone"
              >
                <el-option label="得分" value="2"></el-option>
                <el-option label="数量" value="1"></el-option>
              </el-select>
              <el-select
                v-show="formInline11.type == 2"
                clearable
                v-model="formInline11.firsttu"
                placeholder="请选择"
                class="flex-1 mr20 mws200"
              >
                <el-option label="总分" value="-1"></el-option>
                <el-option label="一级指标" value="-2"></el-option>
              </el-select>
              <el-select
                clearable
                v-show="formInline11.firsttu == -2 && formInline11.type == 2"
                v-model="formInline11.category"
                placeholder="请选择"
                class="flex-1 mr20 mws200"
                @change="pChange"
              >
                <el-option
                  v-for="item in categoryList1"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
              <el-select
                clearable
                v-show="
                  formInline11.firsttu == -2 &&
                    formInline11.category &&
                    formInline11.type == 2
                "
                v-model="formInline11.cate_id"
                placeholder="请选择"
                class="flex-1 mws200"
              >
                <el-option
                  v-for="item in categoryList2"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSeacrhTap">查询</el-button>
          </el-form-item>
        </div>
      </el-form>
      <el-form
        :inline="true"
        :model="formInline11"
        class="demo-form-inline"
        v-show="userId == 275"
      >
        <el-form-item>
          <el-select
            v-model="formInline11.type"
            placeholder="请选择"
            clearable
            class="flex-1 mr20 mws200"
            @change="selectone"
          >
            <el-option label="得分" value="2"></el-option>
            <el-option label="数量" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSeacrhTap">查询</el-button>
        </el-form-item>
      </el-form>
      <div class="chart-block h500 two" v-show="showtwo">
        <div
          class="chart-item"
          id="chart15"
          ref="chart15"
          style="width: 100%;height: 600px;"
        ></div>
      </div>
      <div class="chart-block h500 one flexChart" v-show="showone">
        <div class="chart-item" id="chart11" style="width: 50%"></div>
        <div class="chart-item" id="chart16" style="width: 50%"></div>
      </div>

      <el-form
        :inline="true"
        :model="formInline12"
        class="demo-form-inline"
        style="margin-top: 20px"
        v-show="userId != 275"
      >
        <el-form-item label="区域" class="mr50" style="margin-bottom: 30px">
          <el-select
            v-model="formInline12.city"
            clearable
            placeholder="请选择"
            class="flex-1"
          >
            <el-option
              v-for="item in cityOptions"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-select
            v-model="formInline12.area"
            clearable
            placeholder="请选择"
            class="flex-1 mr20"
          >
            <el-option
              v-for="item in areaOptions"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="行业" class="mr50">
          <el-select
            v-model="formInline12.is_parent"
            placeholder="请选择"
            class="w200"
          >
            <el-option label="行业" :value="1"></el-option>
            <el-option label="子行业" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSeacrhTwoTap">查询</el-button>
        </el-form-item>
      </el-form>
      <div class="chart-block" v-show="userId != 275">
        <div class="chart-item" id="chart12"></div>
        <div class="chart-item" id="chart13"></div>
      </div>

      <!--<div class="chart-block">-->
      <!--  <div class="chart-item" id="chart17"></div>-->
      <!--  <div class="chart-item" id="chart18"></div>-->
      <!--</div>-->
    </div>
  </div>
</template>
<script>
import {
  Get_getWjCategory,
  Get_nothing,
  Get_getStatistical2,
  Get_getStatistical1,
} from "@/api/zf";
import * as echarts from "echarts";

function handleText(params) {
  var newParamsName = "";
  var paramsNameNumber = params.length;
  var provideNumber = 10;
  var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
  if (paramsNameNumber > provideNumber) {
    for (var p = 0; p < rowNumber; p++) {
      var tempStr = "";
      var start = p * provideNumber;
      var end = start + provideNumber;
      if (p == rowNumber - 1) {
        tempStr = params.substring(start, paramsNameNumber);
      } else {
        tempStr = params.substring(start, end) + "\n";
      }
      newParamsName += tempStr;
    }
  } else {
    newParamsName = params;
  }
  return newParamsName;
}
var colorList = ["#F94A7D", "#3587EB", "#7FC068", "#4D7BF3", "#FACE39"];
export default {
  data() {
    return {
      userId: "",
      chartDom11: null,
      chartDom12: null,
      chartDom13: null,
      chartDom16: null,
      myChart11: null,
      myChart15: null,
      myChart12: null,
      myChart13: null,
      myChart16: null,
      randarMax: 0,
      option16: {
        color: ["#FFE434", "#205dc3"],
        title: {
          text: "企业“四化”水平分布",
        },
        legend: {
          left: "55%",
          tooltip: {},
        },
        tooltip: {},
        toolbox: {
          show: true,
          feature: {
            saveAsImage: {
              show: true,
              excludeComponents: ["toolbox"],
              pixelRatio: 2,
            },
          },
          left: "205px",
          top: 0,
        },
        radar: [
          {
            indicator: [
              // { text: "基础要素", color: "#205dc3", min: 0, max: 10},
              // { text: "绿色化提升", color: "#205dc3", min: 0, max: 10},
              // { text: "智能化制造", color: "#205dc3", min: 0, max: 10},
              // { text: "网络化协同", color: "#205dc3", min: 0, max: 10},
              // { text: "数字化转型", color: "#205dc3", min: 0, max: 10},
            ],
            center: ["50%", "55%"],
            radius: 215,
            startAngle: 90,
            splitNumber: 4,
            shape: "circle",
            scale: true,
            axisName: {
              formatter: "{value}",
              color: "#205dc3",
            },
            splitArea: {
              show: true,
              areaStyle: {
                color: ["#64AFE945", "#64AFE990", "#64AFE9", "#428BD4"],
                shadowColor: "rgba(0, 0, 0, 0.2)",
                shadowBlur: 10,
              },
            },
            axisLine: {
              lineStyle: {
                color: "rgba(211, 253, 250, 0.8)",
              },
            },
            splitLine: {
              lineStyle: {
                color: "rgba(211, 253, 250, 0.8)",
              },
            },
          },
        ],
        series: [
          {
            type: "radar",
            lineStyle: {
              width: 4,
            },
            emphasis: {
              lineStyle: {
                width: 5,
              },
            },
            data: [
              {
                value: [0, 0, 0, 0, 0],
                name: "行业“四化”诊断情况",
              },
              {
                value: [0, 0, 0, 0, 0],
                name: "广州市“四化”诊断整体情况",
              },
            ],
          },
        ],
      },
      option11: {
        title: {
          text: "各行业“四化”诊断分布图",
          // subtext: 'Fake Data',
          top: "0%",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: {
              show: true,
              excludeComponents: ["toolbox"],
              pixelRatio: 2,
            },
          },
          left: "245px",
          top: 0,
        },
        grid: {
          top: "15%",
          left: "5%",
          right: "0%",
          bottom: "0%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: [], // 横坐标数据
          axisLabel: {
            width: 60,
            interval: 0,
            // rotate: -45,
            // 设置字体的倾斜角度
            // interval: 0,
            // rotate: 45,
            margin: 10,
            textStyle: {
              // fontWeight: "bolder",
              fontSize: 16,
              color: "#000000",
            },
            // formatter: function (value) {
            //     // var text = handleText(value);
            //     //x轴的文字改为竖版显示
            //     var str = value.split("");
            //     return str.join("\n");
            // }
          },
        },
        yAxis: {
          type: "value",
          name: "得分",
          axisLabel: {
            textStyle: {
              fontSize: 14,
              color: "#000000",
            },
          },
          nameTextStyle: {
            align: "right",
            fontSize: 14,
            padding: [0, 6, 6, 0],
          },
        },
        series: [
          {
            data: [], // 纵坐标数据
            type: "bar",
            // barWidth: 28.5,
            barMaxWidth: 75,
            // showBackground: true,
            // backgroundStyle: {
            //     color: 'rgba(196, 214, 240, 0.4)'
            // },
            // itemStyle: {
            //     normal: {
            //         color: function (colors) {
            //             return colorList[colors.dataIndex];
            //         }
            //     },
            // }
            // showBackground: true,

            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#00B2FF" },
                // { offset: 0.5, color: '#188df0' },
                { offset: 1, color: "#016DFF" },
              ]),
            },
          },
        ],
      },
      option15: {
        title: {
          text: "各行业“四化”诊断分布图",
          // subtext: 'Fake Data',
          top: "0%",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          top: "15%",
          left: "3%",
          right: "4%",
          bottom: "5%",
          containLabel: true,
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: {
              show: true,
              excludeComponents: ["toolbox"],
              pixelRatio: 2,
            },
          },
          left: "245px",
          top: 0,
        },
        xAxis: {
          type: "category",
          data: [], // 横坐标数据
          axisLabel: {
            interval: 0,
            // rotate: -45,
            // 设置字体的倾斜角度
            // interval: 0,
            rotate: 45,
            textStyle: {
              // fontWeight: "bolder",
              fontSize: 14,
              color: "#000000",
            },
            // formatter: function (value) {
            //     // var text = handleText(value);
            //     //x轴的文字改为竖版显示
            //     var str = value.split("");
            //     return str.join("\n");
            // }
          },
        },
        yAxis: {
          type: "value",
          name: "得分",
          axisLabel: {
            textStyle: {
              fontSize: 14,
              color: "#000000",
            },
          },
          nameTextStyle: {
            align: "right",
            fontSize: 14,
            padding: [0, 6, 6, 0],
          },
        },
        series: [
          {
            data: [], // 纵坐标数据
            type: "bar",
            // barWidth: 28.5,
            barMaxWidth: 75,
            // showBackground: true,
            // backgroundStyle: {
            //     color: 'rgba(196, 214, 240, 0.4)'
            // },
            // itemStyle: {
            //     normal: {
            //         color: function (colors) {
            //             return colorList[colors.dataIndex];
            //         }
            //     },
            // }
            // showBackground: true,

            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#00B2FF" },
                // { offset: 0.5, color: '#188df0' },
                { offset: 1, color: "#016DFF" },
              ]),
            },
          },
        ],
      },

      option13: {
        title: {
          text: "“四化”等级数量分布",
          // subtext: 'Fake Data',
          left: "left",
          top: "0%",
        },
        // title: {
        //     text: '预警总数40',
        //     top: "3%",
        //     left: "2%",
        //     textStyle: {
        //         color: 'rgba(255,255,255,0.75)',
        //         fontWeight: 'bold',
        //         fontSize: 13
        //     }
        // },
        tooltip: {
          trigger: "item",
        },
        color: ["#F9A300", "#0E5FFF", "#50D682", "#6236FF"],
        legend: {
          orient: "vertical",
          top: "10%",
          x: "81%",
          y: "center",
          itemWidth: 10,
          itemHeight: 10,
          // data: ['预警类型一', '预警类型二', '预警类型三', '预警类型四'],
          // textStyle: {
          //     fontSize: 10,//字体大小
          //     color: 'rgba(255,255,255,0.65)'//字体颜色
          // }
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: {
              show: true,
              excludeComponents: ["toolbox"],
              pixelRatio: 2,
            },
          },
          left: "205px",
          top: 0,
        },
        grid: {
          top: "20%",
          left: "11%",
          right: "22%",
          bottom: "8%",
          containLabel: true,
        },

        xAxis: {
          type: "category",
          data: ["L1", "L2", "L3", "L4", "L5"], // 横坐标数据
          axisLabel: {
            interval: 0,
            fontSize: 10,
            formatter: function(params) {
              return handleText(params);
            },
          },
        },
        yAxis: {
          type: "value",
          name: "企业数量",
          nameTextStyle: {
            align: "right",
            fontSize: 14,
            padding: [0, 8, 0, 0],
          },
        },
        series: [
          {
            data: [], // 纵坐标数据
            type: "bar",
            barWidth: 30,
            // showBackground: true,
            // backgroundStyle: {
            //     color: 'rgba(196, 214, 240, 0.4)'
            // },
            itemStyle: {
              normal: {
                color: function(colors) {
                  return colorList[colors.dataIndex];
                },
              },
            },
          },
        ],
      },
      formInline11: {
        city: "",
        area: "",
        is_parent: 1,
        type: "", // 1.数量 2.分值
        firsttu: "",
        category: "", //一级指标
        cate_id: "", // 二级指标
        year: "",
      },
      formInline12: {
        city: "",
        area: "",
        is_parent: 1,
        level: "", //等级
      },
      tableData: [],
      categoryList1: [],
      categoryList2: [],
      cityOptions: [
        {
          value: "广州市",
          label: "广州市",
        },
      ],
      areaOptions: [
        {
          value: "越秀区",
          label: "越秀区",
        },
        {
          value: "海珠区",
          label: "海珠区",
        },
        {
          value: "荔湾区",
          label: "荔湾区",
        },
        {
          value: "天河区",
          label: "天河区",
        },
        {
          value: "白云区",
          label: "白云区",
        },
        {
          value: "黄埔区",
          label: "黄埔区",
        },
        {
          value: "花都区",
          label: "花都区",
        },
        {
          value: "番禺区",
          label: "番禺区",
        },
        {
          value: "南沙区",
          label: "南沙区",
        },
        {
          value: "从化区",
          label: "从化区",
        },
        {
          value: "增城区",
          label: "增城区",
        },
      ],
      showone: true,
      showtwo: false,
    };
  },
  // watch: {
  //   showtwo(v) {
  //     // 在show为true，也就是显示的时候，调用resize 解决100px的问题
  //     console.log('vvvvvvvv', v, this.$refs.chart15.instance);

  //     if (v) {
  //       this.$nextTick(() => this.$refs.chart15.instance.resize());
  //     }
  //   },
  // },
  mounted: function() {
    console.log("mounted");
    // this.userId = JSON.parse(localStorage.getItem("user")).id;
    this.userId = JSON.parse(sessionStorage.getItem("user")).id;
    localStorage.setItem("userId", this.userId);
    if (this.userId == 275) {
      this.formInline11.type = "得分";
    }

    console.log(this.userId);
    // document.getElementsByClassName('one').show();
    // document.getElementsByClassName('two').hide();
    var _this = this;
    this.tableData = [];
    this.chartDom11 = document.getElementById("chart11");

    this.chartDom13 = document.getElementById("chart13");
    this.myChart11 = echarts.init(this.chartDom11);

    this.myChart13 = echarts.init(this.chartDom13);
    // this.myChart13.setOption(this.option13);
    setTimeout(function() {
      _this.getStatistical1();
      _this.getWjCategory();
      _this.onSeacrhTwoTap();
      // _this.getWjCategory();
      _this.getRadarData();
    }, 2000);
  },
  methods: {
    chan12(number, data) {
      var _this = this;
      _this.myChart12 = echarts.init(document.getElementById("chart12"));
      // _this.chartDom12 =
      var option12 = {
        title: {
          text: "“四化”等级占比分布",
          // subtext: 'Fake Data',
          left: "left",
        },
        tooltip: {
          trigger: "item",
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: {
              show: true,
              excludeComponents: ["toolbox"],
              pixelRatio: 2,
            },
          },
          left: "205px",
          top: 0,
        },
        // legend: {
        //     orient: 'vertical',
        //     left: '10',
        //     top: '40'
        //     // y: 'center'
        // },
        legend: {
          // 对图形的解释部分
          orient: "vertical",
          left: "10",
          top: "40",
          // icon: 'circle',			// 添加
          formatter: function(name) {
            // 添加
            let total = 0;
            let target;
            for (let i = 0; i < data.length; i++) {
              total += data[i].value;
              if (data[i].name === name) {
                target = data[i].value;
              }
            }
            var arr = [
              "{a|" + name + "}",
              "{b|" + ((target / total) * 100).toFixed(2) + "%}",
              //   "{b|" + target + "%}",
              "{c|" + target + "}",
            ];
            return arr.join("  ");
          },
          textStyle: {
            // 添加
            padding: [5, 0, 0, 0],
            rich: {
              a: {
                fontSize: 15,
                width: 30,
              },
              b: {
                fontSize: 15,
                width: 70,
                color: "#FF0000",
              },
              c: {
                fontSize: 15,
              },
            },
          },
        },
        series: [
          {
            // name: 'Access From',
            type: "pie",
            label: {
              normal: {
                show: true,
                position: "center",
                color: "#4c4a4a",
                formatter:
                  "{total|" +
                  "完成数量" +
                  "}" +
                  "\n\r" +
                  "{active|" +
                  number +
                  "}",
                rich: {
                  total: {
                    fontSize: 14,
                    fontFamily: "微软雅黑",
                    color: "#333333",
                    lineHeight: 30,
                  },
                  active: {
                    fontFamily: "微软雅黑",
                    fontSize: 28,
                    color: "#000000",
                    lineHeight: 30,
                  },
                },
              },
            },
            radius: "50%",
            // radius: ["40%", "70%"],
            data: data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },

            itemStyle: {
              normal: {
                color: function(colors) {
                  return colorList[colors.dataIndex];
                },
              },
            },
          },
        ],
      };

      _this.myChart12.setOption(option12);
    },
    selectone(value) {
      console.log(value);

      this.formInline11.category = "";
      this.formInline11.firsttu = "";
      this.formInline11.cate_id = "";
    },
    leichange(value) {
      console.log(value);
      this.formInline11.is_parent = value;
      if (value == 1) {
        // document.getElementsByClassName('one').show();
        this.showone = true;
        // document.getElementsByClassName('two').hide();
        this.showtwo = false;
        this.getStatistical1();
      }
      if (value == 0) {
        console.log(123);
        // document.getElementsByClassName('one').hide();
        this.showone = false;
        // document.getElementsByClassName('two').show();
        this.showtwo = true;

        this.getStatistical3();
      }
    },
    pChange() {
      // this.categoryList1 = [];
      this.categoryList2 = [];
      this.formInline11.cate_id = "";
      this.getWjCategory();
    },
    onSeacrhTap() {
      // 行业
      console.log(this.formInline11, "onSeacrhTap");

      if (this.formInline11.is_parent == 1) {
        this.getStatistical1();
      }
      if (this.formInline11.is_parent == 0) {
        this.getStatistical3();
      }
    },
    onSeacrhTwoTap() {
      // 分值查询
      console.log(this.formInline12, "onSeacrhTap");
      this.getStatistical2();
    },
    getWjCategory() {
      var _this = this;
      var pdata = {
        pid: _this.formInline11.category,
      };

      Get_getWjCategory(pdata).then((res) => {
        console.log(res, "res-getWjCategory");
        console.log(_this.formInline11.category, 231);
        if (res && res.data && res.data.length) {
          if (
            _this.formInline11.category == "-1" ||
            _this.formInline11.category == ""
          ) {
            _this.categoryList1 = res.data;
          } else {
            _this.categoryList2 = res.data;
          }
        }
      });
    },
    // 细分行业
    getStatistical3() {
      var _this = this;
      var data = {
        // province: _this.formInline11.area.length ? _this.formInline11.area[0] : '',
        city: _this.formInline11.city ? _this.formInline11.city : "",
        area: _this.formInline11.area ? _this.formInline11.area : "",
        is_parent: 0, // 是否是父行业 1.是 0.否
        type: _this.formInline11.type, // 类型，1.数量 2.分值
        year: _this.formInline11.year, // 年度
        cate_id:
          _this.formInline11.category == "-1" ? 0 : _this.formInline11.cate_id, // 问卷分类ID ，0为总分，其他传分类ID
      };
      if (_this.formInline11.firsttu == -1) {
        data.cate_id = 0;
      }
      if (_this.formInline11.firsttu == -2) {
        if (_this.formInline11.category && _this.formInline11.cate_id == "") {
          data.cate_id = _this.formInline11.category;
        }
        if (_this.formInline11.category && _this.formInline11.cate_id != "") {
          data.cate_id = _this.formInline11.cate_id;
        }
      }
      this.chartDom15 = this.$refs.chart15;
      this.myChart15 = echarts.init(this.chartDom15);
      this.$nextTick(() => {
        this.myChart15.resize();
      });

      setTimeout(() => {
        Get_getStatistical1(data).then((res) => {
          console.log(res, "res-getStatistical1");
          if (res && res.code === 1) {
            if (res.data && res.data.length) {
              var list = res.data.map(function(el) {
                return el.name;
              });
              var data = res.data.map(function(el) {
                return parseFloat(el.statistical);
              });
              _this.option15.xAxis.data = list;
              _this.option15.series[0].data = data;
            } else {
              _this.option15.xAxis.data = [];
              _this.option15.series[0].data = [];
            }
            if (_this.formInline11.type == 1) {
              _this.option15.yAxis.name = "数量";
            }
            if (_this.formInline11.type == 2) {
              _this.option15.yAxis.name = "得分";
            }
            _this.option15 && _this.myChart15.setOption(_this.option15);
          } else {
            _this.$message({
              message: res.msg,
              type: "warning",
            });
          }
        });
      }, 500);
    },
    // 行业
    getStatistical1() {
      var _this = this;
      var data = {
        // province: _this.formInline11.area.length ? _this.formInline11.area[0] : '',
        city: _this.formInline11.city ? _this.formInline11.city : "",
        area: _this.formInline11.area ? _this.formInline11.area : "",
        is_parent: 1, // 是否是父行业 1.是 0.否
        type: _this.formInline11.type, // 类型，1.数量 2.分值
        year: _this.formInline11.year, // 年度
        cate_id:
          _this.formInline11.category == "-1" ? 0 : _this.formInline11.cate_id, // 问卷分类ID ，0为总分，其他传分类ID
      };
      if (_this.formInline11.firsttu == -1) {
        data.cate_id = 0;
      }
      if (_this.formInline11.firsttu == -2) {
        if (_this.formInline11.category && _this.formInline11.cate_id == "") {
          data.cate_id = _this.formInline11.category;
        }
        if (_this.formInline11.category && _this.formInline11.cate_id != "") {
          data.cate_id = _this.formInline11.cate_id;
        }
      }
      console.log(data, "------------------->");
      let url =
        this.userId == 275
          ? "/index/Government/getStatistical3"
          : "/index/Government/getStatistical1";
      this.option11.title.text =
        this.userId == 275 ? "各行业“数字化”分布图" : "各行业“四化”诊断分布图";
      this.option11.xAxis.axisLabel.rotate = this.userId == 275 ? 45 : 0;
      this.option11.xAxis.axisLabel.textStyle.fontSize =
        this.userId == 275 ? 10 : 16;

      let postData = data;
      Get_nothing(url, postData).then((res) => {
        if (res.data && res.code === 1) {
          if (res.data && res.data.length) {
            var list = res.data.map(function(el) {
              return el.name;
            });
            var data = res.data.map(function(el) {
              return parseFloat(el.statistical);
            });
            _this.option11.xAxis.data = list;
            _this.option11.series[0].data = data;
          } else {
            _this.option11.xAxis.data = [];
            _this.option11.series[0].data = [];
          }
          if (_this.formInline11.type == 1) {
            _this.option11.yAxis.name = "数量";
          }
          if (_this.formInline11.type == 2) {
            _this.option11.yAxis.name = "得分";
          }
          _this.option11 && _this.myChart11.setOption(_this.option11);
        } else {
          _this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    // 分值等级
    getStatistical2() {
      var _this = this;
      var pdata = {
        // province: _this.formInline12.area.length ? _this.formInline12.area[0] : '',
        city: _this.formInline12.city ? _this.formInline12.city : "",
        area: _this.formInline12.area ? _this.formInline12.area : "",
        is_parent: _this.formInline12.is_parent, // 是否是父行业 1.是 0.否
        level: _this.formInline12.level, // 等级
      };

      //   var key = "IJfV89KGEBLIyfCa";
      // console.log("ceshi", key)
      // key = CryptoJS.enc.Utf8.parse(key);
      // var srcs,encrypted;
      //  for(let item in pdata){
      //     if (typeof pdata[item] != 'undefined' && pdata[item] != 'undefined' && item != 'page' && item != 'pageSize' ) {
      //         srcs = CryptoJS.enc.Utf8.parse(pdata[item]);
      //         encrypted = CryptoJS.AES.encrypt(srcs, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }).toString();
      //         pdata[item] = encrypted;
      //     }
      //  }
      Get_getStatistical2(pdata).then((res) => {
        if (res && res.code === 1) {
          if (res.data && res.data.length) {
            // res.data.forEach(function(item, index) {
            //     _this.option12.series[0].data.push({
            //         name: 'L' + (index + 1),
            //         value: item
            //     })
            // })
            var n = 0;

            var data = res.data.map(function(e, index) {
              n += e;
              return {
                name: "L" + (index + 1),
                value: e,
              };
            });
            _this.chan12(n, data);
            var list = res.data.map(function(el) {
              return el;
            });
            _this.option13.series[0].data = list;
            // _this.option13.series = res.data.map(function (e, index) {
            //     return {
            //         name: 'L' + (index + 1),
            //         // value: e,
            //         type: 'bar',
            //         barWidth: 30,
            //         data: [e]
            //     }
            // });
          }
          _this.option12 && _this.myChart12.setOption(_this.option12);
          _this.option13 && _this.myChart13.setOption(_this.option13);
        } else {
          _this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    // 得分雷达图
    getRandarChart() {
      this.chartDom16 = document.getElementById("chart16");
      this.myChart16 = echarts.init(this.chartDom16);
      this.option16 && this.myChart16.setOption(this.option16);
    },
    getRadarData() {
      let _this = this;
      let totalArr = [];
      let departmentArr = [];
      let nameArr = [];
      let url =
        this.userId == 275
          ? "/index/Government/getRadarData2"
          : "/index/Government/getRadarData";
      this.option16.title.text =
        this.userId == 275 ? "企业“数字化”水平分布" : "企业“四化”水平分布";
      this.option16.series[0].data[1].name =
        this.userId == 275
          ? "广州市“数字化”整体情况"
          : "广州市“四化”诊断整体情况";
      this.option16.series[0].data[0].name =
        this.userId == 275 ? "行业“数字化”情况" : "行业“四化”诊断情况";
      if (this.userId == 275) {
        this.option16.series[0].data.shift();
        this.option16.color.shift();
      }
      Get_nothing(url, "").then((res) => {
        console.log(res, "radar res -----------------");

        res.data.total.forEach((item, index) => {
          if (
            item.name != "其他相关信息（不计分）" &&
            item.name != "其他相关信息"
          ) {
            totalArr[index] = item.statistical;
            if (+_this.randarMax <= +item.statistical) {
              _this.randarMax = item.statistical;
            }
          }
        });
        // $.each(res.data.total, function (index, item) {

        // });
        res.data.department.forEach((item, index) => {
          if (
            item.name != "其他相关信息（不计分）" &&
            item.name != "其他相关信息"
          ) {
            departmentArr[index] = item.statistical;

            if (+_this.randarMax <= +item.statistical) {
              _this.randarMax = item.statistical;
            }
          }
        });
        // $.each(res.data.department, function (index, item) {

        // });

        res.data.department.forEach((item) => {
          if (
            item.name != "其他相关信息（不计分）" &&
            item.name != "其他相关信息"
          ) {
            nameArr.push({
              text: item.name,
              color: "#205dc3",
              min: 0,
              max: +_this.randarMax,
            });
          }
        });
        // $.each(res.data.department, function (index, item) {

        // });
        // ,
        if (_this.userId == 275) {
          _this.option16.series[0].data[0].value = totalArr;
        } else {
          _this.option16.series[0].data[1].value = totalArr;
          _this.option16.series[0].data[0].value = departmentArr;
        }
        _this.option16.radar[0].indicator = nameArr;
        _this.getRandarChart();
      });
    },
  },
};
</script>
<style scoped lang="less">
#app {
  width: 100%;
  height: 100%;
  /*padding: 20px 20px 0;*/
}

.mr50 {
  margin-right: 50px !important;
}

.mr20 {
  margin-right: 12px !important;
}

.w200 {
  width: 200px;
}

.w380 {
  width: 350px;
}

.chart-block {
  display: flex;
  width: 100%;
  height: 400px;
}

.h500 {
  height: 600px;
}

.chart-item {
  flex: 1;
}

.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.mws200 {
  max-width: 200px;
}

.new_z_r {
  width: 100%;
  padding: 0;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  z-index: 98;
  position: sticky;
  margin-bottom: 30px;
}

.flexChart {
  display: flex;
}

.flexChart .chart-item {
  flex: 1;
}
</style>
