<template>
  <div class="table-container">
    <el-table
      :data="tableData"
      border
      stripe
      :header-cell-style="{
        background: '#005bca',
        color: '#ffffff',
        textAlign: 'center',
        borderColor: '#eeeeee',
        height: '20px',
      }"
      :cell-style="{ height: '20px', padding: 0, textAlign: 'center' }"
      style="width: 100%"
      class="productsBenefit-table interlace"
      v-bind="$attrs"
      v-on="$listeners"
      @row-click="handleRow"
      @selection-change="handleSelection"
      row-key="id"
    >
      <!-- 多选框 -->
      <el-table-column
        type="selection"
        v-if="multipleSelect"
        width="50"
        fixed="left"
        disabled="true"
      />
      <!-- 序号 -->
      <el-table-column
        label="序号"
        type="index"
        v-if="orderNumber"
        width="60"
        fixed="left"
      >
        <template slot-scope="scope">
          <span>{{ scope.$index + 1 + (page - 1) * pageSize }}</span>
        </template>
      </el-table-column>
      <!-- 列表 -->
      <template
        v-for="(item, index) in columns.filter(
          (item) => item.prop !== 'image' && item.prop !== 'files'
        )"
      >
        <template>
          <el-table-column
            :label="item.title"
            :prop="item.prop"
            :key="item.key + '-' + index"
            :width="item.width"
            :min-width="item.minWidth"
            :show-overflow-tooltip="item.showTopTip"
          >
            <template slot-scope="scope">
              <span>{{ scope.row[item.prop] }}</span>
            </template>
          </el-table-column>
        </template>
      </template>
      <!-- 图片 -->
      <template
        v-for="(item, index) in columns.filter((item) => item.prop === 'image')"
      >
        <template>
          <el-table-column
            :label="item.title"
            :prop="item.prop"
            :key="index"
            :width="item.width"
            :min-width="item.minWidth"
            :show-overflow-tooltip="item.showTopTip"
          >
            <template slot-scope="scope">
              <span>
                <el-image :src="scope.row.image | contentMedia"></el-image>
              </span>
            </template>
          </el-table-column>
        </template>
      </template>
      <!-- 文件 -->
      <template
        v-for="(item, index) in columns.filter((item) => item.prop === 'files')"
      >
        <template>
          <el-table-column
            :label="item.title"
            :prop="item.prop"
            :key="item.id + '-' + index"
            :width="item.width"
            :min-width="item.minWidth"
            :show-overflow-tooltip="item.showTopTip"
          >
            <template slot-scope="scope">
              <span class="files">
                <!-- {{ scope.row.files }} -->
                <div
                  v-for="(item, index) in scope.row.files.split(',')"
                  :key="index"
                >
                  <div
                    class="el-icon-document"
                    @click="handleFile(scope.row.files, index)"
                  ></div>
                </div>
                <!-- <el-image-viewer v-if="filesShow" :on-close="closeViewer" :url-list="filesUrl" /> -->
              </span>
            </template>
          </el-table-column>
        </template>
      </template>
      <!-- 操作 -->
      <template v-if="operateHandle">
        <el-table-column label="操作" fixed="right" width="180">
          <template slot-scope="scope">
            <el-button
              @click="handleDetail(scope.row)"
              type="text"
              v-if="operateDetail"
              >{{ operateDetail }}</el-button
            >
            <el-button
              @click="handleChange(scope.row)"
              type="text"
              v-if="operateChange"
              >{{ operateChange }}</el-button
            >
          </template>
        </el-table-column>
      </template>
      <template slot="empty" v-if="!tableData.length">
        <div class="no-data-bg-wrap">
          <p>暂无数据</p>
        </div>
      </template>
    </el-table>
  </div>
</template>

<script>
// import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import configs from "@/config/index";
export default {
  // components: { ElImageViewer },
  props: {
    multipleSelect: {
      type: Boolean,
      default: false,
    },
    orderNumber: {
      type: Boolean,
      default: false,
    },
    page: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    operateHandle: {
      type: Boolean,
      default: false,
    },
    operateDetail: {
      type: String,
      default: "",
    },
    operateChange: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      filesShow: false,
      filesUrl: [],
    };
  },
  methods: {
    handleRow() {
      console.log("点击行");
    },
    handleSelection(val) {
      console.log("多选", val);
      this.$emit("tableSelect", val);
    },
    handleDetail(v) {
      console.log("详情");
      this.$emit("tableRowDetail", v);
    },
    handleChange(v) {
      this.$emit("tableRowChange", v);
    },
    handleFile(v, index) {
      console.log("v", v, index);

      // this.filesShow = true;
      // this.filesUrl = v;

      v.split(",").forEach((element, i) => {
        if (index == i) {
          var url = configs.baseUrl + element;
          var a = document.createElement("a");
          a.href = url;
          a.download = element.substring(9); // 设置下载文件名
          a.style.display = "none";
          a.target = "_blank";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      });
    },
    closeViewer() {
      this.filesShow = false;
      this.filesUrl = [];
    },
  },
};
</script>

<style lang="less" scoped>
.table-container {
  .el-image {
    max-width: 100px;
    height: 57px;
  }

  .files {
    display: flex;

    .el-icon-document {
      font-size: 30px;
      color: #a9a9a9;
    }
  }
}
</style>
