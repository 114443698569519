<template>
  <div class="three_step">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="150px"
      class="demo-ruleForm"
    >
      <el-form-item label="企业诊断计划表" style="margin-bottom: 0;">
        <p>允许格式：doc,docx,pdf,csv,xls,xlsx</p>
      </el-form-item>
      <el-form-item label="" label-width="0" class="mosulop" prop="plan">
        <Moseupload
          ref="domref"
          :uploadlist="parentMessage"
          :allownum="1"
          @domfile-sent="handlefile"
          accept=".doc,.docx,.pdf,.csv,.xls,.xlsx"
        ></Moseupload>
        <!-- 代替form验证 -->
        <el-input v-model="ruleForm.plan" v-if="false"></el-input>
      </el-form-item>
      <div>
        <h2 class="the_h2">第一次诊断</h2>
        <el-form-item label="企业诊断签到表" required>
          <el-form-item prop="onefile" style="margin-bottom: 22px;">
            <TUpupload
              ref="Turef"
              :TU_lifils="one_filelist"
              :allownum="1"
              @tufile-sent="one_fitufile"
            >
            </TUpupload>
            <el-input v-model.trim="ruleForm.onefile" v-if="false"></el-input>
          </el-form-item>
        </el-form-item>
        <el-form-item label="现场照片" class="is-required">
          <UploadBtn @uploadImgList="handleUploadImageList"></UploadBtn>
          <!-- <el-col :span="8">
            <el-form-item prop="one_file_one">
              <TUpupload ref="Turef" :TU_lifils="fileonelist" :allownum="1" @tufile-sent="fileonetufile">
              </TUpupload>
              <el-input v-model.trim="ruleForm.one_file_one" v-if="false"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="one_file_two">
              <TUpupload ref="Turef" :TU_lifils="filetwolist" :allownum="1" @tufile-sent="filetwotufile">
              </TUpupload>
              <el-input v-model.trim="ruleForm.one_file_two" v-if="false"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="one_file_three">
              <TUpupload ref="Turef" :TU_lifils="filethreelist" :allownum="1" @tufile-sent="filethreefile">
              </TUpupload>
              <el-input v-model.trim="ruleForm.one_file_three" v-if="false"></el-input>
            </el-form-item>
          </el-col> -->
        </el-form-item>
        <el-form-item label="团队负责人" prop="leader_id">
          <el-select
            v-model="ruleForm.leader_id"
            class="form-input"
            placeholder="请选择"
            :filterable="true"
          >
            <el-option
              v-for="(item, index) in perpolist"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <span style="margin-left: 10px;">{{
            findtitle(ruleForm.leader_id)
          }}</span>
        </el-form-item>
        <el-form-item
          label="诊断人员"
          class="personArr"
          v-for="(mitem, i) in person_arr1List"
          :key="i"
        >
          <el-select
            v-model="person_arr1List[i]"
            class="form-input"
            placeholder="请选择"
            :filterable="true"
            @change="handleSelectPerson1($event, i)"
          >
            <el-option
              v-for="(item, index) in perpolist"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <span class="addBtn" v-if="i == 0 && person_arr1List.length != 8">
            <img
              style="width: 100%;height: 100%;"
              @click="addPerson1"
              :src="'additem.png' | staticMedia"
            />
          </span>
          <span class="removeBtn" v-if="i != 0">
            <img
              style="width: 100%;height: 100%;"
              @click="removePerson1(i)"
              :src="'removeItem.png' | staticMedia"
            />
          </span>
          <span style="margin-left: 10px;">{{
            findtitle(person_arr1List[i])
          }}</span>
        </el-form-item>
      </div>
      <div>
        <h2 class="the_h2">第二次诊断</h2>
        <el-form-item label="企业诊断签到表" required>
          <el-form-item prop="twofile" style="margin-bottom: 22px;">
            <TUpupload
              ref="Turef"
              :TU_lifils="two_filelist"
              :allownum="1"
              @tufile-sent="two_fitufile"
            >
            </TUpupload>
            <el-input v-model.trim="ruleForm.twofile" v-if="false"></el-input>
          </el-form-item>
        </el-form-item>
        <el-form-item label="现场照片" class="is-required">
          <UploadBtn @uploadImgList="handleUploadImageList"></UploadBtn>
          <!-- <el-col :span="8">
            <el-form-item prop="two_file_one">
              <TUpupload ref="Turef" :TU_lifils="filet_onelist" :allownum="1" @tufile-sent="filet_onetufile">
              </TUpupload>
              <el-input v-model.trim="ruleForm.two_file_one" v-if="false"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="two_file_two">
              <TUpupload ref="Turef" :TU_lifils="filet_twolist" :allownum="1" @tufile-sent="filet_twotufile">
              </TUpupload>
              <el-input v-model.trim="ruleForm.two_file_two" v-if="false"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="two_file_three">
              <TUpupload ref="Turef" :TU_lifils="filet_threelist" :allownum="1" @tufile-sent="filet_threefile">
              </TUpupload>
              <el-input v-model.trim="ruleForm.two_file_three" v-if="false"></el-input>
            </el-form-item>
          </el-col> -->
        </el-form-item>
        <el-form-item label="团队负责人" prop="person4_id">
          <el-select
            v-model="ruleForm.person4_id"
            class="form-input"
            placeholder="请选择"
            :filterable="true"
          >
            <el-option
              v-for="(item, index) in perpolist"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <span style="margin-left: 10px;">{{
            findtitle(ruleForm.person4_id)
          }}</span>
        </el-form-item>
        <el-form-item
          label="诊断人员"
          class="personArr"
          v-for="(mitem, i) in person_arr2List"
          :key="i"
        >
          <el-select
            v-model="person_arr2List[i]"
            class="form-input"
            placeholder="请选择"
            :filterable="true"
            @change="handleSelectPerson2($event, i)"
          >
            <el-option
              v-for="(item, index) in perpolist"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <span class="addBtn" v-if="i == 0 && person_arr2List.length != 8">
            <img
              style="width: 100%;height: 100%;"
              @click="addPerson2"
              :src="'additem.png' | staticMedia"
            />
          </span>
          <span class="removeBtn" v-if="i != 0">
            <img
              style="width: 100%;height: 100%;"
              @click="removePerson2(i)"
              :src="'removeItem.png' | staticMedia"
            />
          </span>
          <span style="margin-left: 10px;">{{
            findtitle(person_arr2List[i])
          }}</span>
        </el-form-item>
      </div>
      <div>
        <h2 class="the_h2">第三次诊断</h2>
        <el-form-item label="企业诊断签到表" required>
          <el-form-item prop="threefile" style="margin-bottom: 22px;">
            <TUpupload
              ref="Turef"
              :TU_lifils="three_filelist"
              :allownum="1"
              @tufile-sent="three_fitufile"
            >
            </TUpupload>
            <el-input v-model.trim="ruleForm.threefile" v-if="false"></el-input>
          </el-form-item>
        </el-form-item>
        <el-form-item label="现场照片" class="is-required">
          <UploadBtn @uploadImgList="handleUploadImageList"></UploadBtn>
          <!-- <el-col :span="8">
            <el-form-item prop="three_file_one">
              <TUpupload ref="Turef" :TU_lifils="filetr_onelist" :allownum="1" @tufile-sent="filetr_onetufile">
              </TUpupload>
              <el-input v-model.trim="ruleForm.three_file_one" v-if="false"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="three_file_two">
              <TUpupload ref="Turef" :TU_lifils="filetr_twolist" :allownum="1" @tufile-sent="filetr_twotufile">
              </TUpupload>
              <el-input v-model.trim="ruleForm.three_file_two" v-if="false"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="three_file_three">
              <TUpupload ref="Turef" :TU_lifils="filetr_threelist" :allownum="1" @tufile-sent="filetr_threetufile">
              </TUpupload>
              <el-input v-model.trim="ruleForm.three_file_three" v-if="false"></el-input>
            </el-form-item>
          </el-col> -->
        </el-form-item>
        <el-form-item label="团队负责人" prop="person8_id">
          <el-select
            v-model="ruleForm.person8_id"
            class="form-input"
            placeholder="请选择"
            :filterable="true"
          >
            <el-option
              v-for="(item, index) in perpolist"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <span style="margin-left: 10px;">{{
            findtitle(ruleForm.person8_id)
          }}</span>
        </el-form-item>

        <el-form-item
          label="诊断人员"
          class="personArr"
          v-for="(mitem, i) in person_arr3List"
          :key="i"
        >
          <el-select
            v-model="person_arr3List[i]"
            class="form-input"
            placeholder="请选择"
            :filterable="true"
            @change="handleSelectPerson3($event, i)"
          >
            <el-option
              v-for="(item, index) in perpolist"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <span class="addBtn" v-if="i == 0 && person_arr3List.length != 8">
            <img
              style="width: 100%;height: 100%;"
              @click="addPerson3"
              :src="'additem.png' | staticMedia"
            />
          </span>
          <span class="removeBtn" v-if="i != 0">
            <img
              style="width: 100%;height: 100%;"
              @click="removePerson3(i)"
              :src="'removeItem.png' | staticMedia"
            />
          </span>
          <span style="margin-left: 10px;">{{
            findtitle(person_arr3List[i])
          }}</span>
        </el-form-item>
        <el-form-item label="照片列表">
          <div class="imgList">
            <el-row>
              <el-col
                class="imgcol"
                :span="8"
                v-for="(item, index) in newSceneImgList"
                :key="index"
              >
                <img
                  class="removeImg"
                  :src="'removeItem_red.png' | staticMedia"
                  @click="deleteImg(index)"
                />
                <el-image
                  class="img"
                  :src="item | contentMedia"
                  :preview-src-list="newSceneImgList"
                >
                </el-image>
              </el-col>
            </el-row>
          </div>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>
<script>
import TUpupload from "@/components/TUpupload";
import Moseupload from "@/components/moreupload";
import { Get_poststep2, GetFilingPersonnel } from "@/api/pts";
import UploadBtn from "@/components/UploadBtn.vue";
export default {
  components: {
    Moseupload,
    TUpupload,
    UploadBtn,
  },

  data() {
    return {
      ruleForm: {
        plan: "",
        onefile: "",
        one_file_one: "",
        one_file_two: "",
        one_file_three: "",
        leader_id: "",
        newSceneImgList: [],
      },
      rules: {
        // plan: [
        //   {
        //     required: true,
        //     message: "请上传企业诊断计划表",
        //     trigger: "change",
        //   },
        // ],
        // onefile: [
        //     { required: true, message: "请上传", trigger: "change" }
        // ],
      },
      perpolist: [], //人员数据
      parentMessage: [], //传文件数据进上传文件
      one_filelist: [], // 传图片数据
      fileonelist: [],
      filetwolist: [],
      filethreelist: [],

      two_filelist: [],
      filet_onelist: [],
      filet_twolist: [],
      filet_threelist: [],

      three_filelist: [],
      filetr_onelist: [],
      filetr_twolist: [],
      filetr_threelist: [],

      // 第一次诊断 - 诊断人员数组
      person_arr1List: [null],

      // 第二次诊断 - 诊断人员数组
      person_arr2List: [null],

      // 第三次诊断 - 诊断人员数组
      person_arr3List: [null],

      // 改版后  现场照片的照片列表
      newSceneImgList: [],
    };
  },
  created() {
    this.resetForm();
  },
  mounted() {},
  methods: {
    deleteImg(index) {
      this.newSceneImgList.splice(index, 1);
    },
    handleUploadImageList(image) {
      this.newSceneImgList.push(image);
    },
    handleSelectPerson1($event, i) {
      this.person_arr1List[i] = $event;

      this.person_arr1List = this.person_arr1List.filter(
        (item) => item != null
      );

      // 直接用Set来搞搞，set本身不可重复
      const uniqueSet = new Set(this.person_arr1List);
      if (uniqueSet.size != this.person_arr1List.length) {
        this.$message.error("不可重复选择诊断人员");
        this.person_arr1List[i] = null;
      }
    },
    addPerson1() {
      if (this.person_arr1List.length == 8) {
        this.$message.error("最多可选8位诊断人员");
        return false;
      }
      this.person_arr1List.push(null);
    },
    removePerson1(index) {
      this.person_arr1List.splice(index, 1);
    },
    handleSelectPerson2($event, i) {
      this.person_arr2List[i] = $event;

      this.person_arr2List = this.person_arr2List.filter(
        (item) => item != null
      );

      // 直接用Set来搞搞，set本身不可重复
      const uniqueSet = new Set(this.person_arr2List);
      if (uniqueSet.size != this.person_arr2List.length) {
        this.$message.error("不可重复选择诊断人员");
        this.person_arr2List[i] = null;
      }
    },
    addPerson2() {
      if (this.person_arr2List.length == 8) {
        this.$message.error("最多可选8位诊断人员");
        return false;
      }
      this.person_arr2List.push(null);
    },
    removePerson2(index) {
      this.person_arr2List.splice(index, 1);
    },
    handleSelectPerson3($event, i) {
      this.person_arr3List[i] = $event;

      this.person_arr3List = this.person_arr3List.filter(
        (item) => item != null
      );

      // 直接用Set来搞搞，Set本身不可重复
      const uniqueSet = new Set(this.person_arr3List);
      if (uniqueSet.size != this.person_arr3List.length) {
        this.$message.error("不可重复选择诊断人员");
        this.person_arr3List[i] = null;
      }
    },
    addPerson3() {
      if (this.person_arr3List.length == 8) {
        this.$message.error("最多可选8位诊断人员");
        return false;
      }
      this.person_arr3List.push(null);
    },
    removePerson3(index) {
      this.person_arr3List.splice(index, 1);
    },
    Getinfo(obj) {
      console.log(obj, "========getinfo");
      this.$nextTick(() => {
        this.ruleForm = { ...obj };
        this.ruleForm.leader_id =
          this.ruleForm.leader_id == 0 ? null : this.ruleForm.leader_id;
        // this.ruleForm.person_id =
        //   this.ruleForm.person_id == 0 ? null : this.ruleForm.person_id;
        // this.ruleForm.person2_id =
        //   this.ruleForm.person2_id == 0 ? null : this.ruleForm.person2_id;
        // this.ruleForm.person3_id =
        //   this.ruleForm.person3_id == 0 ? null : this.ruleForm.person3_id;
        this.ruleForm.person4_id =
          this.ruleForm.person4_id == 0 ? null : this.ruleForm.person4_id;
        // this.ruleForm.person5_id =
        //   this.ruleForm.person5_id == 0 ? null : this.ruleForm.person5_id;
        // this.ruleForm.person6_id =
        //   this.ruleForm.person6_id == 0 ? null : this.ruleForm.person6_id;
        // this.ruleForm.person7_id =
        //   this.ruleForm.person7_id == 0 ? null : this.ruleForm.person7_id;
        this.ruleForm.person8_id =
          this.ruleForm.person8_id == 0 ? null : this.ruleForm.person8_id;
        // this.ruleForm.person9_id =
        //   this.ruleForm.person9_id == 0 ? null : this.ruleForm.person9_id;
        // this.ruleForm.person10_id =
        //   this.ruleForm.person10_id == 0 ? null : this.ruleForm.person10_id;
        // this.ruleForm.person11_id =
        //   this.ruleForm.person11_id == 0 ? null : this.ruleForm.person11_id;

        if (this.ruleForm.person_arr1.length > 0) {
          this.person_arr1List = this.ruleForm.person_arr1.map((item) => {
            return item.id;
          });
        }
        if (this.ruleForm.person_arr2.length > 0) {
          this.person_arr2List = this.ruleForm.person_arr2.map((item) => {
            return item.id;
          });
        }
        if (this.ruleForm.person_arr3.length > 0) {
          this.person_arr3List = this.ruleForm.person_arr3.map((item) => {
            return item.id;
          });
        }
        // 文件组件传值

        if (obj.plan) {
          var domayy = [];
          let obi = {
            url: obj.plan.url,
            name: obj.plan.filename,
            state: 1, //代表已经上传过
            id: obj.plan.id,
            size: (obj.plan.filesize / 1024).toFixed(1) + "kb",
            isdele: true,
            renewdata: this.timestampToTime(obj.plan.uploadtime),
          };
          domayy.push(obi);
          this.parentMessage = domayy;
        }

        if (this.ruleForm.files1 != "") {
          this.one_filelist = [];
          this.fileonelist = [];
          this.filetwolist = [];
          this.filethreelist = [];
          var newfile1 = this.ruleForm.files1.split(",").map((item) => {
            if (item != "undefined") {
              return item;
            } else {
              return "";
            }
          });
          this.ruleForm.onefile = newfile1[0] ? newfile1[0] : "";
          this.ruleForm.one_file_one = newfile1[1] ? newfile1[1] : "";
          this.ruleForm.one_file_two = newfile1[2] ? newfile1[2] : "";
          this.ruleForm.one_file_three = newfile1[3] ? newfile1[3] : "";

          if (this.ruleForm.onefile) {
            this.one_filelist.push({ url: newfile1[0] ? newfile1[0] : "" });
          }
          if (this.ruleForm.one_file_one) {
            this.fileonelist.push({ url: newfile1[1] ? newfile1[1] : "" });
          }
          if (this.ruleForm.one_file_two) {
            this.filetwolist.push({ url: newfile1[2] ? newfile1[2] : "" });
          }
          if (this.ruleForm.one_file_three) {
            this.filethreelist.push({ url: newfile1[3] ? newfile1[3] : "" });
          }
        }
        if (this.ruleForm.files2 != "") {
          this.two_filelist = [];
          this.filet_onelist = [];
          this.filet_twolist = [];
          this.filet_threelist = [];
          var newfile2 = this.ruleForm.files2.split(",").map((item) => {
            if (item != "undefined") {
              return item;
            } else {
              return "";
            }
          });
          this.ruleForm.twofile = newfile2[0] ? newfile2[0] : "";
          this.ruleForm.two_file_one = newfile2[1] ? newfile2[1] : "";
          this.ruleForm.two_file_two = newfile2[2] ? newfile2[2] : "";
          this.ruleForm.two_file_three = newfile2[3] ? newfile2[3] : "";

          if (this.ruleForm.twofile) {
            this.two_filelist.push({ url: newfile2[0] ? newfile2[0] : "" });
          }
          if (this.ruleForm.two_file_one) {
            this.filet_onelist.push({ url: newfile2[1] ? newfile2[1] : "" });
          }
          if (this.ruleForm.two_file_two) {
            this.filet_twolist.push({ url: newfile2[2] ? newfile2[2] : "" });
          }
          if (this.ruleForm.two_file_three) {
            this.filet_threelist.push({ url: newfile2[3] ? newfile2[3] : "" });
          }
        }
        if (this.ruleForm.files3 != "") {
          this.three_filelist = [];
          this.filetr_onelist = [];
          this.filetr_twolist = [];
          this.filetr_threelist = [];
          var newfile3 = this.ruleForm.files3.split(",").map((item) => {
            if (item != "undefined") {
              return item;
            } else {
              return "";
            }
          });
          this.ruleForm.threefile = newfile3[0] ? newfile3[0] : "";
          this.ruleForm.three_file_one = newfile3[1] ? newfile3[1] : "";
          this.ruleForm.three_file_two = newfile3[2] ? newfile3[2] : "";
          this.ruleForm.three_file_three = newfile3[3] ? newfile3[3] : "";

          if (this.ruleForm.threefile) {
            this.three_filelist.push({ url: newfile3[0] });
          }
          if (this.ruleForm.three_file_one) {
            this.filetr_onelist.push({ url: newfile3[1] });
          }
          if (this.ruleForm.three_file_two) {
            this.filetr_twolist.push({ url: newfile3[2] });
          }
          if (this.ruleForm.three_file_three) {
            this.filetr_threelist.push({ url: newfile3[3] });
          }
          this.newSceneImgList = this.ruleForm.sceneimgList
            ? this.ruleForm.sceneimgList.split(",")
            : [];
        }
      });
    },
    // 获取负责人
    _GetFilingPersonnel(obj) {
      GetFilingPersonnel().then((res) => {
        console.log(res);
        this.perpolist = res.data;
        this.Getinfo(obj);
      });
    },

    handlefile(list) {
      console.log(list);
      if (list.length > 0) {
        var arr = [];
        list.forEach((item) => {
          arr.push(item.id);
        });
        this.ruleForm.plan = arr;
      } else {
        this.ruleForm.plan = "";
      }
      this.$refs.ruleForm.validateField(["plan"]); //单个校验
    },
    // 图片上传
    one_fitufile(list) {
      if (list != {}) {
        this.ruleForm.onefile = list.url;
      } else {
        this.ruleForm.onefile = "";
      }
      // this.$refs.ruleForm.validateField(["leader_id"]); //单个校验
    },
    fileonetufile(list) {
      if (list != {}) {
        this.ruleForm.one_file_one = list.url;
      } else {
        this.ruleForm.one_file_one = "";
      }
    },
    filetwotufile(list) {
      if (list != {}) {
        this.ruleForm.one_file_two = list.url;
      } else {
        this.ruleForm.one_file_two = "";
      }
    },
    filethreefile(list) {
      if (list != {}) {
        this.ruleForm.one_file_three = list.url;
      } else {
        this.ruleForm.one_file_three = "";
      }
    },

    two_fitufile(list) {
      if (list != {}) {
        this.ruleForm.twofile = list.url;
      } else {
        this.ruleForm.twofile = "";
      }
    },
    filet_onetufile(list) {
      if (list != {}) {
        this.ruleForm.two_file_one = list.url;
      } else {
        this.ruleForm.two_file_one = "";
      }
    },
    filet_twotufile(list) {
      if (list != {}) {
        this.ruleForm.two_file_two = list.url;
      } else {
        this.ruleForm.two_file_two = "";
      }
    },
    filet_threefile(list) {
      if (list != {}) {
        this.ruleForm.two_file_three = list.url;
      } else {
        this.ruleForm.two_file_three = "";
      }
    },

    three_fitufile(list) {
      if (list != {}) {
        this.ruleForm.threefile = list.url;
      } else {
        this.ruleForm.threefile = "";
      }
    },
    filetr_onetufile(list) {
      if (list != {}) {
        this.ruleForm.three_file_one = list.url;
      } else {
        this.ruleForm.three_file_one = "";
      }
    },
    filetr_twotufile(list) {
      if (list != {}) {
        this.ruleForm.three_file_two = list.url;
      } else {
        this.ruleForm.three_file_two = "";
      }
    },
    filetr_threetufile(list) {
      if (list != {}) {
        this.ruleForm.three_file_three = list.url;
      } else {
        this.ruleForm.three_file_three = "";
      }
    },

    findtitle(id) {
      var name = "";
      this.perpolist.forEach((item) => {
        if (item.id == id) {
          name = item.title;
        }
      });
      return name;
    },
    async submitForm(formName) {
      try {
        //调用表单的validate方法，执行表单校验
        await this.$refs[formName].validate();
      } catch (e) {
        //如果表单有报错，则进入catch，此时直接return不执行表单提交
        return false;
      }
      return true;
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 流程三保存
    async Post_one() {
      console.log(this.ruleForm, "流程三保存");
      let params = { ...this.ruleForm };
      if (params.onefile == "" || params.onefile == null) {
        this.$message.error("请选择第一次诊断签到表");
        return false;
      }
      // if (
      //   !this.hasOneNotEmpty(
      //     params.one_file_one,
      //     params.one_file_two,
      //     params.one_file_three
      //   )
      // ) {
      //   this.$message.error("第一次诊断至少一张现场照片");
      //   return false;
      // }
      if (this.person_arr1List.length < 4) {
        this.$message.error("第一次诊断至少选择4个诊断人员");
        return false;
      }
      if (params.twofile == "" || params.twofile == null) {
        this.$message.error("请选择第二次诊断签到表");
        return false;
      }
      // if (
      //   !this.hasOneNotEmpty(
      //     params.two_file_one,
      //     params.two_file_two,
      //     params.two_file_three
      //   )
      // ) {
      //   this.$message.error("第二次诊断至少一张现场照片");
      //   return false;
      // }
      if (this.person_arr2List.length < 4) {
        this.$message.error("第二次诊断至少选择4个诊断人员");
        return false;
      }
      if (params.threefile == "" || params.threefile == null) {
        this.$message.error("请选择第三次诊断签到表");
        return false;
      }
      // if (
      //   !this.hasOneNotEmpty(
      //     params.three_file_one,
      //     params.three_file_two,
      //     params.three_file_three
      //   )
      // ) {
      //   this.$message.error("第三次诊断至少一张现场照片");
      //   return false;
      // }
      if (this.person_arr3List.length < 4) {
        this.$message.error("第三次诊断至少选择4个诊断人员");
        return false;
      }
      if (Array.isArray(params.plan)) {
        params.plan = params.plan.join(",");
      } else {
        params.plan = params.plan.id;
      }
      if (params.onefile) {
        params.files1 = params.onefile;
        //  +
        // "," +
        // params.one_file_one +
        // "," +
        // params.one_file_two +
        // "," +
        // params.one_file_three;
      }
      if (params.twofile) {
        params.files2 = params.twofile;
        //  +
        // "," +
        // params.two_file_one +
        // "," +
        // params.two_file_two +
        // "," +
        // params.two_file_three;
      }
      if (params.threefile) {
        params.files3 = params.threefile;
        //  +
        // "," +
        // params.three_file_one +
        // "," +
        // params.three_file_two +
        // "," +
        // params.three_file_three;
      }

      if (this.person_arr1List.length > 0) {
        params.person_arr1 = this.person_arr1List.join(",");
      }

      if (this.person_arr2List.length > 0) {
        params.person_arr2 = this.person_arr2List.join(",");
      }

      if (this.person_arr3List.length > 0) {
        params.person_arr3 = this.person_arr3List.join(",");
      }
      if (this.person_arr1List.indexOf(null) != -1) {
        this.$message.error("请选择第一次诊断人员");
        return false;
      }
      if (this.person_arr2List.indexOf(null) != -1) {
        this.$message.error("请选择第二次诊断人员");
        return false;
      }
      if (this.person_arr3List.indexOf(null) != -1) {
        this.$message.error("请选择第三次诊断人员");
        return false;
      }
      if (this.newSceneImgList.length == 0) {
        this.$message.error("请上传现场照片");
        return false;
      }
      params.sceneimgList = this.newSceneImgList.join(",");
      try {
        await Get_poststep2(params);
      } catch (e) {
        return false;
      }
      return true;
    },
    timestampToTime(timestamp) {
      var date = new Date(timestamp * 1000);
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();

      let strDate = Y + M + D + h + m + s;
      return strDate;
    },
    // 判断是否有一个值非空/undefined（现场照片要求至少一张）
    hasOneNotEmpty(param1, param2, param3) {
      return (
        (param1 !== "" && param1 !== undefined) ||
        (param2 !== "" && param2 !== undefined) ||
        (param3 !== "" && param3 !== undefined)
      );
    },
  },
};
</script>
<style lang="less" scoped>
::v-deep .el-image__inner {
  object-fit: cover;
}

.imgList {
  width: 100%;

  .imgcol {
    position: relative;

    .removeImg {
      width: 15px;
      height: 15px;
      position: absolute;
      right: 20px;
      top: 8px;
      z-index: 999;
      cursor: pointer;
    }
  }

  .img {
    width: calc(100% - 12px);
    height: 148px;
    object-fit: cover;
    margin-right: 8px;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}

.three_step {
  height: 500px;
  overflow: auto;
  padding-right: 20px;

  .the_h2 {
    font-size: 18px;
    line-height: 18px;
    color: #333;
  }
}

::v-deep .personArr .el-form-item__content {
  display: flex;
  align-items: center;

  .addBtn {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-left: 10px;
  }

  .removeBtn {
    display: inline-block;
    width: 23px;
    height: 23px;
    margin-left: 10px;
  }
}
</style>
