<template>
  <div class="main" :style="$bgImg('mdgs_bg.png')">
    <div class="add-btn">
      <div class="add-btn-body pointer flex column center" @click="Changelog">
        <img class="add-btn-icon" :src="'addContent.png' | staticMedia" />
        <span class="add-btn-title">添加</span>
      </div>
    </div>
    <div class="qiye">
      <div class="qiyetop">
        <div class="qiye_top_souguo">
          <div class="search_box">
            <img :src="'search_icon.png' | staticMedia" class="search_icon" />
            <el-input class="form-input" type="text" v-model="search" value="" placeholder="请输入需要查询的企业名称" />
          </div>
          <el-button class="search-btn" @click="Search_list()">查询</el-button>
        </div>
      </div>
      <div class="qiyelist" v-if="Get_list.length > 0">
        <div class="list_item Changeitem_Img" v-for="(item, index) in Get_list" :key="index">
          <div class="itel_left flex">
            <div class="Info_ChangeImg">
              <img :src="item.image | contentMedia" alt="" />
            </div>
            <div class="Info_title ">
              <div class="tit_top flex bewteen">
                <span class="shgztitle line1">{{ item.qi_name }}</span>

                <div class="item_Date flex vertical">
                  <img :src="'time.png' | staticMedia" />
                  {{ formatDateTime(item.createtime) }}
                </div>
              </div>
              <p class="cont line3">{{ item.content }}</p>
            </div>
          </div>
          <div class="itel_right flex column center">
            <el-button class="primaryBtn1 btn" @click="goUpdate(item.id)">修改</el-button>
            <el-button class="cancleBtn1 btn" @click="goDelete(item.id)">删除</el-button>
          </div>
        </div>
      </div>
      <div class="qiyelist" v-else>
        <div class="list-emp-text">
          暂无数据
        </div>
      </div>
    </div>

    <!-- 弹框 -->

    <el-dialog title="填报信息" :visible.sync="dialogFormVisible" width="1000px" center top="10vh" :before-close="handleClose"
      :close-on-click-modal="false">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" size="medium" label-width="100px"
        class="Userform demo-ruleForm" id="Userform">
        <el-form-item label="企业名称" prop="qi_name">
          <el-input v-model="ruleForm.qi_name" placeholder="请输入企业名称"></el-input>
        </el-form-item>
        <el-form-item label="企业地址" prop="qi_addres">
          <el-input v-model="ruleForm.qi_addres" placeholder="请输入企业地址"></el-input>
        </el-form-item>
        <el-form-item label="情况描述" prop="content">
          <el-input type="textarea" v-model="ruleForm.content" placeholder="请输入情况描述"></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="username">
          <el-input v-model="ruleForm.username" placeholder="请输入联系人"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="mobile" required>
          <el-input v-model="ruleForm.mobile" placeholder="请输入联系电话"></el-input>
        </el-form-item>
        <el-form-item label="申报方向" prop="sbfx">
          <el-select class="S_slet" v-model="ruleForm.sbfx" placeholder="请选择方向">
            <el-option label="共性基础服务平台" value="共性基础服务平台"></el-option>
            <el-option label="工业互联网平台及工业电商服务平台" value="工业互联网平台及工业电商服务平台"></el-option>
            <el-option label="智能制造服务平台" value="智能制造服务平台"></el-option>
            <el-option label="工业绿色化服务平台" value="工业绿色化服务平台"></el-option>
            <el-option label="中小企业强企增效公共服务平台" value="中小企业强企增效公共服务平台"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="申报行业" prop="hy">
          <el-select v-model="ruleForm.hy" multiple placeholder="请选择行业" class="Ms_slet">
            <el-option v-for="(item, index) in hylist" :key="index" :label="item.name" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="申报领域" prop="ly">
          <el-select v-model="ruleForm.ly" multiple placeholder="请选择领域" class="Ms_slet">
            <el-option v-for="(item, index) in lylist" :key="index" :label="item.name" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="列表图" prop="image">
          <TUpupload ref="Turef" :TU_lifils="Datalist" :allownum="1" @tufile-sent="handletufile"></TUpupload>
          <!-- <el-input v-model.trim="ruleForm.image" v-if="false"></el-input> -->
          <!-- <UploadImg v-model="ruleForm.image" @uploadImgFile="uploadImgFile" :detailImage="detailImage"> </UploadImg> -->
        </el-form-item>
        <el-form-item label="证明材料" class="sTip" required style="margin-bottom: 0;">
          <p>项目启动合同，诊断现场照片，诊断报告和诊断评价表</p>
          <p>请注意：证明材料最多上传4份。</p>
          <p>允许格式:jpg,png,bmp,jpeg,gif,zip,rar,mp4,mp3,xls,docx</p>
        </el-form-item>
        <el-form-item label="" class="mosulop" prop="files">
          <Moseupload ref="domref" :uploadlist="parentMessage" :allownum="4" :isShowDelete="true"
            @domfile-sent="handlefile"></Moseupload>
          <!-- 代替form验证 -->
          <el-input v-model="ruleForm.files" v-if="false"></el-input>
        </el-form-item>
        <el-form-item label-width="0">
          <el-checkbox
            v-model="isAgreement">本人承诺：认真遵守国家保密法律、法规和规章制度，内容不涉及国家秘密、商业秘密、个人隐私和其他敏感信息，自愿接受保密审查。如有违反，自愿承担相应责任和后果。</el-checkbox>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="primaryBtn1" @click="submitForm('ruleForm')">确 认</el-button>
        <el-button class="cancleBtn1" @click="resetForm('ruleForm')">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { GetXxtb_list, Get_Post_ssbt, Get_ssbt_detail } from "@/api/pts";
import { formatDateTime, validateMobile } from "@/utils/common.js";

import Moseupload from "@/components/moreupload";
import TUpupload from "@/components/TUpupload";
// import UploadImg from "@/components/UploadImg"
export default {
  components: {
    Moseupload,
    TUpupload,
    // UploadImg
  },
  data() {
    return {
      Get_list: [],
      search: "",
      ruleForm: {
        qi_name: "",
        qi_addres: "",
        content: "",
        username: "",
        mobile: "",
        sbfx: "",
        hy: [],
        ly: [],
        image: "",
        files: [],
      },
      dialogFormVisible: false,
      rules: {
        qi_name: [
          { required: true, message: "企业名称不能为空！", trigger: "blur" },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        qi_addres: [
          { required: true, message: "企业地址不能为空！", trigger: "blur" },
        ],
        content: [
          { required: true, message: "情况描述不能为空！", trigger: "blur" },
        ],
        username: [
          { required: true, message: "联系人不能为空！", trigger: "blur" },
        ],
        mobile: [{ validator: validateMobile.bind(this), trigger: "blur" }],
        sbfx: [
          { required: true, message: "请选择申报方向！", trigger: "change" },
        ],
        hy: [
          { required: true, message: "请选择申报行业！", trigger: "change" },
        ],
        ly: [
          { required: true, message: "申报领域不能为空！", trigger: "change" },
        ],
        type: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个活动性质",
            trigger: "change",
          },
        ],
        image: [
          { required: true, message: "请上传上传列表图", trigger: "change" },
        ],
        files: [
          { required: true, message: "请上传证明材料", trigger: "change" },
        ],
      },
      hylist: [
        // {
        //   name: "全部",
        //   value: "",
        // },
        {
          name: "煤炭/黑色金属矿开采/石油天然气开采",
          value: "煤炭/黑色金属矿开采/石油天然气开采",
        },
        {
          name: "黑色金属",
          value: "黑色金属",
        },
        {
          name: "有色金属",
          value: "有色金属",
        },
        {
          name: "石化化工",
          value: "石化化工",
        },
        {
          name: "建材",
          value: "建材",
        },
        {
          name: "医药",
          value: "医药",
        },
        {
          name: "纺织",
          value: "纺织",
        },
        {
          name: "家电",
          value: "家电",
        },
        {
          name: "食品",
          value: "食品",
        },
        {
          name: "烟草",
          value: "烟草",
        },
        {
          name: "轻工",
          value: "轻工",
        },
        {
          name: "机械",
          value: "机械",
        },
        {
          name: "汽车",
          value: "汽车",
        },
        {
          name: "航空/航天",
          value: "航空/航天",
        },
        {
          name: "船舶",
          value: "船舶",
        },
        {
          name: "机械",
          value: "机械",
        },
        {
          name: "电子",
          value: "电子",
        },
        {
          name: "电力",
          value: "电力",
        },
        {
          name: "热力和燃气",
          value: "热力和燃气",
        },
        {
          name: "建筑业",
          value: "建筑业",
        },
        {
          name: "农业",
          value: "农业",
        },
        {
          name: "其他",
          value: "其他",
        },
      ],
      lylist: [
        // {
        //   name: "全部",
        //   value: "",
        // },
        {
          name: "安全生产",
          value: "安全生产",
        },
        {
          name: "节能减排",
          value: "节能减排",
        },
        {
          name: "质量管控",
          value: "质量管控",
        },
        {
          name: "供应链管理",
          value: "供应链管理",
        },
        {
          name: "研发设计",
          value: "研发设计",
        },
        {
          name: "生产制造",
          value: "生产制造",
        },
        {
          name: "运营管理",
          value: "运营管理",
        },
        {
          name: "仓储物流",
          value: "仓储物流",
        },
        {
          name: "运维服务",
          value: "运维服务",
        },
        {
          name: "其他",
          value: "其他",
        },
      ],
      mostfillist: [],
      Datalist: [], // 传图片数据

      parentMessage: [], //传文件数据进上传文件
      allownum: 3,
      domlist: [], //接收传过来的文件

      // detailImage: ""

      isAgreement: false,
    };
  },
  created() {
    this.Getlist();
  },
  mounted() { },
  methods: {
    handleClose() {
      // this.ruleForm = {}
      this.resetForm("ruleForm");
      this.dialogFormVisible = false;
    },

    // uploadImgFile(v) {
    //     this.ruleForm.image = v;
    //     if (this.ruleForm.image != "") {
    //         this.$refs.ruleForm.validateField('image')
    //     }
    // },
    // 查询列表
    Search_list() {
      this.Getlist();
    },
    // 列表数据
    Getlist() {
      let params = {
        search: this.search,
      };
      GetXxtb_list(params).then((res) => {
        console.log(res);
        this.Get_list = res.data.data;
      });
    },
    // 修改
    goUpdate(id) {
      console.log(id);
      Get_ssbt_detail({ id: id }).then((res) => {
        console.log(res);
        let obj = res.data;
        obj.hy = obj.hy.split(",");
        obj.ly = obj.ly.split(",");

        // obj.language = Number(obj.language)
        // // obj.language = String(Number(obj.language))
        // // obj.stu = String(Number(obj.stu))
        // obj.stu = Number(obj.stu)
        console.log(obj);
        this.dialogFormVisible = true;
        //图片组件传值
        // 懒得点击关闭按钮清空数据
        var tuayy = [];
        tuayy.push({ url: obj.image });
        this.Datalist = tuayy;
        // 文件组件传值

        // this.detailImage = obj.image;

        // var domayy = obj.files.split(",");
        // domayy.forEach((item, index) => {
        //   let obi = {
        //     url: item,
        //     name: item,
        //     state: 1, //代表已经上传过
        //   };
        //   console.log(obi);
        //   domayy[index] = obi;
        // });
        // console.log(domayy);

        var domayy = [...obj.upload_files];
        domayy.forEach((item, index) => {
          let obi = {
            url: item.url,
            name: item.filename,
            state: 1, //代表已经上传过
            id: item.id,
            size: (item.filesize / 1024).toFixed(1) + "kb",
            isdele: false,
            renewdata: this.formatDateTime(item.uploadtime),
          };
          console.log(obi);
          domayy[index] = obi;
        });

        this.parentMessage = domayy;
        this.$nextTick(() => {
          // 这里开始赋值
          this.ruleForm = obj;
          // this.ruleForm.domfile = obj.files //表单验证
        });
      });
    },
    // 删除功能 封装模块使用
    async goDelete(id) {
      console.log(id);

      if (await this.$Delete("post", "/index/company/del_xxtb", { id: id })) {
        this.Getlist();
      } else {
        console.log("-----------3");
      }
    },
    // 这边传递了三个参数
    // row:id -- 要删除的id
    // 'post' -- 请求方式
    // '/sys-user/delete' -- 请求的地址
    // 弹框
    Changelog() {
      this.dialogFormVisible = true;
      delete this.ruleForm.id;
    },
    formatDateTime(timetemp) {
      return formatDateTime(timetemp);
    },

    // 图片上传
    handletufile(list) {
      console.log(list);
      if (list != {}) {
        this.ruleForm.image = list.url;
      } else {
        // 就是删除
        this.ruleForm.image = "";
      }
      this.$refs.ruleForm.validateField(["image"]); //单个校验
    },

    // 多文件上传

    handlefile(list) {
      console.log(list);
      if (list.length > 0) {
        this.domlist = list;
        console.log(this.domlist, "-----------4");
        this.ruleForm.files = this.domlist.map((item) => {
          return item.url;
        });
        this.ruleForm.upload_files = this.domlist.map((item) => {
          return item.id;
        });
      } else {
        console.log("==============1");
        this.ruleForm.files = [];
      }
      console.log(this.ruleForm.files);
      this.$refs.ruleForm.validateField(["files"]); //单个校验
    },

    postData() {
      console.log(this.ruleForm);
      let params = Object.assign({}, this.ruleForm);
      // params.files =
      console.log("jpajdflkjaldjflakdjf", params);

      // params.files = params.files.split(',')
      if (typeof params.upload_files[0] == 'string') {
        params.upload_files = params.upload_files.join(",");
      } else {
        params.upload_files = params.upload_files.map(item=>{
          return item.id
        })
      }
      params.hy = params.hy.join(",");
      params.ly = params.ly.join(",");
      // delete params.admin_id
      // delete params.createtime
      delete params.deletetime;
      // delete params.language
      // delete params.official_category_id
      // delete params.updatetime
      // delete params.weigh
      console.log(params);

      Get_Post_ssbt(params).then((res) => {
        console.log(res);
        if (res.code === 1) {
          if (!params.id) {
            this.$message({
              message: "新增成功",
              type: "success",
            });
          } else {
            this.$message({
              message: "修改成功",
              type: "success",
            });
          }
          this.resetForm("ruleForm");
          this.Getlist();
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });

      // this.resetForm('ruleForm')
    },
    submitForm(formName) {
      if (!this.isAgreement) {
        this.$confirm('是否承诺认真遵守国家保密法律、法规和规章制度，内容不涉及国家秘密、商业秘密、个人隐私和其他敏感信息，自愿接受保密审查？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.isAgreement = true
          this.$refs[formName].validate((valid) => {
            if (valid) {
              // alert('submit!');
              // this.dialogFormVisible = false
              this.postData();
            } else {
              console.log("error submit!!");
              return false;
            }
          });
        }).catch(() => {
          this.isAgreement = false
          return false
        });
      } else {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            // alert('submit!');
            // this.dialogFormVisible = false
            this.postData();
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      // 设置图片为空 文件为空
      this.$refs["Turef"].clearfiles();
      this.$refs["domref"].clearfiles();
      console.log(this.ruleForm);
      this.dialogFormVisible = false;
    },
  },
};
</script>
<style scoped lang="less">
::v-deep .el-checkbox__label {
  white-space: normal;
  word-break: break-all;
  vertical-align: text-top;
}

/deep/ .qiye {
  .qiyelist {
    min-height: 388px;
    width: 100%;

    .list_item {
      display: flex;
      flex-direction: row;
      border-radius: 0;
      border-bottom: 1px solid #d9d9d9;
      margin-bottom: 0;
      width: 100%;
      height: 100%;
      position: relative;

      .itel_left {
        width: 88%;
        padding: 15px 30px;

        .Info_ChangeImg {
          width: 200px;
          height: 122px;

          img {
            object-fit: cover;
          }
        }

        .Info_title {
          // display: flex;
          flex: 1;
          padding: 0px 20px;

          .tit_top {
            margin-bottom: 8px;

            .shgztitle {
              font-size: 20px;
              line-height: 30px;
              font-weight: 600;
              width: 70%;
            }

            .item_Date {
              font-size: 14px;
              font-weight: 400;

              img {
                width: 26px;
                height: 26px;
              }
            }
          }

          .cont {
            font-size: 15px;
            line-height: 20px;
            color: #999;
          }
        }
      }

      .itel_right {
        .el-button+.el-button {
          margin-left: 0;
          margin-top: 10px;
        }
      }
    }
  }
}

// 弹框
/deep/ .el-dialog--center .el-dialog__body {
  height: 600px;
  overflow: auto;
}

/deep/ .Userform {
  .el-select {}

  .S_slet {
    width: 300px;
  }

  .Ms_slet {
    width: 500px;
  }

  // .el-form-item__label{
  //   line-height: 30px;
  // }
  .el-form-item__content {
    width: 500px;
  }

  .el-textarea__inner {
    height: 10vh;
  }

  .el-form-item__label {
    color: #333;
  }

  .mosulop {
    .el-form-item__content {
      width: auto;
    }
  }

  .sTip {
    p {
      // line-height: 14px;
      // margin-bottom: 20px;
    }
  }

  // .el-input{
  //   // height: 30px;
  //   // line-height: 30px;
  // }
  // .el-input__inner{
  //   height: 30px;
  //   line-height: 30px;
  // }
}
</style>
