<template>
  <div class="CenterHeader">
    <div class="nei_zhong">
      <!-- 临时修改 2  -->
      <h2 class="nei_title">“穗智转”公共服务平台 | 用户主页</h2>
      <!-- <h2 class="nei_title">中小企业数字化转型试点中心 | 用户主页</h2> -->
      <!-- <h2 class="nei_title">教学问卷平台系统 | 用户主页</h2> -->
      <div class="nei_zhong_r flex bewteen-wrapper">
        <p class="nei_zhong_r_text" @click="goGateway">
          返回首页
        </p>
        <p @click="handleLogout" class="nei_zhong_r_text">
          退出
        </p>
        <el-dropdown @command="openPersonal">
          <span class="el-dropdown-link flex">
            <img
              class="pointer"
              v-show="user.avatar.indexOf('uploads') == -1"
              :src="user.avatar"
              style="width: 36px;height: 36px;background-color: #fff;border-radius: 36px;"
            />
            <img
              class="pointer"
              v-show="user.avatar.indexOf('uploads') != -1"
              :src="user.avatar | contentMedia"
              style="width: 36px;height: 36px;background-color: #fff;border-radius: 36px;"
            />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="PersonalCenter">
              <span class="flex vertical">
                <img
                  class="person"
                  :src="'/personalCenter.png' | staticMedia"
                  alt=""
                  style="width: 24px;height: 24px;margin-right: 5px;"
                />
                个人中心
              </span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

    <el-dialog
      title="个人中心"
      :visible.sync="personalDialog"
      width="900px"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div class="the_cont">
        <div class="the_tip">
          <img class="" :src="'/update_personalMsg.png' | staticMedia" alt="" />
          <span>账户信息</span>
        </div>
        <div class="siinf">
          <div class="the_one" style="margin-bottom: 20px">
            <div class="tip">公司名称</div>
            <div class="ocname">{{ companyName }}</div>
          </div>
          <div class="the_one">
            <div class="tip">账号</div>
            <div class="ocname">{{ userName }}</div>
          </div>
        </div>
        <div class="changpass" @click="showUpdateForm">修改密码</div>
        <div class="the_tip" id="update_pwd_tips" v-show="isShowForm">
          <img class="" :src="'/update_pwd.png' | staticMedia" alt="" />
          <span>修改密码</span>
        </div>
        <el-form
          :model="passwordForm"
          status-icon
          label-width="100px"
          label-position="left"
          v-show="isShowForm"
          :rules="rules"
          ref="ruleForm"
          style="width: 74%;"
        >
          <el-form-item label="原始密码" prop="oldpassword">
            <el-input
              class="primaryInput"
              type="password"
              v-model="passwordForm.oldpassword"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="newpassword">
            <el-input
              class="primaryInput"
              type="password"
              v-model="passwordForm.newpassword"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="renewpassword">
            <el-input
              class="primaryInput"
              type="password"
              v-model="passwordForm.renewpassword"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button class="primaryBtn1" @click="handleUpdatePassword"
              >确认</el-button
            >
            <el-button class="cancleBtn1" @click="handleClose">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <MeanHeader></MeanHeader>
  </div>
</template>
<script>
import MeanHeader from "@/components/MeanHeader";
import { UpdatePassword } from "@/api/login";
export default {
  components: { MeanHeader },
  data() {
    const regex = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{6,30}$/;
    var validateOldPass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入旧密码"));
        console.log("this.ruleform", this.ruleForm);
      } else if (!regex.test(value)) {
        callback(
          new Error(
            "请输入大写字母、小写字母、数字、字符，包含其中三项的6-30位强密码！"
          )
        );
      } else {
        callback();
      }
    };
    var validateNewPass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新密码"));
        console.log("this.ruleform", this.ruleForm);
      } else if (!regex.test(value)) {
        callback(
          new Error(
            "请输入大写字母、小写字母、数字、字符，包含其中三项的6-30位强密码！"
          )
        );
      } else {
        callback();
      }
    };

    var validateConfirmPass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入确认密码"));
        console.log("this.ruleform", this.ruleForm);
      } else if (!regex.test(value)) {
        callback(
          new Error(
            "请输入大写字母、小写字母、数字、字符，包含其中三项的6-30位强密码！"
          )
        );
      } else if (value !== this.passwordForm.newpassword) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      is_stu: 0,
      personalDialog: false,
      isShowForm: false,
      passwordForm: { oldpassword: "", newpassword: "", renewpassword: "" },
      rules: {
        oldpassword: [
          { required: true, validator: validateOldPass, trigger: "blur" },
        ],
        newpassword: [
          { required: true, validator: validateNewPass, trigger: "blur" },
        ],
        renewpassword: [
          { required: true, validator: validateConfirmPass, trigger: "blur" },
        ],
      },
      companyName: "",
      userName: "",

      user: JSON.parse(sessionStorage.getItem("user")),
    };
  },
  created() {
    this.is_stu = sessionStorage.getItem("is_stu");
  },
  methods: {
    handleUpdatePassword() {
      let pdata = {
        oldpassword: this.passwordForm.oldpassword,
        newpassword: this.passwordForm.newpassword,
        renewpassword: this.passwordForm.renewpassword,
      };

      UpdatePassword(pdata)
        .then((res) => {
          console.log("res", res);
          this.$message.success("修改成功，请重新登录");
          this.handleLogout();
        })
        .catch(() => {
          this.$message.error("修改失败，请联系管理员");
        });
    },
    showUpdateForm() {
      this.isShowForm = true;
    },
    openPersonal() {
      if (this.is_stu == 2 || this.is_stu == 3) {
        this.personalDialog = true;
        this.companyName = JSON.parse(sessionStorage.getItem("ptsInfo")).name;
        this.userName = JSON.parse(sessionStorage.getItem("ptsInfo")).username;
      } else {
        if (this.is_stu == 4) {
          this.$message.error("您不是平台商总账号，禁止访问!");
        } else {
          this.$message.error("您不是平台商身份,禁止访问!");
        }
      }
    },
    handleClose() {
      this.isShowForm = false;
      this.personalDialog = false;
      this.passwordForm = {};
    },
    handleLogout() {
      this.$cookie.set("SZZ_TOKEN", null);
      this.$cookie.set("SZZ_STU", null);

      sessionStorage.clear();
      this.$router.push({
        path: "/login",
      });
    },
    goGateway() {
      let gatewayUrl = location.protocol + "//" + location.hostname;
      location.href = gatewayUrl;
      // location.href = "https://www.suizz.nuxt.hcxtec.com";
    },
  },
};
</script>
<style scoped lang="less">
.el-button + .el-button {
  margin-left: 30px;
}
.CenterHeader {
  min-width: 1200px;
  width: 100%;
  .nei_zhong {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 80px;
    height: 80px;
    line-height: 80px;
    background: #205dc3;
    color: #fff;

    .nei_title {
      font-size: 20px;
      font-weight: 600;
      margin: 0;
    }
    .nei_zhong_r {
      width: 178px;
      .nei_zhong_r_text {
        margin: 0;
        cursor: pointer;
      }
    }
  }
}
::v-deep .nei_zhong_r .el-dropdown {
  height: 36px;
  width: 36px;
}
.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  color: #205dc3;
}
::v-deep .el-dialog {
  height: 618px;
}

.the_cont {
  padding: 0 128px;
}
.the_tip {
  display: flex;
  align-items: center;
  margin: 0 0 30px;
}
.the_tip img {
  width: 22px;
  height: 22px;
}
.the_tip span {
  /*width: 22px;*/
  /*height: 22px;*/
  font-size: 18px;
  line-height: 18px;
  color: #333333;
  margin-left: 12px;
}
.the_one {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: 0em;

  color: #333333;
}
.the_one .tip {
  width: 112px;
}
.changpass {
  width: 88px;
  height: 40px;
  border-radius: 7px;
  opacity: 1;
  color: #ffffff;
  font-size: 15px;
  line-height: 18px;
  cursor: pointer;
  background: #205dc3;
  margin: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
