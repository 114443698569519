<template>
  <div class="main">
    <div class="container">
      <div class="form">
        <div class="breadcrumb">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/government/course' }"
              >课程发布</el-breadcrumb-item
            >
            <el-breadcrumb-item>内容</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="form-header">
          <div class="form-header-title">
            <span class="text">{{ title }}</span>
          </div>
          <div class="form-body">
            <el-form
              ref="courseForm"
              :rules="rules"
              :model="infoForm"
              label-width="150px"
              label-position="left"
            >
              <el-form-item label="课程名称" prop="title">
                <el-input v-model="infoForm.title"></el-input>
              </el-form-item>
              <el-form-item label="所属行业" prop="hy">
                <el-select
                  v-model="infoForm.hy"
                  multiple
                  placeholder="请选择"
                  style="width: 42%;"
                >
                  <el-option
                    v-for="(item, index) in hyOptions"
                    :key="index"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="所属领域" prop="ly">
                <el-select
                  v-model="infoForm.ly"
                  multiple
                  placeholder="请选择"
                  style="width: 42%;"
                >
                  <el-option
                    v-for="(item, index) in lyOptions"
                    :key="index"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="视频封面" prop="image">
                <UploadImg
                  v-model="infoForm.image"
                  @uploadImgFile="uploadImgFile"
                  :detailImage="detailImage"
                >
                </UploadImg>
              </el-form-item>
              <el-form-item label="视频文件" prop="video_file">
                <moreupload
                  ref="domref"
                  :uploadlist="fileList"
                  :allownum="1"
                  @domfile-sent="handleVideoFile"
                  :isShowTable="false"
                  accept=".mp4,.mp3"
                ></moreupload>
                <video
                  class="form-video"
                  ref="formVideo"
                  :src="infoForm.video_file | contentMedia"
                  controls
                ></video>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  class="primaryBtn1"
                  @click="handleSubmit"
                  >提交</el-button
                >
                <el-button @click="handleCancle" class="cancleBtn1"
                  >取消</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import configs from "@/config/index";
// import TUpupload from "@/components/TUpupload.vue";
import moreupload from "@/components/moreupload.vue";
import { addOrEditCourse, getCourseDetail } from "@/api/zf";
import UploadImg from "@/components/UploadImg";
// import { timeToFormat } from "@/utils/common";
export default {
  components: {
    // TUpupload,
    moreupload,
    UploadImg,
  },
  data() {
    var validateImg = (rule, value, callback) => {
      if (this.infoForm.image === "") {
        callback(new Error("请上传视频封面"));
      } else {
        callback();
      }
    };
    var validateVideo = (rule, value, callback) => {
      if (this.t_videoUrl === "") {
        callback(new Error("请上传视频文件"));
      } else {
        callback();
      }
    };
    return {
      isAdd: false,
      title: "修改内容",
      hyOptions: configs.hyOptions,
      lyOptions: configs.lyOptions,
      infoForm: {
        title: "",
        hy: [],
        ly: [],
        image: "",
        video_file: "",
      },
      detailImage: "",
      t_videoUrl: "",
      fileList: [],

      rules: {
        title: [{ required: true, message: "标题不能为空", trigger: "blur" }],
        hy: [
          {
            required: true,
            message: "行业不能为空",
            trigger: "change",
            type: "array",
          },
        ],
        ly: [
          {
            required: true,
            message: "领域不能为空",
            trigger: "change",
            type: "array",
          },
        ],
        image: [{ required: true, validator: validateImg, trigger: "blur" }],
        video_file: [
          { required: true, validator: validateVideo, trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.isAdd = this.$route.query.type;
    if (this.isAdd === "true") {
      this.title = "添加内容";
    } else {
      this.title = "修改内容";
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.getCourseDetail();
    }
  },
  methods: {
    handleCancle() {
      this.$router.go(-1);
    },
    handleSubmit() {
      this.$refs["courseForm"].validate((validate) => {
        if (validate) {
          if (this.infoForm.image == "" || this.infoForm.image == null) {
            this.$message.error("请上传视频封面");
            return false;
          }
          if (this.t_videoUrl == "" || this.t_videoUrl == null) {
            this.$message.error("请上传视频");
            return false;
          }
          this.addOrEditCourse(this.$route.query.id);
        }
      });
    },
    addOrEditCourse(id) {
      let _this = this;
      let pdata = {};
      if (id) {
        pdata = {
          id: id,
          title: _this.infoForm.title,
          hy: _this.infoForm.hy.join(","),
          ly: _this.infoForm.ly.join(","),
          image: _this.infoForm.image,
          video_file: _this.t_videoUrl,
        };
      } else {
        pdata = {
          title: _this.infoForm.title,
          hy: _this.infoForm.hy.join(","),
          ly: _this.infoForm.ly.join(","),
          image: _this.infoForm.image,
          video_file: _this.t_videoUrl,
        };
      }
      addOrEditCourse(pdata)
        .then((res) => {
          console.log("res", res);
          if (res.code == 1) {
            if (_this.$route.query.id) {
              _this.$message.success("修改成功");
            } else {
              _this.$message.success("添加成功");
            }
            setTimeout(() => {
              this.$router.go(-1);
            }, 2000);
          } else {
            if (_this.$route.query.id) {
              _this.$message.success("修改失败");
            } else {
              _this.$message.success("添加失败");
            }
          }
        })
        .catch((err) => {
          console.log("error!", err);
        });
    },
    uploadImgFile(img) {
      this.infoForm.image = img;
      if (this.infoForm.image != "") {
        this.$refs.courseForm.validateField("image");
      }
    },
    getCourseDetail() {
      let _this = this;
      let pdata = {
        id: _this.$route.query.id,
      };
      getCourseDetail(pdata).then((res) => {
        _this.infoForm = res.data;
        _this.infoForm.hy = res.data.hy.split(",");
        _this.infoForm.ly = res.data.ly.split(",");
        _this.detailImage = res.data.image;
        _this.t_videoUrl = res.data.video_file;
      });
    },
    handleVideoFile(fileList, file) {
      this.t_videoUrl = file.data.url;
      this.infoForm.is_file = file.data.url;
      this.$refs.formVideo.src = `${configs.realUrl}/${file.data.url}`;

      this.$refs.courseForm.validateField("video_file");
    },
    uploadVideo() {
      this.$refs.uploadFileInput.click();
    },
  },
};
</script>
<style scoped lang="less">
.el-button + .el-button {
  margin-left: 30px;
}
.container {
  position: relative;
  .form {
    width: 84%;
    margin: 0 auto;
    .form-header {
      background-color: #fff;
      border-radius: 10px;
      padding: 0 50px;
      box-sizing: border-box;
      .form-header-title {
        width: 100%;
        border-bottom: 1px solid #d8d8d8;
        margin-bottom: 20px;
        .text {
          display: inline-block;
          height: 72px;
          line-height: 72px;
          font-size: 18px;
          padding: 0 8px;
          margin-left: 8px;
          border-bottom: 3px solid #9bbef9;
        }
      }
    }
    .form-body {
      padding-right: 180px;
      overflow: hidden;
      .form-video {
        margin-top: 10px;
        width: 50%;
      }
    }
  }
}
</style>
