// 太钩8多了，提出来

// var validateData = (rule, value, callback) => {
//   if (
//     this.infoForm.province === "" ||
//     this.infoForm.city === "" ||
//     this.infoForm.area === ""
//   ) {
//     callback(new Error("请选择省市县"));
//   } else {
//     callback();
//   }
// };
var validatePhone = (rule, value, callback) => {
  // 以1开头的11位数字
  const phone = /^1\d{10}$/;
  if (value == "") {
    callback(new Error("请输入联系方式"));
  } else if (!phone.test(value) && value) {
    callback(new Error("请输入正确的联系方式"));
  } else {
    callback();
  }
};
const rules = {
  // qi_name: [
  //   { required: true, message: "企业名称不能为空", trigger: "blur" },
  // ],
  // fa_name: [
  //   { required: true, message: "法定代表人不能为空", trigger: "blur" },
  // ],
  // areaData: [
  //   { required: true, validator: validateData, trigger: "blur" },
  // ],
  // e_time: [
  //   { required: true, message: "成立时间不能为空", trigger: "blur" },
  // ],
  enterprise_scale: [
    { required: true, message: "企业规模不能为空", trigger: "blur" },
  ],
  mobile: [{ required: true, validator: validatePhone, trigger: "blur" }],
  hy: [{ required: true, message: "行业不能为空", trigger: "blur" }],
  ly: [{ required: true, message: "领域不能为空", trigger: "blur" }],
  product_name: [
    { required: true, message: "产品名称不能为空", trigger: "blur" },
  ],
  yi_xian: [
    {
      required: true,
      message: "已改造项目情况不能为空",
      trigger: "blur",
    },
  ],
  suitability_hy: [
    {
      required: true,
      message: "适用行业不能为空",
      trigger: "blur",
    },
  ],
  test_controller: [
    {
      required: true,
      message: "可控测试结论不能为空",
      trigger: "blur",
    },
  ],
  "declare_info.isGood": [
    {
      required: true,
      message: "是否赛宝优选",
      trigger: "change",
    },
  ],
  "declare_info.benchmarking": [
    {
      required: true,
      message: "对标国外产品不能为空",
      trigger: "blur",
    },
  ],
  "declare_info.adaptationSoftware": [
    {
      required: true,
      message: "适配软件不能为空",
      trigger: "blur",
    },
  ],
  "declare_info.softwareMaturity": [
    {
      required: true,
      message: "软件成熟度不能为空",
      trigger: "blur",
    },
  ],
  "declare_info.developmentLanguage": [
    {
      required: true,
      message: "主要开发语言不能为空",
      trigger: "blur",
    },
  ],
  "declare_info.secondDev": [
    {
      required: true,
      message: "二次开发接口不能为空",
      trigger: "change",
    },
  ],
  "declare_info.saleType": [
    {
      required: true,
      message: "销售方式不能为空",
      trigger: "change",
    },
  ],
  "declare_info.trial": [
    {
      required: true,
      message: "是否支持试用不能为空",
      trigger: "change",
    },
  ],
  deployment_architecture: [
    {
      required: true,
      message: "部署架构不能为空",
      trigger: "blur",
    },
  ],
  "declare_info.listingDate": [
    {
      required: true,
      message: "上市日期不能为空",
      trigger: "change",
    },
  ],
  "declare_info.version": [
    {
      required: true,
      message: "版本号不能为空",
      trigger: "blur",
    },
  ],
  "declare_info.belongingScene": [
    {
      required: true,
      message: "所属场景不能为空",
      trigger: "change",
    },
  ],
  label: [
    {
      required: true,
      message: "标签不能为空",
      trigger: "change",
    },
  ],
  "declare_info.applicationStage": [
    {
      required: true,
      message: "应用环节不能为空",
      trigger: "change",
    },
  ],
  "declare_info.industry": [
    {
      required: true,
      message: "所属行业不能为空",
      trigger: "change",
    },
  ],
  "declare_info.softwareType": [
    {
      required: true,
      message: "软件类型不能为空",
      trigger: "change",
    },
  ],
  type: [
    {
      required: true,
      message: "产品类别不能为空",
      trigger: "change",
    },
  ],
};

export default rules;
