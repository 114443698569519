<template>
  <el-upload
    class="upload-demo"
    ref="moseupload"
    action="#"
    :http-request="uploadReport"
    :headers="{ 'x-auth-token': token }"
    :accept="accept"
    :auto-upload="false"
    :on-change="changeFile"
    :file-list="fileList"
    :limit="allownum || 1"
    :on-success="successfiles"
    :show-file-list="false"
    multiple
    :on-exceed="handleFileExceed"
  >
    <el-button
      slot="trigger"
      class="primaryBtn1"
      style="width:auto;margin-top: 13px;margin-right: 20px;"
      >选取文件</el-button
    >
    <el-button class="primaryBtn1" @click="deleteFile" v-if="allownum == 1"
      >删除已上传文件</el-button
    >
    <el-button class="primaryBtn1" @click="submitUpload">开始上传</el-button>
    <span class="tips" style="margin-left: 20px;" v-show="!isShowTable"
      >*选取文件后请点击开始上传, 最高支持300MB大小的文件</span
    >
    <div v-show="isShowTable">
      <el-table
        class="uplod_list_table"
        :data="fileList"
        border
        :header-cell-style="{ background: '#205dc3', color: '#fff' }"
      >
        <el-table-column
          prop="renewdata"
          label="上传时间"
          width="180"
          align="center"
        >
          <template #default="scope">
            <span>{{ scope.row.renewdata }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="文件名"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column prop="size" label="大小" width="150" align="center">
        </el-table-column>
        <el-table-column label="进度" width="200" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.state == '1'">
              <el-progress
                :text-inside="false"
                :stroke-width="6"
                :percentage="100"
                color="#205dc3"
              ></el-progress>
            </span>
            <span v-if="scope.row.state == '2'">
              <el-progress
                :text-inside="false"
                :stroke-width="6"
                :percentage="progressArr[scope.$index]"
                color="#205dc3"
              ></el-progress>
            </span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="status" label="状态" align="center">
                  <template slot-scope="scope">
                    <span v-if="statusArr[scope.$index] !== '上传成功'" style="color:red">{{ statusArr[scope.$index] }}</span>
                    <span v-else-if="scope.row.status === 'ready' &&
                      progressArr[scope.$index] === 0
                      ">等待上传
                    </span>
                    <span v-else-if="progressArr[scope.$index] > 0 &&
                      progressArr[scope.$index] !== 100
                      ">正在上传
                    </span>
                    <span v-else-if="progressArr[scope.$index] === 100">上传成功
                    </span>
                  </template>
                </el-table-column> -->

        <el-table-column
          label="操作"
          align="center"
          width="120px"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-button
              class="primaryBtn1"
              @click="handleDelete(scope.$index, scope.row)"
              v-if="scope.row.isdele == true || isShowDelete == true"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- <el-button class="primaryBtn1" @click="submitUpload">开始上传
        </el-button> -->
  </el-upload>
</template>
<script>
import axios from "axios";
import configs from "@/config/index";
// import { formatDateTime } from "@/utils/common";
export default {
  props: {
    uploadlist: [],
    allownum: {},
    isShowTable: {
      type: Boolean,
      default: true,
    },
    // 如果需要传递限制文件格式的时候使用这个prop
    accept: {
      type: String,
      default: ".jpg,.png,.bmp,.jpeg,.gif,.zip,.rar,.mp4,.mp3,.xls,.docx",
    },
    isShowDelete: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      token: sessionStorage.token,
      curRowIndex: 0,
      aptsArr: [], //下拉框选项
      fileList: [], //文件上传的列表
      apt: [], //这是是我接触到需要多传递的给后端的参数
      timer: null, //定时器的返回值
      progressArr: [], //控制进度条的进度
      statusArr: [], //控制文件上传的状
      // isShowChooseBtn: true, // 是否显示展示选择文件的按钮
    };
  },
  watch: {
    uploadlist: {
      immediate: true, //首次加载时执行监听
      deep: true, //值改变时执行监听
      handler: function(val) {
        console.log("监听3");
        console.log(val, "上传了 ---------------------- ", this.allownum);
        this.fileList = val;
        // if (val.length == this.allownum) {
        //   this.isShowChooseBtn = false;
        // } else {
        //   this.isShowChooseBtn = true;
        // }
      },
    },
  },
  mounted() {},
  methods: {
    deleteFile() {
      this.fileList = [];
      this.$emit("deleteFile");
    },
    handleFileExceed() {
      // if (this.fileList.length > this.allownum) {
      this.$message.error(`上传文件数量不能超过${this.allownum}个`);
      // }
    },
    // formatDateTime(timetemp) {
    //   return formatDateTime(timetemp);
    // },
    handleDelete(index) {
      //删除操作
      this.fileList.splice(index, 1);
      this.apt.splice(index, 1);
      this.progressArr.splice(index, 1);
      this.statusArr.splice(index, 1);
      if (this.fileList.length == 0) {
        // 列表没数据，可开启校验
        this.$emit("domfile-sent", {});
      } else {
        this.$emit("domfile-sent", this.fileList);
      }
    },
    changeFile(file, fileList) {
      console.log(file, "----------file in moreupload changeFile");
      console.log(fileList);
      if (
        fileList.findIndex((f) => f.name === file.name) !=
        fileList.findLastIndex((f) => f.name === file.name)
      ) {
        this.$message.warning(file.name + " 文件已存在");
        fileList.pop();
      }
      // 为了应对万恶的编辑功能，不能直接拿本地文件来做加载文件，新增可以这么操作要做，要是遇到编辑就走不通，要是后端不止要url还要id，更不能拿本地的文件做显示，得要有载体
      fileList.map((item, index) => {
        if (typeof item.size === "number") {
          this.apt[index] = { fileName: item.name, apt: "", logo: "", des: "" };
          this.progressArr[index] = 0;
          this.statusArr[index] = "上传成功";
          let str = (item.size / 1024).toFixed(1) + "";
          let arr = str.split(".");
          let obj = {
            ...item,
            size: [arr[0], arr[1].slice(0, 1)].join(".") + "kb",
            state: 2, //代表没上传过，用来标识编辑得时候
            isdele: true,
          };
          let _index = this.fileList.findIndex((c) => c.name == item.name);
          if (_index == -1) {
            this.fileList.push(obj);
          }
        } else {
          // this.fileList.push(item)
          // return item;
        }
      });
      // for (let item1 of fileList) {
      //     let _index = this.fileList.findIndex(c => c.name == item1.name)
      //     if (_index == -1) {
      //         this.fileList.push(item1)
      //     }
      // }
      this.fileList.forEach((item) => {
        if (!item.renewdata && item.state == 2) {
          item.renewdata = new Date().toLocaleString().replaceAll("/", "-");
        }
      });
    },
    submitUpload() {
      this.$refs.moseupload.submit();
    },
    uploadReport() {
      //由于submit()会触发多次自定义的http-request的方法，如果不加定时器控制的话，会重复触发上传
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.fileList.forEach((item, index) => {
          console.log(item);
          if (item.state != 1) {
            this.statusArr = this.statusArr.map(() => "上传成功"); //为了使得上传失败，修改后正常上传时的上传状态可以更改

            // let fileMsg = {
            //   fileName: item.raw.name,
            //   fileSize: item.raw.size,
            //   fileUploadTime: new Date().toLocaleString().replaceAll("/", "-"),
            // };

            let formData = new FormData();
            formData.append("file", item.raw);
            // formData.append("fileMsg", JSON.stringify(fileMsg));
            formData.append("apt", JSON.stringify(this.apt));
            let loading = this.$loading({
              lock: true,
              text: "上传中",
              background: "rgba(0, 0, 0, 0.7)",
            });
            axios({
              url: `${configs.baseUrl}/api/common/upload`,
              method: "post",
              data: formData,
              headers: {
                "Content-Type":
                  "multipart/form-data; boundary=----WebKitFormBoundarynl6gT1BKdPWIejNq",
                "x-auth-token": sessionStorage.token,
              },
              onUploadProgress: (progressEvent) => {
                //axios自带可以获取到文件上传是的进度，可以直接用
                let num =
                  ((progressEvent.loaded / progressEvent.total) * 100) | 0;
                this.progressArr = this.progressArr.map((item, ind) => {
                  if (ind === index) {
                    return num;
                  } else {
                    return item;
                  }
                });
              },
            })
              .then(({ data }) => {
                console.log(data);
                // 提交保存成功后清空已上传的文件
                this.fileList.forEach((item) => {
                  if (item.name == data.data.name) {
                    item.id = data.data.id;
                    item.url = data.data.url;
                  }
                });
                this.statusArr = this.statusArr.map((item, ind) => {
                  if (ind === index && data.code !== 0) {
                    return data.msg;
                  } else {
                    return item;
                  }
                });
                console.log(this.fileList);
                // 单个传出去
                this.$emit("domfile-sent", this.fileList, data);
              })
              .catch((err) => {
                console.log("err", err);
              })
              .finally(() => {
                loading.close();
              });
          }
        });
      }, 100);
    },
    successfiles(response, file, fileList) {
      console.log(response, "-----------1");
      console.log(file, "-----------2");
      console.log(fileList, "-----------3");
    },
    // 表单验证清空列表跟本地文件
    clearfiles() {
      this.$refs.moseupload.clearFiles();
      this.fileList = [];
    },
    // handleLogoSuccess(res, file) {
    //     console.log(file)
    //     this.$message.success("封面上传成功!");
    //     this.apt = this.apt.map((item, index) => {
    //         if (index === this.curRowIndex) {
    //             return {
    //                 ...item,
    //                 logo: res.data,
    //             };
    //         } else {
    //             return item;
    //         }
    //     });
    // },

    // beforeLogoUpload(file) { //上传时的封面类型、大小控制
    //     const isJPG = file.type === "image/jpeg" || "image/png";
    //     const isLt10M = file.size / 1024 / 1024 < 10;

    //     if (!isJPG) {
    //         this.$message.error("上传封面只能是 JPG 或 PNG 格式!");
    //     }
    //     if (!isLt10M) {
    //         this.$message.error("上传封面大小不能超过 10MB!");
    //     }
    //     return isJPG && isLt10M;
    // },
  },
};
</script>
<style scoped lang="less">
.moseuplod {
  // width: 100%;
  .el-upload {
    width: 100%;
    text-align: left;
  }
}

.uplod_list_table {
  margin: 10px 0 0 0;

  .cell {
    padding: 0 !important;
    height: 40px;
    line-height: 40px !important;
    text-align: center;
  }

  th.el-table__cell {
    padding: 0;
  }

  .el-table__body td.el-table__cell {
    padding: 6px 0;
  }
}
</style>
