<template>
  <div class="Footer ">
    <div class="content flex bewteen">
      <div class="footer_w">
        <span class="footer_l_item" @click="goAboutUs">关于我们</span> |
        <span class="footer_l_item" @click="goFeedback">问题反馈</span> |
        <span class="footer_l_item" @click="goContactUs">联系我们</span>
      </div>
      <div class="footer_w">
        <div class="backupNum">
          粤公网安备 {{ code }}号
          <img class="backupImg" :src="'gh.png' | staticMedia" alt="" />
        </div>
        <!-- <div class="accessText">欢迎光临, 您是第{{ accessCount }}位访客</div> -->
        <!-- <div class="accessText">欢迎光临, 您是第52451位访客</div> -->
      </div>

      <div
        class="feedbackDialog"
        v-show="isShowFeedbackDialog"
        :style="$bgImg('yhfk_bg.webp')"
      >
        <div class="feedbackTitle">
          用户反馈<img
            @click="handleCancle"
            :src="'close.webp' | staticMedia"
          />
        </div>
        <div class="feedbackBody scroll-style">
          <el-form
            ref="feedbackForm"
            :model="feedbackForm"
            :rules="rules"
            label-width="80px"
          >
            <el-form-item label="姓名" prop="name">
              <el-input
                v-model="feedbackForm.name"
                placeholder="请输入姓名"
              ></el-input>
            </el-form-item>
            <el-form-item label="联系方式" prop="phone">
              <el-input
                v-model="feedbackForm.phone"
                placeholder="请输入联系方式"
              ></el-input>
            </el-form-item>
            <el-form-item label="问题描述" prop="feedbackContent">
              <el-input
                v-model="feedbackForm.feedbackContent"
                placeholder="请输入问题描述"
              ></el-input>
            </el-form-item>
            <el-form-item label="图片">
              <span class="tips">*至多上传3张图片</span>
              <TUpopload
                :allownum="3"
                :TU_lifils="fileList"
                @tufile-sent="handleUploadFile"
              ></TUpopload>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" class="primaryBtn1" @click="onSubmit"
                >提交</el-button
              >
              <el-button class="cancleBtn1" @click="handleCancle"
                >取消</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TUpopload from "@/components/TUpupload.vue";
import { addFeedback } from "@/api/login";
export default {
  name: "Footer",
  components: {
    TUpopload,
  },
  data() {
    const validatePhone = (rule, value, callback) => {
      // 以1开头的11位数字
      const phone = /^1\d{10}$/;
      if (value == "") {
        callback(new Error("请输入联系方式"));
      } else if (!phone.test(value) && value) {
        callback(new Error("请输入正确的联系方式"));
      } else {
        callback();
      }
    };
    return {
      accessCount: 0,
      code: 44011802000613,

      isShowFeedbackDialog: false,

      feedbackForm: {
        name: "",
        phone: "",
        feedbackContent: "",
      },

      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        phone: [{ required: true, validator: validatePhone, trigger: "blur" }],
        feedbackContent: [
          { required: true, message: "请输入问题描述", trigger: "blur" },
        ],
      },

      fileList: [],
      imgUrlList: [],
    };
  },
  mounted() {
    this.getCount();
  },
  methods: {
    getCount() {
      let _this = this;
      setTimeout(function() {
        var s = document.createElement("script");
        s.type = "text/javascript";
        s.src =
          "//statistics.gd.gov.cn/jsonp/site/sum?site=200018&callback=NFCMS_CB_SITE_VISIT_6&t=" +
          Math.random().toString(35);
        window.NFCMS_CB_SITE_VISIT_6 = function(data) {
          _this.accessCount = data.count;
        };
        document.body.appendChild(s);
      }, 10);
    },
    handleUploadFile(file) {
      if (Array.isArray(file)) {
        this.imgUrlList = file.map((item) => item.response.data.src);
      } else {
        this.imgUrlList.push(file.url);
      }
    },
    onSubmit() {
      let _this = this;
      _this.$refs.feedbackForm.validate((valid) => {
        if (valid) {
          let pdata = {
            image: _this.imgUrlList.join(","),
            truename: _this.feedbackForm.name,
            mobile: _this.feedbackForm.phone,
            content: _this.feedbackForm.feedbackContent,
          };
          addFeedback(pdata)
            .then((res) => {
              console.log("res", res);
              if (res.code == 1) {
                _this.$message.success("反馈成功");
                _this.handleCancle();
                _this.fileList = [];
                _this.imgUrlList = [];
              } else {
                _this.$message.error("反馈失败，请稍后重试");
                _this.handleCancle();
                _this.fileList = [];
                _this.imgUrlList = [];
              }
            })
            .catch((err) => {
              console.log("err", err);
            })
            .finally(() => {});
        } else {
          return false;
        }
      });
    },
    handleCancle() {
      // 清空form并关闭dialog
      this.feedbackForm = {};
      this.fileList = [];
      this.isShowFeedbackDialog = false;
    },
    goFeedback() {
      this.isShowFeedbackDialog = true;
    },
    goAboutUs() {
      // this.$router.push({
      //   path: "/aboutUs",
      // });
      let gatewayUrl = location.protocol + "//" + location.hostname;
      location.href = gatewayUrl + "/aboutUs";
      // location.href = "https://www.suizz.nuxt.hcxtec.com/aboutUs";
    },
    goContactUs() {
      // this.$router.push({
      //   path: "/contactUs",
      // });

      let gatewayUrl = location.protocol + "//" + location.hostname;
      location.href = gatewayUrl + "/contactUs";
      // location.href = "https://www.suizz.nuxt.hcxtec.com/contactUs";
    },
  },
};
</script>

<style scoped lang="less">
.Footer {
  width: 100%;
  min-width: 1200px;
  height: 230px;
  background-color: #205dc3;

  .content {
    width: 84%;
    margin: 0 auto;
    color: #fff;
  }

  .footer_w {
    min-width: 400px;
    padding: 60px 0;

    .footer_l_item {
      cursor: pointer;
    }

    .backupNum {
      margin-bottom: 30px;
      cursor: pointer;

      .backupImg {
        margin-left: 20px;
      }
    }
  }
}

.feedbackDialog {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  width: 663px;
  height: fit-content;

  background-size: 100% 100%;
  background-repeat: no-repeat;

  padding: 0 30px 30px;
  z-index: 999;
}

.feedbackTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  line-height: 100px;
  font-size: 21px;
  font-weight: 600;
  color: #fff;
  padding: 0 0 0 70px;
}

.feedbackTitle img {
  height: 21px;
  width: 21px;
  cursor: pointer;
}

.feedbackBody {
  border-radius: 4px;
  background-color: #eff5ff;
  min-height: 480px;
  max-height: 580px;
  padding: 30px;
  box-sizing: border-box;
  overflow-y: scroll;
}
</style>
