<template>
  <div class="main" :style="$bgImg('compony_bg.png')">
    <div class="container">
      <div class="breadcrumb" style="width: 84%; margin: 0 auto 30px">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/company/companyInfo' }"
            >企业信息</el-breadcrumb-item
          >
          <el-breadcrumb-item>内容</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form">
        <div class="form-header">
          <div class="form-header-text">企业信息维护</div>
        </div>
        <el-form
          class="form-body"
          ref="form"
          :model="infoForm"
          label-width="152px"
          label-position="left"
        >
          <el-form-item label="企业名称">
            <el-input v-model="infoForm.name" disabled></el-input>
          </el-form-item>
          <el-form-item label="副标题">
            <el-input v-model="infoForm.subtitle"></el-input>
          </el-form-item>
          <el-form-item label="企业图片">
            <div class="tips">*请上传16:9尺寸的图片</div>
            <TUpupload
              :TU_lifils="imageList"
              :allownum="1"
              @tufile-sent="handleFile"
            ></TUpupload>
          </el-form-item>
          <el-form-item label="企业描述">
            <WangEditor
              @editorInput="handleEditorInput"
              :defVal="infoForm.description"
            ></WangEditor>
          </el-form-item>
          <el-form-item label="所属行政区">
            <el-select v-model="infoForm.belong_area" placeholder="请选择">
              <el-option
                v-for="item in areaList"
                :key="item.id"
                :label="item.name_cn"
                :value="item.name_cn"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="服务企业数">
            <el-input v-model="infoForm.service_busines"></el-input>
          </el-form-item>
          <el-form-item label="联系人">
            <el-input v-model="infoForm.truename" disabled></el-input>
          </el-form-item>
          <el-form-item label="联系方式">
            <el-input v-model="infoForm.mobile" disabled></el-input>
          </el-form-item>
          <el-form-item label="分管副总">
            <el-input v-model="infoForm.deputy"></el-input>
          </el-form-item>
          <el-form-item label="分管副总职务">
            <el-input v-model="infoForm.deputy_job"></el-input>
          </el-form-item>
          <el-form-item label="分管副联系电话">
            <el-input v-model="infoForm.deputy_mobile"></el-input>
          </el-form-item>
          <el-form-item label="分管副联系邮箱">
            <el-input v-model="infoForm.deputy_email"></el-input>
          </el-form-item>
          <el-form-item label="日常联系人">
            <el-input v-model="infoForm.daily_person"></el-input>
          </el-form-item>
          <el-form-item label="日常联系人职务">
            <el-input v-model="infoForm.daily_job"></el-input>
          </el-form-item>
          <el-form-item label="日常联系人电话">
            <el-input v-model="infoForm.daily_mobile"></el-input>
          </el-form-item>
          <el-form-item label="日常联系人邮箱">
            <el-input v-model="infoForm.daily_email"></el-input>
          </el-form-item>
          <el-form-item label="意向行业">
            <!-- <el-select v-model="infoForm.hy" multiple placeholder="请选择" style="width: 42%;">
              <el-option v-for="(item, index) in hyOptions" :key="index" :label="item" :value="item">
              </el-option>
            </el-select> -->
            <el-checkbox-group v-model="infoForm.hy" style="width: 42%">
              <el-checkbox
                v-for="(item, index) in hyOptions"
                :key="index"
                :label="item"
              >
                {{ item }}
                <el-input
                  v-if="item == '其他行业'"
                  class="otherInput"
                  type="text"
                  style="margin-left: 10px"
                  v-model="infoForm.otherhy"
                ></el-input>
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <!-- <el-form-item label="请填写其他行业(如意向行业选择其他行业)">
            <el-input v-model="infoForm.otherhy" style="width: 42%"></el-input>
          </el-form-item> -->
          <el-form-item label="擅长行业">
            <!-- <el-select v-model="infoForm.ly" multiple placeholder="请选择" style="width: 42%;">
              <el-option v-for="(item, index) in lyOptions" :key="index" :label="item" :value="item">
              </el-option>
            </el-select> -->
            <el-checkbox-group v-model="infoForm.ly" style="width: 42%">
              <el-checkbox
                v-for="(item, index) in lyOptions"
                :key="index"
                :label="item"
              >
                {{ item }}
                <el-input
                  v-if="item == '其他行业'"
                  class="otherInput"
                  type="text"
                  style="margin-left: 10px"
                  v-model="infoForm.otherly"
                ></el-input>
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <!-- <el-form-item label="请填写其他行业(如擅长行业选择其他行业)">
            <el-input v-model="infoForm.otherly" style="width: 42%"></el-input>
          </el-form-item> -->
          <el-form-item label="擅长的制造业行业领域以及数字化转型解决方案介绍">
            <el-input
              type="textarea"
              :rows="6"
              placeholder="请输入内容"
              v-model="infoForm.expertise"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button class="primaryBtn1 btn" @click="handleUpdateInfo"
              >确认</el-button
            >
            <el-button class="cancleBtn1 btn" @click="cancleUpdate"
              >取消</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import configs from "@/config/index";
import { getCompanyInfo, editCompanyInfo } from "@/api/pts";
import TUpupload from "@/components/TUpupload.vue";
import WangEditor from "@/components/WangEditor.vue";
import { getArea } from "@/api/login";
export default {
  components: {
    TUpupload,
    WangEditor,
  },
  data() {
    return {
      infoForm: {
        ly: [],
        hy: [],
      },
      config: {},
      imageUrl: "",
      imageList: [],

      hyOptions: [
        "电子信息工业",
        "装备工业",
        "汽车产业",
        "消费品工业",
        "材料工业",
        "其他行业",
      ],
      lyOptions: [
        "电子信息工业",
        "装备工业",
        "汽车产业",
        "消费品工业",
        "材料工业",
        "其他行业",
        "公共型或者基础型",
      ],

      editorStr: "",

      // 行政区列表
      areaList: [],
    };
  },
  created() {},
  mounted() {
    this.config = configs;
    this.getCompanyInfo();
    this.getAreaList();
    // this.hyOptions = configs.hyOptions;
    // this.lyOptions = configs.lyOptions;
  },
  methods: {
    getAreaList() {
      getArea({ pid: 236 }).then((res) => {
        this.areaList = res.data;
      });
    },
    handleEditorInput(html) {
      // console.log('heml', html)
      this.editorStr = html;
    },
    handleFile(file) {
      console.log("file", file);
      this.imageUrl = file.url;
    },
    getCompanyInfo() {
      getCompanyInfo().then((res) => {
        console.log("res", res);
        this.infoForm = res.data;
        this.infoForm.hy = res.data.hy.split(",");
        this.infoForm.ly = res.data.ly.split(",");
        // if (res.data.hy != "") {
        //   this.infoForm.hy = res.data.hy.split(",");
        // } else {
        //   this.infoForm.hy = [];
        // }
        // if (res.data.ly != "") {
        //   this.infoForm.ly = res.data.ly.split(",");
        // } else {
        //   this.infoForm.ly = [];
        // }
        this.imageList.push({ url: res.data.image });
        this.imageUrl = res.data.image;
        this.infoForm.description = this.$encodeToHTML(res.data.description);
      });
    },
    handleUpdateInfo() {
      this.editorStr = this.$encodeHTML(this.editorStr);
      let _this = this;
      if (!_this.infoForm.belong_area) {
        _this.$message.error("请选择所属行政区");
        return false;
      }
      if (!_this.infoForm.service_busines) {
        _this.$message.error("请输入服务企业数");
        return false;
      }
      if (!_this.infoForm.deputy) {
        _this.$message.error("请输入分管副总");
        return false;
      }
      if (!_this.infoForm.deputy_job) {
        _this.$message.error("请输入分管副总职务");
        return false;
      }
      if (!_this.infoForm.deputy_mobile) {
        _this.$message.error("请输入分管副总电话");
        return false;
      }
      if (!_this.infoForm.deputy_email) {
        _this.$message.error("请输入分管副总邮箱");
        return false;
      }
      if (!_this.infoForm.daily_person) {
        _this.$message.error("请输入日常联系人");
        return false;
      }
      if (!_this.infoForm.daily_job) {
        _this.$message.error("请输入日常联系人职务");
        return false;
      }
      if (!_this.infoForm.daily_mobile) {
        _this.$message.error("请输入日常联系人电话");
        return false;
      }
      if (!_this.infoForm.daily_email) {
        _this.$message.error("请输入日常联系人邮箱");
        return false;
      }
      if (_this.infoForm.hy[0] == "") {
        _this.infoForm.hy.shift();
      }
      if (_this.infoForm.ly[0] == "") {
        _this.infoForm.ly.shift();
      }
      let pdata = {
        id: _this.infoForm.id,
        name: _this.infoForm.name,
        subtitle: _this.infoForm.subtitle,
        image: _this.imageUrl,
        description: _this.editorStr,
        expertise: _this.infoForm.expertise,
        hy: _this.infoForm.hy.join(","),
        ly: _this.infoForm.ly.join(","),
        otherhy: _this.infoForm.otherhy,
        otherly: _this.infoForm.otherly,
        belong_area: _this.infoForm.belong_area,
        service_busines: _this.infoForm.service_busines,
        deputy: _this.infoForm.deputy,
        deputy_job: _this.infoForm.deputy_job,
        deputy_mobile: _this.infoForm.deputy_mobile,
        deputy_email: _this.infoForm.deputy_email,
        daily_person: _this.infoForm.daily_person,
        daily_job: _this.infoForm.daily_job,
        daily_mobile: _this.infoForm.daily_mobile,
        daily_email: _this.infoForm.daily_email,
      };

      editCompanyInfo(pdata)
        .then((res) => {
          if (res.code == 1) {
            this.$message.success("修改成功");
            setTimeout(() => {
              this.$router.go(-1);
            }, 1500);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    cancleUpdate() {
      this.$router.back();
    },
  },
};
</script>
<style scoped lang="less">
.otherInput {
  ::v-deep .el-input__inner {
    height: 18px;
    padding: 0 10px;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #555;
  }
}
::v-deep .el-checkbox-group {
  display: flex;
  flex-direction: column;
}

.el-button + .el-button {
  margin-left: 30px;
}

.container {
  position: relative;

  .form {
    width: 84%;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 10px;

    .form-header {
      border-bottom: 1px solid #d8d8d8;

      .form-header-text {
        width: 118px;
        text-align: center;
        color: #333;
        font-size: 18px;
        line-height: 60px;
        margin-left: 30px;
        border-bottom: 3px solid #9bbef9;
        box-sizing: border-box;
      }
    }

    .form-body {
      padding: 20px 280px 20px 40px;
    }
  }
}

::v-deep .el-input.is-disabled .el-input__inner {
  color: #333 !important;
}
</style>
