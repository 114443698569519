<template>
  <div class="main" :style="$bgImg('newsBg.png')">
    <div class="qiye">
      <div class="verify_title">
        <span>审批备案企业</span>
      </div>
      <div class="qiyetop">
        <el-form
          :inline="true"
          ref="formInline"
          :model="formInline"
          class="demo-form-inline"
          size="medium"
        >
          <el-form-item label="公司名称" prop="search">
            <div class="qiye_top_souguo">
              <div class="search_box">
                <img
                  :src="'search_icon.png' | staticMedia"
                  class="search_icon"
                />
                <el-input
                  type="text"
                  v-model="formInline.search"
                  value=""
                  placeholder="请输入需要查询的公司名称"
                />
              </div>
            </div>
          </el-form-item>
          <el-form-item label="诊断机构名称" prop="institution">
            <div class="qiye_top_souguo">
              <div class="search_box">
                <img
                  :src="'search_icon.png' | staticMedia"
                  class="search_icon"
                />
                <el-input
                  type="text"
                  v-model="formInline.institution"
                  value=""
                  placeholder="请输入需要诊断机构名称"
                />
              </div>
            </div>
          </el-form-item>
          <el-form-item label="行业" prop="trade_id">
            <el-select
              v-model="formInline.trade_id"
              class="form-input"
              placeholder="不限"
            >
              <el-option
                v-for="(item, index) in hylist"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="区域" prop="area">
            <el-select
              v-model="formInline.area"
              class="form-input"
              placeholder="不限"
            >
              <el-option
                v-for="item in areaList"
                :key="item.id"
                :label="item.name_cn"
                :value="item.id"
              ></el-option>
              <!-- <el-option label="荔湾区" value="荔湾区"></el-option>
              <el-option label="越秀区" value="越秀区"></el-option>
              <el-option label="海珠区" value="海珠区"></el-option>
              <el-option label="天河区" value="天河区"></el-option>
              <el-option label="白云区" value="白云区"></el-option>
              <el-option label="黄浦区" value="黄浦区"></el-option>
              <el-option label="番禺区" value="番禺区"></el-option>
              <el-option label="花都区" value="花都区"></el-option>
              <el-option label="南沙区" value="南沙区"></el-option>
              <el-option label="从化区" value="从化区"></el-option>
              <el-option label="增城区" value="增城区"></el-option> -->
            </el-select>
          </el-form-item>
          <el-form-item label="审批状态" prop="status">
            <el-select
              v-model="formInline.status"
              class="form-input"
              placeholder="不限"
            >
              <el-option label="待审核" :value="0"></el-option>
              <el-option label="审核通过" :value="1"></el-option>
              <el-option label="审核拒绝" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="page">
            <el-input type="text" v-model="formInline.page" v-if="false" />
          </el-form-item>
          <el-form-item label="" prop="pageSize">
            <el-input type="text" v-model="formInline.pageSize" v-if="false" />
          </el-form-item>
          <el-form-item>
            <el-button
              class="primaryBtn1"
              style="height:36px"
              @click="Search_list"
              >查询</el-button
            >
            <el-button
              class="cancleBtn1 btn"
              style="height:36px"
              @click="resetForm('formInline')"
              >重置</el-button
            >
          </el-form-item>
        </el-form>
        <div class="tablelist">
          <el-table
            ref="multipleTable"
            border
            :data="tableData.data"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="40" align="center">
            </el-table-column>
            <el-table-column
              label="序号"
              type="index"
              width="50"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="company_name"
              label="公司名称"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="code"
              label="机构代码"
              width="180"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              label="行政区域"
              width="120"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row.province + scope.row.city + scope.row.area }}
              </template>
            </el-table-column>
            <el-table-column
              prop="trade"
              label="行业"
              width="80"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="product_type"
              label="产品类型"
              width="120"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="remark"
              label="备注"
              width="120"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="name"
              label="诊断机构"
              width="120"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="contact"
              label="联系人"
              width="70"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="phone"
              label="联系方式"
              width="120"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column label="审批状态" width="80" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.sub_status == 0">审核中</span>
                <span v-if="scope.row.sub_status == 1">已通过</span>
                <span v-if="scope.row.sub_status == 2">已拒绝</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="150" align="center">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  class="primaryBtn1"
                  @click="handleEdit(scope.row, 1)"
                  v-if="scope.row.sub_status != 2"
                  >通过</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  @click="handleDelete(scope.row, 1)"
                  v-if="scope.row.sub_status != 2"
                  >拒绝</el-button
                >
              </template>
            </el-table-column>
          </el-table>

          <div class="publicPage flex bewteen-wrapper">
            <div>
              <el-button
                size="small"
                class="primaryBtn1"
                @click="handleEdit('', 2)"
                >批量通过</el-button
              >
              <el-button size="small" type="danger" @click="handleDelete('', 2)"
                >批量拒绝</el-button
              >
            </div>
            <el-pagination
              style="text-align: right;"
              background
              @current-change="Changepage"
              layout="prev, pager, next"
              :total="tableData.total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Get_companyApplyCheckList, Checkout_companyCheck } from "@/api/pts";
import { getArea } from "@/api/login";
export default {
  data() {
    return {
      formInline: {
        search: "",
        institution: "",
        trade_id: "",
        area: "",
        status: "",
        pageSize: 10,
        page: 1,
      },
      tableData: {},
      multipleSelection: [],
      hylist: JSON.parse(sessionStorage.getItem("hylist")),
      areaList: [],
    };
  },
  created() {},
  mounted() {
    this.Getlist();
    console.log(this.hylist);
    this.getGUANGZHOUArea()
  },
  methods: {
    getGUANGZHOUArea() {
      let pdata = {
        pid: 236,
      };
      getArea(pdata).then((res) => {
        this.areaList = res.data;
      });
    },
    Search_list() {
      this.Getlist();
    },
    // 列表数据
    Getlist() {
      console.log(this.formInline);
      Get_companyApplyCheckList(this.formInline).then((res) => {
        console.log(res);
        this.tableData = res.data;
      });
    },
    handleSelectionChange(val) {
      var ayy = [];
      val.forEach((element) => {
        ayy.push(element.id);
      });
      this.multipleSelection = ayy;
      console.log(this.multipleSelection);
    },
    handleEdit(row, num) {
      if (num == 2) {
        if (this.multipleSelection.length == 0) {
          this.$message({
            type: "warning",
            message: "请至少选择一个！",
          });
          return;
        }
      }
      this.$confirm("确定通过审核吗？", "信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "none",
      })
        .then(() => {
          let id = num == 1 ? row.id : this.multipleSelection.join(",");
          var pdata = {
            ids: id,
            type: 1,
          };
          this.Changestatus(pdata);
          console.log(row, num);
        })
        .catch(() => {});
    },
    handleDelete(row, num) {
      if (num == 2) {
        if (this.multipleSelection.length == 0) {
          this.$message({
            type: "warning",
            message: "请至少选择一个！",
          });
          return;
        }

        this.$confirm("确定拒绝审核吗？", "信息", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "none",
        })
          .then(() => {
            let id = this.multipleSelection.join(",");
            var pdata = {
              ids: id,
              type: 2,
            };
            this.Changestatus(pdata);
            console.log(row, num);
          })
          .catch(() => {});
      }
      if (num == 1) {
        this.$prompt("请输入拒绝原因", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputValidator(value) {
            console.log(value);
            if (value == null) return "请输入拒绝原因";
          },
          inputErrorMessage: "请输入拒绝原因",
          inputType: "textarea",
        })
          .then(({ value }) => {
            console.log(value);
            var pdata = {
              ids: row.id,
              type: 2,
              reason: value,
            };
            this.Changestatus(pdata);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "取消",
            });
          });
      }
    },
    Changestatus(pdata) {
      console.log(pdata);

      Checkout_companyCheck(pdata).then((res) => {
        console.log(res);
        if (res.code == 1) {
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.resetForm("formInline");
        }
      });
    },
    Changepage(val) {
      console.log(val);
      this.formInline.page = val;
      this.Getlist();
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.Getlist();
    },
  },
};
</script>
<style scoped lang="less">
/deep/ .qiye {
  .verify_title {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .qiyetop {
    .form-input {
      border-radius: 4px !important;
      width: 120px !important;
    }
  }

  .primaryBtn1 {
    border-radius: 4px !important;
  }
}
</style>
