<template>
  <div class="main-container" :style="hoverImg">
    <div class="main-content">
      <div class="checkbox" @click="handleCheckbox">
        <img
          v-if="content.official_category_id == 31"
          :src="'choose.png' | staticMedia"
          alt=""
        />
        <img v-else :src="'nochoose.png' | staticMedia" alt="" />
      </div>
      <div class="level">
        <div class="priority">优先级</div>
        <div class="grade">{{ content.yxj }}</div>
      </div>
      <div class="info-container">
        <div class="picture">
          <img :src="content.image | contentMedia" alt="" />
        </div>
        <div class="main-info">
          <div class="title">
            <h4 class="line1">
              {{ itemDetail.info.title }}
            </h4>
          </div>
          <div class="content">{{ content.ly }}</div>
          <div class="other-info">
            <div class="source">
              <img :src="'/source.png' | staticMedia" alt="" />
              来源：{{ content.info.source }}
            </div>
            <div class="time">
              <img :src="'/time.png' | staticMedia" alt="" />
              {{ formatDateTime(itemDetail.publish_time) }}
            </div>
          </div>
        </div>
      </div>
      <div class="btns">
        <el-button class="widthBtn btn primaryBtn1" @click="handleEdit"
          >修改</el-button
        >
        <el-button class="widthBtn btn cancleBtn1" @click="handleDel"
          >删除</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { formatDateTime } from "@/utils/common.js";
export default {
  props: {
    itemDetail: {
      type: Object,
      default: () => {},
    },
    isSelectedAll: {
      type: Boolean,
      default: false,
    },
    dataList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      content: this.itemDetail,
      createTime: formatDateTime(this.itemDetail.createtime),
    };
  },
  watch: {
    itemDetail:{
      immediate: true,
      deep: true,
      handler(val){
        this.content = val
      }
    },
    isSelectedAll(nv) {
      if (nv === true) {
        this.content.official_category_id = 31;
      } else {
        this.content.official_category_id = 35;
      }
      this.getSelectedIds();
    },
    dataList: {
      handler() {
        this.getSelectedIds();
      },
      immediate: false,
    },
  },
  computed: {
    hoverImg() {
      const filteredImageUrl = this.$options.filters.staticMedia(
        "/yxal_list_bg.png"
      );
      return {
        backgroundImage: `url(${filteredImageUrl})`,
      };
    },
  },
  methods: {
    formatDateTime(timetemp) {
      return formatDateTime(timetemp);
    },
    handleCheckbox() {
      if (this.content.official_category_id == 31) {
        this.$set(this.content, "official_category_id", 35);
      } else {
        this.$set(this.content, "official_category_id", 31);
      }
      this.getSelectedIds();
    },
    getSelectedIds() {
      let arr = [];
      let arr_nochoose = [];
      this.dataList.map((item) => {
        if (item.official_category_id === 31) {
          arr.push(item.id);
        } else {
          arr_nochoose.push(item.id);
        }
      });
      this.$emit("setSelectedId", arr, arr_nochoose);
    },
    handleEdit() {
      this.$emit("editContent", true, this.itemDetail);
    },
    handleDel() {
      this.$emit("openDeleteDialog", true, this.itemDetail);
    },
  },
};
</script>

<style lang="less" scoped>
.main-container {
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
  padding: 30px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  position: relative;
  box-sizing: border-box;

  &::before {
    content: "";
    height: 22px;
    width: 2px;
    background-color: #205dc3;
    position: absolute;
    left: 0;
    top: 20px;
  }

  .main-content {
    width: 100%;
    display: flex;

    .checkbox {
      width: 20px;
      height: 20px;
      vertical-align: middle;
      cursor: pointer;
      margin: auto 20px auto 0;

      img {
        width: 20px;
        height: 20px;
      }
    }

    .level {
      width: 56px;
      height: 124px;
      border: 1px solid #ccc;
      margin-right: 20px;

      .priority {
        height: 39px;
        line-height: 39px;
        color: #666666;
        background-color: #cccccc50;
        font-size: 13px;
        text-align: center;
        border-bottom: 1px solid #ccc;
      }

      .grade {
        color: #4a6de7;
        line-height: 86px;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
      }
    }

    .info-container {
      width: calc(90% - 116px);
      display: flex;
      justify-content: space-between;
      cursor: pointer;

      .picture {
        width: 220px;
        height: 124px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: all 0.5s;
        }
      }

      .main-info {
        width: calc(100% - 260px);
        height: 124px;
        padding: 0px 20px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-start;

        .title {
          font-size: 20px;
          font-weight: 600;
          width: 100%;
          line-height: 30px;
          color: #333333;
          margin: 0;
          // white-space: nowrap;
          // overflow: hidden;
          // text-overflow: ellipsis;

          h4 {
            margin: 0;
          }
        }

        .content {
          font-size: 14px;
          padding: 5px 12px;
          background: #ecf5ff;
          color: #205dc3;
          border-radius: 5px;
          margin-right: 14px;
        }

        .other-info {
          width: 80%;
          display: flex;
          justify-content: space-between;
          color: #333333;
          font-size: 14px;

          img {
            width: 26px;
            height: 26px;
            vertical-align: middle;
          }
        }
      }
    }

    .btns {
      width: 10%;
      margin: auto 0;
    }

    &:hover .picture img {
      transform: scale(1.2);
      transition: all 0.5s;
    }
  }

  &:hover {
    box-shadow: 0 0 10px rgba(74, 109, 231, 0.4);
    background-size: 115% 100%;
    background-repeat: no-repeat;
    background-position: 0 10px;
    transition: all 0.5s;
  }
}

.el-button + .el-button,
.el-checkbox.is-bordered + .el-checkbox.is-bordered {
  margin-left: 0;
  margin-top: 4px;
}
</style>
