<template>
  <div class="main" :style="$bgImg('sihua_bg.png')">
    <div class="container">
      <div class="add-btn">
        <div
          class="add-btn-body pointer flex column center"
          @click="goAddSolution"
        >
          <img class="add-btn-icon" :src="'/addContent.png' | staticMedia" />
          <span class="add-btn-title">发布解决方案</span>
        </div>
      </div>
      <div class="solution-list" v-if="SolutionList.length > 0">
        <div class=" flex wrap">
          <div
            class="solution-item pointer shadow"
            v-for="(item, index) in SolutionList"
            :key="index"
          >
            <div class="solution-img">
              <img
                :src="ptsInfoImg | contentMedia"
                class="solution-image image100"
                alt=""
              />
            </div>
            <div class="solution-main ">
              <div class="solution-title line2">
                {{ item.product_name }}
              </div>
              <div class="solution-info flex vertical">
                <img class="bottom-icon" :src="'/qiye.png' | staticMedia" />
                <span class="line1">企业名称：{{ item.qi_name }}</span>
              </div>
            </div>
            <div class="solution-btns flex horizontally vertical">
              <el-button
                class="primaryBtn1 btn"
                @click="goUpdateSolution(item.id)"
                >修改</el-button
              >
              <el-button
                class="cancleBtn1 btn"
                @click="beforeDelSolution(item.id)"
                >删除</el-button
              >
            </div>
          </div>
        </div>
      </div>
      <div class="solution-list" v-else>
        <div class="list-emp-text">
          暂无数据
        </div>
      </div>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :current-page="page"
        :page-size="pageSize"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { getSolutionList } from "@/api/pts";
export default {
  data() {
    return {
      SolutionList: [],
      page: 1,
      pageSize: 12,
      total: 0,
    };
  },
  created() {},
  mounted() {
    this.getSolutionList();
    this.ptsInfoImg = JSON.parse(sessionStorage.getItem('ptsInfo')).image
  },
  methods: {
    handleCurrentChange(page) {
      this.page = page;
      this.getSolutionList();
    },
    beforeDelSolution(id) {
      this.$Delete("post", "/index/company/solution_del", { id }).then(
        (res) => {
          console.log("res", res);
          this.getSolutionList();
        }
      );
    },
    getSolutionList() {
      let _this = this;
      let pdata = {
        page: _this.page,
        pageSize: _this.pageSize,
      };
      getSolutionList(pdata).then((res) => {
        console.log("res", res);
        _this.SolutionList = res.data.data;
        _this.total = res.data.total;
      });
    },
    goUpdateSolution(id) {
      this.$router.push({
        path: "/company/solution/edit",
        query: {
          type: false,
          id: id,
        },
      });
    },
    goAddSolution() {
      this.$router.push({
        path: "/company/solution/add",
        query: {
          type: true,
        },
      });
    },
  },
};
</script>
<style scoped lang="less">
.el-button + .el-button {
  margin-left: 30px;
}

.container {
  position: relative;

  .solution-list {
    width: 84%;
    min-height: 388px;
    margin: 0 auto;

    .solution-item {
      width: 23.95%;
      margin-right: 1.4%;
      border-radius: 10px;
      background-color: #fff;
      padding: 30px 25px 15px;
      margin-bottom: 20px;
      box-sizing: border-box;

      &:nth-child(4n) {
        margin-right: 0;
      }

      &:hover {
        .title {
          color: #205dc3;
        }

        .solution-image {
          transform: scale(1.2);
          transition: all 0.3s;
        }

        .solution-title {
          color: #205dc3 !important;
        }
      }

      .solution-img {
        height: 214px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        margin-bottom: 10px;

        .solution-image {
          transition: all 0.3s;
        }
      }

      .solution-main {
        .solution-title {
          font-size: 20px;
          line-height: 30px;
          height: 60px;
        }

        .solution-info {
          margin: 10px auto;
          font-size: 14px;
        }
      }

      .solution-btns {
        height: 60px;
        width: calc(100% + 50px);
        margin: 0 -25px;
        padding: 8px 0 0;
        border-top: 1px solid #d9d9d9;
      }
    }
  }
}
</style>
