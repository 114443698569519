import Header from "@/components/CenterHeader";
import Footer from "@/components/Footer.vue";
import RequirePublic from "@/views/qy/requirePublic/index";
import SiHuaQuestion from "@/views/qy/siHuaQuestion/index";
import ShuZiHuaQuestion from "@/views/qy/shuZiHuaQuestion/index";
import selfAssessmentQuestion from "@/views/qy/selfAssessmentQuestion/index";
import selfAssessmentQuestionSec from "@/views/qy/selfAssessmentQuestion/indexSec";
import Application from "@/views/qy/ApplicationSolicitation/index";
import ApplicationList from "@/views/qy/ApplicationSolicitation/list";
import ApplicationForm from "@/views/qy/ApplicationSolicitation/form";
import DigitalTransform from "@/views/qy/DigitalTransform/form";
import DigitalTransList from "@/views/qy/DigitalTransform/list";

export default [
  {
    path: "/requirePublic",
    name: "RequirePublic",
    components: {
      header: Header,
      default: RequirePublic, //默认省略不写name的情况
      footer: Footer,
      meta: {
        keepAlive: true,
      },
    },
  },
  {
    path: "/siHuaQuestion",
    name: "SiHuaQuestion",
    components: {
      header: Header,
      default: SiHuaQuestion,
      footer: Footer,
      meta: {
        keepAlive: true,
      },
    },
  },
  {
    path: "/shuZiHuaQuestion",
    name: "ShuZiHuaQuestion",
    components: {
      header: Header,
      default: ShuZiHuaQuestion,
      footer: Footer,
      meta: {
        keepAlive: true,
      },
    },
  },
  {
    path: "/selfAssessmentQuestion",
    name: "selfAssessmentQuestion",
    components: {
      header: Header,
      default: selfAssessmentQuestion,
      footer: Footer,
      meta: {
        keepAlive: true,
      },
    },
  },
  {
    path: "/selfAssessmentQuestionSec",
    name: "selfAssessmentQuestionSec",
    components: {
      header: Header,
      default: selfAssessmentQuestionSec,
      footer: Footer,
      meta: {
        keepAlive: true,
      },
    },
  },
  {
    path: "/enterprise/sbzj",
    name: "enterpriseSbzj",
    components: {
      header: Header,
      default: Application,
      footer: Footer,
      meta: {
        keepAlive: true,
      },
    },
  },
  {
    path: "/enterprise/sbzj/list",
    name: "enterpriseSbzjList",
    components: {
      header: Header,
      default: ApplicationList,
      footer: Footer,
      meta: {
        keepAlive: true,
      },
    },
  },
  {
    path: "/enterprise/sbzj/form",
    name: "enterpriseSbzjForm",
    components: {
      header: Header,
      default: ApplicationForm,
      footer: Footer,
      meta: {
        keepAlive: true,
      },
    },
  },
  {
    path: "/enterprise/sbzj/list2",
    name: "DigitalTransList",
    components: {
      header: Header,
      default: DigitalTransList,
      footer: Footer,
      meta: {
        keepAlive: true,
      },
    },
  },
  {
    path: "/enterprise/sbzj/from2",
    name: "DigitalTransform",
    components: {
      header: Header,
      default: DigitalTransform,
      footer: Footer,
      meta: {
        keepAlive: true,
      },
    },
  },
];
