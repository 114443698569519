<template>
  <div class="main" :style="$bgImg('sihua_bg.png')">
    <div class="container">
      <div class="detail">
        <div class="breadcrumb">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/company/product' }"
              >产品发布</el-breadcrumb-item
            >
            <el-breadcrumb-item>详情</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="detail-body clear">
          <div class="detail-body-title">
            <span class="text">企业信息</span>
          </div>

          <div class="detail-body-list clear">
            <div class="detail-body-l-list">
              <div class="item flex">
                <div class="item-title">
                  企业名称
                </div>
                <div class="item-content">
                  {{ info.qi_name }}
                </div>
              </div>
              <div class="item flex" v-if="info.type == 1">
                <div class="item-title">
                  法定代表人
                </div>
                <div class="item-content">
                  {{ info.fa_name }}
                </div>
              </div>
              <div class="item flex" v-if="info.type == 2">
                <div class="item-title">
                  联系人
                </div>
                <div class="item-content">
                  {{ info.fa_name }}
                </div>
              </div>
              <div class="item flex">
                <div class="item-title" v-if="info.type == 1">
                  企业地点
                </div>
                <div class="item-title" v-if="info.type == 2">
                  清单产品所属地市
                </div>
                <div
                  class="item-content"
                  v-if="info.province && info.city && info.area"
                >
                  {{ `${info.province}${info.city}${info.area}` }}
                </div>
              </div>
              <div class="item flex" v-if="info.type == 1">
                <div class="item-title">
                  成立时间
                </div>
                <div class="item-content">
                  {{ info.e_time }}
                </div>
              </div>
              <div class="item flex">
                <div class="item-title">
                  企业规模
                </div>
                <div class="item-content">
                  {{ info.enterprise_scale }}
                </div>
              </div>
              <div class="item flex">
                <div class="item-title">
                  联系方式
                </div>
                <div class="item-content">
                  {{ info.mobile }}
                </div>
              </div>
            </div>
            <div class="detail-body-r-list">
              <div class="item flex" v-if="info.type == 1">
                <div class="item-title">
                  申报行业
                </div>
                <div class="item-content">
                  {{ info.hy }}
                </div>
              </div>
              <div class="item flex" v-if="info.type == 1">
                <div class="item-title">
                  申报领域
                </div>
                <div class="item-content">
                  {{ info.ly }}
                </div>
              </div>
              <div class="item flex" v-if="info.type == 2">
                <div class="item-title">
                  所属行业
                </div>
                <div class="item-content">
                  {{ info.declare_info.industry }}
                </div>
              </div>
              <div class="item flex" v-if="info.type == 2">
                <div class="item-title">
                  应用环节
                </div>
                <div class="item-content">
                  {{ info.declare_info.applicationStage }}
                </div>
              </div>
            </div>
          </div>
          <div class="detail-body-title">
            <span class="text">产品信息</span>
          </div>
          <div class="detail-body-list">
            <div class="item flex">
              <div class="item-title">
                产品名称
              </div>
              <div class="item-content">
                {{ info.product_name }}
              </div>
            </div>
            <div class="item flex" v-if="info.type == 1">
              <div class="item-title">
                适用行业
              </div>
              <div class="item-content">
                {{ info.suitability_hy }}
              </div>
            </div>
            <div class="item flex" v-if="info.type == 2">
              <div class="item-title">
                软件类型
              </div>
              <div class="item-content">
                {{ info.declare_info.softwareType }}
              </div>
            </div>
            <div class="item flex" v-if="info.type == 2">
              <div class="item-title">
                标签
              </div>
              <div class="item-content">
                {{ info.label }}
              </div>
            </div>
            <div class="item flex" v-if="info.type == 2">
              <div class="item-title">
                所属场景
              </div>
              <div class="item-content">
                <el-cascader
                  v-model="info.declare_info.belongingScene"
                  :options="belongingSceneOptions"
                  :props="{ multiple: true, value: 'id', label: 'title' }"
                  style="width: 100%;"
                  disabled
                ></el-cascader>
              </div>
            </div>
            <div class="item flex" v-if="info.type == 2">
              <div class="item-title">
                版本号
              </div>
              <div class="item-content">
                {{ info.declare_info.version }}
              </div>
            </div>
            <div class="item flex" v-if="info.type == 2">
              <div class="item-title">
                上市日期
              </div>
              <div class="item-content">
                {{ info.declare_info.listingDate }}
              </div>
            </div>
            <div class="item flex" v-if="info.type == 2">
              <div class="item-title">
                部署架构
              </div>
              <div class="item-content">
                {{ info.deployment_architecture }}
              </div>
            </div>
            <div class="item flex" v-if="info.type == 2">
              <div class="item-title">
                是否支持试用
              </div>
              <div class="item-content">
                <!-- {{ info.declare_info.trial }} -->
                <span v-if="info.declare_info.trial == 1">支持</span>
                <span v-if="info.declare_info.trial == 0">不支持</span>
              </div>
            </div>
            <div class="item flex" v-if="info.type == 2">
              <div class="item-title">
                销售方式
              </div>
              <div class="item-content">
                {{ info.declare_info.saleType }}
                <span
                  v-if="
                    info.declare_info.otherSale != null &&
                      info.declare_info.saleType == '其他'
                  "
                  >: {{ info.declare_info.otherSale }}</span
                >
              </div>
            </div>
            <div class="item flex" v-if="info.type == 2">
              <div class="item-title">
                二次开发接口
              </div>
              <div class="item-content">
                <!-- {{ info.declare_info.secondDev }} -->
                <span v-if="info.declare_info.secondDev == 1">支持</span>
                <span v-if="info.declare_info.secondDev == 0">不支持</span>
              </div>
            </div>
            <div class="item flex" v-if="info.type == 2">
              <div class="item-title">
                主要开发语言
              </div>
              <div class="item-content">
                {{ info.declare_info.developmentLanguage }}
              </div>
            </div>
            <div class="item flex" v-if="info.type == 2">
              <div class="item-title">
                软件成熟度
              </div>
              <div class="item-content">
                {{ info.declare_info.softwareMaturity }}
              </div>
            </div>
            <div class="item flex" v-if="info.type == 2">
              <div class="item-title">
                适配软件
              </div>
              <div class="item-content">
                {{ info.declare_info.adaptationSoftware }}
              </div>
            </div>
            <div class="item flex" v-if="info.type == 2">
              <div class="item-title">
                对标国外产品
              </div>
              <div class="item-content">
                {{ info.declare_info.benchmarking }}
              </div>
            </div>
            <div class="item flex" v-if="info.type == 2">
              <div class="item-title">
                是否赛宝优选
              </div>
              <div class="item-content">
                {{ info.declare_info.isGood }}
              </div>
            </div>
            <div class="item flex" v-if="info.type == 2">
              <div class="item-title">
                可控测试结论
              </div>
              <div class="item-content">
                {{ info.test_controller }}
              </div>
            </div>
            <div class="item flex">
              <div class="item-title">
                标签图片
              </div>
              <div class="item-content">
                <img class="item-img" :src="info.image | contentMedia" alt="" />
              </div>
            </div>
            <div class="item flex" v-if="info.type == 1">
              <div class="item-title">
                视频文件
              </div>
              <div class="item-content">
                <video
                  class="item-video"
                  :src="info.is_file | contentMedia"
                  controls
                ></video>
              </div>
            </div>
            <div class="item flex" v-if="info.type == 1">
              <div class="item-title">
                场景描述
              </div>
              <div class="item-content" v-html="info.content"></div>
            </div>
            <div class="item flex" v-if="info.type == 1">
              <div class="item-title">
                已改造项目情况
              </div>
              <div class="item-content">
                {{ info.yi_xian }}
              </div>
            </div>
            <div class="item flex" v-if="info.type == 1">
              <div class="item-title">
                数字化转型领域简述
              </div>
              <div class="item-content">
                {{ info.digitalize_desc }}
              </div>
            </div>
            <div class="item flex" v-if="info.type == 1">
              <div class="item-title">
                网络化协同简述
              </div>
              <div class="item-content">
                {{ info.network_desc }}
              </div>
            </div>
            <div class="item flex" v-if="info.type == 1">
              <div class="item-title">
                智能化制造简述
              </div>
              <div class="item-content">
                {{ info.intellect_desc }}
              </div>
            </div>
            <div class="item flex" v-if="info.type == 1">
              <div class="item-title">
                绿色化提升简述
              </div>
              <div class="item-content">
                {{ info.greenization_desc }}
              </div>
            </div>
            <div class="item flex" v-if="info.type == 1">
              <div class="item-title">
                其他说明事项
              </div>
              <div class="item-content">
                {{ info.other }}
              </div>
            </div>
            <div class="item flex" v-if="info.type == 2">
              <div class="item-title">
                产品关键技术
              </div>
              <div class="item-content" v-html="info.product_technology"></div>
            </div>
            <div class="item flex" v-if="info.type == 2">
              <div class="item-title">
                产品简介
              </div>
              <div class="item-content" v-html="info.product_introduce"></div>
            </div>
            <div class="item flex" v-if="info.type == 2">
              <div class="item-title">
                产品详情
              </div>
              <div class="item-content" v-html="info.product_content"></div>
            </div>
            <!-- <div class="item flex" v-if="info.type == 2">
              <div class="item-title">
                产品功能特性
              </div>
              <div class="item-content" v-html="info.product_function"></div>
            </div>
            <div class="item flex" v-if="info.type == 2">
              <div class="item-title">
                产品核心客户价值
              </div>
              <div class="item-content" v-html="info.product_core"></div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getProductDetail } from "@/api/pts";
// import { belongingSceneOptions } from "./sceenList.js";
import { getSceenList } from "@/api/login.js";
export default {
  components: {},
  data() {
    return {
      info: {},
      belongingSceneOptions: [],
    };
  },
  created() {},
  mounted() {
    this.getProductDetail();
    this.getSceenList();
  },
  methods: {
    getSceenList() {
      getSceenList().then((res) => {
        console.log("res", res);
        this.belongingSceneOptions = res.data;
      });
    },
    getProductDetail() {
      let _this = this;
      let pdata = {
        id: _this.$route.query.id,
      };
      getProductDetail(pdata).then((res) => {
        console.log("res", res);
        _this.info = res.data;
        if (_this.info.type == 1) {
          _this.info.content = this.$encodeToHTML(res.data.content);
        } else {
          _this.info.declare_info = JSON.parse(_this.info.declare_info);
        }
      });
    },
  },
};
</script>
<style scoped lang="less">
.el-button + .el-button {
  margin-left: 30px;
}
.container {
  position: relative;
  .detail {
    width: 84%;
    margin: 0 auto;
    .detail-body {
      background-color: #fff;
      border-radius: 10px;
      margin-top: 20px;
      padding: 0 50px;
      .detail-body-title {
        width: 100%;
        border-bottom: 1px solid #d8d8d8;
        margin-bottom: 20px;
        .text {
          display: inline-block;
          height: 72px;
          line-height: 72px;
          font-size: 18px;
          padding: 0 8px;
          margin-left: 8px;
          border-bottom: 3px solid #9bbef9;
        }
      }
      .detail-body-l-list {
        width: calc(50% - 10px);
        margin-right: 10px;
        float: left;
      }
      .detail-body-r-list {
        width: calc(50% - 10px);
        margin-left: 10px;
        float: right;
      }
      .detail-body-list {
        width: 100%;
        margin-bottom: 30px;
      }
      .detail-body-l-list,
      .detail-body-r-list,
      .detail-body-list {
        .item {
          margin-bottom: 10px;
          font-size: 14px;
          .item-title {
            width: 150px;
            line-height: 1.6;
            color: #666;
          }
          .item-content {
            width: calc(100% - 150px);
            line-height: 1.6;
            .item-img {
              max-width: 100%;
            }
            .item-video {
              max-width: 100%;
            }
          }
        }
      }
    }
  }
}
::v-deep .el-breadcrumb__inner.is-link {
  font-weight: 400;
  color: #333;
  &:hover {
    color: #205dc3;
  }
}
::v-deep .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
  cursor: default;
}
</style>
