<template>
  <div class="sbzj-main" :style="$bgImg('newsBg.png')">
    <div class="contaner">
      <div class="top-nav">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/company/sbzj' }"
            >申报征集</el-breadcrumb-item
          >
          <el-breadcrumb-item :to="{ path: '/company/sbzj/list' }"
            >数字化产品入库申报</el-breadcrumb-item
          >
          <el-breadcrumb-item v-if="isDetail">详情</el-breadcrumb-item>
          <el-breadcrumb-item v-else>申报</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="sbzj-form">
        <h3 class="form-title">
          数字化产品入库申报
        </h3>
        <h4 class="form-subtitle">一、产品提供企业基本情况</h4>
        <el-form
          ref="companyForm"
          :model="form"
          :rules="rules"
          label-width="188px"
          label-position="left"
        >
          <el-form-item label="企业名称" prop="company_name">
            <el-input
              v-model="form.company_name"
              placeholder="请输入企业名称"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="统一社会信用代码" prop="credit_code">
            <el-input
              v-model="form.credit_code"
              placeholder="请输入统一社会信用代码"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="企业地址" prop="company_address">
            <el-input
              v-model="form.company_address"
              placeholder="请输入企业地址"
              :disabled="isDetail"
            ></el-input>
          </el-form-item>
          <el-form-item label="企业性质" prop="company_nature">
            <el-radio-group
              v-model="form.company_nature"
              class="company-nature-radio"
              :disabled="isDetail"
            >
              <el-radio label="国有企业" value="国有企业"></el-radio>
              <el-radio label="集体企业" value="集体企业"></el-radio>
              <el-radio label="私营企业" value="私营企业"></el-radio>
              <el-radio label="有限责任公司" value="有限责任公司"></el-radio>
              <el-radio label="股份有限公司" value="股份有限公司"></el-radio>
              <el-radio label="其他企业" value="其他企业"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="注册资本" prop="registered_capital">
            <el-input
              v-model="form.registered_capital"
              placeholder="请输入注册资本"
              :disabled="isDetail"
            ></el-input>
          </el-form-item>
          <el-form-item label="成立日期" prop="establishment_date">
            <el-date-picker
              type="date"
              placeholder="请选择日期"
              v-model="form.establishment_date"
              value-format="yyyy-MM-dd"
              :disabled="isDetail"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="上年度营收" prop="last_year_revenue">
            <el-input
              v-model="form.last_year_revenue"
              placeholder="请输入上年度营收"
              :disabled="isDetail"
            ></el-input>
          </el-form-item>
          <el-form-item label="融资情况" prop="financing_situation">
            <el-input
              v-model="form.financing_situation"
              placeholder="请输入融资情况"
              :disabled="isDetail"
            ></el-input>
          </el-form-item>
          <el-form-item label="人员规模" prop="personnel_scale">
            <el-input
              v-model="form.personnel_scale"
              placeholder="请输入人员规模"
              :disabled="isDetail"
            ></el-input>
          </el-form-item>
          <el-form-item label="研发人数" prop="design_persons">
            <el-input
              v-model="form.design_persons"
              placeholder="请输入研发人数"
              :disabled="isDetail"
            ></el-input>
          </el-form-item>
          <el-form-item label="上市情况" prop="listed_status">
            <el-radio-group
              v-model="form.listed_status"
              @change="handleListedStatusChange"
              :disabled="isDetail"
            >
              <div style="margin-bottom: 10px;">
                <el-radio :label="1">
                  <span style="margin-right: 20px;">是，上市日期</span>
                  <el-date-picker
                    type="date"
                    placeholder="请选择日期"
                    v-model="form.listed_date"
                    value-format="yyyy-MM-dd"
                    format="yyyy-MM-dd"
                    :disabled="isDetail"
                  ></el-date-picker>
                  <div class="ss-radio">
                    <el-radio-group
                      v-model="form.listed_area"
                      :disabled="isDetail"
                    >
                      <div class="flex bewteen" style="margin-bottom: 10px;">
                        <el-radio label="深交所" value="深交所"
                          >深交所</el-radio
                        >
                        <el-radio label="上交所" value="上交所"
                          >上交所</el-radio
                        >
                        <el-radio label="新加坡" value="新加坡"
                          >新加坡</el-radio
                        >
                        <el-radio label="香港" value="香港">香港</el-radio>
                        <el-radio label="创业板" value="创业板"
                          >创业板</el-radio
                        >
                      </div>
                      <div class="flex bewteen">
                        <el-radio label="新三板" value="新三板"
                          >新三板</el-radio
                        >
                        <el-radio label="纳斯达克" value="纳斯达克"
                          >纳斯达克</el-radio
                        >
                        <el-radio label="纽约交易所" value="纽约交易所"
                          >纽约交易所</el-radio
                        >
                        <el-radio label="其他" value="其他">
                          <span>其他：</span>
                          <el-input
                            class="ss-input"
                            v-model="form.other_listed"
                            :disabled="isDetail"
                          />
                        </el-radio>
                      </div>
                    </el-radio-group>
                  </div>
                </el-radio>
              </div>
              <div>
                <el-radio :label="0">否</el-radio>
              </div>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="联系人" prop="contact_person">
            <el-input
              v-model="form.contact_person"
              placeholder="请输入联系人"
              :disabled="isDetail"
            ></el-input>
          </el-form-item>
          <el-form-item label="职务" prop="position">
            <el-input
              v-model="form.position"
              placeholder="请输入职务"
              :disabled="isDetail"
            ></el-input>
          </el-form-item>
          <el-form-item label="电话" prop="phone">
            <el-input
              v-model="form.phone"
              placeholder="请输入电话"
              :disabled="isDetail"
            ></el-input>
          </el-form-item>
          <el-form-item label="邮箱" prop="email">
            <el-input
              v-model="form.email"
              placeholder="请输入邮箱"
              :disabled="isDetail"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="sbzj-form" style="margin-top: 30px;padding-top: 30px;">
        <h4 class="form-subtitle">二、产品基本情况</h4>
        <h5 class="form-subtitle2">基本情况</h5>
        <el-form
          ref="productForm"
          :model="form"
          :rules="rules"
          label-width="188px"
          label-position="left"
        >
          <el-form-item label="产品名称" prop="product_name">
            <!-- 输入框 -->
            <el-input
              v-model="form.product_name"
              placeholder="请输入产品名称"
              :disabled="isDetail"
            />
          </el-form-item>
          <el-form-item label="产品代码" prop="code">
            <!-- 输入框 -->
            <el-input v-model="form.code" disabled />
          </el-form-item>
          <el-form-item label="标签图片" class="is-required">
            <TUpupload
              :TU_lifils="t_imageList"
              :allownum="1"
              @tufile-sent="handleFile"
              @tufile-remove="handleRemove"
            ></TUpupload>
          </el-form-item>
          <el-form-item label="销售方式" prop="sale_type">
            <el-radio-group
              v-model="form.sale_type"
              @change="handleSaleTypeChange"
              :disabled="isDetail"
            >
              <el-radio label="买断制" value="买断制"></el-radio>
              <el-radio label="订阅制" value="订阅制"></el-radio>
              <el-radio label="其他" value="其他">
                <span>其他：</span>
                <el-input
                  class="ss-input"
                  v-model="form.other_sale"
                  :disabled="isDetail"
                />
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="二次开发" prop="secondary_development">
            <el-radio-group
              v-model="form.secondary_development"
              :disabled="isDetail"
            >
              <el-radio :label="1">支持</el-radio>
              <el-radio :label="0">不支持</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="试用" prop="trial_use">
            <el-radio-group v-model="form.trial_use" :disabled="isDetail">
              <el-radio :label="1">支持</el-radio>
              <el-radio :label="0">不支持</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="应用环节" prop="function_around">
            <el-checkbox-group
              v-model="form.function_around"
              class="product-type-checkbox"
              :disabled="isDetail"
            >
              <el-checkbox label="设计" value="设计"></el-checkbox>
              <el-checkbox label="制造" value="制造"></el-checkbox>
              <el-checkbox label="销售" value="销售"></el-checkbox>
              <el-checkbox label="服务" value="服务"></el-checkbox>
              <el-checkbox label="管理" value="管理"></el-checkbox>
              <el-checkbox label="安全" value="安全"></el-checkbox>
              <el-checkbox label="其他" value="其他"></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="所属行业" prop="industry">
            <el-checkbox-group v-model="form.industry" :disabled="isDetail">
              <el-checkbox label="汽车"></el-checkbox>
              <el-checkbox label="电子元件"></el-checkbox>
              <el-checkbox label="黑色金属冶炼"></el-checkbox>
              <el-checkbox label="煤炭加工"></el-checkbox>
              <el-checkbox label="家具"></el-checkbox>
              <el-checkbox label="食品"></el-checkbox>
              <el-checkbox label="家电"></el-checkbox>
              <el-checkbox label="电子信息"></el-checkbox>
              <el-checkbox label="娱乐用品"></el-checkbox>
              <el-checkbox label="建材"></el-checkbox>
              <el-checkbox label="化工"></el-checkbox>
              <el-checkbox label="纺织"></el-checkbox>
              <el-checkbox label="木材加工"></el-checkbox>
              <el-checkbox label="陶瓷"></el-checkbox>
              <el-checkbox label="通用设备"></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="所属细分行业" prop="sub_industry">
            <el-checkbox-group v-model="form.sub_industry" :disabled="isDetail">
              <el-checkbox label="智能网联和新能源汽车"></el-checkbox>
              <el-checkbox label="工业母机和机器人"></el-checkbox>
              <el-checkbox label="时尚美妆"></el-checkbox>
              <el-checkbox label="定制家居"></el-checkbox>
              <el-checkbox label="服装"></el-checkbox>
              <el-checkbox label="箱包"></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="所属场景" prop="scene">
            <div>
              <el-cascader
                v-model="form.scene"
                :options="belongingSceneOptions"
                :props="{ multiple: true, value: 'id', label: 'title' }"
                clearable
                style="width: 48%"
                :disabled="isDetail"
              ></el-cascader>
            </div>
          </el-form-item>
          <el-form-item label="产品类型" prop="product_type">
            <el-radio-group
              v-model="form.product_type"
              class="product-type-radio"
              :disabled="isDetail"
            >
              <el-radio
                label="研发设计类（CAD/CAE/CAM/CAPP/PLM/PDM/其他）"
                value="研发设计类（CAD/CAE/CAM/CAPP/PLM/PDM/其他）"
              ></el-radio>
              <el-radio
                label="生产制造类(MES/MOM/SCADA/APC/DCS/DNC/PLC/其他)"
                value="生产制造类(MES/MOM/SCADA/APC/DCS/DNC/PLC/其他)"
              ></el-radio>
              <el-radio
                label="经营管理类(ERP/SCM/EAM/HRM/HCM/其他)"
                value="经营管理类(ERP/SCM/EAM/HRM/HCM/其他)"
              ></el-radio>
              <el-radio
                label="运维服务类(MRO/PHM/其他)"
                value="运维服务类(MRO/PHM/其他)"
              ></el-radio>
              <el-radio
                label="销售类(CRM/其他) "
                value="销售类(CRM/其他) "
              ></el-radio>
              <el-radio label="体系设计类" value="体系设计类"></el-radio>
              <el-radio label="安全类" value="安全类"></el-radio>
              <el-radio label="其他" value="其他"></el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="产品关联牵引单位" prop="product_pull">
            <el-checkbox-group
              class="qydw-checkbox"
              v-model="form.product_pull"
              size="medium"
              :disabled="isDetail"
            >
              <div class="qydw-item">
                <el-checkbox
                  class="qydw-checkbox-option"
                  label="无"
                  value="无"
                  key="无"
                ></el-checkbox>
                <el-checkbox
                v-for="(item) in tractionUnitList" 
                  class="qydw-checkbox-option"
                  :label="item.label"
                  :value="item.label"
                  :key="item.label"
                ></el-checkbox>
                <!-- <el-checkbox
                  class="qydw-checkbox-option"
                  label="广州明珞装备股份有限公司"
                  value="广州明珞装备股份有限公司"
                  key="广州明珞装备股份有限公司"
                ></el-checkbox>
                <el-checkbox
                  class="qydw-checkbox-option"
                  label="格创东智（广州）科技技术有限公司"
                  value="格创东智（广州）科技技术有限公司"
                  key="格创东智（广州）科技技术有限公司"
                ></el-checkbox>
                <el-checkbox
                  class="qydw-checkbox-option"
                  label="浪潮云洲工业互联网有限公司"
                  value="浪潮云洲工业互联网有限公司"
                  key="浪潮云洲工业互联网有限公司"
                ></el-checkbox>
                <el-checkbox
                  class="qydw-checkbox-option"
                  label="广州数控设备有限公司"
                  value="广州数控设备有限公司"
                  key="广州数控设备有限公司"
                ></el-checkbox>
                <el-checkbox
                  class="qydw-checkbox-option"
                  label="树根互联股份有限公司"
                  value="树根互联股份有限公司"
                  key="树根互联股份有限公司"
                ></el-checkbox>
                <el-checkbox
                  class="qydw-checkbox-option"
                  label="广东玛斯特智能系统有限公司"
                  value="广东玛斯特智能系统有限公司"
                  key="广东玛斯特智能系统有限公司"
                ></el-checkbox>
                <el-checkbox
                  class="qydw-checkbox-option"
                  label="广州蜂巢互联科技有限公司"
                  value="广州蜂巢互联科技有限公司"
                  key="广州蜂巢互联科技有限公司"
                ></el-checkbox>
                <el-checkbox
                  class="qydw-checkbox-option"
                  label="广州中浩控制技术有限公司"
                  value="广州中浩控制技术有限公司"
                  key="广州中浩控制技术有限公司"
                ></el-checkbox>
                <el-checkbox
                  class="qydw-checkbox-option"
                  label="广州环亚化妆品科技股份有限公司"
                  value="广州环亚化妆品科技股份有限公司"
                  key="广州环亚化妆品科技股份有限公司"
                ></el-checkbox>
                <el-checkbox
                  class="qydw-checkbox-option"
                  label="广州尚品宅配家居股份有限公司"
                  value="广州尚品宅配家居股份有限公司"
                  key="广州尚品宅配家居股份有限公司"
                ></el-checkbox>
                <el-checkbox
                  class="qydw-checkbox-option"
                  label="欧派家居集团股份有限公司"
                  value="欧派家居集团股份有限公司"
                  key="欧派家居集团股份有限公司"
                ></el-checkbox>
                <el-checkbox
                  class="qydw-checkbox-option"
                  label="广州鼎捷软件有限公司"
                  value="广州鼎捷软件有限公司"
                  key="广州鼎捷软件有限公司"
                ></el-checkbox>
                <el-checkbox
                  class="qydw-checkbox-option"
                  label="比音勒芬服饰股份有限公司"
                  value="比音勒芬服饰股份有限公司"
                  key="比音勒芬服饰股份有限公司"
                ></el-checkbox>
                <el-checkbox
                  class="qydw-checkbox-option"
                  label="广东省纺织品进出口股份有限公司"
                  value="广东省纺织品进出口股份有限公司"
                  key="广东省纺织品进出口股份有限公司"
                ></el-checkbox>
                <el-checkbox
                  class="qydw-checkbox-option"
                  label="广州春晓信息科技有限公司"
                  value="广州春晓信息科技有限公司"
                  key="广州春晓信息科技有限公司"
                ></el-checkbox>
                <el-checkbox
                  class="qydw-checkbox-option"
                  label="广州盖特软件有限公司"
                  value="广州盖特软件有限公司"
                  key="广州盖特软件有限公司"
                ></el-checkbox>
                <el-checkbox
                  class="qydw-checkbox-option"
                  label="联通(广东)产业互联网有限公司"
                  value="联通(广东)产业互联网有限公司"
                  key="联通(广东)产业互联网有限公司"
                ></el-checkbox>
                <el-checkbox
                  class="qydw-checkbox-option"
                  label="杭州海康威视数字技术股份有限公司"
                  value="杭州海康威视数字技术股份有限公司"
                  key="杭州海康威视数字技术股份有限公司"
                ></el-checkbox>
                <el-checkbox
                  class="qydw-checkbox-option"
                  label="华为云计算技术有限公司"
                  value="华为云计算技术有限公司"
                  key="华为云计算技术有限公司"
                ></el-checkbox>
                <el-checkbox
                  class="qydw-checkbox-option"
                  label="广东用友软件有限公司"
                  value="广东用友软件有限公司"
                  key="广东用友软件有限公司"
                ></el-checkbox>
                <el-checkbox
                  class="qydw-checkbox-option"
                  label="金蝶软件(中国)有限公司"
                  value="金蝶软件(中国)有限公司"
                  key="金蝶软件(中国)有限公司"
                ></el-checkbox>
                <el-checkbox
                  class="qydw-checkbox-option"
                  label="广东丸美生物技术股份有限公司"
                  value="广东丸美生物技术股份有限公司"
                  key="广东丸美生物技术股份有限公司"
                ></el-checkbox>
                <el-checkbox
                  class="qydw-checkbox-option"
                  label="广州赛意信息科技股份有限公司"
                  value="广州赛意信息科技股份有限公司"
                  key="广州赛意信息科技股份有限公司"
                ></el-checkbox>
                <el-checkbox
                  class="qydw-checkbox-option"
                  label="广东粤桨产业科技有限公司"
                  value="广东粤桨产业科技有限公司"
                  key="广东粤桨产业科技有限公司"
                ></el-checkbox> -->
              </div>
            </el-checkbox-group>
          </el-form-item>
        </el-form>

        <h5 class="form-subtitle2">产品技术</h5>
        <el-form
          ref="technologyForm"
          :model="form"
          :rules="rules"
          label-width="188px"
          label-position="left"
        >
          <el-form-item label="功能性" prop="functionality">
            <el-input
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 8 }"
              placeholder="例如覆盖功能点、功能适用性、数据项正确性等"
              v-model="form.functionality"
              :disabled="isDetail"
              maxlength="500"
              show-word-limit
            />
          </el-form-item>
          <el-form-item label="拓展性" prop="expansibility">
            <el-input
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 8 }"
              placeholder="例如模块化设计、外部集成能力、定制开发、可拓展技术架构等"
              v-model="form.expansibility"
              :disabled="isDetail"
              maxlength="500"
              show-word-limit
            />
          </el-form-item>
          <el-form-item label="安全性" prop="security">
            <el-input
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 8 }"
              placeholder="例如数据保护、身份认证、网络安全等"
              v-model="form.security"
              :disabled="isDetail"
              maxlength="500"
              show-word-limit
            />
          </el-form-item>
          <el-form-item label="可移植性" prop="portability">
            <el-input
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 8 }"
              placeholder="例如CPU适应性、操作系统适应性、数据库适应性等"
              v-model="form.portability"
              :disabled="isDetail"
              maxlength="500"
              show-word-limit
            />
          </el-form-item>
          <el-form-item label="易用性" prop="usability">
            <el-input
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 8 }"
              placeholder="例如覆盖功能点、功能适用性、数据项正确性等"
              v-model="form.usability"
              :disabled="isDetail"
              maxlength="500"
              show-word-limit
            />
          </el-form-item>
          <el-form-item label="创新性" prop="innovativeness">
            <el-input
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 8 }"
              placeholder="例如技术创新（使用了新技术，实现了新功能或性能），业务创新（新的业务模式），产品创新（产品设计具有独特之处，商业模式创新"
              v-model="form.innovativeness"
              :disabled="isDetail"
              maxlength="500"
              show-word-limit
            />
          </el-form-item>
        </el-form>

        <h5 class="form-subtitle2">典型经验案例（每个细分行业至少一个案例）</h5>
        <el-form
          :model="form"
          ref="caseForm"
          label-width="188px"
          label-position="left"
        >
          <div
            v-for="(item, index) in form.companyProductDeclarationList"
            :key="index"
          >
            <div class="flex bewteen vertical">
              <h6 class="form-subtitle3">案例{{ index | getCaseIndex }}</h6>
              <el-button
                class="cancel-btn"
                @click="delCase(index)"
                v-show="index > 0"
                :disabled="isDetail"
                >删除</el-button
              >
            </div>
            <el-form-item label="案例名称" prop="case_name" class="is-required">
              <el-input
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 8 }"
                placeholder="命名采用“产品名称”+“解决方案”的方式"
                v-model="item.case_name"
                :disabled="isDetail"
                maxlength="1000"
                show-word-limit
                @input="handleForeachInputChange"
              />
            </el-form-item>
            <el-form-item
              label="案例背景"
              prop="case_background"
              class="is-required"
            >
              <el-input
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 8 }"
                placeholder="说明原来的状态和希望解决的问题"
                v-model="item.case_background"
                :disabled="isDetail"
                maxlength="1000"
                show-word-limit
                @input="handleForeachInputChange"
              />
            </el-form-item>
            <el-form-item
              label="实践概述"
              prop="practice_overview"
              class="is-required"
            >
              <el-input
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 8 }"
                placeholder="简要说明应用解决方案的基本情况"
                v-model="item.practice_overview"
                :disabled="isDetail"
                maxlength="1000"
                show-word-limit
                @input="handleForeachInputChange"
              />
            </el-form-item>
            <el-form-item
              label="应用效果"
              prop="application_effect"
              class="is-required"
            >
              <el-input
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 8 }"
                placeholder="说明典型经验案例在实践中如何应用，用实例和数据说明带来的变化和效果"
                v-model="item.application_effect"
                :disabled="isDetail"
                maxlength="1000"
                show-word-limit
                @input="handleForeachInputChange"
              />
            </el-form-item>

            <el-form-item label="案例附件" class="is-required">
              <el-upload
                :action="uploadUrl"
                :file-list="item.caseFileList"
                :limit="5"
                :on-success="
                  (response, file, fileList) =>
                    handleCaseFileSuccess(response, file, fileList, item)
                "
                :on-preview="(file) => handleCaseFilePreview(file, item)"
                :on-exceed="
                  (files, fileList) =>
                    handleCaseFileExceed(files, fileList, item)
                "
                :on-remove="
                  (file, fileList) => handleCaseFileRemove(file, fileList, item)
                "
                :on-change="
                  (file, fileList) => handleCaseFileChange(file, fileList, item)
                "
              >
                <el-button size="small" type="primary" :disabled="isDetail"
                  >选择文件</el-button
                >
                <div
                  slot="tip"
                  class="el-upload__tip"
                  style="text-wrap: nowrap;"
                >
                  提示信息：请上传案例的已完工证明材料，包括不限于合同及验收报告等。
                </div>
                <div
                  slot="tip"
                  class="el-upload__tip"
                  style="text-wrap: nowrap;"
                >
                  注：最多可上传5个文件, 文件上传大小不能超过20M
                </div>
              </el-upload>
            </el-form-item>
          </div>
        </el-form>
        <div
          class="addItem flex column center"
          @click="addItem"
          :style="{ cursor: isDetail ? 'not-allowed' : 'pointer' }"
          v-if="form.companyProductDeclarationList.length < 10"
        >
          <img :src="'/additem2.png' | staticMedia" />
          <div>添加更多数据</div>
        </div>

        <h5 class="form-subtitle2">产品说明</h5>
        <el-form
          :model="form"
          ref="descriptionForm"
          :rules="rules"
          label-width="188px"
          label-position="left"
        >
          <el-form-item label="部署架构" prop="deployment_architecture">
            <el-input
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 8 }"
              placeholder="附软件架构图并简要说明"
              v-model="form.deployment_architecture"
              :disabled="isDetail"
              maxlength="500"
              show-word-limit
            />
          </el-form-item>
          <el-form-item prop="deployment_url">
            <el-upload
              :action="uploadUrl"
              :on-success="handleDeploymentFileSuccess"
              :file-list="deploymentFileList"
              :limit="5"
              :on-preview="handleDeploymentFilePreview"
              :on-exceed="handleDeploymentFileExceed"
              :on-remove="handleDeploymentFileRemove"
              :on-change="handleDeploymentFileChange"
            >
              <el-button size="small" type="primary" :disabled="isDetail"
                >选择文件</el-button
              >
              <div slot="tip" class="el-upload__tip" style="text-wrap: nowrap;">
                注：最多可上传5个文件, 文件上传大小不能超过20M
              </div>
            </el-upload>
          </el-form-item>

          <el-form-item label="安全说明" prop="security_description">
            <el-input
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 8 }"
              placeholder="说明产品安全性能，包括但不限于通过的安全测试、安全等级、安全技术指标、安全技术要求等。如有相关报告，可作为附件提交。"
              v-model="form.security_description"
              :disabled="isDetail"
              maxlength="500"
              show-word-limit
            />
          </el-form-item>
          <el-form-item prop="security_url">
            <el-upload
              :action="uploadUrl"
              :on-success="handleSecurityFileSuccess"
              :file-list="securityFileList"
              :limit="5"
              :on-preview="handleSecurityFilePreview"
              :on-exceed="handleSecurityFileExceed"
              :on-remove="handleSecurityFileRemove"
              :on-change="handleSecurityFileChange"
            >
              <el-button size="small" type="primary" :disabled="isDetail"
                >选择文件</el-button
              >
              <div slot="tip" class="el-upload__tip" style="text-wrap: nowrap;">
                注：最多可上传5个文件, 文件上传大小不能超过20M
              </div>
            </el-upload>
          </el-form-item>

          <el-form-item label="自主知识产权说明" prop="independent_description">
            <el-input
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 8 }"
              placeholder="列举产品相关的自主知识产权的材料，包括相关专利、软著等。附件提交盖章证书材料。"
              v-model="form.independent_description"
              :disabled="isDetail"
              maxlength="500"
              show-word-limit
            />
          </el-form-item>
          <el-form-item prop="independent_url">
            <el-upload
              :action="uploadUrl"
              :on-success="handleIndependentFileSuccess"
              :file-list="independentFileList"
              :limit="5"
              :on-preview="handleIndependentFilePreview"
              :on-exceed="handleIndependentFileExceed"
              :on-remove="handleIndependentFileRemove"
              :on-change="handleIndependentFileChange"
            >
              <el-button size="small" type="primary" :disabled="isDetail"
                >选择文件</el-button
              >
              <div slot="tip" class="el-upload__tip" style="text-wrap: nowrap;">
                注：最多可上传5个文件, 文件上传大小不能超过20M
              </div>
            </el-upload>
          </el-form-item>

          <el-form-item label="价格说明" prop="price_description">
            <el-input
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 8 }"
              placeholder="说明产品建议市场价格（根据产品售卖方式具体描述）和建议优惠价格（选填）并在附件中提交产品定价理由详细说明。"
              v-model="form.price_description"
              :disabled="isDetail"
              maxlength="500"
              show-word-limit
            />
          </el-form-item>
          <el-form-item prop="price_url">
            <el-upload
              :action="uploadUrl"
              :on-success="handlePriceFileSuccess"
              :file-list="priceFileList"
              :limit="5"
              :on-preview="handlePriceFilePreview"
              :on-exceed="handlePriceFileExceed"
              :on-remove="handlePriceFileRemove"
              :on-change="handlePriceFileChange"
            >
              <el-button size="small" type="primary" :disabled="isDetail"
                >选择文件</el-button
              >
              <div slot="tip" class="el-upload__tip" style="text-wrap: nowrap;">
                注：最多可上传5个文件, 文件上传大小不能超过20M
              </div>
            </el-upload>
          </el-form-item>

          <el-form-item label="获奖信息">
            <!-- prop="award_situation" -->
            <el-input
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 8 }"
              placeholder="说明产品以往获奖情况，字数控制在500字以内。在附件中提交相关材料复印件。"
              v-model="form.award_situation"
              :disabled="isDetail"
              maxlength="500"
              show-word-limit
            />
          </el-form-item>
          <el-form-item prop="award_url">
            <el-upload
              :action="uploadUrl"
              :on-success="handleAwardFileSuccess"
              :file-list="awardFileList"
              :limit="5"
              :on-preview="handleAwardFilePreview"
              :on-exceed="handleAwardFileExceed"
              :on-remove="handleAwardFileRemove"
              :on-change="handleAwardFileChange"
            >
              <el-button size="small" type="primary" :disabled="isDetail"
                >选择文件</el-button
              >
              <div slot="tip" class="el-upload__tip" style="text-wrap: nowrap;">
                注：最多可上传5个文件, 文件上传大小不能超过20M
              </div>
            </el-upload>
          </el-form-item>
        </el-form>

        <h5 class="form-subtitle2">相关附件</h5>
        <div class="form-desc">
          <div>1、产品获奖材料复印件；</div>
          <div>2、产品价格说明；</div>
          <div>3、产品自主支持产权相关材料，包括相关专利、软著等；</div>
          <div>
            4、产品第三方测试报告，例如功能测试报告、性能测试报告、安全测试报告、兼容性测试报告、用户体验测试报告等。附完整测试报告为附件，若无软件第三方测试报告则提供用户试用报告，并加盖用户公司章；
          </div>
          <div>5、营业执照；</div>
          <div>6、信用查询记录，包括信用中国、中国政府采购网。</div>
        </div>
        <div>
          <h5 class="form-subtitle2" v-if="isDetail">审核结果</h5>
          <div class="auditStatus-container" v-if="isDetail">
            <div class="auditStatus-title">审核状态</div>
            <div class="auditStatus-desc">
              <el-radio-group v-model="form.audit_status" :disabled="isDetail">
                <el-radio :label="1">待审核</el-radio>
                <el-radio :label="2">审核通过</el-radio>
                <el-radio :label="3">审核不通过</el-radio>
              </el-radio-group>
            </div>
            <div class="auditStatus-title">原因</div>
            <div class="audit_reason">
              <el-input
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 8 }"
                placeholder="请输入原因"
                v-model="form.audit_reason"
                :disabled="isDetail"
              />
            </div>
            <div class="auditStatus-title">是否为试点产品</div>
            <div class="auditStatus-desc">
              <el-radio-group v-model="form.try_status" :disabled="isDetail">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="2">否</el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>
        <div class="btns">
          <el-button @click="handleClose" class="cancel-btn btn"
            >关闭</el-button
          >
          <el-button @click="saveDraft" class="draft-btn btn" v-if="!isDetail"
            >保存草稿</el-button
          >
          <el-button @click="getDraft" class="draft-btn btn" v-if="!isDetail"
            >获取草稿</el-button
          >
          <el-button
            @click="beforeSubmit"
            type="primary"
            class="btn"
            v-if="!isDetail"
            >提交</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import configs from "@/config/index";
import {
  saveDraft,
  getDraft,
  productDeclaration,
  productDeclarationDetail,
  getCompanyInfo,
  drowproductList,
  getProductDetail,
  getTractionUnit,
} from "@/api/pts";
// import { belongingSceneOptions } from "../product/sceenList.js";
import { getSceenList } from "@/api/login.js";
import TUpupload from "@/components/TUpupload.vue";
export default {
  components: { TUpupload },
  data() {
    return {
      form: {
        company_name: "", // 企业名称
        credit_code: "", // 统一社会信用代码
        company_address: "", // 企业地址
        company_nature: null, // 企业性质
        registered_capital: "", // 注册资本
        establishment_date: "", // 成立日期
        last_year_revenue: "", // 上年度营收
        financing_situation: "", // 融资情况
        personnel_scale: null, // 人员规模
        design_persons: null, // 研发人数
        listed_status: null, // 上市情况 1：是 0：否
        other_listed: "", // 上市情况-其他
        listed_date: "", // 上市日期  上市了填
        listed_area: null, // 上市地区  上市了才能填
        contact_person: "", // 联系人
        position: "", // 职务
        phone: "", // 电话
        email: "", // 邮箱
        product_name: "", // 产品名称
        code: "", // 产品代码
        product_id: "", // 产品名称
        product_picture: "", // 产品图片
        sale_type: null, // 销售方式
        other_sale: "", // 销售方式-其他
        secondary_development: null, // 是否支持二开 1:支持 0：不支持
        trial_use: null, // 是否支持试用 1：支持 0：不支持
        industry: [], // 所属行业
        sub_industry: [], // 所属细分行业，这里传输给后端为xxx,xxx,xxx
        product_pull: [], //数字牵引单位
        scene: "", // 所属场景
        product_type: null, // 产品类型
        function_around: [], //应用环节
        functionality: "", // 功能性
        expansibility: "", // 拓展性
        security: "", // 安全性
        portability: "", // 可移植性
        usability: "", // 易用性
        innovativeness: "", // 创新性
        deployment_architecture: "", // 部署架构
        deployment_url: "", // 部署架构图
        security_description: "", // 安全说明
        security_url: "", //安全说明附件
        independent_description: "", // 自主知识产权说明
        independent_url: "", //自主知识产权说明附件
        price_description: "", // 价格说明
        price_url: "", //价格说明附件
        award_situation: "", // 获奖情况
        award_url: "", //获奖情况附件
        test_report: "", //第三方测试
        test_url: "", //第三方测试
        license: "", //营业执照
        license_url: "", //营业执照
        credit_history: "", //信用记录
        credit_url: "", //信用记录
        related_attachment: "[]", // 相关附件
        product_case: "", // 案例JSON数据
        pull_unit: "",
        pull_unit_service: "",
        pull_partner: "",

        companyProductDeclarationList: [
          {
            case_name: "", // 案例名称
            case_background: "", // 案例背景
            practice_overview: "", // 实践概述
            application_effect: "", // 应用效果
            case_url: "", // 案例附件
            caseFileList: [], // 案例附件数组
          },
        ],

        options: [], //下拉框

        // company_name: "企业名称", // 企业名称
        // credit_code: "统一社会信用代码", // 统一社会信用代码
        // company_address: "企业地址", // 企业地址
        // company_nature: "国有企业", // 企业性质
        // registered_capital: "注册资本", // 注册资本
        // establishment_date: "2020-01-01", // 成立日期
        // last_year_revenue: "100亿", // 上年度营收
        // financing_situation: "100亿", // 融资情况
        // personnel_scale: 500, // 人员规模
        // design_persons: 100, // 研发人数
        // listed_status: 1, // 上市情况 1：是 0：否
        // listed_date: "2020-02-02", // 上市日期  上市了填
        // listed_area: "深交所", // 上市地区  上市了才能填
        // contact_person: "联系人", // 联系人
        // position: "职务", // 职务
        // phone: "13588888888", // 电话
        // email: "13588888888@qq.com", // 邮箱
        // product_name: "产品名称", // 产品名称
        // sale_type: "买断制", // 销售方式
        // secondary_development: 1, // 是否支持二开 1:支持 0：不支持
        // trial_use: 1, // 是否支持试用 1：支持 0：不支持
        // sub_industry: ["智能网联和新能源汽车"], // 所属细分行业，这里传输给后端为xxx,xxx,xxx
        // scene: "所属场景", // 所属场景
        // product_type: "研发设计类（CAD/CAE/CAM/CAPP/PLM/PDM/其他）", // 产品类型
        // functionality: "功能性", // 功能性
        // expansibility: "拓展性", // 拓展性
        // security: "安全性", // 安全性
        // portability: "可移植性", // 可移植性
        // usability: "易用性", // 易用性
        // innovativeness: "创新性", // 创新性
        // deployment_architecture: "部署架构", // 部署架构
        // deployment_url: "[]", // 部署架构图
        // security_description: "安全说明", // 安全说明
        // independent_description: "自主知识产权说明", // 自主知识产权说明
        // price_description: "价格说明", // 价格说明
        // award_situation: "获奖情况", // 获奖情况
        // related_attachment: "[]", // 相关附件
        // product_case: "", // 案例JSON数据
        // companyProductDeclarationList: [
        //   {
        //     case_name: "案例名称", // 案例名称
        //     case_background: "案例背景", // 案例背景
        //     practice_overview: "实践概述", // 实践概述
        //     application_effect: "应用效果", // 应用效果
        //   },
        // ],
      },
      t_imageList: [], // 图片数组
      t_imageUrl: "", // 上传图片
      rules: {
        company_name: [
          { required: true, message: "企业名称不能为空", trigger: "blur" },
        ],
        credit_code: [
          {
            required: true,
            message: "统一社会信用代码不能为空",
            trigger: "blur",
          },
        ],
        company_address: [
          { required: true, message: "企业地址不能为空", trigger: "blur" },
        ],
        company_nature: [
          { required: true, message: "企业性质不能为空", trigger: "blur" },
        ],
        registered_capital: [
          { required: true, message: "注册资本不能为空", trigger: "blur" },
        ],
        establishment_date: [
          { required: true, message: "成立日期不能为空", trigger: "blur" },
        ],
        last_year_revenue: [
          { required: true, message: "上年度营收不能为空", trigger: "blur" },
        ],
        financing_situation: [
          { required: true, message: "融资情况不能为空", trigger: "blur" },
        ],
        personnel_scale: [
          { required: true, message: "人员规模不能为空", trigger: "blur" },
        ],
        design_persons: [
          { required: true, message: "研发人数不能为空", trigger: "blur" },
        ],
        listed_status: [
          { required: true, message: "上市情况不能为空", trigger: "blur" },
        ],
        contact_person: [
          { required: true, message: "联系人不能为空", trigger: "blur" },
        ],
        position: [
          { required: true, message: "职务不能为空", trigger: "blur" },
        ],
        phone: [{ required: true, message: "电话不能为空", trigger: "blur" }],
        email: [{ required: true, message: "邮箱不能为空", trigger: "blur" }],
        pull_unit: [{ required: true, message: "请选择", trigger: "blur" }],
        pull_unit_service: [
          { required: true, message: "请选择", trigger: "blur" },
        ],
        product_name: [
          { required: true, message: "产品名称不能为空", trigger: "blur" },
        ],
        // image: [{ required: true, message: "请上传标签图片", trigger: "blur" }],
        sale_type: [
          { required: true, message: "销售方式不能为空", trigger: "blur" },
        ],
        secondary_development: [
          { required: true, message: "二次开发不能为空", trigger: "blur" },
        ],
        trial_use: [
          { required: true, message: "试用不能为空", trigger: "blur" },
        ],
        industry: [
          {
            required: true,
            message: "所属行业不能为空",
            trigger: "change",
          },
        ],
        sub_industry: [
          {
            required: true,
            message: "所属细分行业不能为空",
            trigger: "change",
          },
        ],
        product_pull: [
          {
            required: true,
            message: "产品关联牵引单位不能为空",
            trigger: "change",
          },
        ],
        scene: [
          { required: true, message: "所属场景不能为空", trigger: "blur" },
        ],
        product_type: [
          { required: true, message: "产品类型不能为空", trigger: "blur" },
        ],
        function_around: [
          { required: true, message: "应用环节不能为空", trigger: "change" },
        ],
        functionality: [
          { required: true, message: "功能性不能为空", trigger: "blur" },
        ],
        expansibility: [
          { required: true, message: "拓展性不能为空", trigger: "blur" },
        ],
        security: [
          { required: true, message: "安全性不能为空", trigger: "blur" },
        ],
        portability: [
          { required: true, message: "可移植性不能为空", trigger: "blur" },
        ],
        usability: [
          { required: true, message: "易用性不能为空", trigger: "blur" },
        ],
        innovativeness: [
          { required: true, message: "创新性不能为空", trigger: "blur" },
        ],
        deployment_architecture: [
          { required: true, message: "部署架构不能为空", trigger: "blur" },
        ],
        security_description: [
          { required: true, message: "安全说明不能为空", trigger: "blur" },
        ],
        independent_description: [
          {
            required: true,
            message: "自主知识产权说明不能为空",
            trigger: "blur",
          },
        ],
        price_description: [
          { required: true, message: "价格说明不能为空", trigger: "blur" },
        ],
        // award_situation: [
        //   { required: true, message: "获奖信息不能为空", trigger: "blur" },
        // ],
      },
      uploadUrl: "", // 图片上传路径
      fileList: [],
      deploymentFileList: [],
      securityFileList: [], //安全说明附件路径
      independentFileList: [], //自主知识产权
      priceFileList: [], //价格说明
      awardFileList: [], //获奖情况
      creditFileList: [], //信用记录
      licenseFileList: [], //营业执照
      TestFileList: [], //第三方测试
      isDetail: false,
      isFirst: true,
      loadingStatus: null,
      belongingSceneOptions: [],

      // 产品名称下拉框
      projectnamelist: [],
      // 获取牵引单位列表
      tractionUnitList: [],
    };
  },
  filters: {
    getCaseIndex(val) {
      let num = "";
      switch (val) {
        case 0:
          num = "一";
          break;
        case 1:
          num = "二";
          break;
        case 2:
          num = "三";
          break;
        case 3:
          num = "四";
          break;
        case 4:
          num = "五";
          break;
        case 5:
          num = "六";
          break;
        case 6:
          num = "七";
          break;
        case 7:
          num = "八";
          break;
        case 8:
          num = "九";
          break;
        case 9:
          num = "十";
          break;
      }
      return num;
    },
  },
  created() {
    this.uploadUrl = `${configs.baseUrl2}/api/v1/common/upload`;
  },
  computed: {
    declarationId() {
      return Number(this.$route.query.id);
    },
  },
  watch: {
    "form.product_pull": {
      deep: true,
      handler(nval, oval) {
        if (nval.length > 1) {
          if (nval[0] == "无") {
            this.form.product_pull = this.form.product_pull.filter(
              (item) => item !== "无"
            );
          } else if (nval[nval.length - 1] == "无") {
            this.form.product_pull = ["无"];
          }
        }
      },
    },
  },
  mounted() {
    this.getSceenList();
    // 1是填报，2是查看
    if (this.$route.query.type == 1) {
      this.isDetail = false;
      if (this.declarationId) {
        //修改获取之前表单数据
        this.productDeclarationDetail(this.declarationId);
      } else {
        // 渲染已填写数据
        this.getCompanyInfo();
      }
    } else if (this.$route.query.type == 2) {
      this.isDetail = true;
      this.productDeclarationDetail(this.declarationId);
    }

    // 输入框-下拉框加载数据
    this.drowproductList();
    // this.restaurants = this.loadAll();
    this.getTractionUnit();
  },
  methods: {
    getTractionUnit() {
      getTractionUnit(1).then((res) => {
        console.log("res getTractionUnit", res);
        if (res.code == 200) {
          this.tractionUnitList = res.data.selectRespList;
        }
      });
    },
    handleForeachInputChange() {
      this.$forceUpdate();
    },
    handleFile(file) {
      this.t_imageList.push(file);
      this.t_imageUrl = file.url;
    },
    handleRemove(fileList) {
      this.t_imageList = fileList;
      // this.t_imageUrl = fileList[0].url;
      fileList.forEach((item, index) => {
        if (item) {
          this.t_imageUrl = this.t_imageUrl + "," + item.url;
        }
      });
    },
    getSceenList() {
      getSceenList().then((res) => {
        console.log("res", res);
        this.belongingSceneOptions = res.data;
      });
    },
    handleSaleTypeChange(val) {
      if (val != "其他") {
        this.form.other_sale = "";
      }
    },
    handleListedStatusChange() {
      this.form.listed_date = null;
      this.form.listed_area = null;
      this.form.other_listed = "";
    },
    handlePullUnitStatusChange() {
      this.form.pull_partner = null;
    },
    // 渲染已填写数据
    getCompanyInfo() {
      getCompanyInfo().then((res) => {
        this.form.company_name = res.data.name;
        this.form.credit_code = res.data.jgm;
        if (
          res.data.province &&
          res.data.city &&
          res.data.area &&
          res.data.address
        ) {
          this.form.company_address = `${res.data.province}${res.data.city}${res.data.area}${res.data.address}`;
        }
        this.form.contact_person = res.data.daily_person;
        this.form.position = res.data.daily_job;
        this.form.phone = res.data.daily_mobile;
        this.form.email = res.data.daily_email;
      });
    },
    productDeclarationDetail(id) {
      productDeclarationDetail({ id }).then((res) => {
        let data = res.data;
        this.form = data;
        this.form.sub_industry = this.form.sub_industry
          ? this.form.sub_industry.split(",")
          : [];
        this.form.industry = this.form.industry
          ? this.form.industry.split(",")
          : [];
        this.form.product_pull = this.form.product_pull
          ? this.form.product_pull.split(",")
          : [];
        this.form.function_around = this.form.function_around
          ? this.form.function_around.split(",")
          : [];
        console.log(this.form.function_around, "this.form.function_around");
        this.form.product_case = JSON.parse(this.form.product_case);

        this.form.companyProductDeclarationList = this.form.product_case.map(
          (item) => {
            return {
              case_name: item.case_name, // 案例名称
              case_background: item.case_background, // 案例背景
              practice_overview: item.practice_overview, // 实践概述
              application_effect: item.application_effect, // 应用效果
              case_url: item.case_url ? item.case_url : "", // 案例附件
              caseFileList: item.caseFileList ? item.caseFileList : [], // 案例附件数组
            };
          }
        );
        console.log(
          this.form.companyProductDeclarationList,
          "this.form.companyProductDeclarationList"
        );

        this.form.scene = this.form.scene ? JSON.parse(this.form.scene) : [];
        console.log(this.form.scene, "--===");

        this.deploymentFileList = data.deployment_url
          ? JSON.parse(data.deployment_url)
          : [];
        this.securityFileList = data.security_url
          ? JSON.parse(data.security_url)
          : []; //安全说明附件路径
        this.independentFileList = data.independent_url
          ? JSON.parse(data.independent_url)
          : []; //自主知识产权
        this.priceFileList = data.price_url ? JSON.parse(data.price_url) : []; //价格说明
        this.awardFileList = data.award_url ? JSON.parse(data.award_url) : []; //获奖情况
        this.t_imageList.push({ url: data.product_picture });
        this.t_imageUrl = data.product_picture;
        console.log(this.form, "---=====form");
        this.$forceUpdate();
      });
    },
    toIndex() {
      this.$router.push({ name: "Sbzj" });
    },
    delCase(i) {
      if (this.isDetail) return;
      this.form.companyProductDeclarationList.splice(i, 1);
    },
    addItem() {
      if (this.isDetail) {
        return;
      }
      this.form.companyProductDeclarationList.push({
        case_name: "",
        case_background: "",
        practice_overview: "",
        application_effect: "",
        case_url: "",
        caseFileList: [],
      });
      this.$forceUpdate();
    },

    // 案例文件上传 start
    handleCaseFileSuccess(response, file, fileList, item) {
      console.log("handleCaseFileSuccess", response, file, fileList, item);
      console.log(item.caseFileList, "--===Case");

      if (response.code == 200) {
        item.caseFileList.push({
          name: response.originalFilename,
          url: response.url,
        });

        item.case_url = JSON.stringify(item.caseFileList);
        // console.log(item.caseFileList, "--===Case");
      } else {
        if (response.msg == "Uploaded file format is limited") {
          this.$message.error("不符合文件上传格式");
        } else {
          this.$message.error(response.msg);
        }
      }
    },
    handleCaseFilePreview(file, item) {
      console.log("file", file);

      if (file.status != "success") {
        this.$message.error("文件获取失败");
      }

      let linkDom = document.createElement("a");

      if (file.url.indexOf("http") == -1) {
        linkDom.href = `${configs.baseUrl}${file.url}`;
      } else {
        linkDom.href = file.url;
      }
      linkDom.target = "_blank";
      linkDom.download = file.name;
      linkDom.style.display = "none";
      linkDom.click();
      // if (file.response && file.response.code == 1) {
      //   let linkDom = document.createElement("a");
      //   linkDom.href = `${configs.baseUrl}${file.response.data.fullurl}`;
      //   linkDom.target = "_blank";
      //   linkDom.download = file.response.data.name;
      //   linkDom.style.display = "none";
      //   linkDom.click();
      // } else {
      //   let linkDom = document.createElement("a");
      //   linkDom.href = `${configs.baseUrl}${file.url}`;
      //   linkDom.target = "_blank";
      //   linkDom.download = file.name;
      //   linkDom.style.display = "none";
      //   linkDom.click();
      // }
    },
    handleCaseFileExceed(files, fileList, item) {
      this.$message.error("最多只能上传5个文件");
    },
    handleCaseFileRemove(file, fileList, item) {
      console.log("handleCaseFileRemove", file, fileList);
      item.caseFileList = fileList.map((item) => {
        return {
          name: item.name,
          url: item.url,
        };
      });
    },
    handleCaseFileChange(file, fileList, item) {
      console.log("handleCaseFileChange", file, fileList, item);
      if (file.status == "uploading") {
        this.loadingStatus = this.$loading({
          lock: true,
          text: "上传中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
      } else if (file.status == "ready") {
        // console.log("do nothing");
        this.loadingStatus = this.$loading({
          lock: true,
          text: "上传中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
      } else {
        if (file.status != "success") {
          file.status = "error";
        }
        this.loadingStatus.close();
      }
    },
    // 案例文件上传 end

    handleDeploymentFileSuccess(response) {
      console.log("handleDeploymentFileSuccess", response);
      if (response.code == 200) {
        this.deploymentFileList.push({
          name: response.originalFilename,
          url: response.url,
        });

        this.form.deployment_url = JSON.stringify(this.deploymentFileList);
        console.log(this.deploymentFileList, "--===deployment");
      } else {
        if (response.msg == "Uploaded file format is limited") {
          this.$message.error("不符合文件上传格式");
        } else {
          this.$message.error(response.msg);
        }
      }
    },
    handleDeploymentFilePreview(file) {
      if (file.status != "success") {
        this.$message.error("文件获取失败");
      }

      let linkDom = document.createElement("a");

      if (file.url.indexOf("http") == -1) {
        linkDom.href = `${configs.baseUrl}${file.url}`;
      } else {
        linkDom.href = file.url;
      }
      linkDom.target = "_blank";
      linkDom.download = file.name;
      linkDom.style.display = "none";
      linkDom.click();
      // if (file.response && file.response.code == 1) {
      //   let linkDom = document.createElement("a");
      //   linkDom.href = `${configs.baseUrl}${file.response.data.fullurl}`;
      //   linkDom.target = "_blank";
      //   linkDom.download = file.response.data.name;
      //   linkDom.style.display = "none";
      //   linkDom.click();
      // } else {
      //   let linkDom = document.createElement("a");
      //   linkDom.href = `${configs.baseUrl}${file.url}`;
      //   linkDom.target = "_blank";
      //   linkDom.download = file.name;
      //   linkDom.style.display = "none";
      //   linkDom.click();
      // }
    },
    handleDeploymentFileExceed() {
      this.$message.error("最多只能上传5个文件");
    },
    handleDeploymentFileRemove(file, fileList) {
      console.log("handleDeploymentFileRemove", file, fileList);
      this.deploymentFileList = fileList.map((item) => {
        return {
          name: item.name,
          url: item.url,
        };
      });
    },
    handleDeploymentFileChange(file, fileList) {
      console.log("handleDeploymentFileChange", file, fileList);
      if (file.status == "uploading") {
        this.loadingStatus = this.$loading({
          lock: true,
          text: "上传中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
      } else if (file.status == "ready") {
        // console.log("do nothing");
        this.loadingStatus = this.$loading({
          lock: true,
          text: "上传中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
      } else {
        if (file.status != "success") {
          file.status = "error";
        }
        this.loadingStatus.close();
      }
    },

    // 安全说明附件上传j

    handleSecurityFileSuccess(response) {
      console.log("handleSecurityFileSuccess", response);
      if (response.code == 200) {
        this.securityFileList.push({
          name: response.originalFilename,
          url: response.url,
        });
        this.form.security_url = JSON.stringify(this.securityFileList);
      } else {
        if (response.msg == "Uploaded file format is limited") {
          this.$message.error("不符合文件上传格式");
        } else {
          this.$message.error(response.msg);
        }
      }
    },
    handleSecurityFilePreview(file) {
      if (file.status != "success") {
        this.$message.error("文件获取失败");
      }
      let linkDom = document.createElement("a");

      if (file.url.indexOf("http") == -1) {
        linkDom.href = `${configs.baseUrl}${file.url}`;
      } else {
        linkDom.href = file.url;
      }
      linkDom.target = "_blank";
      linkDom.download = file.name;
      linkDom.style.display = "none";
      linkDom.click();
      // if (file.response && file.response.code == 1) {
      //   let linkDom = document.createElement("a");
      //   linkDom.href = `${configs.baseUrl}${file.response.data.fullurl}`;
      //   linkDom.target = "_blank";
      //   linkDom.download = file.response.data.name;
      //   linkDom.style.display = "none";
      //   linkDom.click();
      // } else {
      //   let linkDom = document.createElement("a");
      //   linkDom.href = `${configs.baseUrl}${file.url}`;
      //   linkDom.target = "_blank";
      //   linkDom.download = file.name;
      //   linkDom.style.display = "none";
      //   linkDom.click();
      // }
    },
    handleSecurityFileExceed() {
      this.$message.error("最多只能上传5个文件");
    },
    handleSecurityFileRemove(file, fileList) {
      console.log("handleSecurityFileRemove", file, fileList);
      this.securityFileList = fileList.map((item) => {
        return {
          name: item.name,
          url: item.url,
        };
      });
    },
    handleSecurityFileChange(file, fileList) {
      console.log("handleSecurityFileChange", file, fileList);
      if (file.status == "uploading") {
        this.loadingStatus = this.$loading({
          lock: true,
          text: "上传中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
      } else if (file.status == "ready") {
        // console.log("do nothing");
        this.loadingStatus = this.$loading({
          lock: true,
          text: "上传中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
      } else {
        if (file.status != "success") {
          file.status = "error";
        }
        this.loadingStatus.close();
      }
    },

    // 自主知识产权的附件上传方法

    handleIndependentFileSuccess(response) {
      console.log("handleIndependentFileSuccess", response);
      if (response.code == 200) {
        this.independentFileList.push({
          name: response.originalFilename,
          url: response.url,
        });
        this.form.independent_url = JSON.stringify(this.independentFileList);
      } else {
        if (response.msg == "Uploaded file format is limited") {
          this.$message.error("不符合文件上传格式");
        } else {
          this.$message.error(response.msg);
        }
      }
    },
    handleIndependentFilePreview(file) {
      if (!file.status) {
        this.$message.error("文件获取失败");
      }

      let linkDom = document.createElement("a");
      if (file.url.indexOf("http") == -1) {
        linkDom.href = `${configs.baseUrl}${file.url}`;
      } else {
        linkDom.href = file.url;
      }
      linkDom.target = "_blank";
      linkDom.download = file.name;
      linkDom.style.display = "none";
      linkDom.click();
      // if (file.response && file.response.code == 1) {
      //   let linkDom = document.createElement("a");
      //   linkDom.href = `${configs.baseUrl}${file.response.data.fullurl}`;
      //   linkDom.target = "_blank";
      //   linkDom.download = file.response.data.name;
      //   linkDom.style.display = "none";
      //   linkDom.click();
      // } else {
      //   let linkDom = document.createElement("a");
      //   linkDom.href = `${configs.baseUrl}${file.url}`;
      //   linkDom.target = "_blank";
      //   linkDom.download = file.name;
      //   linkDom.style.display = "none";
      //   linkDom.click();
      // }
    },
    handleIndependentFileExceed() {
      this.$message.error("最多只能上传5个文件");
    },
    handleIndependentFileRemove(file, fileList) {
      console.log("handleIndependentFileRemove", file, fileList);
      this.independentFileList = fileList.map((item) => {
        return {
          name: item.name,
          url: item.url,
        };
      });
    },
    handleIndependentFileChange(file, fileList) {
      console.log("handleIndependentFileChange", file, fileList);
      if (file.status == "uploading") {
        this.loadingStatus = this.$loading({
          lock: true,
          text: "上传中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
      } else if (file.status == "ready") {
        // console.log("do nothing");
        this.loadingStatus = this.$loading({
          lock: true,
          text: "上传中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
      } else {
        if (file.status != "success") {
          file.status = "error";
        }
        this.loadingStatus.close();
      }
    },

    // 价格说明
    handlePriceFileSuccess(response) {
      console.log("handlePriceFileSuccess", response);
      if (response.code == 200) {
        this.priceFileList.push({
          name: response.originalFilename,
          url: response.url,
        });
        this.form.price_url = JSON.stringify(this.priceFileList);
      } else {
        if (response.msg == "Uploaded file format is limited") {
          this.$message.error("不符合文件上传格式");
        } else {
          this.$message.error(response.msg);
        }
      }
    },
    handlePriceFilePreview(file) {
      if (!file.status) {
        this.$message.error("文件获取失败");
      }

      let linkDom = document.createElement("a");
      if (file.url.indexOf("http") == -1) {
        linkDom.href = `${configs.baseUrl}${file.url}`;
      } else {
        linkDom.href = file.url;
      }
      linkDom.target = "_blank";
      linkDom.download = file.name;
      linkDom.style.display = "none";
      linkDom.click();
      // if (file.response && file.response.code == 1) {
      //   let linkDom = document.createElement("a");
      //   linkDom.href = `${configs.baseUrl}${file.response.data.fullurl}`;
      //   linkDom.target = "_blank";
      //   linkDom.download = file.response.data.name;
      //   linkDom.style.display = "none";
      //   linkDom.click();
      // } else {
      //   let linkDom = document.createElement("a");
      //   linkDom.href = `${configs.baseUrl}${file.url}`;
      //   linkDom.target = "_blank";
      //   linkDom.download = file.name;
      //   linkDom.style.display = "none";
      //   linkDom.click();
      // }
    },
    handlePriceFileExceed() {
      this.$message.error("最多只能上传5个文件");
    },
    handlePriceFileRemove(file, fileList) {
      console.log("handlePriceFileRemove", file, fileList);
      this.priceFileList = fileList.map((item) => {
        return {
          name: item.name,
          url: item.url,
        };
      });
    },
    handlePriceFileChange(file, fileList) {
      console.log("handleIndependentFileChange", file, fileList);
      if (file.status == "uploading") {
        this.loadingStatus = this.$loading({
          lock: true,
          text: "上传中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
      } else if (file.status == "ready") {
        // console.log("do nothing");
        this.loadingStatus = this.$loading({
          lock: true,
          text: "上传中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
      } else {
        if (file.status != "success") {
          file.status = "error";
        }
        this.loadingStatus.close();
      }
    },

    // 获奖情况
    handleAwardFileSuccess(response) {
      console.log("handleAwardFileSuccess", response);
      if (response.code == 200) {
        this.awardFileList.push({
          name: response.originalFilename,
          url: response.url,
        });
        this.form.award_url = JSON.stringify(this.awardFileList);
      } else {
        if (response.msg == "Uploaded file format is limited") {
          this.$message.error("不符合文件上传格式");
        } else {
          this.$message.error(response.msg);
        }
      }
    },
    handleAwardFilePreview(file) {
      if (!file.status) {
        this.$message.error("文件获取失败");
      }
      let linkDom = document.createElement("a");

      if (file.url.indexOf("http") == -1) {
        linkDom.href = `${configs.baseUrl}${file.url}`;
      } else {
        linkDom.href = file.url;
      }
      linkDom.target = "_blank";
      linkDom.download = file.name;
      linkDom.style.display = "none";
      linkDom.click();

      // if (file.response && file.response.code == 1) {
      //   let linkDom = document.createElement("a");
      //   linkDom.href = `${configs.baseUrl}${file.response.data.fullurl}`;
      //   linkDom.target = "_blank";
      //   linkDom.download = file.response.data.name;
      //   linkDom.style.display = "none";
      //   linkDom.click();
      // } else {
      //   let linkDom = document.createElement("a");
      //   linkDom.href = `${configs.baseUrl}${file.url}`;
      //   linkDom.target = "_blank";
      //   linkDom.download = file.name;
      //   linkDom.style.display = "none";
      //   linkDom.click();
      // }
    },
    handleAwardFileExceed() {
      this.$message.error("最多只能上传5个文件");
    },
    handleAwardFileRemove(file, fileList) {
      console.log("handleAwardFileRemove", file, fileList);
      this.awardFileList = fileList.map((item) => {
        return {
          name: item.name,
          url: item.url,
        };
      });
    },
    handleAwardFileChange(file, fileList) {
      console.log("handleAwardFileChange", file, fileList);
      if (file.status == "uploading") {
        this.loadingStatus = this.$loading({
          lock: true,
          text: "上传中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
      } else if (file.status == "ready") {
        // console.log("do nothing");
        this.loadingStatus = this.$loading({
          lock: true,
          text: "上传中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
      } else {
        if (file.status != "success") {
          file.status = "error";
        }
        this.loadingStatus.close();
      }
    },

    // // 第三方测试
    // handleTestFileSuccess(response) {
    //   console.log("handleAwardFileSuccess", response);
    //   if (response.code == 1) {
    //     this.TestFileList.push({
    //       name: response.data.name,
    //       url: response.data.fullurl,
    //     });
    //     this.form.test_url = JSON.stringify(this.TestFileList);
    //   } else {
    //     if (response.msg == "Uploaded file format is limited") {
    //       this.$message.error("不符合文件上传格式");
    //     } else {
    //       this.$message.error(response.msg);
    //     }
    //   }
    // },
    // handleTestFilePreview(file) {
    //   if (!file.status) {
    //     this.$message.error("文件获取失败");
    //   }
    //   if (file.response && file.response.code == 1) {
    //     let linkDom = document.createElement("a");
    //     linkDom.href = `${configs.baseUrl}${file.response.data.fullurl}`;
    //     linkDom.target = "_blank";
    //     linkDom.download = file.response.data.name;
    //     linkDom.style.display = "none";
    //     linkDom.click();
    //   } else {
    //     let linkDom = document.createElement("a");
    //     linkDom.href = `${configs.baseUrl}${file.url}`;
    //     linkDom.target = "_blank";
    //     linkDom.download = file.name;
    //     linkDom.style.display = "none";
    //     linkDom.click();
    //   }
    // },
    // handleTestFileExceed() {
    //   this.$message.error("最多只能上传5个文件");
    // },
    // handleTestFileRemove(file, fileList) {
    //   console.log("handleAwardFileRemove", file, fileList);
    //   this.TestFileList = fileList.map((item) => {
    //     return {
    //       name: item.name,
    //       url: item.url,
    //     };
    //   });
    // },
    // handleTestFileChange(file, fileList) {
    //   console.log("handleAwardFileChange", file, fileList);
    //   if (file.status == "uploading") {
    //     this.loadingStatus = this.$loading({
    //       lock: true,
    //       text: "上传中...",
    //       spinner: "el-icon-loading",
    //       background: "rgba(0, 0, 0, 0.7)",
    //     });
    //   } else if (file.status == "ready") {
    //     // console.log("do nothing");
    //     this.loadingStatus = this.$loading({
    //       lock: true,
    //       text: "上传中...",
    //       spinner: "el-icon-loading",
    //       background: "rgba(0, 0, 0, 0.7)",
    //     });
    //   } else {
    //     this.loadingStatus.close();
    //   }
    // },

    // // 营业执照
    // handleLicenseFileSuccess(response) {
    //   console.log("handleLicenseFileSuccess", response);
    //   if (response.code == 1) {
    //     this.licenseFileList.push({
    //       name: response.data.name,
    //       url: response.data.fullurl,
    //     });
    //     this.form.license_url = JSON.stringify(this.licenseFileList);
    //   } else {
    //     if (response.msg == "Uploaded file format is limited") {
    //       this.$message.error("不符合文件上传格式");
    //     } else {
    //       this.$message.error(response.msg);
    //     }
    //   }
    // },
    // handleLicenseFilePreview(file) {
    //   if (!file.status) {
    //     this.$message.error("文件获取失败");
    //   }
    //   if (file.response && file.response.code == 1) {
    //     let linkDom = document.createElement("a");
    //     linkDom.href = `${configs.baseUrl}${file.response.data.fullurl}`;
    //     linkDom.target = "_blank";
    //     linkDom.download = file.response.data.name;
    //     linkDom.style.display = "none";
    //     linkDom.click();
    //   } else {
    //     let linkDom = document.createElement("a");
    //     linkDom.href = `${configs.baseUrl}${file.url}`;
    //     linkDom.target = "_blank";
    //     linkDom.download = file.name;
    //     linkDom.style.display = "none";
    //     linkDom.click();
    //   }
    // },
    // handleLicenseFileExceed() {
    //   this.$message.error("最多只能上传5个文件");
    // },
    // handleLicenseFileRemove(file, fileList) {
    //   console.log("handleLicenseFileRemove", file, fileList);
    //   this.licenseFileList = fileList.map((item) => {
    //     return {
    //       name: item.name,
    //       url: item.url,
    //     };
    //   });
    // },
    // handleLicenseFileChange(file, fileList) {
    //   console.log("handleLicenseFileChange", file, fileList);
    //   if (file.status == "uploading") {
    //     this.loadingStatus = this.$loading({
    //       lock: true,
    //       text: "上传中...",
    //       spinner: "el-icon-loading",
    //       background: "rgba(0, 0, 0, 0.7)",
    //     });
    //   } else if (file.status == "ready") {
    //     // console.log("do nothing");
    //     this.loadingStatus = this.$loading({
    //       lock: true,
    //       text: "上传中...",
    //       spinner: "el-icon-loading",
    //       background: "rgba(0, 0, 0, 0.7)",
    //     });
    //   } else {
    //     this.loadingStatus.close();
    //   }
    // },

    // // 信用记录
    // handleCreditFileSuccess(response) {
    //   console.log("handleCreditFileSuccess", response);
    //   if (response.code == 1) {
    //     this.creditFileList.push({
    //       name: response.data.name,
    //       url: response.data.fullurl,
    //     });
    //     this.form.credit_url = JSON.stringify(this.creditFileList);
    //   } else {
    //     if (response.msg == "Uploaded file format is limited") {
    //       this.$message.error("不符合文件上传格式");
    //     } else {
    //       this.$message.error(response.msg);
    //     }
    //   }
    // },
    // handleCreditFilePreview(file) {
    //   if (!file.status) {
    //     this.$message.error("文件获取失败");
    //   }
    //   if (file.response && file.response.code == 1) {
    //     let linkDom = document.createElement("a");
    //     linkDom.href = `${configs.baseUrl}${file.response.data.fullurl}`;
    //     linkDom.target = "_blank";
    //     linkDom.download = file.response.data.name;
    //     linkDom.style.display = "none";
    //     linkDom.click();
    //   } else {
    //     let linkDom = document.createElement("a");
    //     linkDom.href = `${configs.baseUrl}${file.url}`;
    //     linkDom.target = "_blank";
    //     linkDom.download = file.name;
    //     linkDom.style.display = "none";
    //     linkDom.click();
    //   }
    // },
    // handleCreditFileExceed() {
    //   this.$message.error("最多只能上传5个文件");
    // },
    // handleCreditFileRemove(file, fileList) {
    //   console.log("handlecreditFileRemove", file, fileList);
    //   this.creditFileList = fileList.map((item) => {
    //     return {
    //       name: item.name,
    //       url: item.url,
    //     };
    //   });
    // },
    // handleCreditFileChange(file, fileList) {
    //   console.log("handleCreditFileChange", file, fileList);
    //   if (file.status == "uploading") {
    //     this.loadingStatus = this.$loading({
    //       lock: true,
    //       text: "上传中...",
    //       spinner: "el-icon-loading",
    //       background: "rgba(0, 0, 0, 0.7)",
    //     });
    //   } else if (file.status == "ready") {
    //     // console.log("do nothing");
    //     this.loadingStatus = this.$loading({
    //       lock: true,
    //       text: "上传中...",
    //       spinner: "el-icon-loading",
    //       background: "rgba(0, 0, 0, 0.7)",
    //     });
    //   } else {
    //     this.loadingStatus.close();
    //   }
    // },

    handleDelFile(i) {
      if (this.isDetail) return;
      this.fileList.splice(i, 1);
      this.form.related_attachment = JSON.stringify(this.fileList);
    },
    handleSuccess(response) {
      console.log("handleSuccess", response);
      if (response.code == 200) {
        this.fileList.push({
          name: response.originalFilename,
          url: response.url,
        });
        this.form.related_attachment = JSON.stringify(this.fileList);
      } else {
        if (response.msg == "Uploaded file format is limited") {
          this.$message.error("不符合文件上传格式");
        } else {
          this.$message.error(response.msg);
        }
      }
    },
    handleChange(file, fileList) {
      console.log("handleChange", file, fileList);
      if (file.status == "uploading") {
        this.loadingStatus = this.$loading({
          lock: true,
          text: "上传中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
      } else if (file.status == "ready") {
        // console.log("do nothing");
        this.loadingStatus = this.$loading({
          lock: true,
          text: "上传中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
      } else {
        if (file.status != "success") {
          file.status = "error";
        }
        this.loadingStatus.close();
      }
    },
    handleClose() {
      if (this.isDetail) {
        this.$router.push({ name: "SbzjList" });
      } else {
        this.$router.push({ name: "Sbzj" });
      }
    },
    saveDraft() {
      this.form.product_picture = this.t_imageUrl;
      let pForm = JSON.parse(
        JSON.stringify(this.form) ? JSON.stringify(this.form) : "{}"
      );
      pForm.related_attachment = JSON.parse(
        this.form.related_attachment ? this.form.related_attachment : "[]"
      );
      delete pForm.code;
      let pdata = {
        type: 4,
        content: JSON.stringify(pForm),
      };
      saveDraft(pdata).then((res) => {
        if (res.code == 1) {
          this.$message.success("保存草稿成功");
        } else {
          this.$message.error("保存草稿失败");
        }
      });
    },
    getDraft() {
      let pdata = {
        type: 4,
      };
      this.t_imageList = [];
      this.t_imageUrl = "";

      getDraft(pdata).then((res) => {
        if (res.code == 1) {
          this.$message.success("获取成功");
          let data = JSON.parse(res.data.content ? res.data.content : "{}");
          this.form = data;
          // this.drowproductList();
          // this.deploymentFileList.push(JSON.parse(data.deployment_url)[0]);
          // this.fileList = JSON.parse(data.related_attachment);

          if (this.form.function_around instanceof String) {
            this.form.function_around = this.form.function_around.split(",");
          } else if (this.form.function_around instanceof Array) {
            this.form.function_around = this.form.function_around;
          } else {
            this.form.function_around = [];
          }

          this.deploymentFileList = JSON.parse(
            data.deployment_url ? data.deployment_url : "[]"
          );
          this.fileList = data.related_attachment;
          this.form.related_attachment = JSON.stringify(
            data.related_attachment
          );
          this.securityFileList = JSON.parse(
            data.security_url ? data.security_url : "[]"
          ); //安全说明附件路径
          this.independentFileList = JSON.parse(
            data.independent_url ? data.independent_url : "[]"
          ); //自主知识产权
          this.priceFileList = JSON.parse(
            data.price_url ? data.price_url : "[]"
          ); //价格说明
          this.awardFileList = JSON.parse(
            data.award_url ? data.award_url : "[]"
          ); //获奖情况

          this.form.companyProductDeclarationList.map((item) => {
            item.case_name = item.case_name; // 案例名称
            item.case_background = item.case_background; // 案例背景
            item.practice_overview = item.practice_overview; // 实践概述
            item.application_effect = item.application_effect; // 应用效果
            item.case_url = item.case_url ? item.case_url : ""; // 案例附件
            item.caseFileList = item.caseFileList ? item.caseFileList : []; // 案例附件数组
          });

          // console.log(data, "----====data");

          if (data.product_picture) {
            this.t_imageList.push({ url: data.product_picture });
            this.t_imageUrl = data.product_picture;
          }
          console.log(this.form, "----====this.form草稿");
        } else {
          this.$message.error("获取失败");
        }
      });
    },
    beforeSubmit() {
      // console.log("sdf", this.form);
      let flag = true;
      this.$refs.companyForm.validate((valid) => {
        if (!valid) {
          flag = false;
        }
      });
      this.$refs.productForm.validate((valid) => {
        if (!valid) {
          flag = false;
        }
      });
      this.$refs.technologyForm.validate((valid) => {
        if (!valid) {
          flag = false;
        }
      });
      // this.$refs.caseForm.validate((valid) => {
      //   if (!valid) {
      //     flag = false;
      //   }
      // });
      this.$refs.descriptionForm.validate((valid) => {
        if (!valid) {
          flag = false;
        }
      });
      this.form.companyProductDeclarationList.forEach((item) => {
        // let errMsg = `请输入第${index + 1}条典型经验案例的`
        if (item.case_name == null || item.case_name == "") {
          // this.$message.error("请输入案例名称");
          flag = false;
        }
        if (item.case_background == null || item.case_background == "") {
          // this.$message.error("请输入案例背景");
          flag = false;
        }
        if (item.practice_overview == null || item.practice_overview == "") {
          // this.$message.error("请输入实践概述");
          flag = false;
        }
        if (item.application_effect == null || item.application_effect == "") {
          // this.$message.error("请输入应用效果");
          flag = false;
        }
        if (item.case_url == null || item.case_url == "") {
          flag = false;
        }
        if (item.caseFileList.length == 0) {
          flag = false;
        }
      });
      // if (this.fileList.length == 0) flag = false;
      if (this.deploymentFileList.length == 0) {
        this.$message.error("请上传部署架构文件");
        // flag = false;
        return false;
      }
      if (this.securityFileList.length == 0) {
        this.$message.error("请上传安全说明文件");
        // flag = false;
        return false;
      }
      if (this.independentFileList.length == 0) {
        this.$message.error("请上传自主知识产权说明文件");
        // flag = false;
        return false;
      }
      if (this.priceFileList.length == 0) {
        this.$message.error("请上传价格说明文件");
        // flag = false;
        return false;
      }
      // if (this.awardFileList.length == 0) {
      //   this.$message.error("请上传获奖信息文件");
      //   // flag = false;
      //   return false;
      // }

      if (this.t_imageList.length == 0) {
        this.$message.error("请上传标签图片");
        return false;
      }

      // 深复制
      let dfList = JSON.parse(JSON.stringify(this.deploymentFileList));
      dfList.map((item) => {
        delete item.status;
        delete item.uid;
      });
      this.form.deployment_url = JSON.stringify(dfList);

      let sfList = JSON.parse(JSON.stringify(this.securityFileList));
      sfList.map((item) => {
        delete item.status;
        delete item.uid;
      });
      this.form.security_url = JSON.stringify(sfList);

      let ifList = JSON.parse(JSON.stringify(this.independentFileList));
      ifList.map((item) => {
        delete item.status;
        delete item.uid;
      });
      this.form.independent_url = JSON.stringify(ifList);

      let pfList = JSON.parse(JSON.stringify(this.priceFileList));
      pfList.map((item) => {
        delete item.status;
        delete item.uid;
      });
      this.form.price_url = JSON.stringify(pfList);

      let afList = JSON.parse(
        JSON.stringify(this.awardFileList)
          ? JSON.stringify(this.awardFileList)
          : "[]"
      );
      console.log(123, afList);

      if (afList.length > 0) {
        console.log(456);
        afList.map((item) => {
          delete item.status;
          delete item.uid;
        });
      }
      this.form.award_url = JSON.stringify(afList);

      if (flag) {
        this.form.product_case = JSON.stringify(
          this.form.companyProductDeclarationList
        );
        this.form.scene = JSON.stringify(this.form.scene);
        this.form.product_picture = this.t_imageUrl;
        delete this.form.companyProductDeclarationList;

        //修改去掉
        if (this.$route.query.type == 1 && !this.$route.query.id) {
          delete this.form.id;
        }
        delete this.form.audit_user_id;
        delete this.form.audit_status;
        delete this.form.audit_reason;
        delete this.form.try_status;

        this.form.function_around = this.form.function_around
          ? this.form.function_around.join(",")
          : "";
        this.form.product_pull = this.form.product_pull
          ? this.form.product_pull.join(",")
          : "";
        this.form.industry = this.form.industry
          ? this.form.industry.join(",")
          : "";
        this.form.user_id = JSON.parse(sessionStorage.getItem("user")).id;
        productDeclaration(this.form)
          .then((res) => {
            if (res.code == 200) {
              this.$message.success("申报成功");
              setTimeout(() => {
                this.handleClose();
              }, 1500);
            } else {
              this.$message.error("申报失败");
            }
          })
          .catch((err) => {
            this.form.function_around = this.form.function_around
              ? this.form.function_around.split(",")
              : [];
            this.form.product_pull = this.form.product_pull
              ? this.form.product_pull.split(",")
              : [];
            this.form.industry = this.form.industry
              ? this.form.industry.split(",")
              : [];
          })
          .finally(() => {
            console.log(this.form, "------=====提交1");
            this.form.companyProductDeclarationList = JSON.parse(
              this.form.product_case ? this.form.product_case : "[]"
            );
            console.log(this.form, "------=====提交2");
          });
      } else {
        this.$message.error("请填写完整表单");
      }
    },

    // 下拉框接口数据
    drowproductList() {
      let pdata = {
        type: 2,
      };
      drowproductList(pdata).then((res) => {
        if (res.code == 1) {
          // console.log("接口数据", res);

          // const formattedItems = res.data.map((item) => ({
          //   value: item.product_name,

          // }));
          // this.form.options = formattedItems;
          // this.form.options = res.data;
          this.projectnamelist = res.data;
          // console.log("shuru", this.form.options);
          // console.log("下拉框数据", formattedItems);
        } else {
          this.$message.error("获取失败");
        }
      });
    },
    // querySearchAsync(queryString, cb) {
    //   var restaurants = this.restaurants;
    //   var results = queryString
    //     ? restaurants.filter(this.createStateFilter(queryString))
    //     : restaurants;

    //   clearTimeout(this.timeout);
    //   this.timeout = setTimeout(() => {
    //     cb(results);
    //   }, 100 * Math.random());
    // },
    // createStateFilter(queryString) {
    //   return (state) => {
    //     return (
    //       state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
    //     );
    //   };
    // },
    // handleSelect(item) {
    //   console.log(item);
    // },
    handleCh(value) {
      let pdata = {
        id: value,
      };
      getProductDetail(pdata).then((res) => {
        if (res.code == 1) {
          // console.log("接口数据11", res);

          // console.log(JSON.parse(res.data.declare_info));
          this.form.product_name = res.data.product_name;

          console.log("pp", this.form);
          const declare = JSON.parse(res.data.declare_info);
          this.form.product_type = declare.softwareType;
          this.form.sale_type = declare.saleType;
          this.form.secondary_development = declare.secondDev;
          this.form.trial_use = declare.trial;
          this.form.scene = declare.belongingScene;
          this.form.other_sale = declare.otherSale;
        } else {
          this.$message.error("获取失败");
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
::v-deep .el-textarea {
  .el-input__count {
    background: none;
    bottom: -30px;
  }
}
.sbzj-main {
  min-height: calc(100vh - 208px);
  background: #f0f5ff;
  background-size: cover;
  background-attachment: fixed;
  font-family: Microsoft YaHei;
  .contaner {
    width: 84%;
    margin: 0 auto;
    .sbzj-form {
      width: 100%;
      background-color: #fff;
      border-radius: 10px;
      padding: 0 208px 50px;
      box-sizing: border-box;
      .ss-radio {
        width: calc(100% - 128px);
        border: 1px solid #e3e3e3;
        border-radius: 5px;
        padding: 20px 15px;
        box-sizing: border-box;
        margin-top: 10px;
        margin-left: 128px;
      }
      .ss-input {
        ::v-deep .el-input__inner {
          height: 18px;
          width: 100px;
          padding: 0 10px;
          border-radius: 0;
          border: none;
          border-bottom: 1px solid #efefef;
        }
      }
      .addItem {
        width: fit-content;
        color: #2282f9;
        margin: 0 auto;
        img {
          height: 50px;
          width: 50px;
          margin-bottom: 10px;
        }
      }
      .cancel-btn {
        height: 38px;
        background-color: #e2e2e2;
        border: none;
        color: #333;
        &:hover {
          background-color: #efefef;
          color: #333;
        }
      }
      .dellink {
        cursor: pointer;
        margin-left: 5px;
        color: #409eff;
        &:hover {
          color: #66b1ff;
        }
      }
      .btns {
        margin-top: 38px;
        .draft-btn {
          height: 38px;
          background-color: #ea7100;
          border: none;
          color: #fff;
          &:hover {
            background-color: #ffb774;
            color: #fff;
          }
        }
        .btn {
          margin-right: 10px;
        }
      }
      .related-attachment-list {
        padding: 20px 30px;
        border: 1px solid #e3e3e3;
        border-radius: 8px;
        margin: 20px 0;
        min-height: 88px;
      }
      .product-type-radio,
      .company-nature-radio {
        .el-radio {
          margin-bottom: 10px;
        }
      }
      .form-desc {
        line-height: 1.5;
        font-size: 14px;
        margin-left: 20px;
      }
      .form-subtitle3 {
        font-size: 16px;
        font-weight: 600;
      }
      .form-subtitle2 {
        font-size: 18px;
        color: #2282f9;
        font-weight: 600;
        height: 38px;
        line-height: 38px;
        position: relative;
        margin-left: 20px;
        &::before {
          content: "";
          height: 28px;
          width: 12px;
          background-color: #2282f9;
          position: absolute;
          bottom: 0;
          top: 0;
          left: -24px;
          margin: auto 0;
        }
      }
      .form-subtitle {
        font-size: 22px;
        font-weight: 600;
        height: 48px;
        line-height: 48px;
      }
      .form-title {
        font-weight: 600;
        font-size: 26px;
        text-align: center;
        height: 88px;
        line-height: 88px;
        position: relative;
        &::after {
          content: "";
          height: 4px;
          width: 148px;
          border-radius: 4px;
          background-color: #205dc3;
          position: absolute;
          bottom: 8px;
          left: 0;
          right: 0;
          margin: 0 auto;
        }
      }
    }
    .auditStatus-container {
      // margin-top: 30px;
      .auditStatus-title {
        margin-top: 30px;
        margin-bottom: 10px;

        font-size: 16px;
      }
    }
    .top-nav {
      margin-bottom: 30px;
    }
  }
}
</style>
