<template>
  <div class="main" :style="$bgImg('sihua_bg.png')">
    <div class="container">
      <div class="needinfo-list">
        <div class="needinfo-filter">
          <div class="needinfo-filter-t">
            <div
              class="needinfo-filter-t-list flex bewteen"
              style="margin-bottom: 15px;border-bottom: 1px dashed #eee;padding-bottom: 4px;"
            >
              <div class="needinfo-filter-title">
                行业：
              </div>
              <div class="all">
                <div
                  class="needinfo-filter-item pointer"
                  :class="filterName == '全部' ? 'select' : ''"
                  @click="hyNeedInfoList('全部')"
                >
                  全部
                </div>
              </div>
              <div class="item flex wrap">
                <div
                  class="needinfo-filter-item pointer"
                  v-for="(item, index) in filterList"
                  :key="index"
                  :class="filterName == item ? 'select' : ''"
                  @click="hyNeedInfoList(item)"
                >
                  {{ item }}
                </div>
              </div>
            </div>
            <div class="needinfo-filter-t-list flex bewteen">
              <div class="needinfo-filter-title">
                领域：
              </div>
              <div class="all">
                <div
                  class="needinfo-filter-item pointer"
                  :class="filterName2 == '全部' ? 'select' : ''"
                  @click="lyNeedInfoList('全部')"
                >
                  全部
                </div>
              </div>
              <div class="item flex wrap">
                <div
                  class="needinfo-filter-item pointer"
                  v-for="(item, index) in filterList2"
                  :key="index"
                  :class="filterName2 == item ? 'select' : ''"
                  @click="lyNeedInfoList(item)"
                >
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="needinfo-main flex wrap" v-if="NeedInfoList.length > 0">
          <div
            class="needinfo-item pointer shadow"
            v-for="(item, index) in NeedInfoList"
            :key="index"
            @click="goNeedInfoDetail(item.id)"
          >
            <div class="title line1">{{ item.name }}</div>
            <div class="info flex column">
              <div class="address line1">企业地址:{{ item.address }}</div>
              <div class="ly line1">所属领域:{{ item.industry }}</div>
            </div>
            <div class="createtime flex vertical ">
              <img class="bottom-icon" :src="'/time.png' | staticMedia" />
              {{ formatDateTime(item.createtime) }}
            </div>
          </div>
        </div>
        <div class="needinfo-main" v-else>
          <div class="list-emp-text">
            暂无数据
          </div>
        </div>
      </div>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :current-page="page"
        :page-size="pageSize"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { formatDateTime } from "@/utils/common.js";
import { getNeedInfoList } from "@/api/pts";
export default {
  components: {},
  data() {
    return {
      filterName: "全部",
      filterName2: "全部",
      NeedInfoList: [],

      filterList: [
        "煤炭/黑色金属矿开采/石油天然气开采",
        "黑色金属",
        "有色金属",
        "石化化工",
        "建材",
        "医药",
        "纺织",
        "家电",
        "食品",
        "烟草",
        "轻工",
        "机械",
        "汽车",
        "航天/航空",
        "船舶",
        "轨道交通",
        "电子",
        "电力",
        "热力和燃气",
        "建筑业",
        "农业",
        "其他",
      ],
      filterList2: [
        "安全生产",
        "节能减排",
        "质量管控",
        "供应链管理",
        "研发设计",
        "生产制造",
        "运营管理",
        "仓储物流",
        "运维服务",
        "其他",
      ],

      page: 1,
      pageSize: 12,
      total: 0,
    };
  },
  created() {},
  mounted() {
    this.getNeedInfoList();
  },
  methods: {
    handleCurrentChange(page) {
      // let _this = this;
      // let pdata = {
      //   page: page,
      //   pageSize: _this.pageSize,
      //   hy: _this.filterName != "全部" ? _this.filterName : "",
      //   ly: _this.filterName2 != "全部" ? _this.filterName2 : "",
      // };
      // getNeedInfoList(pdata).then((res) => {
      //   console.log("res", res);
      //   _this.NeedInfoList = res.data.data;
      //   _this.total = res.data.total;
      // });
      this.page = page;
      this.getNeedInfoList();
    },
    hyNeedInfoList(item) {
      this.filterName = item;
      this.getNeedInfoList();
    },
    lyNeedInfoList(item) {
      this.filterName2 = item;
      this.getNeedInfoList();
    },
    getNeedInfoList() {
      let _this = this;
      let pdata = {
        page: _this.page,
        pageSize: _this.pageSize,
        hy: _this.filterName != "全部" ? _this.filterName : "",
        ly: _this.filterName2 != "全部" ? _this.filterName2 : "",
      };
      getNeedInfoList(pdata).then((res) => {
        console.log("res", res);
        _this.NeedInfoList = res.data.data;
        _this.total = res.data.total;
      });
    },
    goNeedInfoDetail(id) {
      this.$router.push({
        path: "/company/needinfo/detail",
        query: {
          id,
        },
      });
    },
    formatDateTime(time) {
      return formatDateTime(time);
    },
  },
};
</script>
<style scoped lang="less">
.el-button + .el-button {
  margin-left: 0;
  margin-top: 10px;
}
.container {
  .needinfo-list {
    width: 84%;
    margin: 0 auto;

    .needinfo-main {
      min-height: 388px;
    }
    .needinfo-filter {
      background-color: #fff;
      .needinfo-filter-t {
        padding: 20px 50px 5px;
        margin-bottom: 20px;
        .needinfo-filter-t-list {
          .needinfo-filter-title {
            font-size: 15px;
            color: #333;
            width: 45px;
            text-align: left;
            margin-top: 5px;
            margin-right: 25px;
          }
          .all {
            width: 60px;
          }
          .item {
            width: calc(100% - 70px - 60px);
          }
          .needinfo-filter-item {
            width: fit-content;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.65);
            line-height: 22px;
            margin-right: 24px;
            margin-bottom: 12px;
            padding: 2px 6px;
            white-space: nowrap;
            background-color: #f5f5f5;

            &:hover {
              background-color: #ecf5ff;
              color: #205dc3;
            }

            &.select {
              background-color: #ecf5ff;
              color: #205dc3;
            }
          }
        }
      }
    }
    .needinfo-item {
      width: 23.95%;
      height: 198px;
      margin-right: 1.4%;
      border-radius: 10px;
      background-color: #fff;
      padding: 30px 25px 20px;
      margin-bottom: 20px;
      box-sizing: border-box;
      &:nth-child(4n) {
        margin-right: 0;
      }
      &:hover {
        .title {
          font-size: 24px;
          font-weight: 600;
          height: 30px;
          line-height: 30px;
          color: #205dc3;
        }
      }
      .title {
        font-size: 24px;
        font-weight: 600;
        height: 30px;
        line-height: 30px;
        color: #333;
      }
      .info {
        height: 78px;
        font-size: 14px;
        color: #666666;
        justify-content: space-evenly;
      }
      .createtime {
        font-size: 14px;
        height: 40px;
        justify-content: flex-end;
      }
    }
  }
}
</style>
