<template>
  <div class="form-container">
    <el-form :inline="true" ref="formData" :model="formData" label-width="80px">
      <el-form-item label="所属行业">
        <el-select v-model="formData.trade" placeholder="请选择所属行业">
          <el-option
            v-for="item in tradeOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="诊断机构">
        <el-input
          v-model="formData.company"
          placeholder="请输入诊断机构"
          class="long-input"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button class="primaryBtn1" @click="handleSearch">查询</el-button>
        <el-button class="primaryBtn1" @click="exportDiagnose"
          >导出四化诊断进度</el-button
        >
        <el-button class="primaryBtn1 orange-btn" @click="handleDownload"
          >人员备案下载</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
// import axios from "axios";
import configs from "@/config/index";
export default {
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
    tradeOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    exportDiagnose() {
      const url =
        configs.baseUrl +
        "/index/government/exportStep?token=" +
        sessionStorage.getItem("token");
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    handleSearch(v) {
      this.$emit("formSearch", v);
    },
    handleDownload(v) {
      console.log("点击人员备案下载");
      this.$emit("formDownload", v);
    },
  },
};
</script>

<style lang="less" scoped>
.form-container {
  .long-input {
    width: 300px;
  }

  .orange-btn {
    background-color: #ff8f1f !important;
    border-color: #ff8f1f !important;
  }
}
</style>
