export let belongingSceneOptions = [
  {
    value: 1,
    label: "产品生命周期数字化",
    children: [
      {
        value: 2,
        label: "产品研发",
        children: [
          {
            value: 3,
            label: "产品功能性仿真测试",
          },
          {
            value: 4,
            label: "产品全生命周期管理",
          },
          {
            value: 5,
            label: "产品三维模型设计",
          },
          {
            value: 6,
            label: "客户需求分析及概念设计",
          },
          {
            value: 7,
            label: "产品设计方案可制造性分析",
          },
        ],
      },
      {
        value: 8,
        label: "工艺设计",
        children: [
          {
            value: 9,
            label: "工艺知识及资源管理",
          },
        ],
      },
      {
        value: 10,
        label: "产品营销",
        children: [
          {
            value: 11,
            label: "新型电子商务",
          },
          {
            value: 12,
            label: "营销数据管理",
          },
        ],
      },
      {
        value: 13,
        label: "产品服务",
        children: [
          {
            value: 14,
            label: "智能产品服务",
          },
          {
            value: 15,
            label: "数字化售后服务",
          },
        ],
      },
    ],
  },
  {
    value: 16,
    label: "生产过程数字化",
    children: [
      {
        value: 17,
        label: "计划调度",
        children: [
          {
            value: 18,
            label: "数字化排产和优化",
          },
          {
            value: 19,
            label: "生产现场管理",
          },
        ],
      },
      {
        value: 20,
        label: "生产作业",
        children: [
          {
            value: 21,
            label: "基于设备、装置、产线升级的生产作业",
          },
          {
            value: 22,
            label: "基于智能装备的生产作业",
          },
          {
            value: 23,
            label: "基于非标自动化的生产作业",
          },
          {
            value: 24,
            label: "规范化作业指导",
          },
          {
            value: 25,
            label: "基于专用设备的自动化生产",
          },
        ],
      },
      {
        value: 26,
        label: "质量管理",
        children: [
          {
            value: 27,
            label: "产品质量追溯",
          },
          {
            value: 28,
            label: "质量数据分析",
          },
          {
            value: 29,
            label: "质量形态检测",
          },
          {
            value: 30,
            label: "质量控制协同",
          },
          {
            value: 31,
            label: "质量性能检测",
          },
        ],
      },
      {
        value: 32,
        label: "设备管理",
        children: [
          {
            value: 33,
            label: "设备状态监测",
          },
          {
            value: 34,
            label: "设备巡点检管理",
          },
          {
            value: 35,
            label: "设备信息全生命周期管理",
          },
        ],
      },
      {
        value: 36,
        label: "能源管理",
        children: [
          {
            value: 37,
            label: "能源数据监测",
          },
          {
            value: 38,
            label: "能源运行优化",
          },
        ],
      },
      {
        value: 39,
        label: "环保管理",
        children: [
          {
            value: 40,
            label: "排放数据监测",
          },
        ],
      },
      {
        value: 41,
        label: "安全管理",
        children: [
          {
            value: 42,
            label: "安全要素识别",
          },
          {
            value: 43,
            label: "危险物料监控",
          },
          {
            value: 44,
            label: "人员智能监控",
          },
          {
            value: 45,
            label: "安全业务管理",
          },
          {
            value: 46,
            label: "网络、数据安全评估",
          },
          {
            value: 47,
            label: "网络、数据安全防护",
          },
        ],
      },
      {
        value: 48,
        label: "数据采集集成",
        children: [
          {
            value: 49,
            label: "设备数据采集感知",
          },
          {
            value: 50,
            label: "设备与系统集成",
          },
        ],
      },
    ],
  },
  {
    value: 51,
    label: "产业链供应链数字化",
    children: [
      {
        value: 52,
        label: "供应链管理",
        children: [
          {
            value: 53,
            label: "数字化采购管理",
          },
          {
            value: 54,
            label: "数字化供应链协同",
          },
          {
            value: 55,
            label: "物料需求计算",
          },
          {
            value: 56,
            label: "客户管理",
          },
          {
            value: 57,
            label: "供应链金融",
          },
        ],
      },
      {
        value: 58,
        label: "仓储管理",
        children: [
          {
            value: 59,
            label: "仓储信息管理",
          },
          {
            value: 60,
            label: "仓储自动化作业",
          },
          {
            value: 61,
            label: "自动化称重计量",
          },
          {
            value: 62,
            label: "精确配送",
          },
          {
            value: 63,
            label: "物料实时跟踪",
          },
          {
            value: 64,
            label: "物流检测与优化",
          },
        ],
      },
      {
        value: 65,
        label: "产业链协同",
        children: [
          {
            value: 66,
            label: "研发设计协同",
          },
          {
            value: 67,
            label: "设备产能共享",
          },
        ],
      },
    ],
  },
  {
    value: 68,
    label: "智能管理决策数字化",
    children: [
      {
        value: 69,
        label: "经营管理",
        children: [
          {
            value: 70,
            label: "协同办公",
          },
          {
            value: 71,
            label: "财务管理",
          },
          {
            value: 72,
            label: "人力资源管理",
          },
        ],
      },
      {
        value: 73,
        label: "平台建设",
        children: [
          {
            value: 74,
            label: "数据中台建设",
          },
          {
            value: 75,
            label: "业务中台建设",
          },
        ],
      },
    ],
  },
  {
    value: 76,
    label: "其他",
  },
];
export const applicationStageList = [
  // "全部",
  "设计",
  "制造",
  "销售",
  "服务",
  "管理",
  "安全",
  "其他",
];
export const industryList = [
  // "全部",
  "汽车",
  "电子元件",
  "黑色金属冶炼",
  "煤炭加工",
  "家具",
  "食品",
  "家电",
  "电子信息",
  "娱乐用品",
  "建材",
  "化工",
  "纺织",
  "木材加工",
  "陶瓷",
  "通用设备",
];
export const softwareTypeList = [
  "研发设计类（CAD/CAE/CAM/CAPP/PLM/PDM/其他）",
  "生产制造类(MES/MOM/SCADA/APC/DCS/DNC/PLC/其他)",
  "经营管理类(ERP/SCM/EAM/HRM/HCM/其他)",
  "运维服务类(MRO/PHM/其他)",
  "销售类(CRM/其他) ",
  "体系设计类",
  "安全类",
  "其他",
];
// export default {
//   belongingSceneOptions,
//   applicationStageList,
//   industryList,
//   softwareTypeList,
// };
