<template>
  <div class="pagination-container">
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :total="total"
      :current-page="currentPage"
      :page-size="pageSize"
      :page-sizes="pageSizes"
      :layout="layout"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    leftSize: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    pageSizes: {
      type: Array,
      default: () => [],
    },
    layout: {
      type: String,
      default: "",
    },
  },
  methods: {
    handleCurrentChange(v) {
      this.$emit("pageChange", v);
    },
    handleSizeChange(v) {
      this.$emit("sizeChange", v);
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .el-pagination__total {
  margin: 0 20px;
}
</style>
