var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"same-container"},[_c('div',{staticClass:"size-container"},[_c('div',{staticClass:"public-container"},[_c('div',{staticClass:"top-nav"},[_c('el-breadcrumb',{attrs:{"separator":">"}},[_c('el-breadcrumb-item',{attrs:{"to":{
                path: '/enterprise/sbzj',
              }}},[_vm._v("申报征集")]),_c('el-breadcrumb-item',[_vm._v("试点意向企业入库申报")])],1)],1),_c('div',{staticClass:"list-content"},_vm._l((_vm.dataList),function(item,index){return _c('div',{key:index,staticClass:"list-item",style:(_vm.$bgImg('bg_paper.png'))},[_c('div',{staticClass:"item-left"},[_c('div',{staticClass:"title"},[_c('span',[_vm._v(_vm._s(item.enterprise_name)+" ")])]),_c('div',{staticClass:"auditStatus"},[(
                    item.company_digitalize_agree_select.audit_status == 1
                  )?_c('span',{staticStyle:{"color":"orange"}},[_vm._v("待审核")]):(
                    item.company_digitalize_agree_select.audit_status == 2
                  )?_c('span',{staticStyle:{"color":"green"}},[_vm._v("审核通过")]):(
                    item.company_digitalize_agree_select.audit_status == 3
                  )?_c('span',{staticStyle:{"color":"red"}},[_vm._v("审核不通过")]):_vm._e()]),_c('div',{staticClass:"time"},[_c('img',{staticClass:"time-icon",attrs:{"src":_vm._f("staticMedia")('time.png')}}),_c('span',[_vm._v(_vm._s(item.updatetime))])])]),_c('div',{staticClass:"item-right"},[_c('el-button',{staticClass:"cancel-btn",attrs:{"type":"primary"},on:{"click":function($event){return _vm.goApplication(item, 2)}}},[_vm._v(" 查看 ")])],1)])}),0),_c('el-pagination',{staticClass:"pagination",attrs:{"total":_vm.total,"current-page":_vm.page,"page-size":+_vm.pageSize,"layout":'prev, pager, next'},on:{"current-change":_vm.handlePageChange}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }