<template>
  <div class="detail-container">
    <!-- <div class="return" @click="handleReturn">
      <img :src="'Vector003.png' | staticMedia" alt="" />
      返回
    </div> -->
    <div class="breadcrumb" style="width: 100%; margin: 0 auto 30px">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item style="cursor: pointer"
          ><span @click="handleReturn">四化诊断</span>
        </el-breadcrumb-item>
        <el-breadcrumb-item>详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="info-box basic-info">
      <div class="title">基本信息</div>
      <div class="info-text">诊断机构名称：{{ basicInfoData.d_company }}</div>
      <div class="info-text">诊断机构联系人：{{ basicInfoData.d_contact }}</div>
      <div class="info-text">诊断机构联系电话：{{ basicInfoData.d_phone }}</div>
      <div class="info-text">被诊断企业名称：{{ basicInfoData.nickname }}</div>
      <div class="info-text">
        被诊断企业联系人： {{ basicInfoData.contact }}
      </div>
      <div class="info-text">被诊断企业联系电话：{{ basicInfoData.phone }}</div>
      <div class="info-text">申报行业：{{ basicInfoData.trade }}</div>
      <div class="info-text">协议签订时间： {{ basicInfoData.signtime }}</div>
    </div>
    <div class="info-box diagnosis-info">
      <div class="title">诊断信息</div>
      <div class="leader">
        <div class="common left">
          <div class="leader-text">
            第一次诊断团队负责人
            <div class="leader-name">
              {{ basicInfoData.leader ? basicInfoData.leader.name : "" }}
            </div>
          </div>
          <div
            class="leader-text"
            v-for="(item, index) in basicInfoData.person_arr1"
            :key="index"
          >
            第一次诊断诊断人员
            <div class="leader-name">
              <!-- {{ basicInfoData.person ? basicInfoData.person.name : "" }} -->
              {{ item.name }}
            </div>
          </div>
          <!-- <div class="leader-text">
            第一次诊断诊断人员
            <div class="leader-name">
              {{ basicInfoData.person2 ? basicInfoData.person2.name : "" }}
            </div>
          </div>
          <div class="leader-text">
            第一次诊断诊断人员
            <div class="leader-name">
              {{ basicInfoData.person3 ? basicInfoData.person3.name : "" }}
            </div>
          </div> -->
        </div>
        <div class="common center">
          <div class="leader-text">
            第二次诊断团队负责人
            <div class="leader-name">
              {{ basicInfoData.person4 ? basicInfoData.person4.name : "" }}
            </div>
          </div>
          <div
            class="leader-text"
            v-for="(item, index) in basicInfoData.person_arr2"
            :key="index"
          >
            第二次诊断诊断人员
            <div class="leader-name">
              <!-- {{ basicInfoData.person5 ? basicInfoData.person5.name : "" }} -->
              {{ item.name }}
            </div>
          </div>
          <!-- <div class="leader-text">
            第二次诊断诊断人员
            <div class="leader-name">
              {{ basicInfoData.person6 ? basicInfoData.person6.name : "" }}
            </div>
          </div>
          <div class="leader-text">
            第二次诊断诊断人员
            <div class="leader-name">
              {{ basicInfoData.person7 ? basicInfoData.person7.name : "" }}
            </div>
          </div> -->
        </div>
        <div class="common right">
          <div class="leader-text">
            第三次诊断团队负责人
            <div class="leader-name">
              {{ basicInfoData.person8 ? basicInfoData.person8.name : "" }}
            </div>
          </div>
          <div
            class="leader-text"
            v-for="(item, index) in basicInfoData.person_arr3"
            :key="index"
          >
            第三次诊断诊断人员
            <div class="leader-name">
              <!-- {{ basicInfoData.person9 ? basicInfoData.person9.name : "" }} -->
              {{ item.name }}
            </div>
          </div>
          <!-- <div class="leader-text">
            第三次诊断诊断人员
            <div class="leader-name">
              {{ basicInfoData.person10 ? basicInfoData.person10.name : "" }}
            </div>
          </div>
          <div class="leader-text">
            第三次诊断诊断人员
            <div class="leader-name">
              {{ basicInfoData.person11 ? basicInfoData.person11.name : "" }}
            </div>
          </div> -->
        </div>
      </div>
      <div class="photos-container">
        <el-row :gutter="10">
          <div
            class="photo-item"
            v-for="(item, index) in filesPhotos"
            :key="index"
          >
            <el-col :span="8">
              <div class="photo-text">
                {{ item.title }}
              </div>
              <div class="photo-list" v-show="item.photos.length >= 1">
                <div class="photo-img">
                  <el-image
                    :src="item.photos[0] | contentMedia"
                    :preview-src-list="[item.photos[0]]"
                  >
                    <div slot="error" class="image-slot">暂无图片</div>
                  </el-image>
                  <!-- <el-image
                :src="item.photos[1] | contentMedia"
                :preview-src-list="[item.photos[1]]"
              >
                <div slot="error" class="image-slot">
                  暂无图片
                </div>
              </el-image>
              <el-image
                :src="item.photos[2] | contentMedia"
                :preview-src-list="[item.photos[2]]"
              >
                <div slot="error" class="image-slot">
                  暂无图片
                </div>
              </el-image>
              <el-image
                :src="item.photos[3] | contentMedia"
                :preview-src-list="[item.photos[3]]"
              >
                <div slot="error" class="image-slot">
                  暂无图片
                </div>
              </el-image> -->
                </div>
              </div>
            </el-col>
          </div>
        </el-row>
      </div>

      <div class="photos-container">
        <div class="photo-text">现场照片</div>
        <el-row :gutter="10">
          <div
            class="photo-item"
            v-for="(item, index) in sceneimgList"
            :key="index"
          >
            <el-col :span="4">
              <div class="photo-list">
                <div class="photo-img" style="margin-bottom: 20px">
                  <el-image
                    :src="item | contentMedia"
                    :preview-src-list="sceneimgList"
                  >
                    <div slot="error" class="image-slot">暂无图片</div>
                  </el-image>
                </div>
              </div>
            </el-col>
          </div>
        </el-row>
      </div>
      <div class="files-container">
        <div class="file-text" v-for="item in filesDocx" :key="item.id">
          <div class="file-title">{{ item.title }}</div>
          <div class="file-icon">
            <!-- <a :href="item ? item.url | contentMedia : ''" :download="item? item.filename: ''"> </a> -->
            <a
              class="file-a"
              :href="!item ? '' : item.url"
              :download="item ? item.filename : ''"
            >
              <i class="el-icon-document"></i>
            </a>
          </div>
        </div>
      </div>
      <!-- <div class="files-container">
        <div class="file-text">
          <div class="file-title">诊断服务协议</div>
          <div class="file-icon el-icon-document">
            <a
              :href="filesDocx1 ? filesDocx1.url | contentMedia : ''"
              :download="filesDocx1 ? filesDocx1.filename : ''"
            >
            </a>
          </div>
        </div>
        <div class="file-text">
          <div class="file-title">诊断服务协议</div>
          <div class="file-icon el-icon-document">
            <a
              :href="filesDocx2 ? filesDocx2.url | contentMedia : ''"
              :download="filesDocx2 ? filesDocx2.filename : ''"
            >
            </a>
          </div>
        </div>
        <div class="file-text">
          <div class="file-title">诊断服务协议</div>
          <div class="file-icon el-icon-document">
            <a
              :href="filesDocx3 ? filesDocx3.url | contentMedia : ''"
              :download="filesDocx3 ? filesDocx3.filename : ''"
            >
            </a>
          </div>
        </div>
        <div class="file-text">
          <div class="file-title">企业诊断计划表</div>
          <div class="file-icon el-icon-document">
            <a
              :href="filesDocx4 ? filesDocx4.url | contentMedia : ''"
              :download="filesDocx4 ? filesDocx4.filename : ''"
            >
            </a>
          </div>
        </div>
        <div class="file-text">
          <div class="file-title">诊断报告</div>
          <div class="file-icon el-icon-document">
            <a
              :href="filesDocx5 ? filesDocx5.url | contentMedia : ''"
              :download="filesDocx5 ? filesDocx5.filename : ''"
            >
            </a>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { getDiagnoseDetail } from "@/api/zf";
import configs from "@/config/index";
export default {
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
    tradeOptions: {
      type: Array,
      default: () => [],
    },
    detailData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      basicInfoData: {},
      files1Photos: [],
      files2Photos: [],
      files3Photos: [],
      filesPhotos: [],
      filesDocx: [],
      filesDocx1: {},
      filesDocx2: {},
      filesDocx3: {},
      filesDocx4: {},
      filesDocx5: {},
      sceneimgList: [],
    };
  },
  created() {
    this.basicInfoData = this.detailData;
    this.setData();
  },
  methods: {
    setData() {
      console.log(
        this.basicInfoData,
        "-------------------------------------------"
      );
      if (this.basicInfoData.sceneimgList) {
        this.sceneimgList = this.basicInfoData.sceneimgList.split(",");
      }
      if (
        this.basicInfoData.files1 &&
        this.basicInfoData.files2 &&
        this.basicInfoData.files3
      ) {
        this.filesPhotos = [
          {
            id: 1,
            title: "第一次企业诊断签到表",
            photos: this.basicInfoData.files1.split(","),
          },
          {
            id: 2,
            title: "第二次企业诊断签到表",
            photos: this.basicInfoData.files2.split(","),
          },
          {
            id: 3,
            title: "第三次企业诊断签到表",
            photos: this.basicInfoData.files3.split(","),
          },
        ];
      }
      // this.filesDocx1 = this.basicInfoData.sign_file[0];
      // this.filesDocx2 = this.basicInfoData.sign_file[1];
      // this.filesDocx3 = this.basicInfoData.sign_file[2];
      // this.filesDocx4 = this.basicInfoData.plan;
      // this.filesDocx5 = this.basicInfoData.report_file;
      this.filesDocx = [
        {
          id: 1,
          title: "诊断服务协议",
          url:
            this.basicInfoData.sign_file.length > 0
              ? configs.baseUrl + this.basicInfoData.sign_file[0].url
              : "javascript:void(0);",
          filename:
            this.basicInfoData.sign_file.length > 0
              ? this.basicInfoData.sign_file[0].filename
              : "",
        },
        {
          id: 2,
          title: "诊断服务协议",
          url:
            this.basicInfoData.sign_file.length > 1
              ? configs.baseUrl + this.basicInfoData.sign_file[1].url
              : "javascript:void(0);",
          filename:
            this.basicInfoData.sign_file.length > 1
              ? this.basicInfoData.sign_file[1].filename
              : "",
        },
        {
          id: 3,
          title: "诊断服务协议",
          url:
            this.basicInfoData.sign_file.length > 2
              ? configs.baseUrl + this.basicInfoData.sign_file[2].url
              : "javascript:void(0);",
          filename:
            this.basicInfoData.sign_file.length > 2
              ? this.basicInfoData.sign_file[2].filename
              : "",
        },
        {
          id: 4,
          title: "企业诊断计划表",
          url: this.basicInfoData.plan
            ? configs.baseUrl + this.basicInfoData.plan.url
            : "javascript:void(0);",
          filename: this.basicInfoData.plan
            ? this.basicInfoData.plan.filename
            : "",
        },
        {
          id: 5,
          title: "诊断报告",
          url: this.basicInfoData.report_file
            ? configs.baseUrl + this.basicInfoData.report_file.url
            : "javascript:void(0);",
          filename: this.basicInfoData.report_file
            ? this.basicInfoData.report_file.filename
            : "",
        },
      ];
    },
    handleReturn() {
      this.$emit("returnIndex");
    },
    // 接口返回数据不全
    getList() {
      getDiagnoseDetail({
        id: this.detailData.id,
      }).then((res) => {
        this.basicInfoData = res.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.detail-container {
  .return {
    margin: 0 0 20px 20px;

    img {
      width: 1vw;
      height: 2vh;
      margin-right: 10px;
    }
  }

  .info-box {
    background: #ffffff;
    border-radius: 20px;
    margin-bottom: 30px;
    box-shadow: 0px 4px 10px 0px rgba(32, 93, 195, 0.16);
  }

  .title {
    font-size: 24px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 30px;
  }

  .info-text {
    font-size: 18px;
    line-height: 18px;
    color: #333333;
    margin-bottom: 20px;
  }

  .basic-info {
    padding: 50px 0 30px 50px;
  }

  .diagnosis-info {
    padding: 50px 0 30px 50px;

    .leader {
      display: flex;

      .left {
        flex: 1;
      }

      .center {
        flex: 1;
      }

      .right {
        flex: 1;
      }

      .common {
        font-size: 18px;
        font-weight: 600;
        color: #333333;
      }

      .leader-text {
        margin-bottom: 20px;

        .leader-name {
          margin-top: 20px;
          font-size: 16px;
          font-weight: 500;
          color: #333333;
        }
      }
    }

    .photos-container {
      margin-bottom: 20px;

      .photo-text {
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: 500;
        color: #333333;
      }
      .photo-item {
        // margin-bottom: 20px;
        width: 100%;

        .photo-list {
          display: flex;

          .photo-img {
            .el-image {
              width: 140px;
              height: 140px;
              margin-right: 30px;
              border-radius: 10px;
              border: 1px solid #eeeeee;
            }
          }
        }
      }
    }

    .files-container {
      width: 80%;
      display: flex;
      justify-content: space-between;

      .file-text {
        text-align: center;

        .file-title {
          font-size: 20px;
          font-weight: 500;
          margin-bottom: 20px;
          color: #333333;
        }

        .file-icon {
          font-size: 70px;
          color: #a9a9a9;

          a {
            color: #a9a9a9;
            text-decoration: none;

            &:hover {
              color: #a9a9a9;
            }

            &:active {
              color: #a9a9a9;
            }
          }
        }
      }
    }
  }
}

::v-deep .image-slot {
  line-height: 140px;
  text-align: center;
  color: #999;
}
</style>
