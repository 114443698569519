// import Header from '@/components/CenterHeader';
import MHHeader from "@/components/MHHeader";
import Footer from "@/components/Footer.vue";
import AboutUs from "@/views/common/aboutUs";
import ContactUs from "@/views/common/contactUs";
// 公共

export default [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/common/Login"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/common/Register"),
  },
  {
    path: "/aboutUs",
    name: "AboutUs",
    components: {
      header: MHHeader,
      default: AboutUs,
      footer: Footer,
      meta: {
        keepAlive: true,
      },
    },
  },
  {
    path: "/contactUs",
    name: "ContactUs",
    components: {
      header: MHHeader,
      default: ContactUs,
      footer: Footer,
      meta: {
        keepAlive: true,
      },
    },
  },
];
