<template>
  <div class="container">
    <div class="same-container">
      <div class="size-container">
        <div class="public-container">
          <div class="top-nav">
            <el-breadcrumb separator=">">
              <el-breadcrumb-item
                :to="{
                  path: '/government/sbzj',
                }"
                >申报征集</el-breadcrumb-item
              >
              <el-breadcrumb-item
                :to="{
                  path: '/government/sbzj/list',
                  query: { type: listType },
                }"
                >数字化产品入库申报</el-breadcrumb-item
              >
              <el-breadcrumb-item>详情</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="sbzj-form">
            <h3 class="form-title">
              数字化产品入库申报
            </h3>
            <h4 class="form-subtitle">一、产品提供企业基本情况</h4>
            <el-form
              ref="form"
              :model="form"
              label-width="188px"
              label-position="left"
            >
              <el-form-item label="企业名称" prop="company_name">
                <el-input
                  v-model="form.company_name"
                  placeholder="请输入企业名称"
                  :disabled="true"
                ></el-input>
              </el-form-item>
              <el-form-item label="统一社会信用代码" prop="credit_code">
                <el-input
                  v-model="form.credit_code"
                  placeholder="请输入统一社会信用代码"
                  :disabled="true"
                ></el-input>
              </el-form-item>
              <el-form-item label="企业地址" prop="company_address">
                <el-input
                  v-model="form.company_address"
                  placeholder="请输入企业地址"
                  :disabled="true"
                ></el-input>
              </el-form-item>
              <el-form-item label="企业性质" prop="company_nature">
                <el-radio-group
                  :disabled="true"
                  v-model="form.company_nature"
                  class="company-nature-radio"
                >
                  <el-radio label="国有企业" value="国有企业"></el-radio>
                  <el-radio label="集体企业" value="集体企业"></el-radio>
                  <el-radio label="私营企业" value="私营企业"></el-radio>
                  <el-radio
                    label="有限责任公司"
                    value="有限责任公司"
                  ></el-radio>
                  <el-radio
                    label="股份有限公司"
                    value="股份有限公司"
                  ></el-radio>
                  <el-radio label="其他企业" value="其他企业"></el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="注册资本" prop="registered_capital">
                <el-input
                  v-model="form.registered_capital"
                  placeholder="请输入注册资本"
                  :disabled="true"
                ></el-input>
              </el-form-item>
              <el-form-item label="成立日期" prop="establishment_date">
                <el-date-picker
                  :disabled="true"
                  type="date"
                  placeholder="请选择日期"
                  v-model="form.establishment_date"
                ></el-date-picker>
              </el-form-item>
              <el-form-item label="上年度营收" prop="last_year_revenue">
                <el-input
                  v-model="form.last_year_revenue"
                  placeholder="请输入上年度营收"
                  :disabled="true"
                ></el-input>
              </el-form-item>
              <el-form-item label="融资情况" prop="financing_situation">
                <el-input
                  v-model="form.financing_situation"
                  placeholder="请输入融资情况"
                  :disabled="true"
                ></el-input>
              </el-form-item>
              <el-form-item label="人员规模" prop="personnel_scale">
                <el-input
                  v-model="form.personnel_scale"
                  placeholder="请输入人员规模"
                  :disabled="true"
                ></el-input>
              </el-form-item>
              <el-form-item label="研发人数" prop="design_persons">
                <el-input
                  v-model="form.design_persons"
                  placeholder="请输入研发人数"
                  :disabled="true"
                ></el-input>
              </el-form-item>
              <el-form-item label="上市情况" prop="listed_status">
                <el-radio-group :disabled="true" v-model="form.listed_status">
                  <div style="margin-bottom: 10px;">
                    <el-radio :label="1">
                      <span style="margin-right: 20px;">是，上市日期</span>
                      <el-date-picker
                        :disabled="true"
                        type="date"
                        placeholder="请选择日期"
                        v-model="form.listed_date"
                      ></el-date-picker>
                      <div class="ss-radio">
                        <el-radio-group
                          :disabled="true"
                          v-model="form.listed_area"
                        >
                          <div
                            class="flex bewteen"
                            style="margin-bottom: 10px;"
                          >
                            <el-radio label="深交所" value="深交所"
                              >深交所</el-radio
                            >
                            <el-radio label="上交所" value="上交所"
                              >上交所</el-radio
                            >
                            <el-radio label="新加坡" value="新加坡"
                              >新加坡</el-radio
                            >
                            <el-radio label="香港" value="香港">香港</el-radio>
                            <el-radio label="创业板" value="创业板"
                              >创业板</el-radio
                            >
                          </div>
                          <div class="flex bewteen">
                            <el-radio label="新三板" value="新三板"
                              >新三板</el-radio
                            >
                            <el-radio label="纳斯达克" value="纳斯达克"
                              >纳斯达克</el-radio
                            >
                            <el-radio label="纽约交易所" value="纽约交易所"
                              >纽约交易所</el-radio
                            >
                            <el-radio label="其他" value="其他">
                              <span>其他：</span>
                              <el-input
                                class="ss-input"
                                v-model="form.other_listed"
                                :disabled="true"
                              />
                            </el-radio>
                          </div>
                        </el-radio-group>
                      </div>
                    </el-radio>
                  </div>
                  <div>
                    <el-radio :label="0">否</el-radio>
                  </div>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="联系人" prop="contact_person">
                <el-input
                  v-model="form.contact_person"
                  placeholder="请输入联系人"
                  :disabled="true"
                ></el-input>
              </el-form-item>
              <el-form-item label="职务" prop="position">
                <el-input
                  v-model="form.position"
                  placeholder="请输入职务"
                  :disabled="true"
                ></el-input>
              </el-form-item>
              <el-form-item label="电话" prop="phone">
                <el-input
                  v-model="form.phone"
                  placeholder="请输入电话"
                  :disabled="true"
                ></el-input>
              </el-form-item>
              <el-form-item label="邮箱" prop="email">
                <el-input
                  v-model="form.email"
                  placeholder="请输入邮箱"
                  :disabled="true"
                ></el-input>
              </el-form-item>
            </el-form>
            <!-- <el-form
              ref="companyForm"
              :model="form"
              :rules="rules"
              label-width="188px"
              label-position="top"
            >
              <el-form-item
                label="是否入选广州市省级中小企业数字化牵引单位"
                prop="pull_unit"
              >
                <el-radio-group
                  style="margin-left: 188px;"
                  v-model="form.pull_unit"
                  :disabled="true"
                >
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                label="是否为数字化牵引单位服务生态企业"
                prop="pull_unit_service"
              >
                <el-radio-group
                  style="margin-left: 188px;"
                  v-model="form.pull_unit_service"
                  :disabled="true"
                >
                  <div style="margin-bottom: 10px;">
                    <el-radio :label="1">
                      <span style="margin-right: 20px;"
                        >是，合作牵引单位为</span
                      >
                      <el-input
                        class="ss-input"
                        v-model="form.pull_partner"
                        :disabled="true"
                      />
                    </el-radio>
                  </div>
                  <div>
                    <el-radio :label="0">否</el-radio>
                  </div>
                </el-radio-group>
              </el-form-item>
            </el-form> -->
          </div>
          <div class="sbzj-form" style="margin-top: 30px;padding-top: 30px;">
            <h4 class="form-subtitle">二、产品基本情况</h4>
            <h5 class="form-subtitle2">基本情况</h5>
            <el-form
              ref="form"
              :model="form"
              label-width="188px"
              label-position="left"
            >
              <el-form-item label="产品名称" prop="product_name">
                <el-input
                  v-model="form.product_name"
                  placeholder="请输入产品名称"
                  :disabled="true"
                ></el-input>
              </el-form-item>
              <el-form-item label="产品代码" prop="code">
                <el-input v-model="form.code" :disabled="true"></el-input>
              </el-form-item>
              <el-form-item label="标签图片" prop="product_picture">
                <el-image
                  style="width: 500px;height: fit-content;"
                  :src="form.product_picture | contentMedia"
                ></el-image>
              </el-form-item>
              <el-form-item label="销售方式" prop="sale_type">
                <el-radio-group :disabled="true" v-model="form.sale_type">
                  <el-radio label="买断制" value="买断制"></el-radio>
                  <el-radio label="订阅制" value="订阅制"></el-radio>
                  <el-radio label="其他" value="其他">
                    <span>其他：</span>
                    <el-input
                      :disabled="true"
                      class="ss-input"
                      v-model="form.other_sale"
                    />
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="二次开发" prop="secondary_development">
                <el-radio-group
                  :disabled="true"
                  v-model="form.secondary_development"
                >
                  <el-radio :label="1">支持</el-radio>
                  <el-radio :label="0">不支持</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="试用" prop="trial_use">
                <el-radio-group :disabled="true" v-model="form.trial_use">
                  <el-radio :label="1">支持</el-radio>
                  <el-radio :label="0">不支持</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="应用环节" prop="function_around">
                <el-checkbox-group
                  v-model="form.function_around"
                  class="product-type-checkbox"
                  :disabled="true"
                >
                  <el-checkbox label="设计" value="设计"></el-checkbox>
                  <el-checkbox label="制造" value="制造"></el-checkbox>
                  <el-checkbox label="销售" value="销售"></el-checkbox>
                  <el-checkbox label="服务" value="服务"></el-checkbox>
                  <el-checkbox label="管理 " value="管理 "></el-checkbox>
                  <el-checkbox label="安全" value="安全"></el-checkbox>
                  <el-checkbox label="其他" value="其他"></el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item label="所属行业" prop="industry">
                <el-checkbox-group v-model="form.industry" :disabled="true">
                  <el-checkbox label="汽车"></el-checkbox>
                  <el-checkbox label="电子元件"></el-checkbox>
                  <el-checkbox label="黑色金属冶炼"></el-checkbox>
                  <el-checkbox label="煤炭加工"></el-checkbox>
                  <el-checkbox label="家具"></el-checkbox>
                  <el-checkbox label="食品"></el-checkbox>
                  <el-checkbox label="家电"></el-checkbox>
                  <el-checkbox label="电子信息"></el-checkbox>
                  <el-checkbox label="娱乐用品"></el-checkbox>
                  <el-checkbox label="建材"></el-checkbox>
                  <el-checkbox label="化工"></el-checkbox>
                  <el-checkbox label="纺织"></el-checkbox>
                  <el-checkbox label="木材加工"></el-checkbox>
                  <el-checkbox label="陶瓷"></el-checkbox>
                  <el-checkbox label="通用设备"></el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item label="所属细分行业" prop="sub_industry">
                <el-checkbox-group :disabled="true" v-model="form.sub_industry">
                  <el-checkbox label="智能网联和新能源汽车"></el-checkbox>
                  <el-checkbox label="工业母机和机器人"></el-checkbox>
                  <el-checkbox label="时尚美妆"></el-checkbox>
                  <el-checkbox label="定制家居"></el-checkbox>
                  <el-checkbox label="服装"></el-checkbox>
                  <el-checkbox label="箱包"></el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item label="所属场景" prop="scene">
                <div>
                  <el-cascader
                    v-model="form.scene"
                    :options="belongingSceneOptions"
                    :props="{ multiple: true, value: 'id', label: 'title' }"
                    :disabled="true"
                    clearable
                    style="width: 48%"
                  ></el-cascader>
                </div>
                <!-- <el-input
                  type="textarea"
                  :disabled="true"
                  :autosize="{ minRows: 4, maxRows: 8 }"
                  placeholder="遵循《工信部中小企业数字化转型城市试点实施指南》中的供需适配库所罗列的场景，例如“产品生命周期数字化-产品研发-产品功能性能仿真测试”，若某一级场景无对应可写为“其他”，例如“产品生命周期数字化-其他”。"
                  v-model="form.scene"
                /> -->
              </el-form-item>
              <el-form-item label="产品类型" prop="product_type">
                <el-radio-group
                  :disabled="true"
                  v-model="form.product_type"
                  class="product-type-radio"
                >
                  <el-radio
                    label="研发设计类（CAD/CAE/CAM/CAPP/PLM/PDM/其他）"
                    value="研发设计类（CAD/CAE/CAM/CAPP/PLM/PDM/其他）"
                  ></el-radio>
                  <el-radio
                    label="生产制造类(MES/MOM/SCADA/APC/DCS/DNC/PLC/其他)"
                    value="生产制造类(MES/MOM/SCADA/APC/DCS/DNC/PLC/其他)"
                  ></el-radio>
                  <el-radio
                    label="经营管理类(ERP/SCM/EAM/HRM/HCM/其他)"
                    value="经营管理类(ERP/SCM/EAM/HRM/HCM/其他)"
                  ></el-radio>
                  <el-radio
                    label="运维服务类(MRO/PHM/其他)"
                    value="运维服务类(MRO/PHM/其他)"
                  ></el-radio>
                  <el-radio
                    label="销售类(CRM/其他) "
                    value="销售类(CRM/其他) "
                  ></el-radio>
                  <el-radio label="体系设计类" value="体系设计类"></el-radio>
                  <el-radio label="安全类" value="安全类"></el-radio>
                  <el-radio label="其他" value="其他"></el-radio>
                </el-radio-group>
              </el-form-item>

              <el-form-item label="产品关联牵引单位" prop="product_pull">
                <el-checkbox-group
                  class="qydw-checkbox"
                  v-model="form.product_pull"
                  size="medium"
                  :disabled="true"
                >
                  <div class="qydw-item">
                    <el-checkbox
                      class="qydw-checkbox-option"
                      label="无"
                    ></el-checkbox>
                    <el-checkbox
                      v-for="(item, index) in tractionUnitList"
                      :key="index"
                      class="qydw-checkbox-option"
                      :label="item.label"
                    ></el-checkbox>
                    <!-- </div>
              <div class="qydw-item">
                <div class="qydw-item-title">
                  智能网联和新能源汽车（零部件）行业：
                </div> -->
                    <!-- <el-checkbox
                      class="qydw-checkbox-option"
                      label="广州明珞装备股份有限公司"
                    ></el-checkbox>
                    <el-checkbox
                      class="qydw-checkbox-option"
                      label="格创东智（广州）科技技术有限公司"
                    ></el-checkbox>
                    <el-checkbox
                      class="qydw-checkbox-option"
                      label="浪潮云洲工业互联网有限公司"
                    ></el-checkbox> -->
                    <!-- </div>
              <div class="qydw-item">
                <div class="qydw-item-title">
                  工业母机和机器人行业：
                </div> -->
                    <!-- <el-checkbox
                      class="qydw-checkbox-option"
                      label="广州数控设备有限公司"
                    ></el-checkbox>
                    <el-checkbox
                      class="qydw-checkbox-option"
                      label="树根互联股份有限公司"
                    ></el-checkbox>
                    <el-checkbox
                      class="qydw-checkbox-option"
                      label="广东玛斯特智能系统有限公司"
                    ></el-checkbox> -->
                    <!-- </div>
              <div class="qydw-item">
                <div class="qydw-item-title">
                  时尚美妆行业：
                </div> -->
                    <!-- <el-checkbox
                      class="qydw-checkbox-option"
                      label="广州蜂巢互联科技有限公司"
                    ></el-checkbox>
                    <el-checkbox
                      class="qydw-checkbox-option"
                      label="广州中浩控制技术有限公司"
                    ></el-checkbox>
                    <el-checkbox
                      class="qydw-checkbox-option"
                      label="广州环亚化妆品科技股份有限公司"
                    ></el-checkbox> -->
                    <!-- </div>
              <div class="qydw-item">
                <div class="qydw-item-title">
                  定制家居行业：
                </div> -->
                    <!-- <el-checkbox
                      class="qydw-checkbox-option"
                      label="广州尚品宅配家居股份有限公司"
                    ></el-checkbox>
                    <el-checkbox
                      class="qydw-checkbox-option"
                      label="欧派家居集团股份有限公司"
                    ></el-checkbox>
                    <el-checkbox
                      class="qydw-checkbox-option"
                      label="广州鼎捷软件有限公司"
                    ></el-checkbox> -->
                    <!-- </div>
              <div class="qydw-item">
                <div class="qydw-item-title">
                  服装行业：
                </div> -->
                    <!-- <el-checkbox
                      class="qydw-checkbox-option"
                      label="比音勒芬服饰股份有限公司"
                    ></el-checkbox>
                    <el-checkbox
                      class="qydw-checkbox-option"
                      label="广东省纺织品进出口股份有限公司"
                    ></el-checkbox>
                    <el-checkbox
                      class="qydw-checkbox-option"
                      label="广州春晓信息科技有限公司"
                    ></el-checkbox> -->
                    <!-- </div>
              <div class="qydw-item">
                <div class="qydw-item-title">
                  箱包行业：
                </div> -->
                    <!-- <el-checkbox
                      class="qydw-checkbox-option"
                      label="广州盖特软件有限公司"
                    ></el-checkbox>
                    <el-checkbox
                      class="qydw-checkbox-option"
                      label="联通(广东)产业互联网有限公司"
                    ></el-checkbox>
                    <el-checkbox
                      class="qydw-checkbox-option"
                      label="杭州海康威视数字技术股份有限公司"
                    ></el-checkbox>
                    <el-checkbox
                      class="qydw-checkbox-option"
                      label="华为云计算技术有限公司"
                    ></el-checkbox>
                    <el-checkbox
                      class="qydw-checkbox-option"
                      label="广东用友软件有限公司"
                    ></el-checkbox>
                    <el-checkbox
                      class="qydw-checkbox-option"
                      label="金蝶软件(中国)有限公司"
                    ></el-checkbox>
                    <el-checkbox
                      class="qydw-checkbox-option"
                      label="广东丸美生物技术股份有限公司"
                    ></el-checkbox>
                    <el-checkbox
                      class="qydw-checkbox-option"
                      label="广州赛意信息科技股份有限公司"
                    ></el-checkbox>
                    <el-checkbox
                      class="qydw-checkbox-option"
                      label="广东粤桨产业科技有限公司"
                    ></el-checkbox> -->
                  </div>
                </el-checkbox-group>
              </el-form-item>
            </el-form>

            <h5 class="form-subtitle2">产品技术</h5>
            <el-form
              ref="form"
              :model="form"
              label-width="188px"
              label-position="left"
            >
              <el-form-item label="功能性" prop="functionality">
                <el-input
                  type="textarea"
                  :disabled="true"
                  :autosize="{ minRows: 4, maxRows: 8 }"
                  placeholder="例如覆盖功能点、功能适用性、数据项正确性等"
                  v-model="form.functionality"
                />
              </el-form-item>
              <el-form-item label="拓展性" prop="expansibility">
                <el-input
                  type="textarea"
                  :disabled="true"
                  :autosize="{ minRows: 4, maxRows: 8 }"
                  placeholder="例如模块化设计、外部集成能力、定制开发、可拓展技术架构等"
                  v-model="form.expansibility"
                />
              </el-form-item>
              <el-form-item label="安全性" prop="security">
                <el-input
                  type="textarea"
                  :disabled="true"
                  :autosize="{ minRows: 4, maxRows: 8 }"
                  placeholder="例如数据保护、身份认证、网络安全等"
                  v-model="form.security"
                />
              </el-form-item>
              <el-form-item label="可移植性" prop="portability">
                <el-input
                  type="textarea"
                  :disabled="true"
                  :autosize="{ minRows: 4, maxRows: 8 }"
                  placeholder="例如CPU适应性、操作系统适应性、数据库适应性等"
                  v-model="form.portability"
                />
              </el-form-item>
              <el-form-item label="易用性" prop="usability">
                <el-input
                  type="textarea"
                  :disabled="true"
                  :autosize="{ minRows: 4, maxRows: 8 }"
                  placeholder="例如覆盖功能点、功能适用性、数据项正确性等"
                  v-model="form.usability"
                />
              </el-form-item>
              <el-form-item label="创新性" prop="innovativeness">
                <el-input
                  type="textarea"
                  :disabled="true"
                  :autosize="{ minRows: 4, maxRows: 8 }"
                  placeholder="例如技术创新（使用了新技术，实现了新功能或性能），业务创新（新的业务模式），产品创新（产品设计具有独特之处，商业模式创新"
                  v-model="form.innovativeness"
                />
              </el-form-item>
            </el-form>

            <h5 class="form-subtitle2">
              典型经验案例（每个细分行业至少一个案例）
            </h5>
            <el-form
              v-for="(item, index) in form.companyProductDeclarationList"
              :key="index"
              :model="item"
              ref="form"
              label-width="188px"
              label-position="left"
            >
              <div class="flex bewteen vertical">
                <h6 class="form-subtitle3">案例{{ index | getCaseIndex }}</h6>
              </div>
              <el-form-item label="案例名称" prop="case_name">
                <el-input
                  type="textarea"
                  :disabled="true"
                  :autosize="{ minRows: 4, maxRows: 8 }"
                  placeholder="命名采用’“产品名称”+“解决方案”的方式"
                  v-model="item.case_name"
                />
              </el-form-item>
              <el-form-item label="案例背景" prop="case_background">
                <el-input
                  type="textarea"
                  :disabled="true"
                  :autosize="{ minRows: 4, maxRows: 8 }"
                  placeholder="说明原来的状态和希望解决的问题"
                  v-model="item.case_background"
                />
              </el-form-item>
              <el-form-item label="实践概述" prop="practice_overview">
                <el-input
                  type="textarea"
                  :disabled="true"
                  :autosize="{ minRows: 4, maxRows: 8 }"
                  placeholder="简要说明应用解决方案的基本情况"
                  v-model="item.practice_overview"
                />
              </el-form-item>
              <el-form-item label="应用效果" prop="application_effect">
                <el-input
                  type="textarea"
                  :disabled="true"
                  :autosize="{ minRows: 4, maxRows: 8 }"
                  placeholder="说明典型经验案例在实践中如何应用，用实例和数据说明带来的变化和效果"
                  v-model="item.application_effect"
                />
              </el-form-item>
              <el-form-item label="案例附件" prop="case_attachment">
                <div class="file-content">
                  <div
                    v-for="(citem, index) in item.caseFileList"
                    :key="index"
                    class="flex vertical "
                    style="margin-bottom:8px;"
                  >
                    <el-link
                      type="primary"
                      target="_blank"
                      :href="citem.url | contentMedia"
                      :download="citem.name"
                      :underline="false"
                    >
                      {{ citem.name }}
                    </el-link>
                  </div>
                </div>
              </el-form-item>
            </el-form>

            <h5 class="form-subtitle2">产品说明</h5>
            <el-form
              :model="form"
              ref="form"
              label-width="188px"
              label-position="left"
            >
              <el-form-item label="部署架构" prop="deployment_architecture">
                <el-input
                  type="textarea"
                  :disabled="true"
                  :autosize="{ minRows: 4, maxRows: 8 }"
                  placeholder="附软件架构图并简要说明"
                  v-model="form.deployment_architecture"
                />
                <div class="file-content">
                  <div
                    v-for="(item, index) in deploymentFileList"
                    :key="index"
                    class="flex vertical "
                    style="margin-bottom:8px;"
                  >
                    <el-link
                      type="primary"
                      target="_blank"
                      :href="item.url | contentMedia"
                      :download="item.name"
                      :underline="false"
                    >
                      {{ item.name }}
                    </el-link>
                  </div>
                </div>
              </el-form-item>

              <el-form-item label="安全说明" prop="security_description">
                <el-input
                  type="textarea"
                  :disabled="true"
                  :autosize="{ minRows: 4, maxRows: 8 }"
                  placeholder="说明产品安全性能，包括但不限于通过的安全测试、安全等级、安全技术指标、安全技术要求等。如有相关报告，可作为附件提交。"
                  v-model="form.security_description"
                />
                <div class="file-content">
                  <div
                    v-for="(item, index) in securityFileList"
                    :key="index"
                    class="flex vertical "
                    style="margin-bottom:8px;"
                  >
                    <el-link
                      type="primary"
                      target="_blank"
                      :href="item.url | contentMedia"
                      :download="item.name"
                      :underline="false"
                    >
                      {{ item.name }}
                    </el-link>
                  </div>
                </div>
              </el-form-item>
              <el-form-item
                label="自主知识产权说明"
                prop="independent_description"
              >
                <el-input
                  type="textarea"
                  :disabled="true"
                  :autosize="{ minRows: 4, maxRows: 8 }"
                  placeholder="列举产品相关的自主知识产权的材料，包括相关专利、软著等。附件提交盖章证书材料。"
                  v-model="form.independent_description"
                />
                <div class="file-content">
                  <div
                    v-for="(item, index) in independentFileList"
                    :key="index"
                    class="flex vertical "
                    style="margin-bottom:8px;"
                  >
                    <el-link
                      type="primary"
                      target="_blank"
                      :href="item.url | contentMedia"
                      :download="item.name"
                      :underline="false"
                    >
                      {{ item.name }}
                    </el-link>
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="价格说明" prop="price_description">
                <el-input
                  type="textarea"
                  :disabled="true"
                  :autosize="{ minRows: 4, maxRows: 8 }"
                  placeholder="明产品建议市场价格（根据产品售卖方式具体描述）和建议优惠价格（选填）并在附件中提交产品定价理由详细说明。"
                  v-model="form.price_description"
                />
                <div class="file-content">
                  <div
                    v-for="(item, index) in priceFileList"
                    :key="index"
                    class="flex vertical "
                    style="margin-bottom:8px;"
                  >
                    <el-link
                      type="primary"
                      target="_blank"
                      :href="item.url | contentMedia"
                      :download="item.name"
                      :underline="false"
                    >
                      {{ item.name }}
                    </el-link>
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="获奖信息" prop="award_situation">
                <el-input
                  type="textarea"
                  :disabled="true"
                  :autosize="{ minRows: 4, maxRows: 8 }"
                  placeholder="说明产品以往获奖情况，字数控制在500字以内。在附件中提交相关材料复印件。"
                  v-model="form.award_situation"
                />
                <div class="file-content">
                  <div
                    v-for="(item, index) in awardFileList"
                    :key="index"
                    class="flex vertical "
                    style="margin-bottom:8px;"
                  >
                    <el-link
                      type="primary"
                      target="_blank"
                      :href="item.url | contentMedia"
                      :download="item.name"
                      :underline="false"
                    >
                      {{ item.name }}
                    </el-link>
                  </div>
                </div>
              </el-form-item>
            </el-form>

            <h5 class="form-subtitle2">相关附件</h5>
            <div class="form-desc">
              <div>1、产品获奖材料复印件；</div>
              <div>2、产品价格说明；</div>
              <div>3、产品自主支持产权相关材料，包括相关专利、软著等；</div>
              <div>
                4、产品第三方测试报告，例如功能测试报告、性能测试报告、安全测试报告、兼容性测试报告、用户体验测试报告等。附完整测试报告为附件，若无软件第三方测试报告则提供用户试用报告，并加盖用户公司章；
              </div>
              <div>5、营业执照；</div>
              <div>6、信用查询记录，包括信用中国、中国政府采购网。</div>
            </div>
            <!-- <div class="related-attachment-list">
              <div
                v-for="(item, index) in JSON.parse(form.related_attachment)"
                :key="index"
              >
                <el-link
                  type="primary"
                  target="_blank"
                  :href="item.url | contentMedia"
                  :download="item.name"
                  :underline="false"
                >
                  {{ item.name }}
                </el-link>
              </div>
            </div> -->

            <!-- <h5 class="form-subtitle2">审核</h5>
            <div class="auditStatus-container">
              <div class="auditStatus-title">审核状态</div>
              <div class="auditStatus-desc">
                <el-radio-group v-model="form.audit_status">
                  <el-radio :label="2">审核通过</el-radio>
                  <el-radio :label="3">审核不通过</el-radio>
                </el-radio-group>
              </div>
              <div class="auditStatus-title">原因</div>
              <div class="audit_reason">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 4, maxRows: 8 }"
                  placeholder="请输入原因"
                  v-model="form.audit_reason"
                />
              </div>
              <div class="auditStatus-title">是否为试点产品</div>
              <div class="auditStatus-desc">
                <el-radio-group v-model="form.try_status">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="2">否</el-radio>
                </el-radio-group>
              </div>
            </div>

            <div class="btns">
              <el-button @click="$router.back()" class="cancel-btn btn"
                >关闭</el-button
              >
              <el-button @click="beforeSubmit" type="primary" class="btn"
                >确定</el-button
              >
            </div> -->
          </div>

          <div class="sbzj-form" style="margin-top: 30px;padding-top: 30px;">
            <h5 class="form-subtitle2">审核</h5>
            <div class="auditStatus-container">
              <div class="auditStatus-item flex">
                <div class="auditStatus-title">审核状态</div>
                <div class="auditStatus-desc">
                  <el-radio-group v-model="form.audit_status">
                    <!-- <el-radio :label="1">待审核</el-radio> -->
                    <el-radio :label="2">审核通过</el-radio>
                    <el-radio :label="3">审核不通过</el-radio>
                  </el-radio-group>
                </div>
              </div>
              <div class="auditStatus-item flex">
                <div class="auditStatus-title">原因</div>
                <div class="audit_reason">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 4, maxRows: 8 }"
                    placeholder="请输入原因"
                    v-model="form.audit_reason"
                  />
                </div>
              </div>
              <!-- <div class="auditStatus-item flex">
                <div class="auditStatus-title" style="line-height: 40px;">
                  产品代码
                </div>
                <div class="audit_reason">
                  <el-input placeholder="请输入产品代码" v-model="form.code" />
                </div>
              </div> -->
              <div class="auditStatus-item flex">
                <div class="auditStatus-title">是否为试点产品</div>
                <div class="auditStatus-desc">
                  <el-radio-group v-model="form.try_status">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="2">否</el-radio>
                  </el-radio-group>
                </div>
              </div>
            </div>

            <div class="btns">
              <el-button @click="$router.back()" class="cancel-btn btn"
                >关闭</el-button
              >
              <el-button @click="beforeSubmit" type="primary" class="btn"
                >确定</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDeclarationInfoById, auditProductApplication } from "@/api/zf";
// import { belongingSceneOptions } from "./sceenList.js";
import { getTractionUnit } from "@/api/pts";
import { getSceenList } from "@/api/login.js";
import configs from "@/config/index";
export default {
  data() {
    return {
      form: {
        company_name: "", // 企业名称
        credit_code: "", // 统一社会信用代码
        company_address: "", // 企业地址
        company_nature: "国有企业", // 企业性质
        registered_capital: "", // 注册资本
        establishment_date: "", // 成立日期
        last_year_revenue: "", // 上年度营收
        financing_situation: "", // 融资情况
        personnel_scale: 0, // 人员规模
        design_persons: 0, // 研发人数
        listed_status: 1, // 上市情况 1：是 0：否
        other_listed: "", // 上市情况-其他
        listed_date: "", // 上市日期  上市了填
        listed_area: "深交所", // 上市地区  上市了才能填
        contact_person: "", // 联系人
        position: "", // 职务
        phone: "", // 电话
        email: "", // 邮箱
        product_name: "", // 产品名称
        sale_type: "买断制", // 销售方式
        other_sale: "", // 销售方式-其他
        secondary_development: 1, // 是否支持二开 1:支持 0：不支持
        trial_use: 1, // 是否支持试用 1：支持 0：不支持
        industry: [], // 所属行业
        sub_industry: [], // 所属细分行业，这里传输给后端为xxx,xxx,xxx
        product_pull: [], //产品关联牵引单位
        scene: "", // 所属场景
        product_type: "研发设计类（CAD/CAE/CAM/CAPP/PLM/PDM/其他）", // 产品类型
        function_around: [], //应用环节
        functionality: "", // 功能性
        expansibility: "", // 拓展性
        security: "", // 安全性
        portability: "", // 可移植性
        usability: "", // 易用性
        innovativeness: "", // 创新性
        deployment_architecture: "", // 部署架构
        deployment_url: "", // 部署架构图
        security_description: "", // 安全说明
        independent_description: "", // 自主知识产权说明
        price_description: "", // 价格说明
        award_situation: "", // 获奖情况
        related_attachment: "[]", // 相关附件
        companyProductDeclarationList: [
          {
            case_name: "", // 案例名称
            case_background: "", // 案例背景
            practice_overview: "", // 实践概述
            application_effect: "", // 应用效果
          },
        ],
        // code: "",
      },
      rules: {},

      deploymentFileList: [],
      securityFileList: [], //安全说明附件路径
      independentFileList: [], //自主知识产权
      priceFileList: [], //价格说明
      awardFileList: [], //获奖情况
      baseUrl: "",

      belongingSceneOptions: [],
      tractionUnitList: [],
    };
  },
  created() {
    this.baseUrl = configs.baseUrl;
    this.getInfoById();
  },
  computed: {
    listType() {
      return Number(this.$route.query.type);
    },
    declarationId() {
      return Number(this.$route.query.id);
    },
  },
  mounted() {
    this.getSceenList();
    this.getTractionUnit();
  },
  filters: {
    getCaseIndex(val) {
      let num = "";
      switch (val) {
        case 0:
          num = "一";
          break;
        case 1:
          num = "二";
          break;
        case 2:
          num = "三";
          break;
        case 3:
          num = "四";
          break;
        case 4:
          num = "五";
          break;
        case 5:
          num = "六";
          break;
        case 6:
          num = "七";
          break;
        case 7:
          num = "八";
          break;
        case 8:
          num = "九";
          break;
        case 9:
          num = "十";
          break;
      }
      return num;
    },
  },
  methods: {
    getTractionUnit() {
      getTractionUnit(1).then((res) => {
        console.log("res getTractionUnit", res);
        if (res.code == 200) {
          this.tractionUnitList = res.data.selectRespList;
        }
      });
    },
    getSceenList() {
      getSceenList().then((res) => {
        console.log("res", res);
        this.belongingSceneOptions = res.data;
      });
    },
    getInfoById() {
      getDeclarationInfoById({ id: this.declarationId }).then((res) => {
        if (res.code == 1) {
          console.log(res, "getDeclarationInfoById");
          res.data.industry = res.data.industry
            ? res.data.industry.split(",")
            : [];
          res.data.sub_industry = res.data.sub_industry
            ? res.data.sub_industry.split(",")
            : [];
          res.data.function_around = res.data.function_around
            ? res.data.function_around.split(",")
            : [];
          res.data.product_pull = res.data.product_pull
            ? res.data.product_pull.split(",")
            : [];
          this.deploymentFileList = JSON.parse(res.data.deployment_url);
          this.securityFileList = res.data.security_url
            ? JSON.parse(res.data.security_url)
            : []; //安全说明附件路径
          this.independentFileList = res.data.independent_url
            ? JSON.parse(res.data.independent_url)
            : []; //自主知识产权
          this.priceFileList = res.data.price_url
            ? JSON.parse(res.data.price_url)
            : []; //价格说明
          this.awardFileList = res.data.award_url
            ? JSON.parse(res.data.award_url)
            : []; //获奖情况
          // console.log(
          //   "deploymentFileList===========>",
          //   this.deploymentFileList
          // );

          res.data.scene = res.data.scene ? JSON.parse(res.data.scene) : [];
          this.form = res.data;
          this.form.audit_reason = res.data.audit_reason
            ? res.data.audit_reason
            : "";
          this.form.companyProductDeclarationList = JSON.parse(
            res.data.product_case
          );
        }
      });
    },
    gotoIndex() {
      this.$router.push({
        path: "/government/sbzj",
      });
    },
    gotoList() {
      this.$router.push({
        path: "/government/sbzj/list",
        query: {
          type: this.listType,
        },
      });
    },

    beforeSubmit() {
      let pdata = {
        id: this.form.id, //该条产品申报id
        audit_status: this.form.audit_status, //审核状态 1：待审核 2：审核通过 3：审核不通过
        audit_reason: this.form.audit_reason, //审核不通过原因
        try_status: this.form.try_status, //1:试点 2：非试点
        // code: this.form.code,
      };
      console.log("pdata", pdata);
      auditProductApplication(pdata).then((res) => {
        console.log(res);
        if (res.code == 1) {
          this.$message.success("操作成功");
          setTimeout(() => {
            this.gotoList();
          }, 1000);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #eff4fe;

  .same-container {
    width: 100%;
    height: 100%;
    background: #f0f5ff;
    background-size: 1920px 1000px;
    background-attachment: fixed;

    .size-container {
      width: 82%;
      height: 100%;
      margin: 0 auto;
      min-height: 700px;

      .public-container {
        overflow: hidden;
        position: relative;
        width: 100%;
        min-width: 1200px;
        margin: auto;
        padding: 10px;
        box-sizing: border-box;

        .sbzj-form {
          width: 100%;
          background-color: #fff;
          border-radius: 10px;
          padding: 0 208px 50px;
          box-sizing: border-box;
          .ss-radio {
            width: calc(100% - 128px);
            border: 1px solid #e3e3e3;
            border-radius: 5px;
            padding: 20px 15px;
            box-sizing: border-box;
            margin-top: 10px;
            margin-left: 128px;
          }
          .ss-input {
            ::v-deep .el-input__inner {
              height: 18px;
              width: 100px;
              padding: 0 10px;
              border-radius: 0;
              border: none;
              border-bottom: 1px solid #efefef;
            }
          }
          .addItem {
            color: #2282f9;
            img {
              height: 50px;
              width: 50px;
              margin-bottom: 10px;
            }
          }
          .cancel-btn {
            height: 38px;
            background-color: #e2e2e2;
            border: none;
            color: #333;
            &:hover {
              background-color: #efefef;
              color: #333;
            }
          }
          .btns {
            margin-top: 38px;
            .draft-btn {
              height: 38px;
              background-color: #ea7100;
              border: none;
              color: #fff;
              &:hover {
                background-color: #ffb774;
                color: #fff;
              }
            }
            .btn {
              margin-right: 10px;
            }
          }
          .related-attachment-list {
            padding: 20px 30px;
            border: 1px solid #e3e3e3;
            border-radius: 8px;
            margin: 20px 0;
            min-height: 88px;
            .el-link {
              margin-bottom: 8px;
            }
          }
          .product-type-radio,
          .company-nature-radio {
            .el-radio {
              margin-bottom: 10px;
            }
          }
          .form-desc {
            line-height: 1.5;
            font-size: 14px;
            margin-left: 20px;
          }
          .form-subtitle3 {
            font-size: 16px;
            font-weight: 600;
          }
          .form-subtitle2 {
            font-size: 18px;
            color: #2282f9;
            font-weight: 600;
            height: 38px;
            line-height: 38px;
            position: relative;
            margin-left: 20px;
            &::before {
              content: "";
              height: 28px;
              width: 12px;
              background-color: #2282f9;
              position: absolute;
              bottom: 0;
              top: 0;
              left: -24px;
              margin: auto 0;
            }
          }

          .file-content {
            // border: 1px solid black;
            margin-top: 10px;

            img {
              width: 100%;
            }
          }

          .form-subtitle {
            font-size: 22px;
            font-weight: 600;
            height: 48px;
            line-height: 48px;
          }
          .form-title {
            font-weight: 600;
            font-size: 26px;
            text-align: center;
            height: 88px;
            line-height: 88px;
            position: relative;
            &::after {
              content: "";
              height: 4px;
              width: 148px;
              border-radius: 4px;
              background-color: #205dc3;
              position: absolute;
              bottom: 8px;
              left: 0;
              right: 0;
              margin: 0 auto;
            }
          }
        }
        .top-nav {
          margin-bottom: 30px;
        }
        .auditStatus-container {
          .auditStatus-item {
            padding: 20px 0 0;
            width: 100%;
          }
          .audit_reason {
            width: calc(100% - 188px);
          }
          // margin-top: 30px;
          .auditStatus-title {
            // margin-top: 30px;
            // margin-bottom: 10px;
            width: 188px;
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>
