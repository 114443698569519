<template>
  <div class="main" :style="$bgImg('sihua_bg.png')">
    <div class="container">
      <div class="add-btn">
        <div
          class="add-btn-body pointer flex column center"
          @click="goAddProduct"
        >
          <img class="add-btn-icon" :src="'/addContent.png' | staticMedia" />
          <span class="add-btn-title">发布产品</span>
        </div>
      </div>
      <div class="product-list" v-if="productList.length > 0">
        <div
          class="product-item pointer shadow"
          v-for="(item, index) in productList"
          :key="index"
          @click="goProductDetail(item.id)"
        >
          <div class="flex column">
            <div class="product-t flex bewteen">
              <div class="product-t-img overflowH">
                <img
                  class="product-t-image image100"
                  :src="item.image | contentMedia"
                  alt=""
                />
              </div>
              <div class="product-t-title">
                <div class="product-t-title-t line1">
                  {{ item.product_name }}
                </div>
                <div
                  class="product-t-title-b line3"
                >{{ item.yi_xian }}</div>
              </div>
              <div class="product-t-btns flex column center">
                <el-button
                  class="primaryBtn1 btn"
                  @click.stop="goUpdateProduct(item.id)"
                  >修改</el-button
                >
                <el-button
                  class="cancleBtn1 btn"
                  @click.stop="beforeDelProductList(item.id)"
                  >删除</el-button
                >
              </div>
            </div>
            <div class="product-b flex bewteen-wrapper">
              <div class="flex vertical qyname">
                <img class="bottom-icon" :src="'/qiye.png' | staticMedia" />
                <span class="line1">企业名称：{{ item.qi_name }}</span>
              </div>
              <div class="flex vertical createtime">
                <img class="bottom-icon" :src="'/time.png' | staticMedia" />
                <span class="line1" style="width: fit-content;">{{
                  formatDateTime(item.createtime)
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="product-list" v-else>
        <div class="list-emp-text">
          暂无数据
        </div>
      </div>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :current-page="page"
        :page-size="pageSize"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { formatDateTime } from "@/utils/common.js";
import { getProductList } from "@/api/pts";
export default {
  components: {},
  data() {
    return {
      productList: [],
      page: 1,
      pageSize: 10,
      total: 0,
    };
  },
  created() {},
  mounted() {
    this.getProductList();
  },
  methods: {
    handleCurrentChange(page) {
      this.page = page;
      this.getProductList();
    },
    beforeDelProductList(id) {
      this.$Delete("post", "/index/company/del", { id }).then((res) => {
        console.log("res", res);
        this.getProductList();
      });
    },
    getProductList() {
      let _this = this;
      let pdata = {
        page: _this.page,
        pageSize: _this.pageSize,
      };
      getProductList(pdata).then((res) => {
        console.log("res", res);
        _this.productList = res.data.data;
        _this.total = res.data.total;
      });
    },
    goProductDetail(id) {
      this.$router.push({
        path: "/company/product/detail",
        query: { id },
      });
    },
    goAddProduct() {
      this.$router.push({
        path: "/company/product/add",
        query: {
          // type赋值为true表示添加
          type: true,
        },
      });
    },
    goUpdateProduct(id) {
      this.$router.push({
        path: "/company/product/edit",
        query: {
          // type赋值为false表示添加
          type: false,
          id,
        },
      });
    },
    formatDateTime(time) {
      return formatDateTime(time);
    },
  },
};
</script>
<style scoped lang="less">
.el-button + .el-button {
  margin-left: 0;
  margin-top: 10px;
}
.container {
  position: relative;
  .product-list {
    width: 84%;
    margin: 0 auto;
    min-height: 388px;
    .product-item {
      border-radius: 10px;
      background-color: #fff;
      padding: 30px 20px 20px;
      margin-bottom: 20px;
      position: relative;
      &::before {
        content: "";
        height: 22px;
        width: 2px;
        background-color: #205dc3;
        position: absolute;
        left: 0;
        top: 20px;
      }

      &:hover {
        .product-t-image {
          transform: scale(1.2);
          transition: all 0.3s;
        }
        .product-t-title-t {
          color: #205dc3 !important;
        }
      }
      .product-t {
        .product-t-img {
          width: 194px;
          height: 122px;
          .product-t-image {
            transition: all 0.3s;
          }
        }
        .product-t-title {
          width: calc(100% - 194px - 200px);
          padding: 0 20px;
          box-sizing: border-box;
          .product-t-title-t {
            font-weight: 600;
            font-size: 20px;
            margin-bottom: 8px;
            display: inline-block;
            width: 100%;
            line-height: 30px;
            color: #333;
          }
          .product-t-title-b {
            font-size: 14px;
            color: #999;
          }
        }
        .product-t-btns {
          width: 200px;
          height: 124px;
        }
      }
      .product-b {
        margin-top: 20px;
        padding: 20px 8px 0;
        border-top: 1px solid #999;
        .qyname {
          width: calc(100% - 250px);
        }
        .createtime {
          text-align: right;
          min-width: 250px;
          justify-content: flex-end;
        }
      }
    }
  }
}
</style>
