<template>
  <div class="MHHeader">
    <div class="top_main">
      <div class="top">
        <div class="top_l">“穗智转”公共服务平台</div>
        <!-- <div class="top_l">中小企业数字化转型试点中心</div> -->
        <div class="top_r">
          <div
            class="top_r_s flex bewteen-wrapper"
            v-if="!szz_v1_token_expiration_time"
            @click="goLogin"
          >
            <img
              :src="'desktop.png' | staticMedia"
              class="chagne2img"
              style="width: 18px; height: 18px;"
            />
            <a href="javascript:;" class="top_r_s_a">办事中心</a>
            <a href="javascript:;" class="top_r_s_a">登录|注册</a>
          </div>
          <div
            class="top_r_s flex bewteen-wrapper"
            v-if="szz_v1_token_expiration_time"
          >
            <div @click="goCenter" class="flex bewteen-wrapper">
              <img
                :src="'desktop.png' | staticMedia"
                class="chagne2img"
                style="width: 18px; height: 18px;"
              />
              <a href="javascript:;" class="top_r_s_a">办事中心</a>
            </div>
            <a href="javascript:;" @click="logout" class="top_r_s_a">退出</a>
          </div>
        </div>
      </div>
    </div>
    <div class="smallheigh"></div>
    <div class="menu">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-b"
        mode="horizontal"
        @select="handleSelect"
      >
        <el-menu-item index="1">政策咨询</el-menu-item>
        <el-menu-item index="2">优秀案例</el-menu-item>
        <el-submenu index="3">
          <template slot="title">信息专区</template>
          <el-menu-item index="3-1">需求列表</el-menu-item>
          <el-menu-item index="3-2">服务商介绍</el-menu-item>
        </el-submenu>
        <el-submenu index="4">
          <template slot="title">应用市场</template>
          <el-menu-item index="4-1">解决方案</el-menu-item>
          <el-menu-item index="4-2">产品</el-menu-item>
        </el-submenu>
        <el-menu-item index="5">四化课堂</el-menu-item>
      </el-menu>
    </div>
  </div>
</template>
<script>
export default {
  name: "GatewayHeader",
  data() {
    return {
      token: "",
      user: {
        is_stu: 1,
      },
      szz_v1_token_expiration_time: "",
      activeIndex: "1",
    };
  },
  mounted() {
    this.token = localStorage.getItem("token");
    this.user = localStorage.getItem("user");
    this.szz_v1_token_expiration_time = sessionStorage.getItem(
      "szz_v1_token_expiration_time"
    );
  },
  methods: {
    handleSelect() {},
    goCenter() {
      console.log("gogogo");
    },
    goLogin() {
      console.log("gogogoLogin");
      this.$router.push({
        path: "/login",
      });
    },
    logout() {
      sessionStorage.setItem("szz_v1_token_expiration_time", "");
      this.$router.push({
        path: "/login",
      });
    },
  },
};
</script>

<style scoped lang="less">
::v-deep .menu {
  background-color: #205dc3;
  .el-menu-b {
    background-color: #205dc3;
    color: #fff;
    width: 85%;
    margin: 0 auto;
    border-bottom: none;
  }
  .el-menu--horizontal > .el-menu-item,
  .el-menu--horizontal > .el-submenu .el-submenu__title {
    color: #fff;
    font-size: 16px;
  }
  .el-submenu__title i {
    color: #fff;
  }
  .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
    border-bottom: 1px solid #205dc3;
  }
  .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
  .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
    background-color: transparent;
    color: #fff;
    border-bottom: 2px solid #fff !important;
  }
  .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus {
    background-color: #205dc3 !important;
    color: #fff !important;
  }
}
.el-menu--horizontal .el-menu .el-menu-item,
.el-menu--horizontal .el-menu .el-submenu__title {
  color: #333;
  margin: 0 15px;
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  border-bottom: 1px solid #205dc3 !important;
}
.top_main {
  background: #205dc3;
  color: #ffffff;
  height: 80px !important;
  box-sizing: border-box;
  .top {
    width: 84%;
    min-width: 1200px;
    height: 80px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .top_l {
      font-size: 30px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: 600;
      color: #ffffff;
    }
    .top_r {
      cursor: pointer;
      width: fit-content;
      .top_r_s_a {
        color: #fff;
        text-decoration: none;
        margin-left: 8px;
      }
    }
  }
}
.smallheigh {
  height: 1px;
  background: #2f71df;
}
</style>
