import CryptoJS from "crypto-js";
import config from "../config";
import { Message } from "element-ui";
import { IMG_CONFIG } from "@/config/env";

// 封装删除引入
import api from "@/api/index";
export const getParam = () => {
  let getqyinfo = window.location.href.split("?")[1] || "";
  let getqys = getqyinfo.split("&");
  let obj = {}; //创建空对象，接收截取的参数

  for (let i = 0; i < getqys.length; i++) {
    // console.log(i)
    let item = getqys[i].split("=");
    let key = item[0];
    let value = item[1];
    obj[key] = value;
  }
  return obj;
};

export const timeToFormat = (dateTime) => {
  let date = new Date(parseInt(dateTime * 1000));
  const Y = date.getFullYear() + "-";
  const M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";
  const D = date.getDate() + " ";
  // const h = date.getHours() + ':';
  // const m = date.getMinutes() + ':';
  // const s = date.getSeconds();
  return Y + M + D;
};

export const formatDateTime = (dateTime) => {
  if (dateTime == "" || !dateTime) {
    return "";
  }
  dateTime = dateTime * 1000;
  let date = new Date(dateTime);
  let y = date.getFullYear();
  let m = date.getMonth() + 1;
  m = m < 10 ? "0" + m : m;
  let d = date.getDate();
  d = d < 10 ? "0" + d : d;
  let h = date.getHours();
  h = h < 10 ? "0" + h : h;
  let minute = date.getMinutes();
  minute = minute < 10 ? "0" + minute : minute;
  let second = date.getSeconds();
  second = second < 10 ? "0" + second : second;
  return y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
};

export const phoneCheck = (val) => {
  let myreg =
    /^(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(17[0-9]{1})|(18[0-9]{1}))+\d{8})$/;
  return myreg.test(val);
};

export const emailCheck = (val) => {
  let myreg =
    /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
  return myreg.test(val);
};

export const aesEncrypt = (encryptString) => {
  var key = CryptoJS.enc.Utf8.parse(config.aesPrivateKey);
  var srcs = CryptoJS.enc.Utf8.parse(encryptString);
  var encrypted = CryptoJS.AES.encrypt(srcs, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
};

export const deepClone = (target) => {
  const map = new WeakMap();

  function isObject(target) {
    return (
      (typeof target === "object" && target) || typeof target === "function"
    );
  }

  function clone(data) {
    if (!isObject(data)) {
      return data;
    }
    if ([Date, RegExp].includes(data.constructor)) {
      return new data.constructor(data);
    }
    if (typeof data === "function") {
      return new Function("return " + data.toString())();
    }
    const exist = map.get(data);
    if (exist) {
      return exist;
    }
    if (data instanceof Map) {
      const result = new Map();
      map.set(data, result);
      data.forEach((val, key) => {
        if (isObject(val)) {
          result.set(key, clone(val));
        } else {
          result.set(key, val);
        }
      });
      return result;
    }
    if (data instanceof Set) {
      const result = new Set();
      map.set(data, result);
      data.forEach((val) => {
        if (isObject(val)) {
          result.add(clone(val));
        } else {
          result.add(val);
        }
      });
      return result;
    }
    const keys = Reflect.ownKeys(data);
    const allDesc = Object.getOwnPropertyDescriptors(data);
    const result = Object.create(Object.getPrototypeOf(data), allDesc);
    map.set(data, result);
    keys.forEach((key) => {
      const val = data[key];
      if (isObject(val)) {
        result[key] = clone(val);
      } else {
        result[key] = val;
      }
    });
    return result;
  }

  return clone(target);
};

export const checkNull = (val, tipText) => {
  if (val == "" || val == null) {
    Message({
      message: tipText,
      type: "error",
    });
    return false;
  } else {
    return true;
  }
};

export const containerImg = (key) => {
  const filteredImageUrl =
    IMG_CONFIG.SERVER + "/assets/addons/official/images/" + key;
  return { backgroundImage: `url(${filteredImageUrl})` };
};

// 删除封装

// 删除弹窗封装

function DeletePath(path, data) {
  return api.post(path, { data });
}
export function Delete_btn(methods, path, params) {
  console.log(methods, path, params);
  return this.$confirm("此操作将永久删除此数据, 是否继续?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(async () => {
      const res = await DeletePath(path, params);
      if (res.code == 1) {
        this.$message.success("删除成功");
        return true;
      }
      if (res.msg == "删除成功") {
        console.log("success");
        this.$message.success("删除成功");
        return true;
      }
    })
    .catch((err) => {
      console.log("err", err);
      if (err == "删除成功") {
        console.log("err");
        this.$message.success("删除成功");
      }
      return false;
    });
}

// 表单验证
export function validateMobile(rule, value, callback) {
  const phoneReg = /^1[2|3|4|5|6|7|8|9][0-9]{9}$/;
  const zuojiReg = /^(0\d{2,3}-\d{7,8})(,0\d{2,3}-\d{7,8})*$/;
  if (!value) {
    return callback(new Error("电话号码不能为空"));
  }
  setTimeout(() => {
    if (phoneReg.test(value) || zuojiReg.test(value)) {
      callback();
    } else {
      callback(new Error("电话号码格式不正确"));
    }
  }, 100);
}

// 转换字符串为encode
export function encodeHTML(str) {
  return str.replace(/[<>"&']/g, function (match) {
    switch (match) {
      case "<":
        return "&lt;";
      case ">":
        return "&gt;";
      case '"':
        return "&quot;";
      case "&":
        return "&amp;";
      case "'":
        return "&#39;";
      // 添加其他需要转换的符号
      default:
        return match;
    }
  });
}

// 转换encode为html字符串
export function encodeToHTML(str) {
  return str.replace(/(&lt;|&gt;|&quot;|&amp;|&#39;)/g, function (match) {
    switch (match) {
      case "&lt;":
        return "<";
      case "&gt;":
        return ">";
      case '&quot;':
        return '"';
      case "&amp;":
        return "&";
      case "&#39;":
        return "'";
      // 添加其他需要转换的符号
      default:
        return match;
    }
  });
}

// 正则判断
export function validateRegex(regex, str) {
  return regex.test(str);
}
