<!-- 当前为移动端富文本配置
TODO：需要修改为PC -->
<template>
  <div style="border: 1px solid #ccc">
    <Toolbar
      style="border-bottom: 1px solid #ccc"
      :editor="editor"
      :defaultConfig="toolbarConfig"
      :mode="mode"
    />
    <Editor
      style="height: 310px; overflow-y: hidden"
      v-model="html"
      :defaultConfig="editorConfig"
      :mode="mode"
      @onCreated="onCreated"
      @onChange="onChange"
    />
  </div>
</template>

<script>
import { UploadFile } from "@/api/login";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
// import { MessageBox } from 'element-ui'
import configs from "@/config/index";
// import {
//   Boot,//下面加入附件上传、进行回车事件需要使用到
//   // createEditor,//创建编译器
//   // createToolbar,//创建顶部工具
//   // IEditorConfig,
//   // IDomEditor,
//   // IToolbarConfig,
// } from "@wangeditor/editor";
// import attachmentModule from "@wangeditor/plugin-upload-attachment";//wangeditor的附件插件
export default {
  components: { Editor, Toolbar },
  props: {
    defVal: {
      type: String,
    },
    // editorId:{
    //   type: String,
    //   default:
    // }
  },
  data() {
    return {
      // 富文本框
      editor: null,
      html: "",
      editorConfig: {
        placeholder: "请输入内容...", // 所有的菜单配置，都要在 MENU_CONF 属性下
        // 在编辑器中，点击选中“附件”节点时，要弹出的菜单
        hoverbarKeys: {
          attachment: {
            menuKeys: ["downloadAttachment"], // “下载附件”菜单
          },
        },
        MENU_CONF: {
          // “上传附件”菜单的配置
          uploadAttachment: {
            customUpload(file, insertFn) {
              let formData = new FormData();
              formData.append("file", file);
              //upload是上传附件接口
              UploadFile(formData).then((res) => {
                if (res.code == 1) {
                  //defaultconfigUrl是接口地址
                  console.log(res.data);
                  insertFn(
                    `${res.data.name}`,
                    configs.baseUrl + res.data.fullurl
                  );
                }
              });
            },
          },
          // 配置上传图片
          uploadImage: {
            customUpload: this.uploadImg,
          },
          // 继续其他菜单配置...
        },
      },
      mode: "default", // or 'simple'
      toolbarConfig: {
        excludeKeys: [
          "todo",
          "blockquote",
          "uploadVideo",
          "table",
          "code",
          "fullScreen",
          "numberedList",
          "fontFamily",
          "bulletedList",
          "uploadVideo",
          "codeBlock",
          "insertTable",
          "group-video",
          "headerSelect",
          "bgColor",
          "group-more-style",
          "divider",
          "group-indent",
          "insertVideo",
        ],
        // 插入哪些菜单
        insertKeys: {
          index: 24, // 自定义插入的位置
          keys: ["uploadAttachment"], // “上传附件”菜单
        },
      },
    };
  },
  watch: {
    defVal: {
      handler(newVal) {
        console.log("new", newVal);
        // setTimeout(() => {
        this.html = newVal;
        // }, 1000);
      },
      deep: true,
    },
  },
  beforeUnmount() {
    if (this.editor) {
      this.editor.destroy();
    }
  },
  mounted() {},
  methods: {
    encodeHTML(str) {
      return str.replace(/[<>"&']/g, function(match) {
        switch (match) {
          case "<":
            return "&lt;";
          case ">":
            return "&gt;";
          case '"':
            return "&quot;";
          case "&":
            return "&amp;";
          case "'":
            return "&#39;";
          // 添加其他需要转换的符号
          default:
            return match;
        }
      });
    },
    onChange(cc) {
      console.log("cc", cc);

      // this.$emit("editorInput", this.encodeHTML(this.html));
      this.$emit("editorInput", this.html);
    },
    uploadImg(file, insertFn) {
      // file 即选中的文件
      // 自己实现上传，并得到图片 url alt href
      let _this = this;
      let fileObj = {};
      let formData = new FormData();
      formData.append("file", file);
      UploadFile(formData)
        .then((res) => {
          console.log("res", res);
          if (res.code == 1) {
            _this.$message.success("上传成功");
            fileObj = res.data;
            // 最后插入图片
            console.log(res);
            insertFn(
              configs.baseUrl + fileObj.fullurl,
              fileObj.name,
              configs.baseUrl + fileObj.fullurl
            );
          }
        })
        .catch((err) => {
          console.log("err", err);
        })
        .finally(() => {});
    },
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>

<style lang="less" scoped>
::v-deep .w-e-text-placeholder {
  top: 7.5px;
}
</style>
