<template>
  <div class="aboutUs">
    <div class="content_box">
      <div class="title">关于我们</div>
      <div class="subtitle">信息来源：本网 时间：2023-07-25</div>
      <div class="body">
        <p>
          “穗智转”公共服务平台由广州市工业和信息化局指导建设，作为广州市“四化”赋能专项行动的门户网站、广州市制造业“四化”促进联盟线上基地，目前已经挂接在广州市工信局官网首页，重点面向政府、企业和“四化”赋能平台打造了一个资源对接、信息赋能的公共服务平台，围绕企业“不敢转”“不想转”“不会转”难题，为企业提供“四化”领域的专业咨询、政策服务和“四化”平台产品及数字化解决方案，有力推动制造业企业和“四化”平台服务商供需对接和政府精准施策。
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AboutUs",
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>
<style scoped lang="less">
.content_box {
  width: 66%;
  margin: 0 auto;
  border-radius: 5px;
  padding: 0 40px 50px;
  min-height: 521px;
  box-sizing: border-box;
  border: 1px solid #898989;
}

.content_box .title {
  font-size: 36px;
  font-weight: 600;
  color: #205dc3;
  line-height: 96px;
  text-align: center;
}

.content_box .subtitle {
  color: #898989;
  font-size: 14px;
  line-height: 36px;
  border-bottom: 1px solid #898989;
}

.content_box .body {
  padding: 30px 40px;
}

.content_box .body p {
  font-size: 18px;
  line-height: 36px;
  color: #3d3d3d;
  text-indent: 2em;
}
</style>
