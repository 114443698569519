<template>
  <div class="main" :style="$bgImg('sihua_bg.png')">
    <div class="container">
      <div class="add-btn">
        <div
          class="add-btn-body pointer flex column center"
          @click="getSZHQuestionResult"
        >
          <img class="add-btn-icon" :src="'/watchresult.png' | staticMedia" />
          <span class="add-btn-title">查看问卷结果</span>
        </div>
      </div>
      <div class="question-list">
        <div
          class="question-header flex column horizontally"
          :style="questionHeaderImg"
        >
          <h3 class="question-header-title">中小企业数字化水平自评测</h3>
          <div class="question-header-tips">
            《中小企业数字化水平评测指标（2024年版）》从数字化基础、经营、管理、成效四个维度综合评估。其中，数字化基础、管理和成效三个维度采用评分的方式确定等级，数字化经营部分用场景等级判定的方式确定等级。依据数字化基础、管理及成效评测得分和数字化经营应用场景等级判定（须同时满足两部分要求），将中小企业数字化水平划分为四个等级：一级（初始级）、二级（规范级）、三级（集成级）、四级（协同级）。
          </div>
        </div>
        <div class="question-form">
          <el-form
            ref="questionForm"
            label-width="40px"
            size="mini"
            class="question-form2"
          >
            <div v-for="(item, index) in formList" :key="index">
              <h4 class="question-form2-h4 first">
                {{ index + 1 }}、{{ item.name }}
              </h4>
              <template v-if="item.selftQuestionStemList.length > 0">
                <div
                  v-for="(sItem, sIndex) in item.selftQuestionStemList"
                  :key="index + '.' + sIndex"
                  class="pl24"
                >
                  <h5 class="question-form2-h5 second">
                    {{ index + 1 }}.{{ sIndex + 1 }} {{ sItem.name }}
                  </h5>
                  <!--单选题-->
                  <el-form-item
                    v-if="sItem.multiple == 2 && sItem.selftQuestionStemDetails"
                  >
                    <el-radio-group v-model="sItem.answer" class="mt5">
                      <el-radio
                        v-for="(answer,
                        aIndex) in sItem.selftQuestionStemDetails"
                        :key="aIndex"
                        :label="answer.id"
                      >
                        {{ answer.chose + ". " + answer.choseDetail }}
                      </el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <!--多选题-->
                  <!-- {{ sItem.selftQuestionStemDetails }} -->
                  <el-form-item
                    v-if="sItem.multiple == 1 && sItem.selftQuestionStemDetails"
                  >
                    <el-checkbox-group v-model="sItem.answer">
                      <el-checkbox
                        v-for="(answer,
                        aIndex) in sItem.selftQuestionStemDetails"
                        :key="aIndex"
                        :label="answer.id"
                      >
                        {{ answer.chose + ". " + answer.choseDetail }}
                      </el-checkbox>
                    </el-checkbox-group>
                  </el-form-item>
                  <!-- 填空题 -->
                  <el-form-item
                    v-if="
                      sItem.isOne == 1 &&
                        (sItem.remark != '' || sItem.remark != null)
                    "
                  >
                    <div class="fillOptions">
                      <div
                        class="fillOption"
                        v-for="(item, index) in sItem.remark.split(/\[\]/)"
                        :key="index"
                      >
                        {{ item }}
                        <el-input
                          v-if="index < sItem.count"
                          v-model="sItem.fill[index]"
                          style="width: 128px;"
                        />
                      </div>
                    </div>
                  </el-form-item>
                </div>
              </template>
            </div>
            <div class="question-form2-btns flex horizontally">
              <el-button class="primaryBtn1 btn" @click="saveDraft"
                >保存草稿</el-button
              >
              <el-button class="primaryBtn1 btn" @click="getDraft"
                >获取草稿</el-button
              >
              <el-button class="primaryBtn1 btn" @click="handleSubmit"
                >提交</el-button
              >
              <el-button class="cancleBtn1 btn" @click="handlePrevious"
                >上一步</el-button
              >
            </div>
          </el-form>
        </div>
      </div>
    </div>
    <el-dialog
      title="选择问卷结果"
      :visible.sync="resultDialog"
      width="30%"
      :close-on-click-modal="false"
    >
      <div class="result-btn flex vertical" @click="getSZHQuestionResult">
        <img
          class="result-btn-img"
          :src="'/szh_wj_nav.png' | staticMedia"
          alt=""
        />
        中小企业数字化水平自评
      </div>
    </el-dialog>
    <question-result ref="questionResult"></question-result>
    <!-- <el-dialog
      title="中小企业数字化水平自评测"
      :visible.sync="shuZiHuaResultDialog"
      width="788px"
      top="3vh"
      :close-on-click-modal="false"
    >
      <div class="shuzihua-result">
        <div class="shuzihua-result-img">
          <img
            class="shuzihua-result-image"
            :src="'/resultbac.png' | staticMedia"
            alt=""
          />
        </div>
      </div>
    </el-dialog> -->
    <Footer></Footer>
  </div>
</template>
<script>
import {
  getSZHQuestionList,
  getTradeOptions,
  getTradeXFOptions,
  getQuestionAll,
  addSelftResult,
  getSelftResult,
  createSelftDraf,
  getSelftDraft,
} from "@/api/qy";
import QuestionResult from "./components/questionResult.vue";
export default {
  components: { QuestionResult },
  data() {
    return {
      // Dialog
      resultDialog: false,
      shuZiHuaResultDialog: false,

      title: "中小企业数字化水平自评测",

      // 企业信息表格（非问卷
      infoForm: {
        qi_name: "",
        qi_address: "",
        hy1: "",
        hy2: "",
        qi_username: "",
        qi_telephone: "",
        sys: "",
        srs: "",
        is_new: "",
        is_new_level: "",
        is_new_money: "",
        new_money: "",
        introduction: "",
        new_project: "",
        new_need_info: "",
        is_has: "",
      },
      formList: [],
      formListResult: [],
      // 行业选项列表
      tradeOptions: [],
      // 细分行业选项列表
      trade_xf_Options: [],
      // 问卷结果表格数据
      tipList: [
        {
          name: "数字化成效",
          statistical: "1.00",
        },
        {
          name: "数字化管理",
          statistical: "1.10",
        },
        {
          name: "数字化经营",
          statistical: "2.10",
        },
      ],
      // 问卷结果表格数据
      dataList: {
        total: "200.00",
        level: "1",
      },
      option: {
        tooltip: {
          show: true,
        },
        radar: [
          {
            indicator: [
              { text: "基础要素", color: "#205dc3", min: 0, max: 10 },
              { text: "绿色化提升", color: "#205dc3", min: 0, max: 10 },
              { text: "智能化制造", color: "#205dc3", min: 0, max: 10 },
              { text: "网络化协同", color: "#205dc3", min: 0, max: 10 },
              { text: "数字化转型", color: "#205dc3", min: 0, max: 10 },
            ],
            center: ["50%", "50%"],
            radius: 215,
            startAngle: 90,
            splitNumber: 4,
            shape: "circle",
            scale: true,
            axisName: {
              formatter: "{value}",
              color: "#205dc3",
            },
            splitArea: {
              show: true,
              areaStyle: {
                color: ["#64AFE945", "#64AFE990", "#64AFE9", "#428BD4"],
                shadowColor: "rgba(0, 0, 0, 0.2)",
                shadowBlur: 10,
              },
            },
            axisLine: {
              lineStyle: {
                color: "rgba(211, 253, 250, 0.8)",
              },
            },
            splitLine: {
              lineStyle: {
                color: "rgba(211, 253, 250, 0.8)",
              },
            },
          },
        ],
        series: [
          {
            type: "radar",
            lineStyle: {
              width: 4,
            },
            emphasis: {
              lineStyle: {
                width: 5,
              },
            },
            data: [
              {
                value: [10, 20, 10, 10, 10],
                name: "",
              },
            ],
          },
        ],
      },
      answer_: [],
    };
  },
  computed: {
    questionHeaderImg() {
      const filteredImageUrl = this.$options.filters.staticMedia(
        "/text_table_bg.png"
      );
      return {
        backgroundImage: `url(${filteredImageUrl})`,
      };
    },
  },
  created() {},
  mounted() {
    // this.getQuestionList();
    this.getTradeOptions();

    this.getQuestionAll();
  },
  methods: {
    handlePrevious() {
      this.$router.push({
        path: "/selfAssessmentQuestion",
      });
    },
    saveDraft() {
      let _this = this;
      let content = {
        infoForm: _this.infoForm,
        formList: _this.formList,
      };
      let pdata = {
        content: JSON.stringify(content),
        questionId: 2,
        userId: JSON.parse(sessionStorage.getItem("user")).id,
      };
      createSelftDraf(pdata).then((res) => {
        console.log("res", res);
        if (res.code == 200) {
          this.$message.success("保存成功");
        } else {
          this.$message.error("保存失败");
        }
      });
    },
    getDraft() {
      let pdata = {
        questionId: 2,
        userId: JSON.parse(sessionStorage.getItem("user")).id,
      };
      getSelftDraft(pdata).then((res) => {
        if (res.code == 200) {
          this.$message.success("获取成功");
          this.infoForm = JSON.parse(res.data.content).infoForm;
          this.formList = JSON.parse(res.data.content).formList;
        } else {
          this.$message.error("获取失败");
        }
      });
    },
    handleResultAnswer(list) {
      list.forEach((el) => {
        if (el.selftQuestionStemList && el.selftQuestionStemList.length) {
          el.selftQuestionStemList.forEach((e) => {
            if (e.multiple == 2) {
              e.answer = e.selectedOptionIds[0];
            } else {
              e.answer = e.selectedOptionIds;
            }
            e.fill = e.fillOptions;
          });
        }
      });
      return list;
    },
    getSZHQuestionResult() {
      let _this = this;
      // _this.$refs.questionResult.isShowDialog = true;
      _this.$refs.questionResult.getSZHQuestionResult();
      _this.resultDialog = false;
    },
    handleSubmit() {
      var _this = this;
      let loading = _this.$loading({
        lock: true,
        text: "提交中",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const answer = _this.getAnswer();

      // var form = _this.infoForm;

      let pdata = {
        content: JSON.stringify(answer),
        questionId: 2,
        type: 2,
        userId: JSON.parse(sessionStorage.getItem("user")).id,
      };
      addSelftResult(pdata)
        .then((res) => {
          console.log("res", res);
          if (res.code == 200) {
            this.$message.success("提交成功");
          } else {
            this.$message.error("提交失败");
          }
        })
        .catch((err) => {
          console.log("add err", err);

          this.$message.error("提交失败");
        })
        .finally(() => {
          loading.close();
        });
    },
    handleCancle() {},
    openResultDialog() {
      this.resultDialog = true;
    },
    getTradeOptions() {
      let pdata = {
        pid: 0,
      };
      getTradeOptions(pdata).then((res) => {
        this.tradeOptions = res.data.map((e) => {
          return {
            id: e.id,
            name: e.name,
          };
        });
      });
    },
    getWjTrade_xf(id) {
      console.log("123", id);
      let pdata = {
        pid: id,
      };
      getTradeXFOptions(pdata).then((res) => {
        console.log("res", res);
        this.trade_xf_Options = res.data.map((e) => {
          return {
            id: e.id,
            name: e.name,
          };
        });
      });
    },
    handleAnswer(list) {
      list.forEach((el) => {
        if (el.selftQuestionStemList && el.selftQuestionStemList.length) {
          el.selftQuestionStemList.forEach((e) => {
            // if (e.multiple == 2) {
            e.answer = [];
            e.fill = [];
            // }
          });
        }
      });
      return list;
    },
    getQuestionAll() {
      let _this = this;
      getQuestionAll({
        questionId: 2,
        userId: JSON.parse(sessionStorage.getItem("user")).id,
      }).then((res) => {
        // console.log("res", res, _this);
        if (
          res.data.selftQuestionDetailDtoList[0].selftQuestionStemList[0]
            .selectedOptionIds &&
          res.data.selftQuestionDetailDtoList[0].selftQuestionStemList[0]
            .selectedOptionIds.length > 0
        ) {
          _this.formList = _this.handleResultAnswer(
            res.data.selftQuestionDetailDtoList
          );
          _this.infoForm = {
            ...JSON.parse(res.data.newData),
            ...JSON.parse(res.data.userInfo),
          };
          // this.getQuestionList();
        } else {
          _this.formList = _this.handleAnswer(
            res.data.selftQuestionDetailDtoList
          );
        }
      });
    },
    getQuestionList() {
      let _this = this;
      getSZHQuestionList()
        .then((res) => {
          console.log("res", res);
          // _this.formList = _this.handleAnswer(res.data.p_cate);
          _this.infoForm.qi_name = res.data.name;
          _this.infoForm.qi_username = res.data.truename;
          _this.infoForm.qi_telephone = res.data.mobile;
          _this.infoForm.qi_address = `${res.data.province}${res.data.city}${res.data.area}`;
        })
        .finally(() => {});
    },
    getAnswer() {
      var answer = [];
      console.log("this.formlist", this.formList);

      this.formList.forEach(function(el) {
        if (el.selftQuestionStemList && el.selftQuestionStemList.length) {
          el.selftQuestionStemList.forEach(function(e) {
            if (
              e.selftQuestionStemDetails &&
              e.selftQuestionStemDetails.length
            ) {
              let obj = {
                questionDetailId: null,
                questionStemId: null,
                selectedOptionIds: [],
              };
              obj.questionDetailId = e.questionDetailId;
              obj.questionStemId = e.selftQuestionStemDetails[0].questionStemId;
              if (e.answer instanceof Array) {
                obj.selectedOptionIds = e.answer;
              } else {
                obj.selectedOptionIds = [e.answer];
              }
              console.log("e.fill", e.fill);

              obj.fillOptions = e.fill;
              answer.push(obj);
            }
          });
        }
      });

      return answer;
    },
  },
};
</script>
<style lang="less" scoped>
.el-button + .el-button {
  margin-left: 30px;
}
.container {
  position: relative;
  .question-list {
    width: 84%;
    margin: 0 auto;
    .question-header {
      width: 100%;
      height: 300px;
      background-size: 100% 100%;
      color: #fff;
      padding: 0 2% 0 32%;
      margin-bottom: 20px;
      box-sizing: border-box;
      .question-header-title {
        font-size: 24px;
        line-height: 36px;
        height: 36px;
        margin-bottom: 20px;
      }

      .question-header-tips {
        line-height: 32px;
        font-size: 15px;
      }
    }
    .question-form {
      background-color: #fff;
      padding: 40px 200px;
      border-radius: 10px;
      .question-form-title {
        position: relative;
        margin-bottom: 88px;
        font-size: 24px;
        text-align: center;
        &::before {
          content: "";
          width: 300px;
          height: 4px;
          position: absolute;
          display: inline-block;
          width: 250px;
          height: 4px;
          background-color: #205dc3;
          bottom: -10px;
          right: 0;
          left: 0;
          margin: 0 auto;
          border-radius: 3px;
        }
      }
      .question-form-item {
        .question-form-item-unit {
          display: inline-block;
          width: 35px;
          text-align: left;
          margin-left: 10px;
          font-size: 14px;
          color: #606266;
        }
      }
      .el-form {
        width: 84%;
      }
      .question-form2 {
        .question-form2-h4,
        .question-form2-h5 {
          font-size: 14px;
          color: #606266;
        }
        .first {
          padding: 8px 0;
        }
        .second {
          padding: 5px 0;
          text-indent: 2rem;
        }
        .third {
          padding: 5px 0;
          text-indent: 4rem;
          line-height: 1.5;
        }
        .el-form-item--mini.el-form-item {
          padding: 0 2rem;
        }
        .el-radio {
          margin-bottom: 10px;
        }
        .el-checkbox {
          display: flex;
          white-space: pre-wrap;
          .el-checkbox__input {
            padding-top: 3px;
          }
        }
      }
    }
  }
}
.result-btn {
  width: fit-content;
  height: 80px;
  font-size: 18px;
  color: #333;
  padding: 0 10px;
  &:hover {
    color: #fff;
    background-color: #205dc3;
    border-radius: 10px;
  }
  .result-btn-img {
    width: 46px;
    height: 46px;
    margin-right: 10px;
  }
}
.shuzihua-result {
  padding: 0 50px 10px;
  .shuzihua-result-image {
    width: 100%;
  }
  .shuzihua-part {
    padding: 10px 0;
    .shuzihua-part-title {
      font-size: 20px;
      font-weight: 600;
      color: #333;
      .blue-point {
        width: 10px;
        height: 10px;
        background-color: #205dc3;
        border-radius: 10px;
        margin: 0 5px;
      }
    }
    .shuzihua-part-text {
      line-height: 1.5;
      margin-top: 10px;
      margin-left: 20px;
      color: #333;
      .shuzihua-part-text-weight {
        color: #333;
        font-weight: 600;
      }
    }
    .shuzihua-part-table {
      width: 373px;
      margin: 10px auto;
      border: 1px solid #d8d8d8;
      .shuzihua-part-table-title {
        .shuzihua-table-title-item {
          width: 186px;
          height: 46px;
          line-height: 46px;
          background-color: #205dc3;
          text-align: center;
          color: #fff;
          border-right: 1px solid #d8d8d8;
          &:last-child {
            border-right: none;
          }
        }
      }
      .shuzihua-part-table-list {
        &:last-child {
          .shuzihua-table-list-item {
            border-bottom: none;
          }
        }
        .shuzihua-table-list-item {
          width: 186px;
          height: 56px;
          line-height: 56px;
          color: #333;
          text-align: center;
          border-bottom: 1px solid #d8d8d8;
          border-right: 1px solid #d8d8d8;
          &:last-child {
            border-right: none;
          }
        }
      }
    }
    .shuzihua-echarts {
      width: 600px;
      height: 500px;
      margin: 10px auto;
    }
  }
}
.fillOptions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .fillOption {
    display: flex;
    align-items: center;
  }
  ::v-deep .el-input__inner {
    border: none;
    border-bottom: 1px solid #dcdfe6;
    border-radius: 0;
    &:hover {
      border: none;
      border-bottom: 1px solid #c0c4cc;
      border-radius: 0;
    }
  }
}
.el-radio-group {
  width: 100%;
  .el-radio {
    text-wrap: wrap;
  }
}
</style>
