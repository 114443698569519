<template>
  <div class="main" :style="$bgImg('mdgs_bg.png')">
    <div class="qiye">
      <div class="qiyetop flex">
        <div class="qiye_top_souguo">
          <div class="search_box">
            <img :src="'search_icon.png' | staticMedia" class="search_icon" />
            <el-input
              class="form-input"
              type="text"
              v-model="search"
              value=""
              placeholder="请输入需要查询的企业名称"
            />
          </div>
          <el-button class="search-btn" @click="Search_list()">查询</el-button>
        </div>
        <Qiyeba></Qiyeba>
      </div>
      <div class="list_page">
        <div class="list" v-if="Main_data_.data.length > 0">
          <div
            class="list_item"
            v-for="(item, index) in Main_data_.data"
            :key="index"
          >
            <div class="ite_top">
              <div class="stipti_ line1">{{ item.company_name }}</div>
              <div class="man_time flex vertical">
                <img :src="'time.png' | staticMedia" class="time_img" />
                <span>{{ item.updatetime }}</span>
              </div>
            </div>
            <div class="ite_bom">
              <el-button class="primaryBtn1" @click="chenckedit(item.id)"
                >录入企业备案</el-button
              >
              <el-button class="cancleBtn1" @click="goDelete(item.id)"
                >删除诊断任务</el-button
              >
              <el-button
                type="warning"
                class="warning1"
                @click="checkscople(item.id, item)"
                >诊断分数录入</el-button
              >
            </div>
          </div>
        </div>
        <div class="list" v-else>
          <div class="list-emp-text">暂无数据</div>
        </div>
        <div
          class="publicPage"
          style="margin-top: 0"
          v-show="Main_data_.total > 0"
        >
          <el-pagination
            style="text-align: right"
            background
            @current-change="Changepage"
            layout="prev, pager, next"
            :page-size="pageSize"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <el-dialog
        title="评估诊断入口"
        :visible.sync="dialogVisible"
        width="300px"
        top="40vh"
        :close-on-click-modal="false"
      >
        <!-- <div
          class="wahch flex center"
          @click="goCompany_wj"
          style="margin-bottom: 20px;"
        >
          评估诊断评分
        </div> -->
        <div class="wahch flex center" @click="logscople">查看结果</div>
      </el-dialog>
      <Scopelog ref="Scopelog"></Scopelog>

      <!-- 编辑 -->
      <Step_TOtal ref="steptotal"></Step_TOtal>
    </div>
  </div>
</template>
<script>
import { Get_dia_list } from "@/api/pts";
import Scopelog from "./scope.vue";
import Qiyeba from "./qiyeba.vue";
import Step_TOtal from "./step.vue";
export default {
  components: {
    Scopelog,
    Qiyeba,
    Step_TOtal,
  },
  data() {
    return {
      search: "", //主页搜搜
      Main_data_: {
        data: [],
      }, // 主数据
      Main_datapae: 1,
      dialogVisible: false,
      showscople: false,
      scopleid: "",
      // 编辑

      total: 0,
      pageSize: 12,

      companyItem: {},
    };
  },
  created() {},
  mounted() {
    this.Search_list();
  },
  methods: {
    // 企业问卷
    goCompany_wj() {
      this.$router.push({
        path:
          "/company/diagnose/question?companyId=" + this.companyItem.company_id,
      });
    },
    // 主页搜索
    Search_list() {
      this._Get_dia_list();
    },
    // 主页列表数据
    _Get_dia_list() {
      let pdata = {
        page: this.Main_datapae,
        search: this.search,
        pageSize: 12,
      };
      Get_dia_list(pdata).then((res) => {
        console.log(res);
        this.Main_data_ = res.data;
        this.total = res.data.total;
      });
    },
    // 主页分页搜索
    Changepage(val) {
      this.Main_datapae = val;
      this._Get_dia_list();
    },
    async goDelete(id) {
      console.log(id);

      if (
        await this.$Delete("post", "/index/company/del_diagnose", { id: id })
      ) {
        this._Get_dia_list();
      }
    },
    checkscople(id, item) {
      console.log(id);
      this.scopleid = id;
      this.companyItem = item;
      this.dialogVisible = !this.dialogVisible;
    },
    logscople() {
      this.$refs["Scopelog"]._getWjDiagnoseRes(this.scopleid);
    },
    // 编辑
    chenckedit(id) {
      console.log(id);
      this.$refs["steptotal"].editVisible = true;
      this.$nextTick(() => {
        this.$refs["steptotal"].sendid(id);
      });
    },
  },
};
</script>
<style scoped lang="less">
/deep/ .qiye {
  .qiyetop {
    .el-button {
      padding: 10px 20px;
    }
  }

  .ml20 {
    margin-left: 20px;
  }

  .list_page {
    margin-top: 20px;

    .primaryBtn1 {
      width: 30%;
      white-space: normal;
      padding: 10px;
    }

    .cancleBtn1 {
      width: 30%;
      white-space: normal;
      padding: 10px;
    }

    .warning1 {
      width: 30%;
      white-space: normal;
      border-radius: 10px;
      padding: 10px 15px;
    }

    .list {
      width: 100%;
      min-height: 388px;
      display: flex;
      flex-wrap: wrap;

      .list_item {
        width: 31.5%;
        height: 235px;
        border: 1px solid #d9d9d9;
        float: left;
        margin-right: 2.2%;
        margin-bottom: 30px;
        overflow: hidden;
        border-radius: 10px;

        .ite_top {
          .stipti_ {
            height: 76px;
            line-height: 76px;
            padding: 0 30px;
            display: inline-block;
            color: #333;
            font-size: 24px;
            font-weight: 600;
          }

          .stipti_:hover {
            background-color: #4a6de7;
            color: #fff;
          }

          .man_time {
            border-bottom: 1px solid #d9d9d9;
            height: 40px;
            text-align: left;
            margin: 0 30px;

            // display: flex;
            // justify-content: flex-start;
            // align-items: center;
            .time_img {
              width: 26px;
              height: 26px;
              margin-right: 10px;
            }

            span {
            }
          }
        }

        .ite_bom {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          height: 118px;
          width: 100%;
          padding: 0 30px;
          box-sizing: border-box;
        }
      }

      .list_item:nth-child(3n) {
        margin-right: 0;
      }
    }
  }

  .wahch {
    width: 80%;
    line-height: 50px;
    height: 60px;
    box-sizing: border-box;
    margin: 0 auto;
    border: 2px solid #205dc3;
    cursor: pointer;
    border-radius: 8px;
    padding: 0.5vh 1vw;
    transition: all 0.5s;

    &:hover {
      transition: all 0.3s;
      background-color: #205dc3;
      color: #fff;
    }
  }

  .editbod {
    .el-dialog__body {
      padding: 20px;
      padding-right: 0;
    }

    .el-dialog__header {
      padding: 20px 20px 0px;
    }
  }
}
</style>
