<template>
  <div class="dialog">
    <el-dialog
      title="问卷结果"
      :visible.sync="scopelog"
      width="600px"
      :close-on-click-modal="false"
    >
      <el-table
        :data="tableData"
        :span-method="objectSpanMethod"
        border
        show-summary
        style="width: 560px;margin: 0 auto;"
      >
        <el-table-column prop="pname" label="一级指标" width="180">
        </el-table-column>
        <el-table-column prop="name" label="二级指标"> </el-table-column>
        <el-table-column prop="score" label="分值"> </el-table-column>
        <el-table-column prop="actual_score" label="实际得分">
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.actual_score"
              :max="scope.row.score"
              @input="handleInput(scope)"
              type="Number"
              placeholder="请输入内容"
            ></el-input>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="downloadData">下 载</el-button>
        <el-button type="primary" @click="keeploadData">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { GetWjDiagnoseRes, PostchangeExamScore } from "@/api/pts";
export default {
  props: {},
  data() {
    return {
      scopelog: false,
      tableData: [],
      answer_data: [],
      allactual_score: 0,
      allscore: 0,
      userid: "",
    };
  },
  watch: {},
  mounted() {},
  methods: {
    _getWjDiagnoseRes(id) {
      this.userid = id;
      console.log(id);
      let paroam = {
        d_id: id,
      };
      GetWjDiagnoseRes(paroam).then((res) => {
        this.scopelog = true;
        var data = res.data;

        let score = [
          "3.00",
          "2.00",
          "2.00",
          "3.00",
          "5.00",
          "5.00",
          "5.00",
          "5.00",
          "4.75",
          "3.25",
          "3.75",
          "5.00",
          "4.00",
          "4.25",
          "6.00",
          "4.25",
          "4.00",
          "3.75",
          "5.00",
          "2.00",
          "4.00",
          "4.00",
          "2.00",
          "3.00",
          "3.00",
          "4.00",
          "0.00",
        ];

        let scoreIndex = 0;

        if (res.code === 1) {
          this.answer_data = data.answer;
          this.allactual_score = res.data.all_score;
          var list = [];
          if (data.answer) {
            for (var key in data.answer) {
              var el = data.answer[key];
              var obj = {
                pid: el.id,
                pname: el.name,
                // rowspan: el.two_cate.length
              };
              if (el.two_cate && el.two_cate.length) {
                el.two_cate.forEach(function(item, index) {
                  list.push({
                    ...obj,
                    rowspan: index ? 0 : el.two_cate.length,
                    name: item.name,
                    score: score[scoreIndex],
                    actual_score: item.actual_score,
                  });
                  scoreIndex++;
                });
              }
            }
            this.tableData = list;
          }
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    closevialog() {
      this.scopelog = false;
      this.$emit("show_valig_scope", this.scopelog);
    },
    objectSpanMethod({ row, columnIndex }) {
      if (columnIndex === 0) {
        if (row.rowspan) {
          return {
            rowspan: row.rowspan,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },
    handleInput(scope) {
      if (+scope.row.actual_score > scope.row.score) {
        this.$message({
          message: "输入的分数不能大于分值！",
          type: "error",
        });
        scope.row.actual_score = scope.row.score;
      }
    },
    downloadData() {
      var data = [];
      var obj = {
        pname: "一级指标",
        name: "二级指标",
        score: "分值",
        actual_score: "实际得分",
      };
      data.push(obj);
      this.tableData.forEach((item) => {
        let obj = {
          pname: item.pname,
          name: item.name,
          score: item.score,
          actual_score: item.actual_score,
        };
        data.push(obj);
      });
      let totalResult = 0;

      console.log(data)

      data.forEach((item) => {
        if (!isNaN(Number(item.actual_score))) {
          totalResult = totalResult + Number(item.actual_score);
        }
      });

      let lastobj = {
        pname: "合计",
        name: "",
        score: 100,
        actual_score: totalResult,
      };
      data.push(lastobj);

      var name = "问卷结果.xls";
      let a = document.createElement("a");
      const result = data
        .map((v) => {
          return Object.values(v).join("\t");
        })
        .join("\n");

      a.href = URL.createObjectURL(new Blob([result]));
      a.setAttribute("download", name);
      a.click();
    },
    keeploadData() {
      var _this = this;

      var i = 0;
      _this.answer_data.forEach(function(item) {
        if (item.two_cate) {
          item.two_cate.forEach(function(item2, index2) {
            item.two_cate[index2].actual_score =
              _this.tableData[i].actual_score;
            i++;
          });
        }
      });
      let params = {
        d_id: _this.userid,
        answer: JSON.stringify(_this.answer_data),
      };
      PostchangeExamScore(params).then((res) => {
        if (res.code == 1) {
          this.$message.success("修改成功");
        }
      });
    },
  },
};
</script>
<style scoped lang="less">
/deep/ .el-dialog__body {
  height: 569px;
  overflow: auto;
}
</style>
