<template>
  <div class="main" :style="$bgImg('sihua_bg.png')">
    <div class="container">
      <div class="add-btn">
        <div
          class="add-btn-body pointer flex column center"
          @click="beforeAddRequire"
        >
          <img class="add-btn-icon" :src="'/addContent.png' | staticMedia" />
          <span class="add-btn-title">发布需求</span>
        </div>
      </div>
      <div class="require-list flex wrap">
        <div
          class="require-item pointer shadow"
          v-for="(item, index) in requireList"
          :key="index"
        >
          <div>
            <div class="require-item-title line1">
              {{ item.name }}
            </div>
            <div class="require-item-info">
              <div class="require-item-info-item line1">
                企业地址：{{ item.address }}
              </div>
              <div class="require-item-info-item line1">
                联系方式：{{ item.mobile }}
              </div>
              <div class="require-item-info-item time flex vertical">
                <img class="bottom-icon" :src="'/time.png' | staticMedia" />{{
                  formatDateTime(item.createtime)
                }}
              </div>
            </div>
            <div class="require-btns flex horizontally vertical">
              <el-button
                class="primaryBtn1 btn"
                @click="beforeEditRequire(item.id)"
                >修改</el-button
              >
              <el-button
                class="cancleBtn1 btn"
                @click="beforeDelRequire(item.id)"
                >删除</el-button
              >
            </div>
          </div>
        </div>
      </div>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :current-page="page"
        :page-size="pageSize"
      >
      </el-pagination>
    </div>

    <el-dialog
      :title="dialogTitle"
      :visible.sync="requireDialog"
      width="946px"
      top="5vh"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form"
        :model="requireForm"
        label-width="90px"
        label-position="left"
        style="padding: 0 50px 0 20px"
      >
        <el-form-item label="企业名称">
          <el-input v-model="requireForm.name"></el-input>
        </el-form-item>
        <el-form-item label="企业地址">
          <el-input v-model="requireForm.address"></el-input>
        </el-form-item>
        <el-form-item label="所属行业">
          <el-select
            v-model="requireForm.industry"
            multiple
            placeholder="请选择"
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in hyOptions"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属领域">
          <el-select
            v-model="requireForm.field"
            multiple
            placeholder="请选择"
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in lyOptions"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input v-model="requireForm.mobile"></el-input>
        </el-form-item>
        <el-form-item label="需求描述">
          <div style="border: 1px solid #ccc">
            <!-- <Toolbar
              style="border-bottom: 1px solid #ccc"
              :editor="editor"
              :defaultConfig="toolbarConfig"
              :mode="mode"
            />
            <Editor
              style="height: 310px; overflow-y: hidden;"
              v-model="requireForm.xqms"
              :defaultConfig="editorConfig"
              :mode="mode"
              @onCreated="onCreated"
            /> -->
            <WangEditor
              @editorInput="handleEditorInput"
              :defVal="requireForm.xqms"
            ></WangEditor>
          </div>
        </el-form-item>
        <el-form-item label-width="0">
          <el-checkbox v-model="isAgreement"
            >本人承诺：认真遵守国家保密法律、法规和规章制度，内容不涉及国家秘密、商业秘密、个人隐私和其他敏感信息，自愿接受保密审查。如有违反，自愿承担相应责任和后果。</el-checkbox
          >
        </el-form-item>
        <el-form-item>
          <el-button class="primaryBtn1 btn" @click="postData">确认</el-button>
          <el-button class="cancleBtn1 btn" @click="handleClose"
            >取消</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { formatDateTime } from "@/utils/common.js";
// import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { getRequireList, getOneRequire, submitRequire } from "@/api/qy";
// import { UploadFile } from "@/api/login";
// import configs from "@/config/index.js";
import WangEditor from "../../../components/WangEditor.vue";
export default {
  name: "RequirePublic",
  components: {
    // Editor,
    // Toolbar,
    WangEditor,
  },
  data() {
    return {
      // // 富文本框
      // editor: null,
      // editorConfig: {
      //   placeholder: "请输入内容...", // 所有的菜单配置，都要在 MENU_CONF 属性下
      //   MENU_CONF: {
      //     // 配置上传图片
      //     uploadImage: {
      //       customUpload: this.uploadImg,
      //     },
      //     // 继续其他菜单配置...
      //   },
      // },
      // mode: "default", // or 'simple'
      // toolbarConfig: {
      //   excludeKeys: [
      //     "todo",
      //     "blockquote",
      //     "uploadVideo",
      //     "table",
      //     "code",
      //     "fullScreen",
      //     "numberedList",
      //     "fontFamily",
      //     "bulletedList",
      //     "uploadVideo",
      //     "codeBlock",
      //     "insertTable",
      //     "group-video",
      //     "headerSelect",
      //     "bgColor",
      //     "group-more-style",
      //     "divider",
      //     "group-indent",
      //     "insertVideo",
      //   ],
      // },

      // ------------
      requireDialog: false,
      requireList: [],
      dialogTitle: "发布需求",
      requireForm: {
        name: "",
        address: "",
        industry: "",
        field: "",
        mobile: "",
        xqms: "",
      },
      hyOptions: [
        "煤炭/黑色金属矿开采/石油天然气开采",
        "黑色金属",
        "有色金属",
        "石化化工",
        "建材",
        "医药",
        "纺织",
        "家电",
        "食品",
        "烟草",
        "轻工",
        "机械",
        "汽车",
        "航天/航空",
        "船舶",
        "轨道交通",
        "电子",
        "电力",
        "热力和燃气",
        "建筑业",
        "农业",
        "其他",
      ],
      lyOptions: [
        "安全生产",
        "节能减排",
        "质量管控",
        "供应链管理",
        "研发设计",
        "生产制造",
        "运营管理",
        "仓储物流",
        "运维服务",
        "其他",
      ],

      page: 1,
      pageSize: 9,
      total: 0,

      isAgreement: false,

      editorStr: "",
    };
  },
  mounted() {
    // this.getRequireList();
    console.log("this.ediot", this.editor, this.$refs);
    this.getRequireList();
    this.isAgreement = false;
  },
  methods: {
    handleEditorInput(html) {
      // console.log('heml', html)
      this.editorStr = html;
      this.requireForm.xqms = html;
    },
    // uploadImg(file, insertFn) {
    //   // file 即选中的文件
    //   // 自己实现上传，并得到图片 url alt href
    //   let _this = this;
    //   let fileObj = {};
    //   let formData = new FormData();
    //   formData.append("file", file);
    //   UploadFile(formData)
    //     .then((res) => {
    //       console.log("res", res);
    //       if (res.code == 1) {
    //         _this.$message.success("上传成功");
    //         fileObj = res.data;
    //         fileObj.fullurl = configs.baseUrl + fileObj.fullurl;
    //         // 最后插入图片
    //         console.log(res);
    //         insertFn(fileObj.fullurl, fileObj.name, fileObj.fullurl);
    //       }
    //     })
    //     .catch((err) => {
    //       console.log("err", err);
    //     })
    //     .finally(() => {
    //       console.log("finally");
    //     });
    // },
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
    formatDateTime(timetemp) {
      return formatDateTime(timetemp);
    },
    getRequireList() {
      let pdata = {
        page: this.page,
        pageSize: this.pageSize,
      };
      getRequireList(pdata).then((res) => {
        this.requireList = res.data.data;
        this.total = res.data.total;
      });
    },
    getOneRequire(id) {
      let pdata = {
        id,
      };
      getOneRequire(pdata).then((res) => {
        console.log("res", res);
        this.requireForm = res.data;
        this.requireForm.industry = res.data.industry.split(",");
        this.requireForm.field = res.data.field.split(",");
        this.requireForm.xqms = this.$encodeToHTML(res.data.xqms)
      });
    },
    beforeAddRequire() {
      this.dialogTitle = "发布需求";
      this.requireDialog = true;
      this.isAgreement = false;
    },
    beforeEditRequire(id) {
      this.dialogTitle = "修改需求";
      this.getOneRequire(id);
      this.requireDialog = true;
    },
    postData() {
      this.editorStr = this.$encodeHTML(this.editorStr);
      this.requireForm.xqms = this.$encodeHTML(this.requireForm.xqms);
      if (
        this.requireForm.xqms == "<p><br></p>" ||
        this.requireForm.xqms == null
      ) {
        this.$message.error("请输入需求描述");
        return false;
      }
      if (!this.isAgreement) {
        this.$confirm(
          "是否承诺认真遵守国家保密法律、法规和规章制度，内容不涉及国家秘密、商业秘密、个人隐私和其他敏感信息，自愿接受保密审查？",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            this.isAgreement = true;
            let _this = this;
            let pdata = { id: 0, ...this.requireForm };
            submitRequire(pdata)
              .then((res) => {
                console.log("res", res);
                if (res.code == 1) {
                  _this.$message.success(res.msg);
                } else {
                  _this.$message.error("新增失败");
                }
              })
              .catch((err) => {
                console.log("err", err);
              })
              .finally(() => {
                console.log("finally");
                _this.requireDialog = false;
                _this.requireForm = {};
                _this.getRequireList();
              });
          })
          .catch(() => {
            this.isAgreement = false;
            return false;
          });
      } else {
        let _this = this;
        let pdata = { id: 0, ...this.requireForm };
        submitRequire(pdata)
          .then((res) => {
            console.log("res", res);
            if (res.code == 1) {
              _this.$message.success(res.msg);
            } else {
              _this.$message.error("新增失败");
            }
          })
          .catch((err) => {
            console.log("err", err);
          })
          .finally(() => {
            console.log("finally");
            _this.requireDialog = false;
            _this.requireForm = {};
            _this.getRequireList();
          });
      }
    },
    beforeDelRequire(id) {
      this.$Delete("post", "/index/demand/del", { id }).then((res) => {
        console.log("res", res);
        this.getRequireList();
      });
    },
    handleClose() {
      this.requireForm = {};
      this.requireDialog = false;
    },
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="less" scoped>
::v-deep .el-checkbox__label {
  white-space: normal;
  word-break: break-all;
  vertical-align: text-top;
}

.el-button + .el-button {
  margin-left: 30px;
}

.container {
  position: relative;

  .require-list {
    width: 84%;
    min-height: 588px;
    margin: 0 auto;

    .require-item {
      width: 27.75%;
      height: fit-content;
      border-radius: 10px;
      background-color: #fff;
      padding: 30px 20px 15px;
      margin-right: 1.23%;
      margin-bottom: 20px;
      position: relative;

      &:nth-child(3n) {
        margin-right: 0;
      }

      &:hover {
        .require-item-title {
          color: #205dc3;
          font-size: 20px;
          font-weight: 600;
          line-height: 30px;
          height: 30px;
          margin-bottom: 10px;
        }
      }

      .require-item-title {
        color: #333;
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        height: 30px;
        margin-bottom: 10px;
      }

      .require-item-info {
        display: inline-block;

        .require-item-info-item {
          color: #999;
          font-size: 16px;
          line-height: 26px;
          margin-bottom: 8px;

          &.time {
            color: #333;
            font-size: 14px;
          }
        }
      }

      .require-btns {
        height: 60px;
        width: calc(100% + 40px);
        margin: 0 -20px;
        padding: 12px 0 0;
        border-top: 1px solid #d9d9d9;

        .el-button {
          padding: 15px 45px;
        }
      }
    }
  }
}

.shuzihua-result {
  padding: 0 50px 10px;

  .shuzihua-result-image {
    width: 100%;
  }

  .shuzihua-part {
    padding: 10px 0;

    .shuzihua-part-title {
      font-size: 20px;
      font-weight: 600;
      color: #333;

      .blue-point {
        width: 10px;
        height: 10px;
        background-color: #205dc3;
        border-radius: 10px;
        margin: 0 5px;
      }
    }

    .shuzihua-part-text {
      line-height: 1.5;
      margin-top: 10px;
      margin-left: 20px;
      color: #333;

      .shuzihua-part-text-weight {
        color: #333;
        font-weight: 600;
      }
    }

    .shuzihua-part-table {
      width: 373px;
      margin: 10px auto;
      border: 1px solid #d8d8d8;

      .shuzihua-part-table-title {
        .shuzihua-table-title-item {
          width: 186px;
          height: 46px;
          line-height: 46px;
          background-color: #205dc3;
          text-align: center;
          color: #fff;
          border-right: 1px solid #d8d8d8;

          &:last-child {
            border-right: none;
          }
        }
      }

      .shuzihua-part-table-list {
        &:last-child {
          .shuzihua-table-list-item {
            border-bottom: none;
          }
        }

        .shuzihua-table-list-item {
          width: 186px;
          height: 56px;
          line-height: 56px;
          color: #333;
          text-align: center;
          border-bottom: 1px solid #d8d8d8;
          border-right: 1px solid #d8d8d8;

          &:last-child {
            border-right: none;
          }
        }
      }
    }

    .shuzihua-echarts {
      width: 600px;
      height: 500px;
      margin: 10px auto;
    }
  }
}

.el-dialog {
  margin: 0 auto;
}
</style>
