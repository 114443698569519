<template>
  <div class="main" :style="$bgImg('sihua_bg.png')">
    <div class="container">
      <div class="form">
        <div class="breadcrumb">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/company/product' }"
              >产品发布</el-breadcrumb-item
            >
            <el-breadcrumb-item>内容</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="form-header">
          <div class="form-header-title">
            <span class="text">{{ title }}</span>
          </div>
          <div class="form-body">
            <el-form
              ref="productForm"
              :rules="rules"
              :model="infoForm"
              label-width="150px"
              label-position="left"
            >
              <el-form-item label="产品类别" prop="type">
                <el-radio-group
                  v-model="infoForm.type"
                  @change="handleTypeChange"
                  :disabled="$route.query.id != null"
                >
                  <el-radio :label="2">政策申报产品</el-radio>
                  <el-radio :label="1">政策非申报产品</el-radio>
                </el-radio-group>
              </el-form-item>
              <div v-if="infoForm.type == 2">
                <el-form-item label="企业名称" prop="qi_name">
                  <el-input v-model="infoForm.qi_name"></el-input>
                </el-form-item>
                <el-form-item label="联系人" prop="fa_name">
                  <el-input v-model="infoForm.fa_name"></el-input>
                </el-form-item>
                <el-form-item label="清单产品所属地市" prop="areaData">
                  <AreaSelect
                    :defVal="areaData"
                    @submitArea="getAreaData"
                  ></AreaSelect>
                </el-form-item>
                <el-form-item label="软件类型" prop="declare_info.softwareType">
                  <el-select
                    v-model="infoForm.declare_info.softwareType"
                    placeholder="请选择"
                    style="width: 42%;"
                  >
                    <el-option
                      v-for="(item, index) in softwareTypeList"
                      :key="index"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="企业规模" prop="enterprise_scale">
                  <el-input v-model="infoForm.enterprise_scale"></el-input>
                </el-form-item>
                <el-form-item label="联系方式" prop="mobile">
                  <el-input v-model="infoForm.mobile"></el-input>
                </el-form-item>
                <el-form-item label="所属行业" prop="declare_info.industry">
                  <el-select
                    v-model="infoForm.declare_info.industry"
                    placeholder="请选择"
                    style="width: 42%;"
                  >
                    <el-option
                      v-for="(item, index) in industryList"
                      :key="index"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="应用环节"
                  prop="declare_info.applicationStage"
                >
                  <el-select
                    v-model="infoForm.declare_info.applicationStage"
                    placeholder="请选择"
                    style="width: 42%;"
                  >
                    <el-option
                      v-for="(item, index) in applicationStageList"
                      :key="index"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="产品名称" prop="product_name">
                  <el-input v-model="infoForm.product_name"></el-input>
                </el-form-item>
                <!-- 独立 -->
                <el-form-item label="标签" prop="label">
                  <el-select
                    v-model="infoForm.label"
                    multiple
                    filterable
                    allow-create
                    default-first-option
                    placeholder="请输入后选择"
                  >
                    <el-option
                      v-for="item in labelOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="所属场景"
                  prop="declare_info.belongingScene"
                >
                  <el-cascader
                    v-model="infoForm.declare_info.belongingScene"
                    :options="belongingSceneOptions"
                    :props="{ multiple: true, value: 'id', label: 'title' }"
                    clearable
                  ></el-cascader>
                </el-form-item>
                <div class="flex wrap bewteen">
                  <el-form-item
                    label="版本号"
                    prop="declare_info.version"
                    style="width: 45%;"
                  >
                    <el-input
                      v-model="infoForm.declare_info.version"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="上市日期"
                    prop="declare_info.listingDate"
                    style="width: 45%;"
                  >
                    <el-date-picker
                      v-model="infoForm.declare_info.listingDate"
                      type="date"
                      placeholder="选择日期"
                      format="yyyy 年 MM 月 dd 日"
                      value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                  </el-form-item>
                  <!-- 独立 -->
                  <el-form-item
                    label="部署架构"
                    prop="deployment_architecture"
                    style="width: 45%;"
                  >
                    <!-- <el-input
                      v-model="infoForm.deployment_architecture"
                    ></el-input> -->
                    <el-radio-group v-model="infoForm.deployment_architecture">
                      <el-radio label="PC单机">PC单机</el-radio>
                      <el-radio label="C/S">C/S</el-radio>
                      <el-radio label="B/S">B/S</el-radio>
                      <el-radio label="其他">其他</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item
                    label="是否支持试用"
                    prop="declare_info.trial"
                    style="width: 45%;"
                  >
                    <el-radio-group v-model="infoForm.declare_info.trial">
                      <el-radio :label="1">支持</el-radio>
                      <el-radio :label="0">不支持</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item
                    label="销售方式"
                    prop="declare_info.saleType"
                    style="width: 45%;"
                  >
                    <el-radio-group v-model="infoForm.declare_info.saleType">
                      <el-radio label="买断制">买断制</el-radio>
                      <el-radio label="订阅制">订阅制</el-radio>
                      <el-radio label="其他">
                        <span>其他：</span>
                        <el-input
                          class="ss-input"
                          v-model="infoForm.declare_info.otherSale"
                        />
                      </el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item
                    label="二次开发接口"
                    prop="declare_info.secondDev"
                    style="width: 45%;"
                  >
                    <el-radio-group v-model="infoForm.declare_info.secondDev">
                      <el-radio :label="1">支持</el-radio>
                      <el-radio :label="0">不支持</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item
                    label="主要开发语言"
                    prop="declare_info.developmentLanguage"
                    style="width: 45%;"
                  >
                    <el-input
                      maxlength="20"
                      show-word-limit
                      v-model="infoForm.declare_info.developmentLanguage"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="软件成熟度"
                    prop="declare_info.softwareMaturity"
                    style="width: 45%;"
                  >
                    <!-- <el-input
                      v-model="infoForm.declare_info.softwareMaturity"
                    ></el-input> -->
                    <el-radio-group
                      v-model="infoForm.declare_info.softwareMaturity"
                    >
                      <el-radio label="稳定版">稳定版</el-radio>
                      <el-radio label="Release(发行版)"
                        >Release(发行版)</el-radio
                      >
                      <el-radio label="Free(免费版)">Free(免费版)</el-radio>
                      <el-radio label="Beta(外部测试版)"
                        >Beta(外部测试版)</el-radio
                      >
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item
                    label="适配软件"
                    prop="declare_info.adaptationSoftware"
                    style="width: 45%;"
                  >
                    <el-input
                      maxlength="20"
                      show-word-limit
                      v-model="infoForm.declare_info.adaptationSoftware"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="对标国外产品"
                    prop="declare_info.benchmarking"
                    style="width: 45%;"
                  >
                    <el-input
                      maxlength="20"
                      show-word-limit
                      v-model="infoForm.declare_info.benchmarking"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="是否赛宝优选"
                    prop="declare_info.isGood"
                    style="width: 45%;"
                  >
                    <el-radio-group v-model="infoForm.declare_info.isGood">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <!-- 独立 -->
                  <el-form-item
                    label="可控测试结论"
                    prop="test_controller"
                    style="width: 45%;"
                  >
                    <el-input
                      v-model="infoForm.test_controller"
                      maxlength="20"
                      show-word-limit
                    ></el-input>
                  </el-form-item>
                </div>
                <el-form-item label="标签图片" prop="image" class="is-required">
                  <TUpupload
                    :TU_lifils="t_imageList"
                    :allownum="1"
                    @tufile-sent="handleFile"
                  ></TUpupload>
                </el-form-item>

                <!-- 独立 -->
                <el-form-item
                  label="产品关键技术"
                  prop="product_technology"
                  class="is-required"
                >
                  <!-- <WangEditor
                    @editorInput="handleProductTechnologyInput"
                    :defVal="infoForm.product_technology"
                  ></WangEditor> -->
                  <MultipleWangEditor
                    ref="product_technology"
                    wangId="product_technologyEditor"
                    :defVal="infoForm.product_technology"
                  ></MultipleWangEditor>
                </el-form-item>
                <el-form-item
                  label="产品简介"
                  prop="product_introduce"
                  class="is-required"
                >
                  <!-- <WangEditor
                    @editorInput="handleProductIntroduceInput"
                    :defVal="infoForm.product_introduce"
                  ></WangEditor> -->
                  <MultipleWangEditor
                    ref="product_introduce"
                    wangId="product_introduceEditor"
                    :defVal="infoForm.product_introduce"
                  ></MultipleWangEditor>
                </el-form-item>
                <el-form-item
                  label="产品详情"
                  prop="product_content"
                  class="is-required"
                >
                  <!-- <WangEditor
                    @editorInput="handleProductContentInput"
                    :defVal="infoForm.product_content"
                  ></WangEditor> -->
                  <MultipleWangEditor
                    ref="product_content"
                    wangId="product_contentEditor"
                    :defVal="infoForm.product_content"
                  ></MultipleWangEditor>
                </el-form-item>
                <!-- <el-form-item
                  label="产品功能特性"
                  prop="product_function"
                  class="is-required"
                > -->
                <!-- <WangEditor
                    @editorInput="handleProductFunctionInput"
                    :defVal="infoForm.product_function"
                  ></WangEditor> -->
                <!-- <MultipleWangEditor
                    ref="product_function"
                    wangId="product_functionEditor"
                    :defVal="infoForm.product_function"
                  ></MultipleWangEditor>
                </el-form-item> -->
                <!-- <el-form-item
                  label="产品核心客户价值"
                  prop="product_core"
                  class="is-required"
                > -->
                <!-- <WangEditor
                    @editorInput="handleProductCoreInput"
                    :defVal="infoForm.product_core"
                  ></WangEditor> -->
                <!-- <MultipleWangEditor
                    ref="product_core"
                    wangId="product_coreEditor"
                    :defVal="infoForm.product_core"
                  ></MultipleWangEditor>
                </el-form-item> -->
              </div>
              <div v-if="infoForm.type == 1">
                <el-form-item label="企业名称" prop="qi_name">
                  <el-input v-model="infoForm.qi_name"></el-input>
                </el-form-item>
                <el-form-item label="法定代表人" prop="fa_name">
                  <el-input v-model="infoForm.fa_name"></el-input>
                </el-form-item>
                <el-form-item label="企业地点" prop="areaData">
                  <AreaSelect
                    :defVal="areaData"
                    @submitArea="getAreaData"
                  ></AreaSelect>
                </el-form-item>
                <el-form-item label="成立时间" prop="e_time">
                  <el-date-picker
                    v-model="infoForm.e_time"
                    type="date"
                    placeholder="选择日期"
                    format="yyyy 年 MM 月 dd 日"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="企业规模" prop="enterprise_scale">
                  <el-input v-model="infoForm.enterprise_scale"></el-input>
                </el-form-item>
                <el-form-item label="联系方式" prop="mobile">
                  <el-input v-model="infoForm.mobile"></el-input>
                </el-form-item>
                <el-form-item label="行业" prop="hy">
                  <el-select
                    v-model="infoForm.hy"
                    multiple
                    placeholder="请选择"
                    style="width: 42%;"
                  >
                    <el-option
                      v-for="(item, index) in hyOptions"
                      :key="index"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="领域" prop="ly">
                  <el-select
                    v-model="infoForm.ly"
                    multiple
                    placeholder="请选择"
                    style="width: 42%;"
                  >
                    <el-option
                      v-for="(item, index) in lyOptions"
                      :key="index"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="产品名称" prop="product_name">
                  <el-input v-model="infoForm.product_name"></el-input>
                </el-form-item>
                <el-form-item label="适用行业" prop="suitability_hy">
                  <el-input v-model="infoForm.suitability_hy"></el-input>
                </el-form-item>
                <el-form-item label="标签图片" prop="image">
                  <TUpupload
                    :TU_lifils="t_imageList"
                    :allownum="1"
                    @tufile-sent="handleFile"
                  ></TUpupload>
                </el-form-item>
                <el-form-item label="视频文件" prop="is_file">
                  <moreupload
                    ref="domref"
                    :uploadlist="fileList"
                    :allownum="1"
                    @domfile-sent="handleVideoFile"
                    :isShowTable="false"
                    accept=".mp3,.mp4"
                    @deleteFile="handleDeleteFile"
                  ></moreupload>
                  <video
                    class="form-video"
                    ref="formVideo"
                    :src="infoForm.is_file | contentMedia"
                    controls
                  ></video>
                </el-form-item>
                <el-form-item label="场景描述" prop="content">
                  <WangEditor
                    @editorInput="handleEditorInput"
                    :defVal="infoForm.content"
                  ></WangEditor>
                </el-form-item>
                <el-form-item label="已改造项目情况" prop="yi_xian">
                  <el-input v-model="infoForm.yi_xian"></el-input>
                </el-form-item>
                <el-form-item label="数字化转型领域简述" prop="digitalize_desc">
                  <el-input
                    v-model="infoForm.digitalize_desc"
                    type="textarea"
                    :rows="2"
                    :maxlength="500"
                    :show-word-limit="true"
                  ></el-input>
                </el-form-item>
                <el-form-item label="网络化协同简述" prop="network_desc">
                  <el-input
                    v-model="infoForm.network_desc"
                    type="textarea"
                    :rows="2"
                    :maxlength="500"
                    :show-word-limit="true"
                  ></el-input>
                </el-form-item>
                <el-form-item label="智能化制造简述" prop="intellect_desc">
                  <el-input
                    v-model="infoForm.intellect_desc"
                    type="textarea"
                    :rows="2"
                    :maxlength="500"
                    :show-word-limit="true"
                  ></el-input>
                </el-form-item>
                <el-form-item label="绿色化提升简述" prop="greenization_desc">
                  <el-input
                    v-model="infoForm.greenization_desc"
                    type="textarea"
                    :rows="2"
                    :maxlength="500"
                    :show-word-limit="true"
                  ></el-input>
                </el-form-item>
                <el-form-item label="其他说明事项" prop="other">
                  <el-input
                    v-model="infoForm.other"
                    type="textarea"
                    :rows="2"
                    :maxlength="500"
                    :show-word-limit="true"
                  ></el-input>
                </el-form-item>
              </div>

              <el-form-item label-width="0">
                <el-checkbox v-model="isAgreement"
                  >本人承诺：认真遵守国家保密法律、法规和规章制度，内容不涉及国家秘密、商业秘密、个人隐私和其他敏感信息，自愿接受保密审查。如有违反，自愿承担相应责任和后果。</el-checkbox
                >
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  class="primaryBtn1"
                  @click="handleSubmit"
                  >提交</el-button
                >
                <el-button
                  type="primary"
                  class="primaryBtn1"
                  @click="handleGetDraft"
                  >获取草稿</el-button
                >
                <el-button
                  type="primary"
                  class="primaryBtn1"
                  @click="handleSaveDraft"
                  >保存草稿</el-button
                >
                <el-button @click="handleCancle" class="cancleBtn1"
                  >取消</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import configs from "@/config/index";
import TUpupload from "@/components/TUpupload.vue";
import moreupload from "@/components/moreupload.vue";
import {
  getProductDetail,
  updateProduct,
  addProduct,
  saveDraft,
  getDraft,
} from "@/api/pts";
import AreaSelect from "@/components/AreaSelect.vue";
import WangEditor from "@/components/WangEditor.vue";
import {
  // belongingSceneOptions,
  applicationStageList,
  industryList,
  softwareTypeList,
} from "./sceenList.js";
import { getSceenList } from "@/api/login.js";
import rules from "./rule.js";
// import WangEditor from "wangeditor";
// import { timeToFormat } from "@/utils/common";

import MultipleWangEditor from "@/components/MultipleWangEditor.vue";
export default {
  components: {
    TUpupload,
    moreupload,
    AreaSelect,
    WangEditor,
    MultipleWangEditor,
  },
  data() {
    var validateImg = (rule, value, callback) => {
      if (this.t_imageUrl === "") {
        callback(new Error("请上传标签图片"));
      } else {
        callback();
      }
    };
    var validateVideo = (rule, value, callback) => {
      if (this.t_videoUrl === "") {
        callback(new Error("请上传视频文件"));
      } else {
        callback();
      }
    };
    var validateEditor = (rule, value, callback) => {
      if (this.editorStr === "") {
        callback(new Error("请填写场景描述"));
      } else {
        callback();
      }
    };
    return {
      isAdd: false,
      title: "修改内容",
      hyOptions: configs.hyOptions,
      lyOptions: configs.lyOptions,
      infoForm: {
        qi_name: "",
        fa_name: "",
        e_time: "",
        enterprise_scale: "",
        mobile: "",
        hy: "",
        ly: "",
        product_name: "",
        is_file: "",
        content: "",
        yi_xian: "",
        declare_info: {
          softwareType: "",
        },
        product_technology: "",
        product_introduce: "",
        product_content: "",
        product_function: "",
        product_core: "",
      },
      t_imageList: [],
      t_imageUrl: "",
      t_videoUrl: "",
      fileList: [],
      areaData: {},
      editorStr: "",

      rules: {
        image: [{ required: true, validator: validateImg, trigger: "blur" }],
        is_file: [
          { required: true, validator: validateVideo, trigger: "blur" },
        ],
        content: [
          { required: true, validator: validateEditor, trigger: "blur" },
        ],
        ...rules,
      },

      isAgreement: false,
      softwareTypeList,
      industryList,
      applicationStageList,
      belongingSceneOptions: [],
      labelOptions: [],
      editor: null,
      editor2: null,
      editor3: null,
    };
  },
  created() {
    this.isAdd = this.$route.query.type;
    if (this.isAdd === "true") {
      this.title = "添加内容";
    } else {
      this.title = "修改内容";
    }
  },
  mounted() {
    this.getSceenList();
    if (this.$route.query.id) {
      this.getProductDetail();
    } else {
      let ptsInfo = JSON.parse(sessionStorage.getItem("ptsInfo"));
      setTimeout(() => {
        this.infoForm.qi_name = ptsInfo.name;
        this.infoForm.fa_name = ptsInfo.representative;
        // this.areaData = `${ptsInfo.province}/${ptsInfo.city}/${ptsInfo.area}/`;
        // this.areaData = {
        //   provinceId: ptsInfo.province == 0 ? null : ptsInfo.province,
        //   cityId: ptsInfo.city == 0 ? null : ptsInfo.city,
        //   areaId: ptsInfo.area == 0 ? null : ptsInfo.area,
        // };

        this.areaData = {
          provinceId:
            ptsInfo.province_id == 0
              ? null
              : typeof ptsInfo.province == "string"
              ? null
              : ptsInfo.province_id,
          cityId:
            ptsInfo.city_id == 0
              ? null
              : typeof ptsInfo.city == "string"
              ? null
              : ptsInfo.city_id,
          areaId:
            ptsInfo.area_id == 0
              ? null
              : typeof ptsInfo.area == "string"
              ? null
              : ptsInfo.area_id,
        };
        // this.infoForm.e_time = timeToFormat(ptsInfo.e_time);
      }, 500);
    }
  },
  methods: {
    getSceenList() {
      getSceenList().then((res) => {
        console.log("res", res);
        this.belongingSceneOptions = res.data;
      });
    },
    handleTypeChange(val) {
      if (val == 2) {
        // this.$refs.product_core.onCreatedEditor();
        // this.$refs.product_function.onCreatedEditor();
        this.$refs.product_content.onCreatedEditor();
        this.$refs.product_introduce.onCreatedEditor();
        this.$refs.product_technology.onCreatedEditor();
      } else if (val == 1) {
        // this.$refs.product_core.onDestoryEditor();
        // this.$refs.product_function.onDestoryEditor();
        this.$refs.product_content.onDestoryEditor();
        this.$refs.product_introduce.onDestoryEditor();
        this.$refs.product_technology.onDestoryEditor();
      }
    },
    handleProductTechnologyInput(html) {
      this.infoForm.product_technology = html;
    },
    handleProductIntroduceInput(html) {
      this.infoForm.product_introduce = html;
    },
    handleProductContentInput(html) {
      this.infoForm.product_content = html;
    },
    // handleProductFunctionInput(html) {
    //   this.infoForm.product_function = html;
    // },
    // handleProductCoreInput(html) {
    //   this.infoForm.product_core = html;
    // },
    handleGetDraft() {
      let _this = this;
      let pdata = {
        type: 2,
      };

      // reset一下
      _this.infoForm = {
        qi_name: "",
        fa_name: "",
        e_time: "",
        enterprise_scale: "",
        mobile: "",
        hy: "",
        ly: "",
        declare_info: { softwareType: "" },
        product_name: "",
        is_file: "",
        content: "",
        yi_xian: "",
      };
      _this.t_imageList = [];
      _this.t_imageUrl = null;
      _this.t_videoUrl = null;

      getDraft(pdata).then((res) => {
        if (res.code == 1) {
          _this.$message.success("获取草稿成功");
          let data = JSON.parse(res.data.content);
          _this.infoForm = JSON.parse(JSON.stringify(data));
          console.log(_this.infoForm, "----=====");
          _this.infoForm.declare_info = _this.infoForm.declare_info
            ? _this.infoForm.declare_info
            : {};
          _this.infoForm.declare_info.softwareType = _this.infoForm.declare_info
            .softwareType
            ? _this.infoForm.declare_info.softwareType
            : "";

          if (_this.infoForm.type == 2) {
            setTimeout(() => {
              // 创建并渲染富文本框
              // _this.$refs.product_core.onCreatedEditor();
              // _this.$refs.product_function.onCreatedEditor();
              _this.$refs.product_content.onCreatedEditor();
              _this.$refs.product_introduce.onCreatedEditor();
              _this.$refs.product_technology.onCreatedEditor();
              // _this.$refs.product_core.setEditorHTML(data.product_core);
              // _this.$refs.product_function.setEditorHTML(data.product_function);
              _this.$refs.product_content.setEditorHTML(data.product_content);
              _this.$refs.product_introduce.setEditorHTML(
                data.product_introduce
              );
              _this.$refs.product_technology.setEditorHTML(
                data.product_technology
              );
              _this.t_imageList.push({ url: data.image });
              _this.t_imageUrl = data.image;
              _this.t_videoUrl = data.is_file;
              _this.areaData = {
                provinceId:
                  data.province_id == 0
                    ? null
                    : data.province
                    ? data.province
                    : data.province_id,
                cityId:
                  data.city_id == 0
                    ? null
                    : data.city
                    ? data.city
                    : data.city_id,
                areaId:
                  data.area_id == 0
                    ? null
                    : data.area
                    ? data.area
                    : data.area_id,
              };
            }, 300);
          } else if (_this.infoForm.type == 1) {
            _this.t_imageList.push({ url: data.image });
            _this.t_imageUrl = data.image;
            _this.t_videoUrl = data.is_file;
            _this.areaData = {
              provinceId:
                data.province_id == 0
                  ? null
                  : data.province
                  ? data.province
                  : data.province_id,
              cityId:
                data.city_id == 0 ? null : data.city ? data.city : data.city_id,
              areaId:
                data.area_id == 0 ? null : data.area ? data.area : data.area_id,
            };
          }
        } else {
          _this.$message.error("获取草稿失败");
        }
      });
    },
    handleSaveDraft() {
      let _this = this;

      let data = {};

      if (_this.infoForm.type == 2) {
        _this.infoForm.product_technology = _this.$refs.product_technology.getEditorHTML();
        _this.infoForm.product_introduce = _this.$refs.product_introduce.getEditorHTML();
        _this.infoForm.product_content = _this.$refs.product_content.getEditorHTML();
        // _this.infoForm.product_function = _this.$refs.product_function.getEditorHTML();
        // _this.infoForm.product_core = _this.$refs.product_core.getEditorHTML();
        data = {
          type: _this.infoForm.type,
          qi_name: _this.infoForm.qi_name,
          fa_name: _this.infoForm.fa_name,
          province: _this.infoForm.province,
          city: _this.infoForm.city,
          area: _this.infoForm.area,
          declare_info: _this.infoForm.declare_info,
          enterprise_scale: _this.infoForm.enterprise_scale,
          mobile: _this.infoForm.mobile,
          product_name: _this.infoForm.product_name,
          label: _this.infoForm.label,
          deployment_architecture: _this.infoForm.deployment_architecture,
          test_controller: _this.infoForm.test_controller,
          image: _this.t_imageUrl,
          product_technology: _this.infoForm.product_technology,
          product_introduce: _this.infoForm.product_introduce,
          product_content: _this.infoForm.product_content,
          product_function: _this.infoForm.product_function,
          product_core: _this.infoForm.product_core,
        };
      } else if (_this.infoForm.type == 1) {
        data = {
          qi_name: _this.infoForm.qi_name,
          fa_name: _this.infoForm.fa_name,
          province: _this.infoForm.province,
          city: _this.infoForm.city,
          area: _this.infoForm.area,
          e_time: _this.infoForm.e_time,
          enterprise_scale: _this.infoForm.enterprise_scale,
          mobile: _this.infoForm.mobile,
          hy: _this.infoForm.hy,
          ly: _this.infoForm.ly,
          product_name: _this.infoForm.product_name,
          image: _this.t_imageUrl,
          is_file: _this.t_videoUrl,
          yi_xian: _this.infoForm.yi_xian,
          content: _this.editorStr,
          suitability_hy: _this.infoForm.suitability_hy,
          digitalize_desc: _this.infoForm.digitalize_desc,
          network_desc: _this.infoForm.network_desc,
          intellect_desc: _this.infoForm.intellect_desc,
          greenization_desc: _this.infoForm.greenization_desc,
          other: _this.infoForm.other,
          type: _this.infoForm.type,
        };
      }
      let pdata = {
        type: 2,
        content: JSON.stringify(data),
      };

      saveDraft(pdata).then((res) => {
        if (res.code == 1) {
          _this.$message.success("保存草稿成功");
        } else {
          _this.$message.error("保存草稿失败");
        }
      });
    },
    handleCancle() {
      this.$router.go(-1);
    },
    handleSubmit() {
      if (this.infoForm.type == 2) {
        this.infoForm.product_technology = this.$refs.product_technology.getEditorHTML();
        this.infoForm.product_introduce = this.$refs.product_introduce.getEditorHTML();
        this.infoForm.product_content = this.$refs.product_content.getEditorHTML();
        // this.infoForm.product_function = this.$refs.product_function.getEditorHTML();
        // this.infoForm.product_core = this.$refs.product_core.getEditorHTML();
      }
      if (!this.isAgreement) {
        this.$confirm(
          "是否承诺认真遵守国家保密法律、法规和规章制度，内容不涉及国家秘密、商业秘密、个人隐私和其他敏感信息，自愿接受保密审查？",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            this.isAgreement = true;
            this.$refs["productForm"].validate((validate) => {
              if (validate) {
                if (this.infoForm.type == 2) {
                  if (this.infoForm.product_technology == "<p><br></p>") {
                    this.$message.error("请填写产品关键技术");
                    return false;
                  }
                  if (this.infoForm.product_introduce == "<p><br></p>") {
                    this.$message.error("请填写产品简介");
                    return false;
                  }
                  if (this.infoForm.product_content == "<p><br></p>") {
                    this.$message.error("请填写产品介绍");
                    return false;
                  }
                  if (this.infoForm.product_function == "<p><br></p>") {
                    this.$message.error("请填写产品功能特性");
                    return false;
                  }
                  if (this.infoForm.product_core == "<p><br></p>") {
                    this.$message.error("请填写产品核心客户价值");
                    return false;
                  }
                } else if (this.infoForm.type == 1) {
                  // 这个默认空值是 ‘<p><br></p>’
                  if (this.editorStr == "<p><br></p>") {
                    this.$message.error("请填写场景描述");
                    return false;
                  }
                }
                if (this.$route.query.id) {
                  this.updateProduct();
                } else {
                  this.addProduct();
                }
              }
            });
          })
          .catch(() => {
            this.isAgreement = false;
            return false;
          });
      } else {
        this.$refs["productForm"].validate((validate) => {
          if (validate) {
            if (this.infoForm.type == 1) {
              // 这个默认空值是 ‘<p><br></p>’
              if (this.editorStr == "<p><br></p>") {
                this.$message.error("请填写场景描述");
                return false;
              }
            }
            if (this.$route.query.id) {
              this.updateProduct();
            } else {
              this.addProduct();
            }
          }
        });
      }
    },
    addProduct() {
      this.editorStr = this.$encodeHTML(this.editorStr);
      let _this = this;
      let pdata = {};
      console.log("this.t_imageUrl", this.t_imageUrl);

      if (_this.infoForm.type == 1) {
        pdata = {
          type: _this.infoForm.type,
          qi_name: _this.infoForm.qi_name,
          fa_name: _this.infoForm.fa_name,
          province: _this.infoForm.province,
          city: _this.infoForm.city,
          area: _this.infoForm.area,
          e_time: _this.infoForm.e_time,
          enterprise_scale: _this.infoForm.enterprise_scale,
          mobile: _this.infoForm.mobile,
          hy: _this.infoForm.hy.join(","),
          ly: _this.infoForm.ly.join(","),
          product_name: _this.infoForm.product_name,
          image: _this.t_imageUrl,
          is_file: _this.t_videoUrl,
          yi_xian: _this.infoForm.yi_xian,
          content: _this.editorStr,
          suitability_hy: _this.infoForm.suitability_hy,
          digitalize_desc: _this.infoForm.digitalize_desc,
          network_desc: _this.infoForm.network_desc,
          intellect_desc: _this.infoForm.intellect_desc,
          greenization_desc: _this.infoForm.greenization_desc,
          other: _this.infoForm.other,
        };
      } else if (_this.infoForm.type == 2) {
        pdata = {
          type: _this.infoForm.type,
          qi_name: _this.infoForm.qi_name,
          fa_name: _this.infoForm.fa_name,
          province: _this.infoForm.province,
          city: _this.infoForm.city,
          area: _this.infoForm.area,
          declare_info: JSON.stringify(_this.infoForm.declare_info),
          enterprise_scale: _this.infoForm.enterprise_scale,
          mobile: _this.infoForm.mobile,
          product_name: _this.infoForm.product_name,
          label: _this.infoForm.label,
          deployment_architecture: _this.infoForm.deployment_architecture,
          test_controller: _this.infoForm.test_controller,
          image: _this.t_imageUrl,
          product_technology: _this.infoForm.product_technology,
          product_introduce: _this.infoForm.product_introduce,
          product_content: _this.infoForm.product_content,
          product_function: _this.infoForm.product_function,
          product_core: _this.infoForm.product_core,
        };
      }
      addProduct(pdata).then((res) => {
        console.log("res", res);
        if (res.code == 1) {
          _this.$message.success("正在审核中，请稍等");
          setTimeout(() => {
            this.$router.go(-1);
          }, 1500);
        } else {
          _this.$message.error("添加失败");
        }
      });
    },
    updateProduct() {
      this.editorStr = this.$encodeHTML(this.editorStr);
      let _this = this;
      let pdata = {};
      if (_this.infoForm.type == 1) {
        pdata = {
          type: _this.infoForm.type,
          qi_name: _this.infoForm.qi_name,
          fa_name: _this.infoForm.fa_name,
          province: _this.infoForm.province,
          city: _this.infoForm.city,
          area: _this.infoForm.area,
          e_time: _this.infoForm.e_time,
          enterprise_scale: _this.infoForm.enterprise_scale,
          mobile: _this.infoForm.mobile,
          hy: _this.infoForm.hy.join(","),
          ly: _this.infoForm.ly.join(","),
          product_name: _this.infoForm.product_name,
          image: _this.t_imageUrl,
          is_file: _this.t_videoUrl,
          yi_xian: _this.infoForm.yi_xian,
          id: _this.$route.query.id,
          content: _this.editorStr,
          suitability_hy: _this.infoForm.suitability_hy,
          digitalize_desc: _this.infoForm.digitalize_desc,
          network_desc: _this.infoForm.network_desc,
          intellect_desc: _this.infoForm.intellect_desc,
          greenization_desc: _this.infoForm.greenization_desc,
          other: _this.infoForm.other,
        };
        if (!pdata.digitalize_desc) delete pdata.digitalize_desc;
        if (!pdata.network_desc) delete pdata.network_desc;
        if (!pdata.intellect_desc) delete pdata.intellect_desc;
        if (!pdata.greenization_desc) delete pdata.greenization_desc;
        if (!pdata.other) delete pdata.other;
      } else {
        pdata = {
          type: _this.infoForm.type,
          qi_name: _this.infoForm.qi_name,
          fa_name: _this.infoForm.fa_name,
          province: _this.infoForm.province,
          city: _this.infoForm.city,
          area: _this.infoForm.area,
          declare_info: JSON.stringify(_this.infoForm.declare_info),
          enterprise_scale: _this.infoForm.enterprise_scale,
          mobile: _this.infoForm.mobile,
          product_name: _this.infoForm.product_name,
          label: _this.infoForm.label,
          deployment_architecture: _this.infoForm.deployment_architecture,
          test_controller: _this.infoForm.test_controller,
          image: _this.t_imageUrl,
          product_technology: _this.infoForm.product_technology,
          product_introduce: _this.infoForm.product_introduce,
          product_content: _this.infoForm.product_content,
          product_function: _this.infoForm.product_function,
          product_core: _this.infoForm.product_core,
          id: _this.$route.query.id,
        };
      }
      updateProduct(pdata).then((res) => {
        console.log("res", res);
        if (res.code == 1) {
          _this.$message.success("修改成功");
          setTimeout(() => {
            this.$router.go(-1);
          }, 1500);
        } else {
          _this.$message.error("修改失败");
        }
      });
    },
    getProductDetail() {
      let _this = this;
      let pdata = {
        id: _this.$route.query.id,
      };
      getProductDetail(pdata).then((res) => {
        _this.infoForm = JSON.parse(JSON.stringify(res.data));
        if (_this.infoForm.type == 1) {
          setTimeout(() => {
            _this.infoForm.hy = res.data.hy.split(",");
            _this.infoForm.ly = res.data.ly.split(",");
            _this.t_imageList.push({ url: res.data.image });
            _this.t_imageUrl = res.data.image;
            _this.t_videoUrl = res.data.is_file;
            // _this.areaData = `${res.data.province}/${res.data.city}/${res.data.area}/`;
            _this.areaData = {
              provinceId:
                res.data.province_id == 0 ? null : res.data.province_id,
              cityId: res.data.city_id == 0 ? null : res.data.city_id,
              areaId: res.data.area_id == 0 ? null : res.data.area_id,
            };
            _this.infoForm.suitability_hy = res.data.suitability_hy;
            _this.infoForm.digitalize_desc = res.data.digitalize_desc;
            _this.infoForm.network_desc = res.data.network_desc;
            _this.infoForm.intellect_desc = res.data.intellect_desc;
            _this.infoForm.greenization_desc = res.data.greenization_desc;
            _this.infoForm.other = res.data.other;
            _this.infoForm.content = this.$encodeToHTML(res.data.content);
            // this.editorStr = this.$encodeToHTML(res.data.content);
            _this.infoForm.province = res.data.province_id;
            _this.infoForm.city = res.data.city_id;
            _this.infoForm.area = res.data.area_id;
            console.log("_this.refsaf", this.infoForm);
          }, 300);
        } else if (_this.infoForm.type == 2) {
          setTimeout(() => {
            _this.infoForm.declare_info = JSON.parse(res.data.declare_info);
            _this.t_imageList.push({ url: res.data.image });
            _this.t_imageUrl = res.data.image;
            console.log("res.data.label", res.data);

            _this.infoForm.label = res.data.label.split(",");
            _this.areaData = {
              provinceId:
                res.data.province_id == 0 ? null : res.data.province_id,
              cityId: res.data.city_id == 0 ? null : res.data.city_id,
              areaId: res.data.area_id == 0 ? null : res.data.area_id,
            };

            // 创建并渲染富文本框
            _this.$refs.product_content.onCreatedEditor();
            _this.$refs.product_introduce.onCreatedEditor();
            _this.$refs.product_technology.onCreatedEditor();
            _this.$refs.product_content.setEditorHTML(res.data.product_content);
            _this.$refs.product_introduce.setEditorHTML(
              res.data.product_introduce
            );
            _this.$refs.product_technology.setEditorHTML(
              res.data.product_technology
            );
            _this.infoForm.province = res.data.province_id;
            _this.infoForm.city = res.data.city_id;
            _this.infoForm.area = res.data.area_id;
          }, 300);
        }
      });
    },
    handleEditorInput(html) {
      this.editorStr = html;
      console.log("handleEditorInput", this.editorStr);
    },
    handleVideoFile(fileList, file) {
      this.t_videoUrl = file.data.url;
      this.infoForm.is_file = file.data.url;
      this.$refs.formVideo.src = `${configs.realUrl}/${file.data.url}`;
    },
    handleDeleteFile() {
      this.t_videoUrl = "";
      this.infoForm.is_file = "";
      this.$refs.formVideo.src = "";
    },
    uploadVideo() {
      this.$refs.uploadFileInput.click();
    },
    handleFile(file) {
      // this.t_imageList.push(file);
      this.t_imageUrl = file.url;
    },
    getAreaData(areaObj) {
      this.infoForm.province = areaObj.provinceId;
      this.infoForm.city = areaObj.cityId;
      this.infoForm.area = areaObj.areaId;
    },
  },
};
</script>
<style scoped lang="less">
.ss-input {
  ::v-deep .el-input__inner {
    height: 18px;
    width: 100px;
    padding: 0 10px;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #efefef;
  }
}
::v-deep .el-checkbox__label {
  white-space: normal;
  word-break: break-all;
  vertical-align: text-top;
}

.el-button + .el-button {
  margin-left: 30px;
}

.container {
  position: relative;

  .form {
    width: 84%;
    margin: 0 auto;

    .form-header {
      background-color: #fff;
      border-radius: 10px;
      padding: 0 50px;
      box-sizing: border-box;

      .form-header-title {
        width: 100%;
        border-bottom: 1px solid #d8d8d8;
        margin-bottom: 20px;

        .text {
          display: inline-block;
          height: 72px;
          line-height: 72px;
          font-size: 18px;
          padding: 0 8px;
          margin-left: 8px;
          border-bottom: 3px solid #9bbef9;
        }
      }
    }

    .form-body {
      padding-right: 180px;
      overflow: hidden;

      .form-video {
        margin-top: 10px;
        width: 50%;
      }
    }
  }
}
</style>
