<template>
  <div>
    <el-upload
      class="avatar-uploader"
      :action="uploadUrl"
      :show-file-list="false"
      :on-success="handleAvatarSuccess"
      accept=".jpg, .jpeg, .png"
    >
      <el-button class="upload-btn">点击上传</el-button>
      <!-- {{ imageUrl }} -->
      <img v-if="imageUrl" :src="imageUrl | contentMedia" class="avatar" />
    </el-upload>
  </div>
</template>

<script>
import configs from "@/config/index";
export default {
  props: {
    detailImage: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      imageUrl: "",
      uploadUrl: "", // 图片上传路径
    };
  },
  watch: {
    detailImage(nv) {
      this.imageUrl = nv;
    },
  },
  created() {
    this.uploadUrl = `${configs.baseUrl}/api/common/upload`;
  },
  methods: {
    // 上传成功
    handleAvatarSuccess(res, file) {
      console.log("file, ", file);

      // this.imageUrl = URL.createObjectURL(file.raw);
      // 之前设计的基本都是存储'/upload/xxx.png'的字符串，不用转换为base64
      this.imageUrl = file.response.data.src;
      this.$emit("uploadImgFile", res.data.src);
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .el-button {
  height: 38px;
  line-height: 38px;
  padding: 0 18px;
  background: #205dc3;
  color: #ffffff;
  font-size: 14px;
  border-radius: 2px;
}

::v-deep .el-button:hover,
::v-deep .el-button:focus {
  background: #205dc3;
  color: white;
}

::v-deep .el-upload {
  text-align: left;
}

.avatar {
  max-width: 20vw;
  height: 100%;
  display: block;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #205dc3;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  max-width: 20vw;
  height: 100%;
  text-align: center;
}
</style>
