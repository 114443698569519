<template>
  <div class="ONE_step">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="150px"
      class="demo-ruleForm"
    >
      <el-form-item label="诊断机构名称" prop="d_company">
        <el-input
          v-model="ruleForm.d_company"
          disabled
          class="chaninp"
        ></el-input>
      </el-form-item>
      <el-form-item label="诊断机构联系人" prop="d_contact">
        <el-input v-model="ruleForm.d_contact" class="chaninp"></el-input>
      </el-form-item>
      <el-form-item label="诊断机构联系方式" prop="d_phone">
        <el-input v-model="ruleForm.d_phone" class="chaninp"></el-input>
      </el-form-item>
      <el-form-item label="被诊断企业名称" prop="company_id">
        <el-input
          v-model="ruleForm.company"
          disabled
          class="chaninp"
        ></el-input>
      </el-form-item>
      <el-form-item label="被诊断企业联系人" prop="contact">
        <el-input v-model="ruleForm.contact" class="chaninp"></el-input>
      </el-form-item>
      <el-form-item label="被诊断企业联系电话" prop="phone">
        <el-input v-model="ruleForm.phone" class="chaninp"></el-input>
      </el-form-item>
      <el-form-item label="企业地点" prop="areaData">
        <AreaSelect :defVal="areaData" @submitArea="getAreaData"></AreaSelect>
      </el-form-item>
      <el-form-item label="所属行业" prop="trade_id">
        <el-select
          v-model="ruleForm.trade_id"
          class="form-input"
          placeholder="请选择"
          @change="checksecond"
          style="margin-right: 20px;"
        >
          <el-option
            v-for="(item, index) in hylist"
            :key="index"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-select
          v-model="ruleForm.secondtradeid"
          collapse-tags
          multiple
          placeholder="请选择"
          @change="$forceUpdate()"
          v-if="lylist.length > 0"
        >
          <el-option
            v-for="(item, index) in lylist"
            :key="index"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="协议签订时间" prop="signtime">
        <el-date-picker
          type="date"
          placeholder="选择日期"
          :picker-options="pickerOptions"
          v-model="ruleForm.signtime"
          style="width: 50%;"
        ></el-date-picker>
      </el-form-item>
      <el-form-item
        label="证明材料"
        class="sTip"
        required
        style="margin-bottom: 0;"
      >
        <p>允许格式：doc,docx,pdf,csv,xls,xlsx</p>
        <p>
          <span style="color: red;">请注意：</span>1. 不可重复上传同一个文件 2.
          最多只能上传3个文件 3. 上传文件并保存后不可删除
        </p>
      </el-form-item>
      <el-form-item label="" label-width="0" class="mosulop" prop="sign_file">
        <Moseupload
          ref="domref"
          :uploadlist="parentMessage"
          :allownum="3"
          @domfile-sent="handlefile"
          accept=".doc,.docx,.pdf,.csv,.xls,.xlsx"
        ></Moseupload>
        <!-- 代替form验证 -->
        <el-input v-model="ruleForm.sign_file" v-if="false"></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { validateMobile } from "@/utils/common.js";
import AreaSelect from "@/components/AreaSelect.vue";
import { GetTrade } from "@/api/login.js";
import Moseupload from "@/components/moreupload";
import { Get_companyedit_one, getMyCompany2 } from "@/api/pts";
import { formatDateTime } from "@/utils/common";
export default {
  components: {
    AreaSelect,
    Moseupload,
  },

  data() {
    return {
      myCompany2: [],
      ruleForm: {
        d_company: "",
        d_contact: "",
        d_phone: "",
        contact: "",
        phone: "",
        secondtradeid: [],
        signtime: "",
        sign_file: [],
        is_save: 1,
        company: "",
      },
      areaData: {}, //企业地点
      rules: {
        d_contact: [
          { required: true, message: "请输入", trigger: "blur" },
          { min: 2, max: 4, message: "长度在 2 到 4 个字符", trigger: "blur" },
        ],
        d_phone: [
          {
            required: true,
            validator: validateMobile.bind(this),
            trigger: "blur",
          },
        ],
        contact: [
          { required: true, message: "请输入", trigger: "blur" },
          { min: 2, max: 4, message: "长度在 2 到 4 个字符", trigger: "blur" },
        ],
        phone: [
          {
            required: true,
            validator: validateMobile.bind(this),
            trigger: "blur",
          },
        ],
        signtime: [
          { required: true, message: "请选择时间", trigger: "change" },
        ],
        sign_file: [
          { required: true, message: "请上传证明材料", trigger: "change" },
        ],
      },
      hylist: JSON.parse(sessionStorage.getItem("hylist")),
      lylist: [],
      selectDate: "",
      pickerOptions: {
        //写在data函数里
        disabledDate: (time) => {
          return time.getTime() > Date.now() - 8.64e7;
        },
      },
      parentMessage: [], //传文件数据进上传文件
      result: false, //表单校验返回值
    };
  },
  mounted() {
    this.getMyCompany2();
  },
  methods: {
    getMyCompany2() {
      getMyCompany2().then((res) => {
        this.myCompany2 = res.data;
      });
    },
    Getinfo(obj) {
      this.ruleForm = { ...obj };
      // this.ruleForm.trade_id = obj.trade_id[0]
      this.myCompany2.forEach((item) => {
        if (this.ruleForm.company_id == item.id) {
          this.ruleForm.company = item.nickname;
        }
      });
      // this.ruleForm.trade_id = +obj.trade_id;
      this.ruleForm.d_company = JSON.parse(
        sessionStorage.getItem("ptsInfo")
      ).name;
      this.ruleForm.company = obj.company_name
      var traid = obj.trade_id.split(",");
      this.ruleForm.trade_id = Number(traid[0]);
      if (traid.length >= 1) {
        this.getsecondtrade(traid[0]);
        this.ruleForm.secondtradeid = traid.slice(1).map(item=>{
          return Number(item)
        });
      }
      // this.areaData = `${this.ruleForm.province}/${this.ruleForm.city}/${this.ruleForm.area}/`;
      this.areaData = {
        provinceId: this.ruleForm.province == 0 ? null : this.ruleForm.province,
        cityId: this.ruleForm.city == 0 ? null : this.ruleForm.city,
        areaId: this.ruleForm.area == 0 ? null : this.ruleForm.area,
      };

      var domayy = [...obj.sign_file];
      domayy.forEach((item, index) => {
        let obi = {
          url: item.url,
          name: item.filename,
          state: 1, //代表已经上传过
          id: item.id,
          size: (item.filesize / 1024).toFixed(1) + "kb",
          isdele: false,
          renewdata: this.formatDateTime(item.uploadtime),
        };
        console.log(obi);
        domayy[index] = obi;
      });
      this.ruleForm.sign_file.forEach((item, index) => {
        this.ruleForm.sign_file[index] = item.id;
      });
      this.parentMessage = domayy;
    },
    getsecondtrade(pid) {
      console.log(pid);
      GetTrade({ pid: pid }).then((res) => {
        console.log(res);
        this.lylist = res.data;
      });
    },
    checksecond(val) {
      this.ruleForm.secondtradeid = [];
      this.getsecondtrade(val);
    },
    getAreaData(value) {
      this.ruleForm.province = value.provinceId;
      this.ruleForm.city = value.cityId;
      this.ruleForm.area = value.areaId;
    },
    handlefile(list) {
      console.log(list);
      if (list.length > 0) {
        var arr = [];
        list.forEach((item) => {
          arr.push(item.id);
        });

        console.log(this.arr, "-----------4");
        this.ruleForm.sign_file = arr;
      } else {
        console.log("==============1");
        this.ruleForm.sign_file = [];
      }
      console.log(this.ruleForm.sign_file);
      this.$refs.ruleForm.validateField(["sign_file"]); //单个校验
    },
    async submitForm(formName) {
      try {
        //调用表单的validate方法，执行表单校验
        await this.$refs[formName].validate();
      } catch (e) {
        //如果表单有报错，则进入catch，此时直接return不执行表单提交
        return false;
      }
      return true;
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 流程一保存
    async Post_one() {
      let params = { ...this.ruleForm };
      params.is_save = 1;
      params.sign_file = params.sign_file.join(",");
      console.log(params, "params");
      params.trade_id =
        params.trade_id + "," + params.secondtradeid.join(",");
      try {
        await Get_companyedit_one(params);
      } catch (e) {
        return false;
      }
      return true;
    },
    formatDateTime(timestamp) {
      return formatDateTime(timestamp);
    },
    timestampToTime(timestamp) {
      var date = new Date(timestamp * 1000);
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();

      let strDate = Y + M + D + h + m + s;
      return strDate;
    },
  },
};
</script>
<style lang="less" scoped>
.ONE_step {
  height: 500px;
  overflow: auto;
  padding-right: 20px;

  .chaninp {
    width: 50%;
  }
}
</style>
