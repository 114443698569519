import api from "./index";
import api2 from "./index2";

// 获取需求列表
export function getRequireList(data) {
  return api.post("/index/demand/index", { data });
}

// 根据id获取单个需求信息
export function getOneRequire(data) {
  return api.post("/index/demand/get_one", { data });
}

// 提交需求信息
export function submitRequire(data) {
  return api.post("/index/demand/saveEdit", { data });
}

// 获取四化问卷
export function getSHQuestionList() {
  return api.get("/index/demand/get_examine_wj");
}

// 提交四化问卷
export function setSHQuestion(data) {
  return api.post("/index/demand/set_examine_wj", { data });
}

// 获取四化问卷结果
export function getSHResult() {
  return api.post("/index/demand/get_examine_res");
}

// 获取数字化问卷
export function getSZHQuestionList(data) {
  return api.get("/index/demand/get_exam_wj", { data });
}

// 提交数字化问卷
export function setSZHQuestion(data) {
  return api.post("/index/demand/set_exam_wj", { data });
}

// 获取数字化问卷结果
export function getSZHResult() {
  return api.post("/index/demand/get_exam_res");
}

// 获取行业
export function getTradeOptions(data) {
  return api.post("/api/common/getWjTrade", { data });
}

// 获取细分行业
export function getTradeXFOptions(data) {
  return api.post("/api/common/getWjTrade", { data });
}

//问卷列表
export function getmyCompanyDigitalizeList(data) {
  return api.get("/index/CompanyDigitalize/myCompanyDigitalizeList", {
    params: data,
  });
}

// 获取企业信息
export function getCompanyInfo() {
  return api.post("/index/company/companyInfoEdit");
}

// 保存草稿
export function saveDraft(data) {
  return api.post("/index/company/saveDraft", { data });
}

// 获取草稿
export function getDraft(data) {
  return api.post("/index/company/getDraft", { data });
}

// 企业改造意向摸查表填写
export function searchTableAdd(data) {
  return api.post("/index/CompanyDigitalize/addData", { data });
}

// 平台商企业改造意向摸查表详情
export function getmyCompanyDigitalizeListInfo(data) {
  return api.post("/index/CompanyDigitalize/getInfoById", { data });
}

// 获取中小企业数字化水平自评测问卷
export function getQuestionAll(data) {
  return api2.post("/api/selftResult/getQuestionAll", { data });
}

// 提交中小企业数字化水平自评测问卷
export function addSelftResult(data) {
  return api2.post("/api/selftResult/createSelftResult", { data });
}

// 获取中小企业数字化水平自评测问卷结果
export function getSelftResult(data) {
  return api2.post("/api/selftResult/getSelftResult", { data });
}

// 保存中小企业数字化水平自评测问卷草稿
export function createSelftDraf(data) {
  return api2.post("/api/selftDraft/createSelftDraf", { data });
}

// 获取中小企业数字化水平自评测问卷草稿
export function getSelftDraft(data) {
  return api2.post("/api/selftDraft/getSelftDraft", { data });
}

// 获取企业详细数据
export function getCompanyInfoEdit(data) {
  return api2.post("/api/company/companyInfoEdit", { data });
}

// 获取平台商企业名称列表
export function getCompanyStuList(data) {
  return api2.post("/api/digital/getCompanyStu", { data });
}

// 获取产品申报列表
export function getProductDeclarationList(data) {
  return api2.post("/api/digital/getProductDeclaration", { data });
}

// 获取产品申报列表
export function addOrUpdateDigital(data) {
  return api2.post("/api/digital/addOrUpdateDigital", { data });
}

//分页查询数字化培育项目
export function getDigitalPage(data) {
  return api2.post("/api/digital/getDigitalPage", { data });
}

// 查询是否完成试点意向企业入库申报
export function checkDigital(data) {
  return api2.post("/api/digital/checkDigital", { data });
}

//修改项目进度
export function updateDigitalProgress(data) {
  return api2.post("/api/digital/changeProgress", { data });
}

//导出数字化培育项目
export function exportDigitalProject(data) {
  return api2.post("/api/digital/export", { data, responseType: "blob" });
}

// 获取产品申报详情
export function getDigitalDetail(data) {
  return api2.post("/api/digital/getDetail", { data });
}

// export default {
//     getQuestionList
// }
