import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    user: [],
    navName: "",
  },
  mutations: {
    SET_USER(state, payload) {
      state.user = payload;
    },
    SET_NAV_NAME(state, payload) {
      state.navName = payload;
    },
  },
  actions: {
    fetchUserInfo() {
      // TODO 调用接口 用token获取当前信息 然后存user
    },
  },
  getters: {
    getNavName(state) {
      return state.navName;
    },
  },
});

export default store;
