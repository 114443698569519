<template>
  <div class="container">
    <div class="same-container">
      <div class="size-container">
        <div class="public-container">
          <div class="top-nav">
            <el-breadcrumb separator=">">
              <el-breadcrumb-item
                :to="{
                  path: '/government/sbzj',
                }"
                >申报征集</el-breadcrumb-item
              >
              <el-breadcrumb-item
                :to="{
                  path: '/government/sbzj/listSecond',
                  query: { type: listType },
                }"
                >试点意向企业入库申报</el-breadcrumb-item
              >
              <el-breadcrumb-item>详情</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="form-container">
            <div class="form-title-content">
              <h1 v-if="listType == 1" class="form-title">
                数字化产品入库申报
              </h1>
              <h1 v-else-if="listType == 2" class="form-title">
                试点意向企业入库申报
              </h1>
              <div class="line"></div>
            </div>
            <el-form
              label-position="left"
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="250px"
              class="ruleForm"
            >
              <h5 class="subheadings">一、企业基本信息</h5>
              <el-form-item label="企业名称" prop="enterprise_name">
                <el-input
                  v-model="ruleForm.enterprise_name"
                  disabled
                ></el-input>
              </el-form-item>
              <el-form-item label="所属行政区" prop="administrative_district">
                <el-input
                  v-model="ruleForm.administrative_district"
                  disabled
                ></el-input>
              </el-form-item>
              <el-form-item label="所属细分行业" prop="sub_industry">
                <el-radio-group
                  v-model="ruleForm.sub_industry"
                  size="medium"
                  disabled
                >
                  <el-radio label="智能网联和新能源汽车（零部件）"></el-radio>
                  <el-radio label="工业母机和机器人"></el-radio>
                  <el-radio label="时尚美妆"></el-radio>
                  <el-radio label="定制家居"></el-radio>
                  <el-radio label="服装"></el-radio>
                  <el-radio label="箱包"></el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="企业性质" prop="enterprise_nature">
                <el-radio-group
                  v-model="ruleForm.enterprise_nature"
                  size="medium"
                  disabled
                >
                  <el-radio label="内资企业"></el-radio>
                  <el-radio label="外资企业"></el-radio>
                  <el-radio label="中外合资"></el-radio>
                  <el-radio label="其他"></el-radio>
                </el-radio-group>
              </el-form-item>
              <!-- label="是否为中小企业" -->
              <el-form-item prop="is_small_medium_enterprise">
                <span slot="label">
                  是否为中小企业
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="（企业划型标准依据《工业和信息化部 统计局 发展改革委
                    财政部关于印发中小企业划型标准规定的通知》（工信部联企业〔2011〕300号））"
                    placement="top-start"
                  >
                    <i class="el-icon-warning"></i>
                  </el-tooltip>
                </span>
                <div class="sf-content">
                  <el-radio-group
                    style="display: flex;"
                    v-model="ruleForm.is_small_medium_enterprise"
                    size="medium"
                    disabled
                  >
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </div>
              </el-form-item>
              <!-- label="是否为制造业企业" -->
              <el-form-item prop="is_manufacturing_enterprise">
                <span slot="label">
                  是否为制造业企业
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="（行业划分标准依据《国民经济行业分类(GB/T 4754-2017)》）"
                    placement="top-start"
                  >
                    <i class="el-icon-warning"></i>
                  </el-tooltip>
                </span>
                <div class="sf-content">
                  <el-radio-group
                    style="display: flex;"
                    v-model="ruleForm.is_manufacturing_enterprise"
                    size="medium"
                    disabled
                  >
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </div>
              </el-form-item>
              <el-form-item label="统一社会信用代码" prop="social_credit_code">
                <el-input
                  v-model="ruleForm.social_credit_code"
                  disabled
                ></el-input>
              </el-form-item>
              <el-form-item
                label="企业类别（一）"
                prop="enterprise_category_one"
              >
                <el-radio-group
                  v-model="ruleForm.enterprise_category_one"
                  size="medium"
                  disabled
                >
                  <el-radio label="专精特新“小巨人”企业"></el-radio>
                  <el-radio label="专精特新中小企业"></el-radio>
                  <el-radio label="创新型中小企业"></el-radio>
                  <el-radio label="其他中小企业"></el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                label="企业类别（二）"
                prop="enterprise_category_two"
              >
                <el-radio-group
                  v-model="ruleForm.enterprise_category_two"
                  size="medium"
                  disabled
                >
                  <el-radio label="规上企业"></el-radio>
                  <el-radio label="规下企业"></el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                label="2023年营业收入（万元）"
                prop="annual_revenue"
              >
                <el-input v-model="ruleForm.annual_revenue" disabled></el-input>
              </el-form-item>
              <el-form-item label="员工数（截至申报日）" prop="employee_count">
                <el-input v-model="ruleForm.employee_count" disabled></el-input>
              </el-form-item>
              <el-form-item label="主营业务或主营产品" prop="main_business">
                <el-input
                  type="textarea"
                  v-model="ruleForm.main_business"
                  :autosize="{ minRows: 4, maxRows: 8 }"
                  disabled
                ></el-input>
              </el-form-item>
              <el-form-item
                label="所属细分行业开展业务证明材料"
                prop="business_evidence_material"
              >
                <div class="zmcl-content">
                  <el-input
                    type="textarea"
                    placeholder="例如营业执照中经营范围，或具备申报细分行业客户服务案例合同。"
                    v-model="ruleForm.business_evidence_material"
                    :autosize="{ minRows: 4, maxRows: 8 }"
                    disabled
                  ></el-input>
                  <div class="zmcl-upload">
                    <div class="zmcl-content-left">
                      <div
                        v-for="(item, index) in JSON.parse(
                          ruleForm.business_evidence_material_url
                        )"
                        :key="index"
                        class="flex vertical "
                        style="margin-bottom:8px;"
                      >
                        <el-link
                          type="primary"
                          target="_blank"
                          :underline="false"
                          :href="item.url | contentMedia"
                          :download="item.name"
                        >
                          {{ item.name }}
                        </el-link>
                        <!-- <i
                          class="el-icon-error dellink"
                          @click="handleDelFileBEM(index)"
                        ></i> -->
                      </div>
                    </div>
                    <!-- <div class="zmcl-content-right">
                      <el-button type="primary" class="pri-btn"
                        >查看文件</el-button
                      >
                    </div> -->
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="联系人" prop="contact_person">
                <el-input v-model="ruleForm.contact_person" disabled></el-input>
              </el-form-item>
              <el-form-item label="职务" prop="position">
                <el-input v-model="ruleForm.position" disabled></el-input>
              </el-form-item>
              <el-form-item label="电话" prop="phone">
                <el-input v-model="ruleForm.phone" disabled></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div class="form-container">
            <el-form
              label-position="top"
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="250px"
              class="ruleForm"
            >
              <h5 class="subheadings">二、制造业中小企业数字化水平评估</h5>
              <el-form-item label="">
                <el-radio-group
                  v-model="ruleForm.company_digitalize_assess.assess_lv"
                  size="medium"
                  disabled
                >
                  <!-- <el-radio label="1级以下（20分以下）"></el-radio>
                  <el-radio label="1级（20分～40分）"></el-radio>
                  <el-radio label="2级（40分～60分）"></el-radio>
                  <el-radio label="3级（60分～80分）"></el-radio>
                  <el-radio label="4级（80分以上）"></el-radio> -->
                  <el-radio label="无等级"></el-radio>
                  <el-radio label="一级"></el-radio>
                  <el-radio label="二级"></el-radio>
                  <el-radio label="三级"></el-radio>
                  <el-radio label="四级"></el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="">
                <div class="sppgfj-upload">
                  <div
                    v-for="(item, index) in JSON.parse(
                      JSON.parse(ruleForm.company_digitalize_assess.assess_file)
                    )"
                    :key="index"
                    class="flex vertical "
                    style="margin-bottom:8px;margin-right: 10px;"
                  >
                    <el-link
                      type="primary"
                      target="_blank"
                      :underline="false"
                      :href="item.url | contentMedia"
                      :download="item.name"
                    >
                      {{ item.name }}
                    </el-link>
                  </div>
                  <!-- <div class="sppgfj-content-right">
                    <el-button type="primary" class="pri-btn"
                      >查看文件</el-button
                    >
                  </div> -->
                </div>
              </el-form-item>
            </el-form>
          </div>
          <div class="form-container">
            <el-form
              label-position="top"
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm2"
              label-width="250px"
              class="ruleForm"
            >
              <h5 class="subheadings">三、企业数字化转型意愿摸查</h5>
              <el-form-item
                label="企业数字化改造意愿强烈程度"
                prop="company_digitalize_agree.willingness_degree"
              >
                <el-radio-group
                  v-model="ruleForm.company_digitalize_agree.willingness_degree"
                  size="medium"
                  disabled
                >
                  <el-radio label="强烈"></el-radio>
                  <el-radio label="一般"></el-radio>
                  <el-radio label="较弱"></el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                label="企业改造后目标数字化水平"
                prop="company_digitalize_agree.target_digital_level"
              >
                <el-radio-group
                  v-model="
                    ruleForm.company_digitalize_agree.target_digital_level
                  "
                  size="medium"
                  disabled
                >
                  <el-radio label="二级"></el-radio>
                  <el-radio label="三级"></el-radio>
                  <el-radio label="四级"></el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                label="企业数字化转型第三方诊断及整体规划（含企业业务数字化痛点诊断、数字化相关产品选型、数字化转型规划建议等）"
                prop="company_digitalize_agree.third_party_diagnosis"
              >
                <el-radio-group
                  v-model="
                    ruleForm.company_digitalize_agree.third_party_diagnosis
                  "
                  size="medium"
                  disabled
                >
                  <el-radio :label="1">需要</el-radio>
                  <el-radio :label="0">不需要</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                label="企业工业数字化改造升级计划"
                prop="company_digitalize_agree.upgrade_plan"
              >
                <el-radio-group
                  v-model="ruleForm.company_digitalize_agree.upgrade_plan"
                  size="medium"
                  disabled
                >
                  <el-radio label="单机"></el-radio>
                  <el-radio label="产线"></el-radio>
                  <el-radio label="车间"></el-radio>
                  <el-radio label="工厂整体"></el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                label="企业通过数字化转型拟投资额度"
                prop="company_digitalize_agree.investment_amount"
              >
                <el-radio-group
                  v-model="ruleForm.company_digitalize_agree.investment_amount"
                  size="medium"
                  disabled
                >
                  <el-radio label="50万以下"></el-radio>
                  <el-radio label="100万以下"></el-radio>
                  <el-radio label="200万以下"></el-radio>
                  <el-radio label="300万以下"></el-radio>
                  <el-radio label="400万以下"></el-radio>
                  <el-radio label="500万以下"></el-radio>
                  <el-radio label="数字化车间标准（800万）"></el-radio>
                  <el-radio label="智能工厂标准（2000万）"></el-radio>
                  <el-radio label="链主工厂标准（3000万）"></el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                label="企业通过数字化转型拟提升的效益指标"
                prop="company_digitalize_agree.benefit_indicators"
              >
                <el-radio-group
                  v-model="ruleForm.company_digitalize_agree.benefit_indicators"
                  size="medium"
                  disabled
                >
                  <div class="radio-content">
                    <el-radio label="产品质量"></el-radio>
                    <el-radio label="生产效率"></el-radio>
                    <el-radio label="产品成本"></el-radio>
                    <el-radio label="其他：">
                      <span>其他：</span>
                      <el-input
                        class="ss-input"
                        v-model="
                          ruleForm.company_digitalize_agree
                            .benefit_indicators_other
                        "
                        disabled
                      />
                    </el-radio>
                  </div>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                label="企业更倾向于哪种转型牵引模式？"
                prop="company_digitalize_agree.mode"
              >
                <el-radio-group
                  class="qyms-radio-group"
                  v-model="ruleForm.company_digitalize_agree.mode"
                  size="medium"
                  disabled
                >
                  <el-radio class="qyms-radio-option" label="产业链模式">
                    <span>产业链模式</span>
                    <div class="qyms-description">
                      （该模式由具有较深行业制造知识和服务经验、较强行业资源整合能力的细分行业平台型企业，包括从制造业企业衍生的工业服务平台或深耕制造业细分产业链的第三方行业平台等为牵引单位，牵头开展本行业中小企业数字化改造实施。）
                    </div>
                  </el-radio>
                  <el-radio class="qyms-radio-option" label="供应链模式">
                    <span>供应链模式</span>
                    <div class="qyms-description">
                      (该模式由具有产业链供应链号召力、有强烈的供应链数字化提升意愿、能够辐射带动供应链中小企业的制造业骨干企业为牵引单位，牵头开展本行业中小企业数字化改造实施。)
                    </div>
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                label="在数字化转型方面最迫切的诉求（可多选）"
                prop="company_digitalize_agree.urgent_needs"
              >
                <el-checkbox-group v-model="zxsq_checkList" disabled>
                  <div class="checkbox-content">
                    <el-checkbox label="获取低成本原材料采购渠道"></el-checkbox>
                    <el-checkbox label="获取更多市场订单"></el-checkbox>
                    <el-checkbox label="打通生产制造环节数据要素"></el-checkbox>
                    <el-checkbox label="提升供应链管理能力"></el-checkbox>
                    <el-checkbox label="提升企业经营管理能力"></el-checkbox>
                    <el-checkbox label="降低企业人力成本"></el-checkbox>
                    <el-checkbox label="提升产品研发设计能力"></el-checkbox>
                    <el-checkbox label="提升生产制造管理能力"></el-checkbox>
                    <el-checkbox label="产品质量全流程追溯"></el-checkbox>
                    <el-checkbox label="其他：">
                      <span>其他：</span>
                      <el-input
                        class="ss-input"
                        v-model="
                          ruleForm.company_digitalize_agree.urgent_needs_other
                        "
                        disabled
                      />
                    </el-checkbox>
                  </div>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item
                label="企业当前急需进行数字化转型的业务场景或业务环节"
                prop="company_digitalize_agree.business_scenarios"
              >
                <el-checkbox-group
                  v-model="ruleForm.company_digitalize_agree.business_scenarios"
                  disabled
                >
                  <div class="checkbox-content">
                    <el-checkbox label="研发设计"></el-checkbox>
                    <el-checkbox label="生产制造"></el-checkbox>
                    <el-checkbox label="仓储物流"></el-checkbox>
                    <el-checkbox label="市场营销"></el-checkbox>
                    <el-checkbox label="产品/服务"></el-checkbox>
                    <el-checkbox label="供应链管理"></el-checkbox>
                    <el-checkbox
                      label="运营管理（人力、财务、资产管理等）"
                    ></el-checkbox>
                    <el-checkbox label="其他：">
                      <span>其他：</span>
                      <el-input
                        class="ss-input"
                        v-model="
                          ruleForm.company_digitalize_agree
                            .business_scenarios_other
                        "
                        disabled
                      />
                    </el-checkbox>
                  </div>
                </el-checkbox-group>
                <!-- <el-radio-group
                  v-model="ruleForm.company_digitalize_agree.business_scenarios"
                  size="medium"
                  disabled
                >
                  <div class="radio-content">
                    <el-radio label="研发设计"></el-radio>
                    <el-radio label="生产制造"></el-radio>
                    <el-radio label="仓储物流"></el-radio>
                    <el-radio label="市场营销"></el-radio>
                    <el-radio label="产品/服务"></el-radio>
                    <el-radio label="供应链管理"></el-radio>
                    <el-radio
                      label="运营管理（人力、财务、资产管理等）"
                    ></el-radio>
                    <el-radio label="其他：">
                      <span>其他：</span>
                      <el-input
                        class="ss-input"
                        v-model="
                          ruleForm.company_digitalize_agree
                            .business_scenarios_other
                        "
                        disabled
                      />
                    </el-radio>
                  </div>
                </el-radio-group> -->
              </el-form-item>
              <el-form-item
                label="企业当前数字化转型痛点难点或转型需求的描述"
                prop="company_digitalize_agree.notes"
              >
                <el-input
                  type="textarea"
                  placeholder="500字以内"
                  v-model="ruleForm.company_digitalize_agree.notes"
                  :autosize="{ minRows: 4, maxRows: 8 }"
                  disabled
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div class="form-container">
            <el-form
              label-position="top"
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="250px"
              class="ruleForm"
            >
              <h5 class="subheadings">四、意向数字化牵引单位选择</h5>
              <el-form-item
                label="企业意向合作数字化牵引单位"
                prop="company_digitalize_agree_select.company"
              >
                <div class="qydw-content">
                  <el-checkbox-group
                    class="qydw-radio"
                    v-model="ruleForm.company_digitalize_agree_select.company"
                    size="medium"
                    disabled
                  >
                    <div
                      class="qydw-item"
                      v-for="(item, index) in tractionUnitList"
                      :key="index"
                    >
                      <div class="qydw-item-title">
                        {{ item.segmentedIndustry }}：
                      </div>
                      <el-checkbox
                        v-for="(subitem,
                        subindex) in item.digitalTractionUnitList"
                        :key="subindex"
                        class="qydw-radio-option"
                        :label="
                          subitem.label +
                            '（' +
                            (subitem.contactPerson || '') +
                            ' ' +
                            (subitem.contactWay || '') +
                            '）'
                        "
                      ></el-checkbox>
                    </div>
                    <!-- <div class="qydw-item">
                      <div class="qydw-item-title">
                        智能网联和新能源汽车（零部件）行业：
                      </div>
                      <el-checkbox
                        class="qydw-radio-option"
                        label="广州明珞装备股份有限公司（陈晓霞 18898608792）"
                      ></el-checkbox>
                      <el-checkbox
                        class="qydw-radio-option"
                        label="格创东智（广州）科技技术有限公司（刘舒妍 18813293359）"
                      ></el-checkbox>
                      <el-checkbox
                        class="qydw-radio-option"
                        label="浪潮云洲工业互联网有限公司（罗嘉楠 18825188587）"
                      ></el-checkbox>
                      <el-checkbox
                        class="qydw-radio-option"
                        label="联通(广东)产业互联网有限公司（胡盛龙 15608488188）"
                      ></el-checkbox>
                      <el-checkbox
                        class="qydw-radio-option"
                        label="杭州海康威视数字技术股份有限公司（窦彬 18969181880）"
                      ></el-checkbox>
                    </div>
                    <div class="qydw-item">
                      <div class="qydw-item-title">
                        工业母机和机器人行业：
                      </div>
                      <el-checkbox
                        class="qydw-radio-option"
                        label="广州数控设备有限公司（翁晓芳 13316295118）"
                      ></el-checkbox>
                      <el-checkbox
                        class="qydw-radio-option"
                        label="树根互联股份有限公司（魏茂生 13726227688）"
                      ></el-checkbox>
                      <el-checkbox
                        class="qydw-radio-option"
                        label="广东玛斯特智能系统有限公司（赵瑾 18520139696）"
                      ></el-checkbox>
                      <el-checkbox
                        class="qydw-radio-option"
                        label="华为云计算技术有限公司（陈玲 18314582780）"
                      ></el-checkbox>
                      <el-checkbox
                        class="qydw-radio-option"
                        label="广东用友软件有限公司（夏芸 13428829487）"
                      ></el-checkbox>
                    </div>
                    <div class="qydw-item">
                      <div class="qydw-item-title">
                        时尚美妆行业：
                      </div>
                      <el-checkbox
                        class="qydw-radio-option"
                        label="广州蜂巢互联科技有限公司（陈晓杰 18998291266）"
                      ></el-checkbox>
                      <el-checkbox
                        class="qydw-radio-option"
                        label="广州中浩控制技术有限公司（卢颖希 15011703065）"
                      ></el-checkbox>
                      <el-checkbox
                        class="qydw-radio-option"
                        label="广州环亚化妆品科技股份有限公司（袁首春 18922206198）"
                      ></el-checkbox>
                      <el-checkbox
                        class="qydw-radio-option"
                        label="金蝶软件(中国)有限公司（梁惠娜 15018726132）"
                      ></el-checkbox>
                      <el-checkbox
                        class="qydw-radio-option"
                        label="广东丸美生物技术股份有限公司（唐彬峰 18320022582）"
                      ></el-checkbox>
                    </div>
                    <div class="qydw-item">
                      <div class="qydw-item-title">
                        定制家居行业：
                      </div>
                      <el-checkbox
                        class="qydw-radio-option"
                        label="广州尚品宅配家居股份有限公司（黄雪丹 13760963979）"
                      ></el-checkbox>
                      <el-checkbox
                        class="qydw-radio-option"
                        label="欧派家居集团股份有限公司（刘婷 13922220885）"
                      ></el-checkbox>
                      <el-checkbox
                        class="qydw-radio-option"
                        label="广州鼎捷软件有限公司（陶建林 13925119286）"
                      ></el-checkbox>
                      <el-checkbox
                        class="qydw-radio-option"
                        label="广州赛意信息科技股份有限公司（罗冰 13612826403）"
                      ></el-checkbox>
                    </div>
                    <div class="qydw-item">
                      <div class="qydw-item-title">
                        服装行业：
                      </div>
                      <el-checkbox
                        class="qydw-radio-option"
                        label="比音勒芬服饰股份有限公司（胡蓉 17727617169）"
                      ></el-checkbox>
                      <el-checkbox
                        class="qydw-radio-option"
                        label="广东省纺织品进出口股份有限公司（宫兵 13922272819）"
                      ></el-checkbox>
                      <el-checkbox
                        class="qydw-radio-option"
                        label="广州春晓信息科技有限公司（梁丽萍 13533123810）"
                      ></el-checkbox>
                      <el-checkbox
                        class="qydw-radio-option"
                        label="广东粤桨产业科技有限公司（魏建亮 13642304604）"
                      ></el-checkbox>
                    </div>
                    <div class="qydw-item">
                      <div class="qydw-item-title">
                        箱包行业：
                      </div>
                      <el-checkbox
                        class="qydw-radio-option"
                        label="广州盖特软件有限公司（曾怡 18928781587）"
                      ></el-checkbox>
                    </div> -->
                  </el-checkbox-group>
                </div>
              </el-form-item>

              <el-form-item
                label="是否与所选数字化牵引单位或牵引单位服务生态企业已建立联系"
                prop="company_digitalize_agree_select.is_selected"
              >
                <el-radio-group
                  v-model="ruleForm.company_digitalize_agree_select.is_selected"
                  size="medium"
                  disabled
                >
                  <el-radio label="是，已建立联系"></el-radio>
                  <el-radio label="否，需要引导对接建立联系"></el-radio>
                </el-radio-group>
              </el-form-item>

              <el-form-item
                label="是否有与上述牵引单位之外的服务商建立联系或合作"
                prop="company_digitalize_agree_select.is_other_company"
              >
                <el-radio-group
                  v-model="
                    ruleForm.company_digitalize_agree_select.is_other_company
                  "
                  size="medium"
                  disabled
                >
                  <div class="radio-content">
                    <el-radio label="是" style="width: 400px;">
                      <span>服务商全称：</span>
                      <el-input
                        class="ss-input"
                        style="width: 300px;"
                        v-model="
                          ruleForm.company_digitalize_agree_select.other_company
                        "
                        disabled
                      />
                    </el-radio>
                    <el-radio label="否"></el-radio>
                  </div>
                </el-radio-group>
              </el-form-item>

              <h5 class="form-subtitle2">审核</h5>
              <div class="auditStatus-container">
                <div class="auditStatus-title">审核状态</div>
                <div class="auditStatus-desc">
                  <el-radio-group
                    v-model="
                      ruleForm.company_digitalize_agree_select.audit_status
                    "
                  >
                    <el-radio :label="1">待审核</el-radio>
                    <el-radio :label="2">审核通过</el-radio>
                    <el-radio :label="3">审核不通过</el-radio>
                  </el-radio-group>
                </div>
                <div class="auditStatus-title">原因</div>
                <div class="audit_reason">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 4, maxRows: 8 }"
                    placeholder="请输入原因"
                    v-model="
                      ruleForm.company_digitalize_agree_select.audit_reason
                    "
                  />
                </div>
                <div class="auditStatus-title">政府分配意向数字化牵引单位</div>
                <el-select
                  v-model="importCompanyName"
                  filterable
                  placeholder="请选择"
                  @change="handleImportCompanyChange"
                >
                  <el-option
                    v-for="(item, index) in importCompanyList"
                    :key="index"
                    :label="item.label"
                    :value="item.label"
                  >
                  </el-option>
                </el-select>
              </div>

              <div class="cancel-btn">
                <el-button type="info" class="pri-btn" @click="$router.back()"
                  >关闭</el-button
                >
                <el-button @click="beforeSubmit" type="primary" class="btn"
                  >确定</el-button
                >
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCompanyDigitalizeById, govementUpdateCompany } from "@/api/zf";
import { getTractionUnit } from "@/api/pts";
export default {
  data() {
    return {
      ruleForm: {
        enterprise_name: "",
        administrative_district: "",
        sub_industry: "",
        enterprise_nature: "",
        is_small_medium_enterprise: 0,
        is_manufacturing_enterprise: 0,
        social_credit_code: "",
        enterprise_category_one: "",
        enterprise_category_two: "",
        annual_revenue: "",
        employee_count: "",
        main_business: "",
        business_evidence_material: "",
        business_evidence_material_url: "[]",
        contact_person: "",
        position: "",
        phone: "",
        company_digitalize_agree: {
          willingness_degree: "",
          target_digital_level: "",
          third_party_diagnosis: 0,
          upgrade_plan: "",
          investment_amount: "",
          benefit_indicators: "",
          benefit_indicators_other: "",
          mode: "",
          urgent_needs: [],
          urgent_needs_other: "",
          business_scenarios: "",
          business_scenarios_other: "",
          notes: "",
        },
        company_digitalize_agree_select: {
          company: [],
          is_selected: "",
        },
        company_digitalize_assess: {
          assess_lv: "",
          assess_file: "[]",
        },
      },

      rules: {
        // 一
        enterprise_name: [
          { required: true, message: "请输入企业名称", trigger: "change" },
        ],
        administrative_district: [
          { required: true, message: "请输入所属行政区", trigger: "change" },
        ],
        sub_industry: [
          { required: true, message: "请输入所属细分行业", trigger: "change" },
        ],
        enterprise_nature: [
          { required: true, message: "请输入企业性质", trigger: "change" },
        ],
        is_small_medium_enterprise: [
          {
            required: true,
            message: "请输入是否为中小企业",
            trigger: "change",
          },
        ],
        is_manufacturing_enterprise: [
          {
            required: true,
            message: "请输入是否为制造业企业",
            trigger: "change",
          },
        ],
        social_credit_code: [
          { required: true, message: "请输入社会信用代码", trigger: "change" },
        ],
        enterprise_category_one: [
          {
            required: true,
            message: "请输入企业类别（一）",
            trigger: "change",
          },
        ],
        enterprise_category_two: [
          {
            required: true,
            message: "请输入企业类别（二）",
            trigger: "change",
          },
        ],
        annual_revenue: [
          {
            required: true,
            message: "请输入2023年营业收入（万元）",
            trigger: "change",
          },
        ],
        employee_count: [
          {
            required: true,
            message: "请输入员工数（截至申报日）",
            trigger: "change",
          },
        ],
        main_business: [
          {
            required: true,
            message: "请输入主营业务或主营产品",
            trigger: "change",
          },
        ],
        business_evidence_material: [
          {
            required: true,
            message: "请输入所属细分行业开展业务证明材料",
            trigger: "change",
          },
        ],
        contact_person: [
          { required: true, message: "请输入联系人", trigger: "change" },
        ],
        position: [
          { required: true, message: "请输入职务", trigger: "change" },
        ],
        phone: [{ required: true, message: "请输入电话", trigger: "change" }],

        //三
        "company_digitalize_assess.assess_lv": [
          {
            required: true,
            message: "请选择制造业中小企业数字化水平评估",
            trigger: "change",
          },
        ],
        "company_digitalize_agree.willingness_degree": [
          {
            required: true,
            message: "请选择企业数字化改造意愿强烈程度",
            trigger: "change",
          },
        ],
        "company_digitalize_agree.target_digital_level": [
          {
            required: true,
            message: "请选择企业改造后目标数字化水平",
            trigger: "change",
          },
        ],
        "company_digitalize_agree.third_party_diagnosis": [
          {
            required: true,
            message: "请选择企业数字化转型第三方诊断及整体规划",
            trigger: "change",
          },
        ],
        "company_digitalize_agree.upgrade_plan": [
          {
            required: true,
            message: "请选择企业工业数字化改造升级计划",
            trigger: "change",
          },
        ],
        "company_digitalize_agree.investment_amount": [
          {
            required: true,
            message: "请选择企业通过数字化转型拟投资额度",
            trigger: "change",
          },
        ],
        "company_digitalize_agree.benefit_indicators": [
          {
            required: true,
            message: "请选择企业通过数字化转型拟提升的效益指标",
            trigger: "change",
          },
        ],
        "company_digitalize_agree.mode": [
          {
            required: true,
            message: "请选择企业更倾向于哪种转型牵引模式？",
            trigger: "change",
          },
        ],
        "company_digitalize_agree.urgent_needs": [
          {
            required: true,
            message: "请选择在数字化转型方面最迫切的诉求",
            trigger: "change",
          },
        ],
        "company_digitalize_agree.business_scenarios": [
          {
            required: true,
            message: "请选择企业当前急需进行数字化转型的业务场景或业务环节",
            trigger: "change",
          },
        ],
        "company_digitalize_agree.notes": [
          {
            required: true,
            message: "请输入企业当前数字化转型痛点难点或转型需求的描述",
            trigger: "change",
          },
        ],

        //四
        "company_digitalize_agree_select.company": [
          {
            required: true,
            message: "请选择企业意向合作数字化牵引单位",
            trigger: "change",
          },
        ],
        "company_digitalize_agree_select.is_selected": [
          {
            required: true,
            message:
              "请选择是否与所选数字化牵引单位或牵引单位服务生态企业已建立联系",
            trigger: "change",
          },
        ],
        "company_digitalize_agree_select.is_other_company": [
          {
            required: true,
            message: "请选择是否有与上述牵引单位之外的服务商建立联系或合作",
            trigger: "change",
          },
        ],
      },

      zxsq_checkList: [],

      // 企业下拉框
      importCompanyList: [
        // {
        //   id: 1,
        //   name: "广州明珞装备股份有限公司",
        // },
        // {
        //   id: 2,
        //   name: "格创东智（广州）科技技术有限公司",
        // },
        // {
        //   id: 3,
        //   name: "浪潮云洲工业互联网有限公司",
        // },
        // {
        //   id: 4,
        //   name: "广州数控设备有限公司",
        // },
        // {
        //   id: 5,
        //   name: "树根互联股份有限公司",
        // },
        // {
        //   id: 6,
        //   name: "广东玛斯特智能系统有限公司",
        // },
        // {
        //   id: 7,
        //   name: "广州蜂巢互联科技有限公司",
        // },
        // {
        //   id: 8,
        //   name: "广州中浩控制技术有限公司",
        // },
        // {
        //   id: 9,
        //   name: "广州环亚化妆品科技股份有限公司",
        // },
        // {
        //   id: 10,
        //   name: "广州尚品宅配家居股份有限公司",
        // },
        // {
        //   id: 11,
        //   name: "欧派家居集团股份有限公司",
        // },
        // {
        //   id: 12,
        //   name: "广州鼎捷软件有限公司",
        // },
        // {
        //   id: 13,
        //   name: "比音勒芬服饰股份有限公司",
        // },
        // {
        //   id: 14,
        //   name: "广东省纺织品进出口股份有限公司",
        // },
        // {
        //   id: 15,
        //   name: "广州春晓信息科技有限公司",
        // },
        // {
        //   id: 16,
        //   name: "广州盖特软件有限公司",
        // },
      ],
      importCompanyName: "",
      tractionUnitList: [],
      govement_user_id: "",
    };
  },
  computed: {
    listType() {
      return Number(this.$route.query.type);
    },
    companyDigitalizeId() {
      return Number(this.$route.query.id);
    },
  },
  created() {
    this.getInfoById();
    this.getTractionUnit1();
    this.getTractionUnit2();
  },
  methods: {
    getTractionUnit2() {
      getTractionUnit(2).then((res) => {
        console.log("res getTractionUnit", res);
        if (res.code == 200) {
          this.tractionUnitList = res.data.digitalTractionUnitTypeDtoList;
        }
      });
    },
    getTractionUnit1() {
      getTractionUnit(1).then((res) => {
        console.log("res getTractionUnit", res);
        if (res.code == 200) {
          this.importCompanyList = res.data.selectRespList;
        }
      });
    },
    getInfoById() {
      getCompanyDigitalizeById({ id: this.companyDigitalizeId }).then((res) => {
        if (res.code == 1) {
          this.zxsq_checkList = JSON.parse(
            res.data.company_digitalize_agree.urgent_needs
          );
          this.importCompanyName =
            res.data.company_digitalize_agree_select.govement_select;
          this.ruleForm = res.data;
          this.ruleForm.company_digitalize_agree.business_scenarios = JSON.parse(
            this.ruleForm.company_digitalize_agree.business_scenarios
          );
          this.ruleForm.company_digitalize_agree_select.company = this.ruleForm.company_digitalize_agree_select.company.split(
            ","
          );
          this.ruleForm.company_digitalize_agree_select.audit_reason = res.data
            .company_digitalize_agree_select.audit_reason
            ? res.data.company_digitalize_agree_select.audit_reason
            : "";
          // console.log(this.ruleForm, "this.ruleForm");

          // console.log(
          //   "this.ruleForm.company_digitalize_assess.assess_file",
          //   JSON.parse(
          //     JSON.parse(this.ruleForm.company_digitalize_assess.assess_file)
          //   )
          // );
        }
      });
    },
    gotoIndex() {
      this.$router.push({
        path: "/government/sbzj",
      });
    },
    gotoList() {
      this.$router.push({
        path: "/government/sbzj/listSecond",
        query: {
          type: this.listType,
        },
      });
    },
    handleImportCompanyChange(value) {
      this.importCompanyList.forEach((item) => {
        if (item.label == value) {
          this.govement_user_id = item.value;
        }
      });
    },
    beforeSubmit() {
      let data = {
        audit_status: this.ruleForm.company_digitalize_agree_select
          .audit_status, //审核状态 1：待审核 2：审核通过 3：审核不通过
        audit_reason: this.ruleForm.company_digitalize_agree_select
          .audit_reason, //审核不通过原因
        govement_select: this.importCompanyName,
        govement_user_id: this.govement_user_id,
      };
      // console.log(data, "data-===");

      govementUpdateCompany({
        id: this.ruleForm.id, //该条产品申报id
        company_digitalize_agree_select: JSON.stringify(data),
      }).then((res) => {
        // console.log(res);
        if (res.code == 1) {
          this.$message.success("操作成功");
          setTimeout(() => {
            this.gotoList();
          }, 1000);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #eff4fe;

  .same-container {
    width: 100%;
    height: 100%;
    background: #f0f5ff;
    background-size: 1920px 1000px;
    background-attachment: fixed;

    .size-container {
      width: 82%;
      height: 100%;
      margin: 0 auto;
      min-height: 700px;

      .public-container {
        overflow: hidden;
        position: relative;
        width: 100%;
        min-width: 1200px;
        margin: auto;
        padding: 10px;
        box-sizing: border-box;

        .top-nav {
          margin-bottom: 30px;
        }

        // .routerPath {
        //   a {
        //     font-size: 14px;
        //     font-family: Microsoft YaHei;
        //     font-weight: 400;
        //     color: #333333;
        //     text-decoration: none;
        //   }
        //   span {
        //     font-size: 14px;
        //     font-family: Microsoft YaHei;
        //     font-weight: 400;
        //     color: #333333;
        //     cursor: default;
        //   }
        // }

        .form-container {
          width: 100%;
          padding: 40px 200px;
          background-color: #fff;
          border-radius: 10px;
          box-sizing: border-box;
          margin-top: 30px;

          .form-title-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .form-title {
              font-family: Microsoft YaHei;
              font-size: 24px;
              font-weight: bold;
              line-height: normal;
              text-align: center;
              color: #333333;

              margin: 0;
              margin-bottom: 10px;
            }
            .line {
              width: 150px;
              height: 4px;
              border-radius: 2px;
              background: #205dc3;
            }
          }

          h5.subheadings {
            font-family: Microsoft YaHei;
            font-size: 22px;
            font-weight: bold;
            font-variation-settings: "opsz" auto;
            color: #333333;

            margin-top: 80px;
            margin-bottom: 40px;
          }
          .ruleForm {
            /deep/.el-radio {
              // margin-bottom: 10px;
              padding-top: 10px;
            }

            .sf-content {
              display: flex;
              align-items: center;
              span {
                font-family: Microsoft YaHei;
                font-size: 16px;
                line-height: 18px;
                color: #666666;

                margin-left: 50px;
              }
            }

            .zmcl-content {
              .zmcl-upload {
                display: flex;
                justify-content: space-between;
                align-items: center;

                width: 100%;
                // height: 100px;
                padding: 15px;
                box-sizing: border-box;
                border-radius: 5px;
                border: 1px solid #dcdfe6;
                margin-top: 20px;

                .zmcl-content-left {
                  width: 70%;

                  font-family: Microsoft YaHei;
                  font-size: 16px;
                  line-height: 26px;
                  color: #2282f9;
                }
              }
            }

            .sppgfj-upload {
              display: flex;
              // justify-content: space-between;
              align-items: center;

              width: 100%;
              // height: 100px;
              padding: 15px;
              box-sizing: border-box;
              border-radius: 5px;
              border: 1px solid #dcdfe6;
              margin-top: 20px;

              .sppgfj-content-left {
                width: 70%;

                font-family: Microsoft YaHei;
                font-size: 16px;
                line-height: 26px;
                color: #2282f9;
              }
            }

            .radio-content {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              .other {
                margin-left: -20px;
                border-bottom: 1px solid #dcdfe6;

                /deep/.el-input__inner {
                  border: none;
                }
              }
            }

            .qyms-radio-group {
              display: flex;
              flex-direction: column;

              /deep/.qyms-radio-option {
                display: flex;
                // align-items: center;
                margin-bottom: 30px;
                .el-radio__label {
                  display: inline-block;
                  display: flex;
                  flex-direction: column;
                  .qyms-description {
                    // display: inline-block;
                    width: 100%;
                    // height: 100px;
                    margin-top: 10px;

                    font-family: Microsoft YaHei;
                    font-size: 14px;
                    line-height: 26px;
                    color: #666666;
                    text-wrap: wrap;
                  }
                }
              }
            }

            .checkbox-content {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              .other {
                // margin-left: -20px;
                border-bottom: 1px solid #dcdfe6;

                /deep/.el-input__inner {
                  border: none;
                }
              }
            }

            .qydw-content {
              .qydw-radio {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;

                margin-left: 30px;

                .qydw-item {
                  width: 350px;
                  display: flex;
                  flex-direction: column;
                  margin-right: 30px;

                  .qydw-item-title {
                    font-family: Microsoft YaHei;
                    font-size: 14px;
                    font-weight: bold;
                    font-variation-settings: "opsz" auto;
                    color: #333333;

                    margin-bottom: 30px;
                  }

                  .qydw-radio-option {
                    margin-bottom: 30px;
                  }
                }
              }
            }

            .cancel-btn {
              margin-top: 150px;
            }

            .form-subtitle2 {
              font-size: 18px;
              margin-top: 50px;
            }
            .auditStatus-container {
              // margin-top: 30px;
              .auditStatus-title {
                margin-top: 30px;
                margin-bottom: 10px;

                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
