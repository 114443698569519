import api from "@/api/index";
import api2 from "@/api/index2";

// 获取四化改造列表
export function GetXxtb_list(data) {
  return api.post("/index/company/xxtb", { data });
}
// 新增修改四化改造列表
export function Get_Post_ssbt(data) {
  return api.post("/index/company/xxtbSave", { data });
}
// 获取四化改造详情
export function Get_ssbt_detail(data) {
  return api.post("/index/company/get_edit", { data });
}
// 平台商待我审批列表
export function Get_companyApplyCheckList(data) {
  return api.post("/index/company/companyApplyCheckList", { data });
}
// 平台商待我审批拒绝以及通过
export function Checkout_companyCheck(data) {
  return api.post("/index/company/companyApplyCheck", { data });
}
// 平台商四化诊断主数据 - 首页的列表数据
export function Get_dia_list(data) {
  return api.post("/index/company/diagnose", { data });
}
// 平台商四化诊断主数据详情
export function get_diagnose_detail(data) {
  return api.post("/index/company/get_diagnose_edit", { data });
}
// 平台商四化诊断主数据详情编辑流程一
export function Get_companyedit_one(data) {
  return api.post("/index/Diagnose/edit", { data });
}

// 平台商四化诊断主数据详情编辑流程三
export function Get_poststep2(data) {
  return api.post("/index/Diagnose/step2", { data });
}
// 平台商四化诊断主数据详情编辑流程四哦
export function Get_poststep3(data) {
  return api.post("/index/Diagnose/step3", { data });
}
// 平台商四化诊断主数据详人员获取
export function GetFilingPersonnel(data) {
  return api.post("/index/Diagnose/getFilingPersonnel", { data });
}

// 平台商四化诊断问卷结果
export function GetWjDiagnoseRes(data) {
  return api.post("/index/diagnose/getWjDiagnoseRes", { data });
}

// 平台商四化诊断 流程二
export function getStep2(data) {
  return api.post("/index/company/get_wj", { data });
}
// 平台商四化诊断问卷结果保存
export function PostchangeExamScore(data) {
  return api.post("/index/diagnose/changeExamScore", { data });
}
// 平台商四化诊断录入备案企业列表
export function SearchShowCompany(data) {
  return api.post("/index/company/searchShowCompany", { data });
}
// 平台商四化诊断新增编辑录入备案企业列表
export function Add_edit_list(url, data) {
  return api.post(url, { data });
}
// 平台商四化诊断查看企业备案表 - 弹窗的列表数据
export function Get_companyApplyList(data) {
  return api.post("/index/Diagnose/companyApplyList", { data });
}
// 平台商四化诊断查看企业备案表详情
export function Get_companyApplyInfo(data) {
  return api.post("/index/Diagnose/companyApplyInfo", { data });
}

// 平台商四化诊断录入 - 获取企业列表2
export function getMyCompany2(data) {
  return api.post("/index/Diagnose/getCompany2", { data });
}

// 平台商四化诊断问卷 - 获取问卷
export function getDiagnose_Wj(data) {
  return api.post("/index/diagnose/get_examine_wj", { data });
}

// 平台商四化诊断问卷 - 提交问卷
export function setDiagnose_Wj(data) {
  return api.post("/index/diagnose/set_examine_wj", { data });
}

// 平台商四化诊断问卷 - 获取我录入的企业
export function getMyCompany(data) {
  return api.post("/index/diagnose/getMyCompany", { data });
}

// 获取企业信息
export function getCompanyInfo() {
  return api.post("/index/company/companyInfoEdit");
}

// 编辑企业信息
export function editCompanyInfo(data) {
  return api.post("/index/company/companyInfoSave", { data });
}

// 上传人员备案文件
export function UploadPerson(data) {
  return api.post("/index/company/importPersonnel", { data });
}

// 获取经典案例列表
export function getClassicCaseList(data) {
  return api.post("/index/company/classicalCase", { data });
}

// 获取已发布经典案例数量
export function getClassicCaseCount() {
  return api.post("/index/company/getCount");
}

// 获取经典案例详情
export function getClassicCaseDetail(data) {
  return api.post("/index/company/classicalCaseDetail", { data });
}

// 修改/添加经典案例
export function updateClassicCase(data) {
  return api.post("/index/company/classicalCaseAdd", { data });
}

// 获取需求信息列表
export function getNeedInfoList(data) {
  return api.post("/index/company/needInfo", { data });
}

// 获取需求详情
export function getNeedInfoDetail(data) {
  return api.post("/index/company/needInfoDetail", { data });
}

// 获取产品发布列表
export function getProductList(data) {
  return api.post("/index/company/index", { data });
}

// 获取产品发布详情
export function getProductDetail(data) {
  return api.post("/index/company/details", { data });
}

// 修改产品
export function updateProduct(data) {
  return api.post("/index/company/edit", { data });
}

// 添加产品
export function addProduct(data) {
  return api.post("/index/company/add", { data });
}

// 获取解决方案列表
export function getSolutionList(data) {
  return api.post("/index/company/solution_list", { data });
}

// 新增解决方案
export function addSolution(data) {
  return api.post("/index/company/solution_add", { data });
}

// 编辑解决方案
export function editSolution(data) {
  return api.post("/index/company/solution_edit", { data });
}

// 获取解决方案详情
export function getSolution(data) {
  return api.post("/index/company/solution_details", { data });
}

// 获取经典案例数据
export function getAnLiList(data) {
  return api.post("/index/company/classicalCase", { data });
}

// 保存草稿
export function saveDraft(data) {
  return api.post("/index/company/saveDraft", { data });
}

// 获取草稿
export function getDraft(data) {
  return api.post("/index/company/getDraft", { data });
}

// 平台商导出四化诊断流程
export function exportDiagnose() {
  return api.download("/index/company/exportStep");
}

// 平台商企业产品申报接口
export function productDeclaration(data) {
  return api2.post("/api/productDeclaration/addOrUpdateDeclaration", { data });
}

// 平台商企业产品申报详情接口
export function productDeclarationDetail(data) {
  return api.post("/index/Declaration/getInfo", { data });
}

// 平台商企业改造意向摸查表填写
export function searchTableAdd(data) {
  return api.post("/index/CompanyDigitalize/addData", { data });
}

// 平台商企业改造意向摸查表详情
export function searchTableDetail() {
  return api.post("/index/CompanyDigitalize/getInfo");
}

// 平台商产品申报列表接口
export function declarationIndexList(data) {
  return api.post("/index/Declaration/declarationIndex", { data });
}

// 产品下拉接口
export function drowproductList() {
  return api.post("/index/Declaration/productList", { data: { type: 2 } });
}

// 牵引单位下拉列表
export function getTractionUnit(type) {
  return api2.get("/api/digitalTractionInfo/getTractionUnit", {
    params: { type },
  });
}

// 新增或编辑产业生态联合体企业备案申请
export function addOrUpdateEcological(data) {
  return api2.post("/api/ecological/addOrUpdateEcological", {
    data,
  });
}

// 获取企业名称列表
export function getCompanyList(data) {
  return api2.post("/api/ecological/getCompanyList", {
    data,
  });
}

// 分页查询产业生态联合体企业备案申请
export function getEcologicalPage(data) {
  return api2.post("/api/ecological/getEcologicalPage", {
    data,
  });
}

// 查询产业生态联合体企业备案申请详情
export function getEcologicalDetail(data) {
  return api2.post("/api/ecological/getDetail", {
    data,
  });
}

// 导出产业生态联合体企业备案申请
export function getEcologicalExportl(data) {
  return api2.post("/api/ecological/export", {
    data,
    responseType: "blob",
  });
}
