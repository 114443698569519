<template>
  <div class="list3-main">
    <div class="list3-content">
      <div class="top-nav">
        <el-breadcrumb separator=">">
          <el-breadcrumb-item
            :to="{
              path: '/company/sbzj',
            }"
            >申报征集</el-breadcrumb-item
          >
          <el-breadcrumb-item>产业生态联合体企业备案申请</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <div class="list3-main-content">
        <div class="flex vertical bewteen">
          <el-tabs
            v-model="activeName"
            @tab-click="handleClickTabs"
            style="flex: 1;"
          >
            <el-tab-pane label="已审批" name="ysp"></el-tab-pane>
            <el-tab-pane label="待审批" name="dsp"></el-tab-pane>
          </el-tabs>
          <el-button
            @click="handleExport"
            type="primary"
            style="margin-left: 10px;"
            >导出</el-button
          >
        </div>
        <div>
          <el-form :inline="true" :model="searchForm" class="demo-form-inline">
            <el-form-item label="单位名称">
              <el-input
                v-model="searchForm.pullName"
                placeholder="请输入单位名称"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="getList">查询</el-button>
            </el-form-item>
          </el-form>
        </div>
        <el-table
          :data="tableData"
          style="width: 100%"
          border
          :header-cell-style="getTableRowStyle"
          :cell-style="getTableCellStyle"
        >
          <el-table-column type="index" label="序号" width="58">
          </el-table-column>
          <el-table-column prop="pullUnit" label="单位名称"> </el-table-column>
          <el-table-column prop="creditCode" label="统一信用代码">
          </el-table-column>
          <el-table-column prop="serviceActor" label="生态角色">
          </el-table-column>
          <el-table-column prop="masterService" label="主要产品/服务">
          </el-table-column>
          <el-table-column
            prop="comment"
            label="单位简介"
            width="188"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column prop="auditStatus" label="审核状态">
            <template slot-scope="scope">
              <span style="color: #2282F9;" v-if="scope.row.auditStatus == 1"
                >待审核</span
              >
              <span style="color: #349827;" v-if="scope.row.auditStatus == 2"
                >审核通过</span
              >
              <span style="color: #FF0000;" v-if="scope.row.auditStatus == 3"
                >审核拒绝</span
              >
            </template>
          </el-table-column>
          <el-table-column prop="address" label="操作" width="108">
            <template slot-scope="scope">
              <el-button type="text" @click="beforeEdit(scope.row)"
                >编辑</el-button
              >
              <el-button type="text" @click="handleDetail(scope.row)"
                >详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- 分页 -->
      <el-pagination
        class="pagination"
        :total="total"
        :current-page="page"
        :layout="'prev, pager, next'"
        @current-change="handlePageChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
// import { getDeclarationList } from "@/api/zf";
import {
  declarationIndexList,
  getEcologicalPage,
  getEcologicalExportl,
} from "@/api/pts";
import { formatDateTime } from "@/utils/common";
export default {
  data() {
    return {
      tableData: [],

      // 分页
      total: 0,
      page: 1,

      activeName: "ysp",
      searchForm: {
        pullName: "",
      },
    };
  },
  created() {
    this.getList();
  },
  computed: {},
  methods: {
    handleExport() {
      // let pdata = {
      //   auditStatus: this.activeName == "ysp" ? 2 : 1,
      // };
      getEcologicalExportl({
        userId: JSON.parse(sessionStorage.getItem("ptsInfo")).user_id,
      }).then((res) => {
        var blob = new Blob([res], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
        });
        var url = URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = `产业生态联合体企业备案申请${new Date().toLocaleString()}.xls`; // 设置下载文件名
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        // 清理 Blob 对象
        URL.revokeObjectURL(url);
      });
    },
    handleDetail(row) {
      this.$router.push({
        name: "SthzhbForm",
        // type = 1为申报
        // type = 2为查看
        query: {
          type: 2,
          id: row.id,
        },
      });
    },
    beforeEdit(row) {
      this.$router.push({
        name: "SthzhbForm",
        // type = 1为申报
        // type = 2为查看
        query: {
          type: 1,
          id: row.id,
        },
      });
    },
    handleClickTabs(val) {
      this.getList();
    },
    formatDateTime(time) {
      return formatDateTime(time);
    },
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    getList() {
      getEcologicalPage({
        pageNum: this.page,
        pageSize: 10,
        userId: JSON.parse(sessionStorage.getItem("ptsInfo")).user_id,
        auditStatus: this.activeName == "dsp" ? 1 : null,
        ...this.searchForm,
      }).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    getTableRowStyle() {
      return "background-color: #eef2ff;text-align: center;";
    },
    getTableCellStyle() {
      return "text-align: center;";
    },
  },
};
</script>

<style lang="less" scoped>
.list3-main {
  background-color: #eff4fe;
  width: 100%;
  min-height: calc(100vh -408px);
  height: fit-content;

  .list3-content {
    width: 82%;
    height: 100%;
    margin: 0 auto;
    min-height: 700px;

    .top-nav {
      margin-bottom: 30px;
    }
    .list3-main-content {
      background-color: #fff;
      height: 500px;

      border-radius: 10px;
      padding: 10px 18px;
    }
    .el-pagination {
      width: 100%;
      margin: 10px 0 0;
      ::v-deep .el-pager li,
      ::v-deep .btn-prev,
      ::v-deep .btn-next {
        background-color: transparent;
        padding: 0;
        margin: 0 4px;
        min-width: 32px;
        width: 32px;
        height: 32px;
        line-height: 32px;
      }
      ::v-deep .el-pager li:hover {
        background: #e3ecfc;
        color: #205dc3;
        border-radius: 4px;
      }
      ::v-deep .el-pager li.active {
        background: linear-gradient(to bottom, #145dff, #0699fd);
        // padding: 0;
        // margin: 0 4px;
        // min-width: 32px;
        // width: 32px;
        // height: 32px;
        // line-height: 32px;
        color: #fff;
        border-radius: 4px;
      }
    }
  }
}
::v-deep .el-tabs__item.is-active {
  color: #205dc3;
  font-size: 18px;
}
::v-deep .el-tabs__item {
  font-size: 18px;
  color: #666666;
  margin-bottom: 10px;
}
::v-deep .el-tabs__item:hover {
  color: #205dc3;
}
::v-deep .el-tabs .el-tabs__header .el-tabs__active-bar {
  width: 78px !important;
  height: 5px !important;
  border-radius: 5px !important;
}
</style>
